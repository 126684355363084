import {
  ConfirmWeeklyCoachingCheckoutResult,
  LanguagePairCode,
} from "@fluent-forever/types";
import { ChargeBee, hideChargebeeModal } from "../api/ChargeBee";
import { store } from "../store/configureStore";
import { clearUserMessages } from "./operation";
import { fetchWeeklyCoachingPlans } from "./plans";

export const subscribeToWeeklyCoaching = ({
  planId,
  langPairCode,
}: {
  planId: string;
  langPairCode: LanguagePairCode;
}): Promise<ConfirmWeeklyCoachingCheckoutResult> => {
  clearUserMessages(store.dispatch);
  try {
    const plan = fetchWeeklyCoachingPlans().find((plan) => plan.id === planId);
    if (plan === undefined) throw "No plan found.";
    return ChargeBee.subscribeWeeklyCoaching({
      langPairCode,
      planId,
    });
  } catch (err) {
    hideChargebeeModal();
    throw err;
  }
};
