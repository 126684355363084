import {
  basePrice,
  intensePrice,
  privacyPrice,
} from "../constants/bootcamp_constants";

export const calculateBootcampPrice = ({
  isIntense,
  isPrivate,
  isSixBootcamps,
}: {
  isIntense: boolean;
  isPrivate: boolean;
  isSixBootcamps: boolean;
}): number =>
  (basePrice +
    (isIntense ? intensePrice : 0) +
    (isPrivate ? privacyPrice : 0)) *
  (isSixBootcamps ? 6 : 1) *
  (isSixBootcamps ? 0.9 : 1);
