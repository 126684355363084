import {
  isLanguagePairCode,
  LanguagePairCode,
  noRenderNull,
} from "@fluent-forever/types";
import { getTypedKeys } from "@fluent-forever/utils";
import { useMachine } from "@xstate/react";
import React, { useEffect } from "react";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Loader,
  Radio,
  Segment,
  Select,
} from "semantic-ui-react";
import { fetchWeeklyCoachingPlans } from "../../../application/plans";
import { languagePairNamesWithVariant } from "../../bootcamp/constants/language";
import { weeklyCoachingSignupMachine } from "../machines/weekly_coaching_signup_machine";

interface WeeklyCoachingLandingQueryParams {
  langPairCode?: LanguagePairCode;
  logIn?: true;
}

const parseQueryParams = (query: string): WeeklyCoachingLandingQueryParams => {
  const searchParams = new URLSearchParams(query);
  const langPairCode = searchParams.get("langPairCode");
  const logIn = searchParams.get("logIn");
  return {
    langPairCode: isLanguagePairCode(langPairCode) ? langPairCode : undefined,
    logIn: logIn === "true" ? true : undefined,
  };
};

export const WeeklyCoachingLanding = (): React.ReactElement => {
  const [machine, send] = useMachine(weeklyCoachingSignupMachine);
  const queryValues = parseQueryParams(location.search);

  const { context, value: currentState } = machine;
  const {
    availableLanguagePlans,
    errorMessage,
    selectedLanguage,
    selectedPlan,
  } = context;

  const selectedLanguagePlans = selectedLanguage
    ? availableLanguagePlans?.[selectedLanguage]
    : undefined;

  useEffect(() => {
    send({ send, type: "INITIALIZE" });
  }, []);

  useEffect(() => {
    if (currentState === "idle") send({ all: true, type: "FETCH_PLANS" });
    if (currentState === "dataEntry" && queryValues.langPairCode) {
      send({
        type: "UPDATE_SELECTED_LANGUAGE",
        value: queryValues.langPairCode,
      });
    }
  }, [currentState]);

  switch (currentState) {
    case "fetchingPlans":
      return (
        <Container>
          <Segment padded="very">
            <Dimmer active inverted>
              <Loader size="big" />
            </Dimmer>
          </Segment>
        </Container>
      );
    case "dataEntry":
      return (
        <Container>
          <Segment basic padded="very">
            <Segment padded="very">
              <Form>
                <Header>
                  Hey!! Bla bla bla, weekly coaching is pretty cool!! Wanna try
                  some?
                </Header>
                {availableLanguagePlans ? (
                  <Form.Field>
                    <Label pointing="below">Select Language</Label>
                    <Select
                      onChange={(_, data) =>
                        send("UPDATE_SELECTED_LANGUAGE", { value: data.value })
                      }
                      options={getTypedKeys(availableLanguagePlans).map(
                        (langPairCode) => ({
                          key: langPairCode,
                          text: languagePairNamesWithVariant[langPairCode],
                          value: langPairCode,
                        })
                      )}
                      value={selectedLanguage}
                    />
                  </Form.Field>
                ) : (
                  noRenderNull
                )}
                {selectedLanguagePlans && selectedLanguagePlans.length > 1
                  ? selectedLanguagePlans.map((planId) => {
                      const plan = fetchWeeklyCoachingPlans().find(
                        (plan) => plan.id === planId
                      );
                      return plan ? (
                        <Form.Field>
                          <Radio
                            checked={selectedPlan === plan.id}
                            label={plan.name}
                            onChange={(_, data) =>
                              send("UPDATE_SELECTED_PLAN", {
                                value: data.value?.toString(),
                              })
                            }
                            value={plan.id}
                          />
                        </Form.Field>
                      ) : (
                        noRenderNull
                      );
                    })
                  : noRenderNull}
                <Button
                  animated="vertical"
                  onClick={() =>
                    send({
                      logInOnly: queryValues.logIn,
                      type: "START_LANDING_CHECKOUT",
                    })
                  }
                  primary
                  size="big"
                >
                  <Button.Content visible>
                    {queryValues.logIn ? "Log in" : "Subscribe now"}
                  </Button.Content>
                  <Button.Content className={"centered"} hidden>
                    <Icon name="arrow right" />
                  </Button.Content>
                </Button>
              </Form>
            </Segment>
          </Segment>
        </Container>
      );
    case "fatal_error":
      return (
        <Container>
          <Segment padded="very">
            <Header>{errorMessage}</Header>
            <Grid>
              <Grid.Column textAlign="center">
                <Button
                  animated="vertical"
                  onClick={() => send("FETCH_PLANS")}
                  primary
                  size="big"
                >
                  <Button.Content visible>{"Retry"}</Button.Content>
                  <Button.Content className={"centered"} hidden>
                    <Icon name="redo" />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      );
    default:
      return <React.Fragment />;
  }
};
