/* eslint-disable react/prop-types */
import * as React from "react";
import { Container, Header, List } from "semantic-ui-react";

interface ITermChapter {
  title?: string;
  subchapters?: boolean;
}

const Chapter: React.SFC<ITermChapter> = (props) => {
  const children = props.subchapters ? (
    <List.List>{props.children}</List.List>
  ) : (
    props.children
  );
  return (
    <List.Item>
      <Container text>
        <Header>{props.title}</Header> {children}
      </Container>{" "}
    </List.Item>
  );
};

export const TermsOfService: React.SFC = () => {
  return (
    <Container>
      <Header>Terms Of Service</Header>
      <List ordered>
        <Chapter title="General.">
          Fluent Forever websites (“Websites”) and mobile applications (“Apps”)
          and related services (together with the Websites, the “Product”), are
          operated by Fluent Forever Inc. (“Fluent Forever,” “us,” or “we”).
          Access and use of the Product is subject to the following Terms of
          Service (“Terms of Service”). By accessing or using any part of the
          Product, you represent that you have read, understood, and agree to be
          bound by these Terms of Service including any future modifications.
          Fluent Forever may amend, update or change these Terms of Service. If
          you do not agree to abide by these Terms of Service, you are not
          authorized to use, access or participate in the Product. PLEASE NOTE
          THAT THESE TERMS OF SERVICE CONTAIN A MANDATORY ARBITRATION OF
          DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN CIRCUMSTANCES, RATHER
          THAN JURY TRIALS OR CLASS ACTION LAWSUITS. VIEW THESE TERMS HERE.
        </Chapter>
        <Chapter title="The Product.">
          The Product allows users to access and use a variety of educational
          services, including learning or practicing a language, and creating
          and viewing flashcards on the App. Fluent Forever may, in its sole
          discretion and at any time, update, change, suspend, make improvements
          to or discontinue any aspect of the Product, temporarily or
          permanently.
        </Chapter>
        <Chapter title="Registration and Submission of Content.">
          In connection with registering for and using the Product, you agree
          (i) to provide accurate, current and complete information about you
          and/or your organization as requested by Fluent Forever; (ii) to
          maintain the confidentiality of your password and other information
          related to the security of your account; (iii) to maintain and
          promptly update any registration information you provide to Fluent
          Forever, to keep such information accurate, current and complete; and
          (iv) to be fully responsible for all use of your account and for any
          actions that take place through your account.
        </Chapter>
        <Chapter title="User’s Representations and Warranties.">
          You represent and warrant to Fluent Forever that your access and use
          of the Product will be in accordance with these Terms of Service and
          with all applicable laws, rules and regulations of the United States
          and any other relevant jurisdiction, including those regarding online
          conduct or acceptable content, and those regarding the transmission of
          data or information exported from the United States and/or the
          jurisdiction in which you reside. You further represent and warrant
          that you have created or own any material you submit via the Product
          and that you have the right, as applicable, to grant us a license to
          use that material as set forth above or the right to assign that
          material to us as set forth below. You represent and warrant that (1)
          you are not organized under the laws of, operating from, or otherwise
          ordinarily resident in a country or territory that is the target or
          comprehensive U.S. economic or trade sanctions (i.e., an embargo) or
          (2) identified on a list of prohibited or restricted persons, such as
          the U.S. Treasury Department’s List of Specially Designated Nationals
          and Blocked Persons, or (3) otherwise the target of U.S. sanctions.
        </Chapter>
        <Chapter subchapters title="Inappropriate Use.">
          You will not upload, display or otherwise provide on or through the
          Product any content that:
          <Chapter>
            infringes any patent, trademark, trade secret, copyright, right of
            publicity, right of privacy or other right of any other person or
            entity;
          </Chapter>
          <Chapter>
            is unlawful, harmful, fraudulent, deceptive, threatening, abusive,
            harassing, tortious, defamatory, vulgar, obscene, libelous, or
            otherwise objectionable;
          </Chapter>
          <Chapter>impersonates any person or entity;</Chapter>
          <Chapter>
            constitutes unauthorized or unsolicited advertising, junk or bulk
            e-mail (“spamming”);
          </Chapter>
          <Chapter>
            contains a virus, trojan horse, worm, time bomb, or other harmful
            computer code, file, or program, uses any manual or automated
            software, devices, or other processes to “crawl,” “scrape,” or
            “spider” any part of the Product;
          </Chapter>
          <Chapter>
            attempts to decompile, reverse engineer, or otherwise attempt to
            obtain the source code of the Product;
          </Chapter>
          <Chapter>
            take any action that imposes or may impose (as determined by us in
            our sole discretion) an unreasonable or disproportionately large
            load on our (or our third-party providers’) infrastructure;
          </Chapter>
          <Chapter>
            interfere or attempt to interfere with the security or proper
            working of the Product; or
          </Chapter>
          <Chapter>
            otherwise take any action in violation of our guidelines and
            policies.
          </Chapter>
        </Chapter>
        <Chapter title="Indemnification of Fluent Forever.">
          You agree to defend, indemnify and hold harmless Fluent Forever and
          its directors, officers, employees, contractors, agents, suppliers,
          licensors, successors and assigns, from and against any and all
          losses, claims, causes of action, obligations, liabilities and damages
          whatsoever, including attorneys’ fees, arising out of or relating to
          your access or use of the Product, any false representation made to us
          (as part of these Terms of Service or otherwise), your breach of any
          of these Terms of Service, or any claim that any translation we
          provide to you is inaccurate, inappropriate or defective in any way
          whatsoever.
        </Chapter>
        <Chapter title="License.">
          Subject to the terms of these Terms of Service, Fluent Forever grants
          you a non-transferable, non-exclusive license to download, install,
          and use the Product in object code form only on a device that you own
          or control. Fluent Forever and its licensors own and shall retain all
          intellectual property rights and other rights in and to the Apps, and
          any changes, modifications, or corrections thereto.
        </Chapter>
        <Chapter subchapters title="Proprietary Rights.">
          <Chapter title="Ownership by Fluent Forever.">
            All content available through the Product, including designs, text,
            graphics, images, information, software, audio and other files, and
            their selection and arrangement (the “Product Content“), are the
            proprietary property of Fluent Forever or its licensors. No Product
            Content may be modified, copied, distributed, framed, reproduced,
            republished, downloaded, scraped, displayed, posted, transmitted, or
            sold in any form or by any means, in whole or in part, other than as
            expressly permitted in these Terms of Service.
          </Chapter>
          <Chapter title="User Generated Materials.">
            As between you and Fluent Forever, all data, information and
            materials generated from your access and use of the Product,
            including content generated by you (collectively, the “User
            Generated Materials”), shall be exclusively owned by Fluent Forever,
            and you shall not have any right to use such User Generated
            Materials except as expressly authorized by these Terms of Service.
            By using the Product, you hereby assign to Fluent Forever any and
            all rights, title and interest, including any intellectual property
            rights or proprietary rights, in the User Generated Materials. All
            rights of Fluent Forever or its licensors that are not expressly
            granted in these Terms of Service are reserved to Fluent Forever and
            its licensors. Notwithstanding the foregoing, Fluent Forever will
            not share your personal photographs with other users of the Product
            or in connection with third-party advertising without your prior
            consent.
          </Chapter>
          <Chapter title="Royalty Free Perpetual License.">
            To the extent rights to the User Generated Materials cannot be
            assigned to Fluent Forever, you hereby grant to Fluent Forever a
            royalty free, perpetual, irrevocable, worldwide, nonexclusive,
            transferable, and sublicensable license to use, reproduce, copy,
            adapt, modify, merge, distribute, publicly display, create
            derivative works from, incorporate such User Generated Materials
            into other works; sublicense through multiple tiers the User
            Generated Materials, and acknowledge that this license cannot be
            terminated by you once your User Generated Materials are submitted
            to the Product. You represent that you own or have secured all legal
            rights necessary for the User Generated Materials submitted by you
            to be used by you, Fluent Forever, and others as described and
            otherwise contemplated in these Terms of Service. You understand
            that other users will have access to the User Generated Materials
            and that neither they or Fluent Forever have any obligation to you
            or anyone else to maintain the confidentiality of the User Generated
            Materials.
          </Chapter>
        </Chapter>
        <Chapter title="Subscription.">
          If you purchase an auto-renewing periodic subscription through the
          Product, your Fluent Forever account will be billed continuously for
          the subscription until you terminate it. After your initial
          subscription period, and again after any subsequent subscription
          period, your subscription will automatically renew for an additional
          equivalent period. If you do not wish your subscription to renew
          automatically, or if you want to change or terminate your
          subscription, you will need to log in to your Fluent Forever account
          and follow instructions to terminate or change your subscription.
          Deleting the Product from your devices will not delete your Fluent
          Forever account and will not cancel your subscription. You agree to
          pay all fees and applicable taxes incurred by you or anyone using a
          Fluent Forever account registered to you. Fluent Forever may revise
          the pricing for the goods and services offered through the Product at
          any time. All information that you provide in connection with a
          purchase or transaction or other monetary transaction interaction with
          the Product must be accurate, complete, and current. You agree to pay
          all charges incurred by users of your credit card, debit card, or
          other payment method used in connection with a purchase or transaction
          or other monetary transaction interaction with the Product at the
          prices in effect when such charges are incurred. You will pay any
          applicable taxes, if any, relating to any such purchases, transactions
          or other monetary transaction interactions.
        </Chapter>
        <Chapter title="Processing of Payment.">
          All financial transactions made in connection with the Product will be
          processed by a third party in accordance with their respective terms
          of use, privacy policy, and/or any applicable payment terms and
          conditions. We encourage you to learn about the practices of such
          third party. In no event will Fluent Forever be responsible for the
          actions or inactions of any third party payment processor, including,
          but not limited to, system downtime or payment service outages.
        </Chapter>
        <Chapter title="Termination.">
          Fluent Forever may terminate your access and use of the Product
          immediately at any time, for any reason, and at such time you will
          have no further right to use the Product. You may terminate your
          Fluent Forever account at any time by following the instructions
          available through the Product. The provisions of these Terms of
          Service relating to the protection and enforcement of Fluent Forever’s
          proprietary rights, your representations and warranties, disclaimer of
          representations and warranties, release and indemnities, limitations
          of liability and types of damages, ownership of data and information,
          governing law and venue, and miscellaneous provisions shall survive
          any such termination.
        </Chapter>
        <Chapter title="No Refunds.">
          In the event that Fluent Forever suspends or terminates your use of
          the Product or these Terms of Service or you close your account
          voluntarily, you understand and agree that you will receive no refund
          or exchange of any kind.
        </Chapter>
        <Chapter title="Third Parties.">
          The Product may contain links to third-party websites, advertisers,
          services, special offers, or other events or activities that are not
          owned or controlled by Fluent Forever. We do not endorse or assume any
          responsibility for any such third-party sites, information, materials,
          products, or services. If you access any third-party website, service,
          or content from Fluent Forever, you understand that these Terms of
          Service and our Privacy Policy do not apply to your use of such sites.
          You expressly acknowledge and agree that Fluent Forever shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          arising from your use of any third-party website, service, or content.
          The Product may include advertisements, which may be targeted to the
          content or information on the Product, or other information. The types
          and extent of advertising by Fluent Forever on the Product are subject
          to change. In consideration for Fluent Forever granting you access to
          and use of the Product, you agree that Fluent Forever and its
          third-party providers and partners may place such advertising in
          connection with the display of content or information submitted by you
          or others.
        </Chapter>
        <Chapter title="No Representations or Warranties by Fluent Forever.">
          The Product, including all images, audio, and other content therein,
          and any other information, property and rights granted or provided to
          you by Fluent Forever are provided to you on an “as-is” basis. Fluent
          Forever and its suppliers make no representations or warranties of any
          kind with respect to the Product, either express or implied, and all
          such representations and warranties, including warranties of
          merchantability, fitness for a particular purpose or noninfringement,
          are expressly disclaimed. Fluent Forever makes no warranty or
          representation that the content accessed, displayed, or used in
          connection with the Product will be appropriate for all users. Without
          limiting the generality of the foregoing, Fluent Forever does not make
          any representation or warranty of any kind relating to accuracy,
          service availability, completeness, informational content, error-free
          operation, results to be obtained from use, or non-infringement.
          Access and use of the Product may be unavailable during periods of
          peak demand, system upgrades, malfunctions or periods of maintenance
          or for other reasons.
        </Chapter>
        <Chapter title="Limitation on Damages and Liability.">
          In no event will Fluent Forever be liable to you or any third party
          claiming through you (whether based in contract, tort, strict
          liability or other theory) for indirect, incidental, special,
          consequential or exemplary damages arising out of or relating to the
          access or use of, or the inability to access or use, the Product or
          any portion thereof, including but not limited to the loss of use of
          the Product, inaccurate results, loss of profits, business
          interruption, or damages stemming from loss or corruption of data or
          being rendered inaccurate, the cost of recovering any data, the cost
          of substitute services or claims by third parties for any damage to
          computers, software, modems, telephones or other property, even if
          Fluent Forever has been advised of the possibility of such damages.
          Fluent Forever’s liability to you or any third party claiming through
          you for any cause whatsoever, and regardless of the form of action, is
          limited to the amount paid, if any, by you to Fluent Forever in the
          twelve (12) months prior to the initial action giving rise to
          liability. This is an aggregate limit. The existence of more than one
          claim will not increase such limit.
        </Chapter>
        <Chapter title="Trademarks.">
          “Fluent Forever” and all other trademarks, service marks, graphics and
          logos used in connection with the Product are trademarks or service
          marks of Fluent Forever or their respective owners, and certain of
          them are registered with the United States Patent and Trademark
          Office. Access and use of the Product does not grant or provide you
          with the right or license to reproduce or otherwise use the Fluent
          Forever name or any Fluent Forever or third-party trademarks, service
          marks, graphics or logos.
        </Chapter>
        <Chapter title="Privacy.">
          Use of the Product is also governed by our Privacy Policy, a copy of
          which is located at [
          <a href="https://fluent-forever.com/privacy-policy/">
            https://fluent-forever.com/privacy-policy/
          </a>
          ]. By using the Product, you consent to the terms of the Privacy
          Policy.
        </Chapter>

        <Chapter title="Digital Millennium Copyright Act.">
          If you are a copyright owner and have a good faith belief that any
          material available through the Product infringes upon your copyrights,
          you may submit a copyright infringement notification to Fluent Forever
          pursuant to the Digital Millennium Copyright Act by providing us with
          the following information in writing:
          <Chapter subchapters>
            <Chapter>
              an electronic or physical signature of the copyright owner or the
              person authorized to act on behalf of the owner of the copyright
              interest;
            </Chapter>
            <Chapter>
              a description of the copyrighted work that you claim has been
              infringed;
            </Chapter>
            <Chapter>
              a description of where the material that you claim is infringing
              is located on the Product, with enough detail that we may find it
              on the Product;
            </Chapter>
            <Chapter>
              your address, telephone number, and email address;
            </Chapter>
            <Chapter>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law; and
            </Chapter>
            <Chapter>
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or are authorized to act on the copyright owner’s
              behalf. Procedure to Deliver Counter-Notice:If any user believes
              any material removed is either not infringing or that such user
              has the right to post and use such material from the copyright
              owner, the copyright owner’s agent, or pursuant to the law, the
              user must send a counter-notice containing the following
              information to the Designated Agent listed below:
              <Chapter subchapters>
                <Chapter>
                  A physical or electronic signature of the user;
                </Chapter>
                <Chapter>
                  Identification of the material that has been removed and the
                  location at which the material appeared before it was removed;
                </Chapter>
                <Chapter>
                  A statement that the user has a good faith belief that the
                  material was removed as a result of mistake or a
                  misidentification of the material; and
                </Chapter>

                <Chapter>
                  The user’s name, address, telephone number, and, if available,
                  e-mail address and a statement that such person or entity
                  consents to the jurisdiction of the Federal Court for the
                  judicial district in which the user’s address is located, or
                  if the user’s address is located outside the United States,
                  for any judicial district in which Fluent Forever is located,
                  and that such person or entity will accept service of process
                  from the person who provided notification of the alleged
                  infringement. If a counter-notice is received by the
                  Designated Agent, Fluent Forever will forward a copy of the
                  counter-notice to the original complaining party informing
                  that person that Fluent Forever may restore the removed
                  material following 10 days. The original complaining party
                  will then have 10 days to notify us that he or she has filed
                  legal action relating to the allegedly infringing material. If
                  Fluent Forever does not receive any such notification within
                  10 days, we may restore the material, at our discretion.Please
                  contact the Designated Agent to Receive Notification of
                  Claimed Infringement for Fluent Forever at: [Matthew:
                  matthew@fluent-forever.com] Please consult your legal counsel
                  for further details or see 17 U.S.C. §512(c)(3).
                </Chapter>
              </Chapter>
            </Chapter>
          </Chapter>
        </Chapter>
        <Chapter title="Governing Law; Arbitration; No Class Action.">
          These Terms of Service, its subject matter and Fluent Forever’s and
          your respective rights under these Terms of Service, as well as and
          any claim, cause of action or dispute (“claim”) arising out of or
          related to these Terms of Service, shall be governed by and construed
          under the laws of the State of Delaware, United States of America,
          excluding the conflict of law provisions of that or any other
          jurisdiction, regardless of your country of origin or where you access
          the Product. ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THESE TERMS
          OF SERVICE OR THE PRODUCT WILL BE RESOLVED BY BINDING ARBITRATION,
          RATHER THAN IN COURT, except for Fluent Forever’s right to seek
          injunctive relief as set forth below. Unless otherwise expressly
          required by applicable law, each party shall bear its own attorneys’
          fees without regard to which party is deemed the prevailing party in
          the arbitration proceeding. If you do not want to arbitrate disputes
          with Fluent Forever and you are an individual, you may opt out of this
          arbitration agreement by sending an email to [help@fluent-forever.com]
          within 30 days of the day you first access or use the Product.If you
          intend to seek arbitration you must first send written notice to
          Fluent Forever of your intent to arbitrate (“Notice”). The Notice to
          Fluent Forever should be sent by any of the following means: (i)
          electronic mail to [help@fluent-forever.com]; or (ii) sending the
          Notice by U.S. Postal Service certified mail to Fluent Forever Inc.,
          Attention: [FLUENT FOREVER INC % GABRIEL WYNER | 16192 COASTAL HWY |
          LEWES, DE 19958]. The Notice must (x) describe the nature and basis of
          the claim or dispute; and (y) set forth the specific relief sought;
          and (z) set forth your name, address and contact information. If we
          intend to seek arbitration against you, we will send any notice of
          dispute to you at the contact information we have for you. The
          arbitration will be conducted before a neutral single arbitrator in
          the Cook County, Illinois, whose decision will be final and binding,
          and the arbitral proceedings will be governed by the American
          Arbitration Association (“AAA”) under its AAA Commercial Arbitration
          Rules, Consumer Due Process Protocol, and Supplementary Procedures for
          Resolution of Consumer Related Disputes, as modified by these Terms of
          Service. The AAA’s rules are available at www.adr.org or by calling
          1-800-778-7879. All issues are for the arbitrator to decide, including
          the scope of this arbitration clause, but the arbitrator is bound by
          the terms of these Terms of Service. If you initiate arbitration, your
          arbitration fees will be limited to the filing fee set forth in the
          AAA’s Consumer Arbitration Rules. We will reimburse all other AAA
          filing, administration and arbitrator fees paid by you, unless the
          arbitrator determines that the arbitration was frivolous or brought
          for an improper purpose, in which case the payment of all such fees
          shall be governed by the AAA rules. The arbitration will be conducted
          in the English language. Judgment on the award rendered by the
          arbitrator may be entered in any court of competent jurisdiction. For
          any claim where the potential award is reasonably likely to be $10,000
          or less, either you or Fluent Forever may elect to have the dispute
          resolved through non-appearance-based arbitration. To the fullest
          extent permitted by applicable law, YOU AND FLUENT FOREVER EACH AGREE
          THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON AN
          INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE
          ACTION. If for any reason a claim proceeds in court rather than in
          arbitration, YOU AND FLUENT FOREVER EACH WAIVE ANY RIGHT TO A JURY
          TRIAL. If a court of competent jurisdiction finds the foregoing
          arbitration provisions invalid or inapplicable, you and Fluent Forever
          agree that all claims arising out of or related to these Terms of
          Service must be resolved exclusively by a state or federal court
          located in the Cook County, Illinois, and you and Fluent Forever each
          agree to submit to the exercise of personal jurisdiction of such
          courts for the purpose of litigating all such claims. Notwithstanding
          the above, you agree that Fluent Forever shall still be allowed to
          apply for and obtain injunctive remedies (or an equivalent type of
          urgent legal relief) in any jurisdiction.
        </Chapter>

        <Chapter title="Language.">
          This agreement was originally written in English (US). To the extent
          any translated version of this agreement conflicts with the English
          version, the English version controls.
        </Chapter>
        <Chapter title="COPPA Compliance.">
          To the extent that the Children’s Online Privacy Protection Act 1998
          is deemed to apply to us, the Product does not seek to collect
          personal information from children under 13. You may request access
          and/or rectification of your data or data relating to your children at
          all times via our support email address, help@fluent-forever.com.
        </Chapter>
        <Chapter title="Miscellaneous.">
          These Terms of Service constitute the entire agreement between Fluent
          Forever and you concerning the subject matter hereof. In the event
          that any of the Terms of Service are held by a court or other tribunal
          of competent jurisdiction to be unenforceable, such provisions shall
          be limited or eliminated to the minimum extent necessary so that these
          Terms of Service shall otherwise remain in full force and effect. A
          waiver by Fluent Forever or you of any provision of these Terms of
          Service or any breach thereof, in any one instance, will not waive
          such term or condition or any subsequent breach thereof. Fluent
          Forever may assign its rights or obligations under these Terms of
          Service without condition. These Terms of Service will be binding upon
          and will inure to the benefit of Fluent Forever and you, and Fluent
          Forever’s and your respective successors and permitted assigns.
        </Chapter>
      </List>
    </Container>
  );
};
