import React from "react";
import { socialIcons, SocialLink } from "../../../assets/images/social/social";
import "../styles/FooterSectionPromo.scss";

export const FooterSectionPromo = (): React.ReactElement => {
  const sections = [
    {
      links: [
        {
          name: "Get the App",
          url: "https://fluentforever.app.link/49DWKOFMgY",
        },
        {
          name: "Press Kit",
          url:
            "https://drive.google.com/drive/folders/1-AQSMz6RBQYJ5RNhDTX6CocKUIChQ--e?usp=sharing",
        },
      ],
      name: "DOWNLOAD",
    },
    {
      links: [
        { name: "Method", url: "https://fluent-forever.com/method/" },
        { name: "Book", url: "https://fluent-forever.com/book/" },
        { name: "Shop", url: "https://fluent-forever.com/shop/#" },
        { name: "Blog", url: "http://blog.fluent-forever.com/" },
      ],
      name: "LEARN",
    },
    {
      links: [
        {
          name: "Quick Start Guide",
          url: "https://fluent-forever.com/quick-start-guide",
        },
        { name: "Help Center", url: "http://help.fluent-forever.com/" },
        {
          name: "Member Community",
          url: "https://help.fluent-forever.com/hc/en-us/articles/360022439491",
        },
        {
          name: "Feature Roadmap",
          url: "http://progress.fluent-forever.com/",
        },
        {
          name: "Privacy Policy",
          url: "https://fluent-forever.com/privacy-policy/",
        },
        {
          name: "Terms of Use",
          url: "https://fluent-forever.com/terms-of-service/",
        },
      ],
      name: "SUPPORT",
    },
    {
      links: [
        { name: "Team", url: "https://fluent-forever.com/team/" },
        { name: "Press", url: "https://fluent-forever.com/press/" },
        {
          name: "Testimonials",
          url: "https://fluent-forever.com/testimonials/",
        },
        { name: "Schools", url: "https://fluent-forever.com/schools/" },
        { name: "Enterprise", url: "https://fluent-forever.com/business" },
        { name: "Careers", url: "https://fluent-forever.com/careers/" },
      ],
      name: "COMPANY",
    },
  ];

  const socialLinks: { icon: SocialLink; name: string; url: string }[] = [
    {
      icon: "facebook",
      name: "Facebook",
      url: "https://www.facebook.com/FluentForeverApp/",
    },
    {
      icon: "twitter",
      name: "Twitter",
      url: "https://twitter.com/fluent_forever",
    },
    {
      icon: "instagram",
      name: "Instagram",
      url: "https://www.instagram.com/fluentforeverapp",
    },
    {
      icon: "youtube",
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCQAUWk_yGz7bk1181DrijNw",
    },
  ];

  return (
    <div className="footer-section">
      <div className="sections">
        {sections.map((section, i) => (
          <div className="link-section" key={i}>
            <span className="title">{section.name}</span>
            <div className="links">
              {section.links.map((link, i) => (
                <a className="single-link" href={link.url} key={i}>
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        ))}
        <div className="contact-section">
          <div className="newsletter-container">
            <span className="title">Subscribe to our newsletter</span>
            <div className="input-container">
              <input placeholder="your email address" type="text" />
              <div className="button">Go</div>
            </div>
          </div>
          <div className="social-container">
            <span className="title">Follow us</span>
            <div className="social-links">
              {socialLinks.map((link, i) => (
                <a className="link" href={link.url} key={i}>
                  <img src={socialIcons[link.icon]} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <span className="love">Created with ❤️ by language lovers.</span>
        <span className="copyright">
          © Fluent Forever, 2020. All rights reserved.
        </span>
      </div>
    </div>
  );
};
