/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { env } from "@fluent-forever/env";
import * as App from "application";
import { ROUTES } from "config/routes";
import { parse as parseQueryString } from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { StaticRouterProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { IRootState } from "store";
import { Pages } from "..";
import { BootcampEnrollment } from "../../../features/bootcamp/views/BootcampEnrollment";
import { BootcampForm } from "../../../features/bootcamp/views/BootcampForm";
import { WeeklyCoachingForm } from "../../../features/weekly_coaching/views/WeeklyCoachingForm";
import { WeeklyCoachingLanding } from "../../../features/weekly_coaching/views/WeeklyCoachingLanding";
import {
  AnonymousOnlyRoute,
  AuthenticationHandler,
  ProtectedRoute,
} from "../../containers";
import { OperationMessage } from "./OperationMessage";

export interface IContentViewProps extends StaticRouterProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  // isPendingEmail: boolean;
  // isHasSubscription: boolean;
}

const mapStateToProps = (state: IRootState) => ({
  // isPendingEmail: App.User.isPendingEmailConfirmation(state),
  // isHasSubscription: App.User.hasSubscriptions(state),
  isAuthenticated: App.Authentication.isAuthenticated(state),
  isLoading: App.UserInfo.isLoaded(state) === false,
});

/** Component showing application content (switching between routes/different content views) */
const ContentViewComponent: React.SFC<IContentViewProps> = (props) => {
  const { isAuthenticated, isLoading } = props;
  const loc = props.location as any;
  const queryValues = parseQueryString(loc.search);

  if (isAuthenticated && isLoading) {
    return <OperationMessage message="Loading..." />;
  }

  console.debug("Content Routing PATH:[" + loc.pathname + "]");
  console.debug(props.location);
  return (
    <Switch>
      {/* Anonymous  */}
      <AnonymousOnlyRoute
        path={ROUTES.SIGNUP}
        render={() => (
          <Pages.Landing isShowSignUp={true} queryValues={queryValues} />
        )}
      />
      <AnonymousOnlyRoute
        path={ROUTES.PROMO}
        render={() => <Pages.Promo queryValues={queryValues} />}
      />
      {env.asBoolean("REACT_APP_WEEKLY_COACHING_ENABLED", false) ? (
        <AnonymousOnlyRoute
          component={WeeklyCoachingLanding}
          path={ROUTES.WEEKLY_COACHING_LANDING}
          redirectAuthPath={ROUTES.WEEKLY_COACHING}
        />
      ) : undefined}

      {/* Protected  */}
      <ProtectedRoute
        component={Pages.PlanRenewView}
        path={ROUTES.SUBSCRIPTION_RENEW}
      />
      <ProtectedRoute
        component={Pages.PlanUpgradeView}
        path={ROUTES.SUBSCRIPTION_UPGRADE}
      />
      <ProtectedRoute
        component={Pages.AccountSettings}
        path={ROUTES.SETTINGS}
      />
      <ProtectedRoute
        component={BootcampEnrollment}
        path={ROUTES.BOOTCAMP_ENROLL}
      />
      {env.asBoolean("REACT_APP_MXU_BOOTCAMP_ENABLED", false) ? (
        <ProtectedRoute component={BootcampForm} path={ROUTES.BOOTCAMP} />
      ) : undefined}
      {env.asBoolean("REACT_APP_WEEKLY_COACHING_ENABLED", false) ? (
        <ProtectedRoute
          component={WeeklyCoachingForm}
          path={ROUTES.WEEKLY_COACHING}
        />
      ) : undefined}

      {/* Handlers/Triggers  */}
      <Route component={AuthenticationHandler} path={ROUTES.AUTHENTICATE} />
      <Route
        path={ROUTES.EMAIL_CONFIRMATION}
        render={() => <Pages.EmailConfirmation />}
      />

      {/* Developer  */}
      <Route component={Pages.TestThemeView} path="/theme" />
      <Route component={Pages.TestSettingsView} path="/testSettings" />

      {/* Anything else => Go to Landing  */}
      <Route render={() => <Redirect to={ROUTES.LANDING} />} />
    </Switch>
  );
};
const reduxConnected = connect(mapStateToProps)(ContentViewComponent);
export const ContentView = withRouter<any>(reduxConnected); //this makes sure component re-renders when route changes
