/* eslint-disable react/prop-types */
import { Models, noRenderNull } from "@fluent-forever/types";
import * as App from "application";
import * as React from "react";
import { connect } from "react-redux";
import { Divider, Header, Segment } from "semantic-ui-react";
import { IRootState } from "store";
import { ButtonLarge } from "theme";

export interface ISubscriptionItemComponentProps {
  title: string;
  bestValue: boolean;
  monthlyPrice: number;
  totalPrice: number;
  dueText: string;
  showInfo: boolean;
  info?: string;
  warning?: string;
  buttonText: string;
  hidePurchaseButton: boolean;
  onSubscribe: () => void;
  discounts?: Models.CouponDiscountType[];
}

export const SubscriptionItemComponent: React.SFC<ISubscriptionItemComponentProps> = ({
  title,
  bestValue,
  monthlyPrice,
  totalPrice,
  dueText,
  showInfo,
  info,
  onSubscribe,
  buttonText,
  warning,
  hidePurchaseButton,
  discounts,
}) => {
  const buttonClass =
    "plans__button text-medium" +
    (hidePurchaseButton ? " plans__button__hidden" : "");
  const infoTextClass =
    (showInfo && info) || warning
      ? "plans__info-text"
      : "plans__info-text-empty";
  const isDiscountApplicable = discounts && discounts.length;
  const planPriceHeaderClass = isDiscountApplicable
    ? "plans__monthly-price-header strike-through plans__text-discount-applied"
    : "plans__monthly-price-header";
  const planDueHeaderClass = isDiscountApplicable
    ? "plans__due-header strike-through plans__text-discount-applied"
    : "plans__due-header";

  return (
    <Segment className="plans__plan-segment" raised>
      {bestValue && (
        <div className="plans__best-value text-bold background-yellow color-white">
          BEST VALUE
        </div>
      )}
      <Header as="h2" className="plans__title-text">
        {title}
      </Header>
      <div className="text-small">SUBSCRIPTION</div>
      <Divider />
      {discounts && discounts.length ? (
        <Header as="h4" className="plans__monthly-price-header">
          {`Backer-only pricing: $${App.Subscriptions.getDiscountedSubscriptionPrice(
            discounts[0],
            totalPrice
          ).toFixed(2)}`}
        </Header>
      ) : // eslint-disable-next-line no-null/no-null
      null}
      <Header as="h1" className={planPriceHeaderClass}>
        ${monthlyPrice}/month
      </Header>
      <Header as="h5" className={planDueHeaderClass}>
        {`$${totalPrice} ${dueText}`}
      </Header>
      {!isDiscountApplicable ? (
        <p className={infoTextClass}>
          {showInfo ? info : noRenderNull}
          {warning ? (
            <React.Fragment>
              <br />
              {warning}
            </React.Fragment>
          ) : (
            noRenderNull
          )}
        </p>
      ) : (
        noRenderNull
      )}

      <ButtonLarge className={buttonClass} onClick={onSubscribe}>
        {buttonText}
      </ButtonLarge>
    </Segment>
  );
};

const mapStateToProps = (state: IRootState) => ({
  dueText:
    !App.Trial.hasStartedTrial(state) || App.Trial.isInTrial(state)
      ? "due at end of trial"
      : "due today",
  showInfo: !App.Trial.hasStartedTrial(state),
});

export const SubscriptionItem = connect(mapStateToProps)(
  SubscriptionItemComponent
);
