import fb from "./fb.svg";
import ig from "./ig.svg";
import tw from "./tw.svg";
import yt from "./yt.svg";

export type SocialLink = "facebook" | "instagram" | "twitter" | "youtube";

export const socialIcons: Record<SocialLink, string> = {
  facebook: fb,
  instagram: ig,
  twitter: tw,
  youtube: yt,
};
