/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { noRenderNull } from "@fluent-forever/types";
import { Disclosure } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import * as App from "application";
import { scrollToTop } from "application/navigation";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { subscribeToPlan } from "../../../../application/account";
import { HasAnotherSubscriptionResult } from "../../../../application/planSubscription";
import { fetchPlans } from "../../../../application/plans";
import TwelveMonthPlanIllustration from "../../../../assets/images/12-month-plan-illustration.svg";
import TwentyFourMonthPlanIllustration from "../../../../assets/images/24-month-plan-illustration.svg";
import SixMonthPlanIllustration from "../../../../assets/images/6-month-plan-illustration.svg";
import FooterIllustration from "../../../../assets/images/footer-illustration.svg";
// import HeaderBg from "../../../../assets/images/header-bg-svg.svg";
import MonthlyPlanIllustration from "../../../../assets/images/monthly-plan-illustration.svg";
import { ArrowUpIcon } from "../../../../assets/svgs/ArrowUpIcon";
import { CheckIcon } from "../../../../assets/svgs/CheckIcon";
import { ChevronDownIcon } from "../../../../assets/svgs/ChevronDownIcon";
import { RibbonSvg } from "../../../../assets/svgs/RibbonSvg";
import { IPlanInfoData } from "../../../../store/modules/plans";
import "../../../../styles/tailwind.css";
import { RedeemBackerCode } from "../../controls/RedeemBackerCode/RedeemBackerCode";

export interface ILandingViewProps {
  isShowLogin?: boolean;
  isShowSignUp?: boolean;
  isSubscribing?: boolean;
  queryValues?: any;
}

const features = [
  "14-Day Free Trial",
  "Secure Payment",
  "Cancel Anytime",
  "No Ads",
];

const ILLUSTRATIONS: Record<string, string> = {
  "12-Month": TwelveMonthPlanIllustration,
  "24-Month": TwentyFourMonthPlanIllustration,
  "6-Month": SixMonthPlanIllustration,
  Monthly: MonthlyPlanIllustration,
};

const NAMES: Record<string, string> = {
  "12-Month": "Fluent",
  "24-Month": "Pro",
  "6-Month": "Base",
  Monthly: "Intro",
};

const TITLES: Record<string, string> = {
  "12-Month": "12 Months",
  "24-Month": "24 Months",
  "6-Month": "6 Months",
  Monthly: "Monthly",
};

const FREQUENCY: Record<string, string> = {
  "12-Month": "every 12 months",
  "24-Month": "every 24 months",
  "6-Month": "every 6 months",
  Monthly: "monthly",
};

const DISCOUNT: Record<string, number> = {
  "12-Month": 0.3,
  "24-Month": 0.2,
  "6-Month": 0.1,
  Monthly: 0,
};

const faqs = [
  {
    answer: `Yes, you can cancel your subscription at any point in time via your account on our portal [https://fluent-forever.app/](https://fluent-forever.app/) (for subscriptions purchased directly on our website) or via the Google Play Store and iOS App Store (for subscriptions purchased via the app stores). Check out our [Cancellation Policy](https://help.fluent-forever.com/hc/en-us/articles/360030662051-What-is-the-Fluent-Forever-app-cancellation-policy-) for more information.`,
    id: 1,
    question: `Can I cancel anytime?`,
  },
  {
    answer: `Our 14-day free trial allows access to all features in the app completely free of charge. You will not be charged before the 14 days end. If you’d like not to continue with the subscription after the trial, you can [cancel your trial](https://help.fluent-forever.com/hc/en-us/articles/360037167772-How-do-I-cancel-during-the-free-trial-period-) at any point during the 14 days.`,
    id: 2,
    question: `Will I be charged before the trial ends?`,
  },
  {
    answer: `The app is only officially supported on smartphones running Android 7.0 and newer, or iOS 10 and newer. For the best experience in the app, we only recommend running it on these devices. We do not currently offer a desktop version. For more information, [check here](https://help.fluent-forever.com/hc/en-us/articles/360004642752-Will-the-app-run-only-on-mobile-phones-).`,
    id: 3,
    question: `Will the app work on Mac, PC, tablet, phone?`,
  },
  {
    answer: `We currently offer French, Spanish (Castilian), Spanish (LA), Italian, German, Dutch, Portuguese (Brazilian), Korean, Mandarin, Japanese, and Russian.`,
    id: 4,
    question: `What languages are available?`,
  },
  {
    answer: `1) Create your account on the portal and verify your email. \n2) Log in to the portal at [https://fluent-forever.app/](https://fluent-forever.app/) with your account details. \n3) Choose your plan. \n4) Go to checkout and enter the coupon code in the Edit Cart section. \n5) Confirm and pay.
    \nFor further guidance, [please see this article](https://help.fluent-forever.com/hc/en-us/articles/360036268971-How-do-I-redeem-a-coupon-code-).
    `,
    id: 5,
    question: `How to add a coupon code`,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Landing = ({
  isShowLogin,
  isShowSignUp,
  isSubscribing = false,
}: ILandingViewProps): React.ReactElement => {
  const dispatch = useDispatch();
  const checkMaintenanceMode = async () =>
    App.Application.checkMaintenanceMode(dispatch);
  const openLogin = () => App.Authentication.openLogin(dispatch);
  const openSignUp = () => App.Authentication.openSignUp(dispatch);

  const plans = fetchPlans();
  const [popularOption, setPopularOption] = useState<"12-Month" | "24-Month">(
    "12-Month"
  );
  const [selectedPlan, setSelectedPlan] = useState<IPlanInfoData | undefined>(
    isSubscribing ? plans[0] : undefined
  );

  const callOnSubscribe = ({
    forceStart = false,
    plan,
  }: {
    forceStart?: boolean;
    plan: IPlanInfoData;
  }) => {
    scrollToTop();
    return subscribeToPlan({
      discount: undefined,
      flow: "sign up",
      forceStart,
      plan,
    });
  };

  const onSubscribeWithRetry = async (plan: IPlanInfoData) => {
    const result = ((await callOnSubscribe({
      plan,
    })) as unknown) as HasAnotherSubscriptionResult;
    if (!result?.success && result?.hasAnotherSubscription) {
      setSelectedPlan(plan);
    }
  };

  React.useEffect(() => {
    const checkForMaintenanceMode = async () => {
      const isMaintenanceModeEnabled = await checkMaintenanceMode();
      if (!isMaintenanceModeEnabled) {
        if (isShowLogin === true) {
          openLogin();
        } else if (isShowSignUp === true) {
          openSignUp();
        }
      }
    };

    checkForMaintenanceMode();
  }, []);

  return (
    <div className="relative bg-white scroll-smooth">
      {/* Email Confirmation Banner */}
      {isSubscribing && (
        <aside className="h-[60px] w-full flex flex-row justify-center items-center bg-[#a2ebc6]">
          <div className="flex flex-row items-center">
            <CheckIcon />
            <p className="ml-4 text-[17px] md:text-[20px] text-shark font-circular font-medium tracking-[.2px]">
              Thank you for confirming your email!
            </p>
          </div>
        </aside>
      )}

      <div className="bg-[url('../assets/images/header-bg-svg.svg')] bg-no-repeat bg-cover">
        {/* Header and Page Header */}
        <div className="relative">
          {/* Page Hero */}
          <div className="relative max-w-2xl mx-auto py-10 md:py-24 px-4 sm:px-6 lg:max-w-7xl lg:pt-20 lg:pb-[50px] lg:px-8">
            <div className="relative">
              <h1 className="text-left md:text-center font-medium md:font-extrabold text-shark text-[38px] leading-[57px]">
                {isSubscribing
                  ? "Now let’s choose a plan that starts after your 14-day free trial ends"
                  : "Start your 14-day free trial by choosing the best plan for you"}
              </h1>
              <p className="mt-6 text-left md:text-center mx-auto max-w-4xl text-base md:text-xl text-gray-700">
                After the free trial period ends, you will be automatically
                charged the chosen plan fee. You can cancel your plan choice
                anytime.
              </p>
            </div>
          </div>

          <div className="px-6">
            <div className="max-w-4xl mx-auto grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-0">
              {features.map((feature, i, arr) => (
                <div
                  className={`p-2 sm:py-5 flex items-center sm:justify-center bg-dark-royal-blue sm:bg-pastel-green rounded-full sm:rounded-none ${
                    i === 0 && "sm:rounded-l-xl"
                  } ${i === arr.length - 1 && "sm:rounded-r-xl"}`}
                  key={feature}
                >
                  <div className="sm:hidden">
                    <CheckCircleIcon className="h-5 w-5 text-pastel-green" />
                  </div>
                  <p className="ml-1 text-white sm:text-shark sm:text-lg sm:font-medium sm:tracking-wide">
                    {feature}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <section
          aria-labelledby="pricing-heading"
          className="relative mt-16 md:mt-[120px]"
        >
          <h2 className="sr-only" id="pricing-heading">
            Pricing
          </h2>

          {/* Tiers */}
          <div className="max-w-2xl mx-auto px-4 space-y-12 sm:px-6 lg:max-w-7xl lg:space-y-0 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            {plans
              .filter((plan) => {
                if (plan.bestValue) return plan.name === popularOption;
                else return true;
              })
              .map((plan) => (
                <div
                  className={`relative flex flex-col p-8 bg-white rounded-2xl shadow-lg transform ${
                    isSubscribing && selectedPlan?.name === plan.name
                      ? "border-4 border-red-500"
                      : "border-4 border-transparent"
                  }`}
                  key={plan.name}
                >
                  {Boolean(
                    isSubscribing && selectedPlan?.name === plan.name
                  ) && <RibbonSvg className="absolute top-0 left-[15px]" />}

                  <div className="flex-1">
                    <div className="flex flex-col items-center">
                      {Boolean(plan.bestValue) && (
                        <p
                          className={`absolute top-0 left-1/2 py-1.5 px-4 rounded-bl-[10px] rounded-br-[10px] text-white text-[14px] font-bold font-circular uppercase tracking-[.5px] transform -translate-x-1/2 ${
                            popularOption === "12-Month"
                              ? "bg-red-bull"
                              : "bg-royal-blue"
                          }`}
                        >
                          {popularOption === "12-Month"
                            ? "Most popular"
                            : "Best Overall"}
                        </p>
                      )}

                      {Boolean(plan.bestValue) && (
                        <div className="mt-[20px] mx-auto flex flex-row items-center space-x-[5px]">
                          <button
                            className={`py-[3px] px-[8px] text-[15px] font-circular spacing-[.3px] rounded-full ${
                              popularOption === "12-Month"
                                ? "text-white bg-shark"
                                : "text-gray-600 bg-slate-200"
                            }`}
                            onClick={() => setPopularOption("12-Month")}
                          >
                            12 Months
                          </button>
                          <button
                            className={`py-[3px] px-[8px] text-[15px] text-white font-circular spacing-[.3px] rounded-full ${
                              popularOption === "24-Month"
                                ? "text-white bg-shark"
                                : "text-gray-600 bg-slate-200"
                            }`}
                            onClick={() => setPopularOption("24-Month")}
                          >
                            24 Months
                          </button>
                        </div>
                      )}

                      <p
                        className={`${
                          plan.bestValue ? "mt-[13px]" : "mt-0"
                        } text-center text-gun-powder font-circular`}
                      >
                        Cancel anytime
                      </p>
                    </div>

                    <h3 className="text-[20px] font-bold text-gray-900 tracking-[.5px]">
                      {NAMES[plan.name]}
                    </h3>
                    <p className="mt-2 flex text-gray-900 text-[38px] font-medium leading-[46px] tracking-[.13px]">
                      {TITLES[plan.name]}
                    </p>
                    <p className="mt-[2px] text-avocado text-[22px] font-circular font-semibold">
                      14-Day Free Trial
                    </p>
                    <p className="mt-[25px] text-shark font-bold text-xl">
                      {DISCOUNT[plan.name]
                        ? `Promo Price: $${plan.totalPrice}`
                        : `Price: $${plan.totalPrice}`}
                    </p>
                    {DISCOUNT[plan.name] ? (
                      <div className="mt-2 inline-block px-3 py-1 bg-pastel-green w-auto rounded-full">
                        <p className="text-black font-circular">
                          You Save:{" "}
                          <span className="font-semibold">
                            {DISCOUNT[plan.name] * 100}%
                          </span>
                        </p>
                      </div>
                    ) : (
                      noRenderNull
                    )}
                    <div className="mt-2 flex items-center">
                      <CalendarIcon className="w-5 h-5 text-gun-powder" />
                      <p className="ml-1 text-gun-powder font-circular font-light tracking-[.2px]">
                        Charged {FREQUENCY[plan.name]}
                      </p>
                    </div>
                  </div>

                  <div className="mt-8 flex flex-col items-center w-full">
                    <div className="h-[187px] md:h-48">
                      <img className="h-full" src={ILLUSTRATIONS[plan.name]} />
                    </div>
                    <p className="mt-2 md:mt-6 text-shark text-xl font-semibold">
                      Today&apos;s charge: $0.00
                    </p>
                    <p className="mt-0 md:mt-2 mx-auto px-2 py-1 inline-block text-shark text-center bg-gray-100 rounded-full">
                      {`$${plan.totalPrice} charged at end of trial.`}
                    </p>
                    <button
                      className={classNames(
                        "mt-3 py-3 px-6 border bg-pastel-green text-shark font-circular font-medium border-transparent rounded-full text-center uppercase shadow-lg"
                      )}
                      onClick={() => {
                        if (isSubscribing && selectedPlan) {
                          return onSubscribeWithRetry(selectedPlan);
                        }
                        return openSignUp();
                      }}
                      onMouseEnter={() => setSelectedPlan(plan)}
                    >
                      Start my free 14 days
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>

      {/* Backers Code Section */}
      {Boolean(isSubscribing) && (
        <section>
          <div className="mt-[50px]">
            <RedeemBackerCode />
          </div>
        </section>
      )}

      <div className="bg-[url('../assets/images/faq-bg-svg.svg')] bg-no-repeat bg-cover">
        {/* FAQ Section */}
        <section
          className={`mt-[40px] relative ${
            isSubscribing ? "md:mt-[66px]" : "md:mt-[0px]"
          }`}
        >
          <div className="max-w-[830px] mx-auto py-12 sm:py-16 px-[33px] md:px-0 flex flex-col items-center">
            <h2 className="hidden md:inline-block text-shark text-[40px] font-black">
              Frequently asked questions
            </h2>
            <h2 className="inline-block md:hidden text-gray-800 text-[22px] font-black">
              FAQ
            </h2>

            <div className="w-full mt-[20px] md:mt-[40px] flex flex-col space-y-[16px]">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.id}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={`w-full pl-[20px] md:pl-[60px] pr-[12px] md:pr-[25px] py-[25px] flex flex-row items-center justify-between rounded-[10px] ${
                          open ? "bg-pastel-green" : "bg-gun-powder"
                        }`}
                      >
                        <span
                          className={`text-[15px] md:text-[30px] text-left font-circular ${
                            open ? "text-shark" : "text-white"
                          }`}
                        >
                          {faq.question}
                        </span>

                        <ChevronDownIcon
                          className={`w-[20px] md:w-[34px] h-[20px] md:h-[34px] fill-pastel-green md:fill-white ${
                            open
                              ? "fill-gun-powder md:fill-gun-powder transform rotate-180"
                              : ""
                          }`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="w-full mt-[3px] px-[12px] md:px-[60px] py-[10px] bg-[#e2e8f0] rounded-bl-[10px] rounded-br-[10px]">
                        <ReactMarkdown
                          className="prose list-decimal text-[17px] md:text-[18px] font-circular leading-[30px] tracking-[.2]"
                          components={{
                            a: (props) => (
                              /* eslint-disable react/prop-types */
                              <a
                                {...props}
                                className={`${props.className} text-royal-blue`}
                              >
                                {props.children}
                              </a>
                              /* eslint-enable react/prop-types */
                            ),
                          }}
                        >
                          {faq.answer}
                        </ReactMarkdown>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>

            <div className="mt-[26px] w-full self-start flex flex-row items-center justify-around md:justify-start">
              <span className="inline text-gun-powder text-[15px] md:text-[20px] tracking-[.2]">
                Still have questions?
              </span>
              <a
                className="ml-0 md:ml-2 inline text-royal-blue text-[15px] md:text-[20px] tracking-[.2]"
                href="https://help.fluent-forever.com/hc/en-us/requests/new"
              >
                Contact support
              </a>
            </div>
          </div>
        </section>

        {/* Backers Code Section */}
        {Boolean(isSubscribing) && (
          <section>
            <div className="mt-[0px]">
              <RedeemBackerCode />
            </div>
          </section>
        )}

        {/* Footer Decoration Section */}
        <section className={`${isSubscribing ? "mt-[200px]" : ""}`}>
          <div className="relative max-w-2xl mx-auto px-4 space-y-12 sm:px-6 lg:max-w-7xl">
            <div className="mx-auto w-[250px] md:w-[570px]">
              <img className="w-full" src={FooterIllustration} />
            </div>

            <div className="absolute bottom-0 right-1/2 z-10 md:right-0 w-full flex flex-row justify-center items-center w-[57px] md:w-[91px] h-[57px] md:h-[91px] bg-pastel-green rounded-full shadow-2xl transform translate-x-1/2 md:translate-x-0 translate-y-1/2">
              <a href="#">
                <ArrowUpIcon className="h-[24px] md:h-[40px] w-[24px] md:w-[40px] stroke-[#4B495B]" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
