/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as actions from "./actions";
import { IApplicationState } from ".";

const initialState: IApplicationState = {
  isInitialized: false,
  isLoading: false,
  isMaintenanceModeEnabled: false,
};

const reducer = (state: IApplicationState = initialState, action: any) => {
  switch (action.type) {
    case actions.APPLICATION_INITIALIZED:
      return state;
    //Stop Loading
    case actions.APPLICATION_STOP_LOADING:
      return { ...state, isLoading: false };
    //Loading...
    case actions.APPLICATION_LOADING:
      return { ...state, isLoading: true };
    case actions.ENABLE_MAINTENANCE_MODE:
      return { ...state, isMaintenanceModeEnabled: true };
    case actions.DISABLE_MAINTENANCE_MODE:
      return { ...state, isMaintenanceModeEnabled: false };
    default:
      return state;
  }
};

export default reducer;
