import { UserAccountStatus } from "@fluent-forever/types";
import { useMachine } from "@xstate/react";
import { ChargeBee } from "api";
import * as App from "application";
import { ROUTES } from "config";
import { SubscriptionInfoInnerComponent } from "features/subscription/components/SubscriptionInfoInnerComponent";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Confirm, Modal } from "semantic-ui-react";
import { IRootState } from "store/modules";
import { selectUserId } from "../../../application/user";
import {
  trackRenewSubscriptionButtonClicked,
  trackUpgradeSubscriptionButtonClicked,
} from "../../analytics/procedures/analytics_event_procedures";
import { BootcampCancelForm } from "../../bootcamp/components/BootcampCancelForm";
import { isPlanIdMonthlyPlan } from "../functions/subscription_cancellation_functions";
import { subscriptionCancellationMachine } from "../machines/subscription_cancellation_machine";
import { ConfirmedCancellationEvent } from "../machines/subscription_cancellation_machine_types";
import { SubscriptionAntiChurnModal } from "./SubscriptionAntiChurnModal";
import { SubscriptionCancellationModal } from "./SubscriptionCancellationModal";

export const SubscriptionInfoContainer = (): React.ReactElement => {
  const dispatch = useDispatch();
  const {
    activeWeeklyCoachingSubscriptions,
    discounts,
    enrolledBootcamps,
    expiry,
    isAllowCancel,
    isAllowChangeBillingAddress,
    isAllowChangePaymentMethod,
    isAllowChangePlan,
    isAllowNewPlan,
    isBackerSubscription,
    nextBillingAmount,
    nextBillingDate,
    planId,
    planName,
    status,
    subscriptionId,
    subscriptionProviderTitle,
    subscriptionProviderUrl,
  } = useSelector((state: IRootState) => state.activeSubscription);
  const discount = discounts ? discounts.join(", ") : "N/A";

  const isCurrentSubscriptionInApp = useSelector(
    (state: IRootState) =>
      !!state.activeSubscription.subscriptionProviderType &&
      App.Subscriptions.isInAppSubscription(
        state.activeSubscription.subscriptionProviderType
      )
  );
  const userId = useSelector(selectUserId);
  const [cancellationState, cancellationSend] = useMachine(
    subscriptionCancellationMachine
  );

  const [isShowMXUPrompt, setIsShowMXUPrompt] = useState(false);
  const [selectedBootcampsForCancel, setSelectedBootcampsForCancel] = useState<
    string[]
  >([]);

  const warn =
    status === UserAccountStatus.Cancelled
      ? `Your subscription has been Cancelled. You can still use the application until ${expiry}`
      : status === UserAccountStatus.Expired
      ? `Your subscription is Expired. Please subscribe to a new plan to access application again`
      : undefined;

  return (
    <React.Fragment>
      <SubscriptionCancellationModal
        isOpen={cancellationState.matches("reasonsForm")}
        onCancel={() => cancellationSend({ type: "ABORT_CANCELLATION" })}
        onConfirm={(surveyResponse) =>
          cancellationSend({ surveyResponse, type: "FILLED_IN_REASONS" })
        }
        userId={userId ?? ""}
      />
      <SubscriptionAntiChurnModal
        doesOfferRequireExtraSteps={
          !isPlanIdMonthlyPlan(cancellationState.context.planIdForOffer)
        }
        isOpen={cancellationState.matches("offerModal")}
        onAcceptOffer={() => cancellationSend({ type: "ACCEPT_OFFER" })}
        onConfirmCancel={() => cancellationSend({ type: "REJECT_OFFER" })}
        text={cancellationState.context.offerText ?? ""}
      />
      <Confirm
        cancelButton="No"
        confirmButton="Yes"
        content={
          isCurrentSubscriptionInApp
            ? `This will redirect you to ${subscriptionProviderTitle} where you can cancel the subscription, continue?`
            : "This will cancel your subscription, continue?"
        }
        onCancel={() => cancellationSend({ type: "ABORT_CANCELLATION" })}
        onConfirm={() => {
          cancellationSend({
            isCurrentSubscriptionInApp,
            subscriptionId,
            subscriptionProviderUrl: subscriptionProviderUrl ?? "",
            type: "CONFIRMED_CANCELLATION",
            userId,
          } as ConfirmedCancellationEvent);
        }}
        open={cancellationState.matches("cancelConfirmationModal")}
        style={{ zIndex: 1000 }}
      />
      <Confirm
        cancelButton="Cancel"
        confirmButton="Confirm"
        content={() => (
          <BootcampCancelForm
            bootcamps={enrolledBootcamps}
            onSelectionUpdate={(updatedSelection) =>
              setSelectedBootcampsForCancel(updatedSelection)
            }
          />
        )}
        header="Please select the bootcamps from which you wish to cancel your
          enrollment from"
        onCancel={() => setIsShowMXUPrompt(false)}
        onConfirm={async () => {
          await App.Bootcamp.cancelBootcampEnrollments(
            selectedBootcampsForCancel
          );
          setIsShowMXUPrompt(false);
        }}
        open={isShowMXUPrompt}
        style={{ zIndex: 1000 }}
      />
      <Modal open={cancellationState.matches("appliedCoupon")}>
        <Modal.Header>Done!</Modal.Header>
        <Modal.Content>
          <p>The discount has been applied to your subscription!</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => cancellationSend({ type: "DISMISS" })}>
            OK!
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={cancellationState.matches("failedToApplyCoupon")}>
        <Modal.Header>Something went wrong</Modal.Header>
        <Modal.Content>
          <p>
            We could not apply the discount to your subscription. Please try
            again.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => cancellationSend({ type: "DISMISS" })}>
            OK!
          </Button>
        </Modal.Actions>
      </Modal>
      <SubscriptionInfoInnerComponent
        activeWeeklyCoachingSubscriptions={activeWeeklyCoachingSubscriptions}
        currentPlanId={planId}
        discounts={discount}
        enrolledBootcamps={enrolledBootcamps}
        expiry={expiry}
        isAllowCancel={isAllowCancel}
        isAllowChangeBillingAddress={isAllowChangeBillingAddress}
        isAllowChangePaymentMethod={isAllowChangePaymentMethod}
        isAllowReNewSubscription={isAllowNewPlan}
        isAllowUpgradeSubscription={isAllowChangePlan}
        isBackerSubscription={isBackerSubscription}
        isInAppSubscription={isCurrentSubscriptionInApp}
        isLoading={
          cancellationState.matches("loading") ||
          cancellationState.matches("applyingCoupon")
        }
        nextBillingAmount={nextBillingAmount}
        nextBillingDate={nextBillingDate}
        onCancel={() =>
          cancellationSend({
            planId,
            type: "START_CANCELLATION",
          })
        }
        onManageMXU={() => setIsShowMXUPrompt(true)}
        onRenew={() => {
          App.Navigation.redirectTo(dispatch, ROUTES.SUBSCRIPTION_RENEW);
          trackRenewSubscriptionButtonClicked({
            ffId: userId ?? "",
          });
        }}
        onRenewWeeklyCoaching={() => {
          App.Navigation.redirectTo(dispatch, ROUTES.WEEKLY_COACHING);
          trackRenewSubscriptionButtonClicked({
            ffId: userId ?? "",
          });
        }}
        onUpgrade={() => {
          App.Navigation.redirectTo(dispatch, ROUTES.SUBSCRIPTION_UPGRADE);
          trackUpgradeSubscriptionButtonClicked({
            ffId: userId ?? "",
          });
        }}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle={subscriptionProviderTitle ?? ""}
        providerUrl={subscriptionProviderUrl}
        // TODO: this should be correctly typed
        status={status as UserAccountStatus | undefined}
        subscriptionId={subscriptionId}
        warn={warn}
      />
    </React.Fragment>
  );
};
