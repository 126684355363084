import ArrowImage from "assets/images/arrow.svg";
import * as moment from "moment";
import * as React from "react";
import { FC } from "react";
import "./BannerAd.css";

export const BannerAd: FC = () => {
  const startDate = moment.utc("2019-12-27T05:01:00");
  const endDate = moment.utc("2020-01-09T07:59:00");
  const now = moment.utc();
  return now.isBetween(startDate, endDate, undefined, "[]") ? (
    <a
      className={"banner-holiday-deal-container"}
      href="https://fluent-forever.com/getfluent2020"
    >
      <span>NEW YEAR&apos;S SALE:</span>
      <span>50% off any subscription</span>
      <span>(only 500 available!)</span>
      <img className="banner-holiday-deal-arrow" src={ArrowImage} />
      <style>
        {`
          .ui.menu.top.fixed {
            top: 40px;
          }
          main.theme-layout__main {
            padding-top: 110px;
          }
          @media only screen and (max-width: 768px) {
            main.theme-layout__main {
              padding-top: 102px !important;
            }
          }
        `}
      </style>
    </a>
  ) : // eslint-disable-next-line no-null/no-null
  null;
};
