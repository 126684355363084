import { Models } from "@fluent-forever/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as App from "../../../application";
import { getCouponDisplayStrings } from "../../../components/presentational/views/Promo/LandingCouponPage/logic/landingCouponPageLogic";
import { Dispatch } from "../../../store/types";
import { trackSignUpButtonClicked } from "../../analytics/procedures/analytics_event_procedures";
import "../styles/CoolPromo.scss";
import { OldPromoVersion } from "./OldPromoVersion";
import { PromoVersionFour } from "./PromoVersionFour";
import { PromoVersionThree } from "./PromoVersionThree";

interface CoolPromoPageComponentProps extends RouteComponentProps {
  redirectAway: () => void;
  onSignUp: (ctaButtonText: string, coupon: Models.ISubscriptionCoupon) => void;
  loadCouponInformation: (
    coupon: string
  ) => Promise<Models.ISubscriptionCoupon | undefined>;
}

const CoolPromoPageComponent = ({
  onSignUp,
  loadCouponInformation,
  location,
}: CoolPromoPageComponentProps) => {
  const couponParam = new URLSearchParams(location.search).get("coupon");
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState<Models.ISubscriptionCoupon | undefined>(
    undefined
  );

  const ctaButtonText = "Sign Up";

  useEffect(() => {
    initializeCoupon();
  }, []);

  useEffect(() => {
    if (coupon) setLoading(false);
  }, [coupon]);

  const initializeCoupon = async () => {
    setCoupon(await loadCouponInformation(couponParam || ""));
  };

  const onSignUpPressed = (buttonClassName: string) => {
    trackSignUpButtonClicked({ className: buttonClassName });
    coupon && onSignUp(ctaButtonText, coupon);
  };

  const couponDetails = coupon ? getCouponDisplayStrings(coupon) : undefined;

  return loading ? (
    <div className="loading-container">
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" />
      </svg>
    </div>
  ) : coupon && couponDetails ? (
    coupon.metadata.version === 3 ? (
      <PromoVersionThree
        couponDetails={couponDetails}
        onSignUpPressed={onSignUpPressed}
      />
    ) : coupon.metadata.version === 4 ? (
      <PromoVersionFour coupon={coupon} onSignUpPressed={onSignUpPressed} />
    ) : (
      <OldPromoVersion coupon={coupon} onSignUpPressed={onSignUpPressed} />
    )
  ) : (
    <React.Fragment />
  );
};

// === CONTAINER ===

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCouponInformation: (coupon: string) =>
    App.Subscriptions.getCouponInfo(coupon, dispatch),
  onSignUp: (cbaButtonText: string, coupon: Models.ISubscriptionCoupon) =>
    App.Authentication.openPromoSignUp(dispatch, cbaButtonText, coupon),
  redirectAway: () => App.Navigation.redirectToDefaultPath(dispatch),
});

export const CoolPromoPage = withRouter(
  connect(undefined, mapDispatchToProps)(CoolPromoPageComponent)
);
