/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from "react";
import "./Video.css";

interface IVideoProps {
  src: string;
}

/**
 * Responsive video component for videos with an
 * aspect ratio of 16:9.
 * It could be made more generic by accepting an
 * aspectRatio prop and calculating the proper
 * padding-bottom from it (take a look at the associated
 * CSS file).
 * For now, we don't need that, so we won't implement it (YAGNI).
 */
export const Video = ({ src }: IVideoProps) => (
  <div className="video__video-container">
    <div>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        allowFullScreen
        frameBorder="0"
        scrolling="no"
        src={src}
      />
    </div>
  </div>
);
