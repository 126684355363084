/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as API from "api";
import { IRootState } from "store";
import {
  disableMaintenanceMode,
  enableMaintenanceMode,
} from "../store/modules/application";
import { applicationInstance } from ".";

export const isLoading = ({ application }: IRootState) => {
  return application.isLoading;
};

export const checkMaintenanceMode = async (dispatch: any): Promise<boolean> => {
  const maintenanceModeEnabled = await API.Service.isMaintenanceModeEnabled();
  if (maintenanceModeEnabled) {
    dispatch(enableMaintenanceMode());
  } else {
    dispatch(disableMaintenanceMode());
  }
  return maintenanceModeEnabled;
};

export const isMaintenanceModeEnabled = (): boolean => {
  const state: IRootState = applicationInstance.store.getState();
  return state.application.isMaintenanceModeEnabled;
};
