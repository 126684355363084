/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAuthResult, IUserProfile } from "api/Auth0";
import { temporaryAny } from "../../../types/any_types";
import * as actionsAuth from "../authentication/actions";
import { AUTHENTICATION_SUCCESS } from "../authentication/actions";
import * as actions from "./actions";
import { USER_PROFILE_LOADED } from "./actions";
import { IUserState } from ".";

const initialState: IUserState = {
  activePlanId: undefined,
  displayName: "",
  email: "",
  firstName: "",
  id: "",
  isConfirmEmailLoading: false,
  isPendingEmail: false,
  subscriptions: [],
};

export const userReducer = (
  state: IUserState = initialState,
  action: temporaryAny
) => {
  switch (action.type) {
    case actions.LOAD_USER_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case USER_PROFILE_LOADED:
      const profile = action.payload as IUserProfile;
      return { ...state, isPendingEmail: profile.emailVerified === false };
    case AUTHENTICATION_SUCCESS:
      const { userProfile } = action.payload as IAuthResult;
      return { ...state, isPendingEmail: userProfile.emailVerified === false };
    case actions.USER_EMAIL_CONFIRMATION_LOADING:
      return { ...state, isConfirmEmailLoading: true };
    case actions.USER_EMAIL_CONFIRMATION_RESULT:
      return {
        ...state,
        isConfirmEmailLoading: false,
        isPendingEmail: action.payload,
      };
    case actions.REGISTER_SUBSCRIPTION_COUPON:
      return {
        ...state,
        subscriptionCoupon: action.payload,
      };
    case actions.REGISTER_WEEKLY_COACHING_LANGUAGE:
      return {
        ...state,
        weeklyCoachingSignUpLanguage: action.payload,
      };
    case actions.REGISTER_WEEKLY_COACHING_PLAN_ID:
      return {
        ...state,
        weeklyCoachingSignUpPlanId: action.payload,
      };
    case actions.LOAD_USER_INFO_FAIL:
    case actionsAuth.LOGIN_START:
    case actionsAuth.SIGN_UP_START:
    case actionsAuth.AUTHENTICATION_FAIL:
    case actionsAuth.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
