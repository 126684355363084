import { LanguagePairCode } from "@fluent-forever/types";

export const languagePairNamesWithVariant: Record<LanguagePairCode, string> = {
  "en-US_de-DE": "German",
  "en-US_en-US": "English",
  "en-US_es-ES": "Spanish (Spain)",
  "en-US_es-MX": "Spanish (Mexico)",
  "en-US_fr-FR": "French",
  "en-US_he-IL": "Hebrew",
  "en-US_it-IT": "Italian",
  "en-US_ja-JP": "Japanese",
  "en-US_ko-KR": "Korean",
  "en-US_nl-NL": "Dutch",
  "en-US_pt-BR": "Portuguese",
  "en-US_ru-RU": "Russian",
  "en-US_zh-CN": "Mandarin",
};

export const languagePairNames: Record<LanguagePairCode, string> = {
  "en-US_de-DE": "German",
  "en-US_en-US": "English",
  "en-US_es-ES": "Spanish",
  "en-US_es-MX": "Spanish",
  "en-US_fr-FR": "French",
  "en-US_he-IL": "Hebrew",
  "en-US_it-IT": "Italian",
  "en-US_ja-JP": "Japanese",
  "en-US_ko-KR": "Korean",
  "en-US_nl-NL": "Dutch",
  "en-US_pt-BR": "Portuguese",
  "en-US_ru-RU": "Russian",
  "en-US_zh-CN": "Mandarin",
};
