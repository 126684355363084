import React from "react";
import { Button, Modal } from "semantic-ui-react";

export const SubscriptionAntiChurnModal = ({
  doesOfferRequireExtraSteps,
  isOpen,
  onAcceptOffer,
  onConfirmCancel,
  text,
}: {
  doesOfferRequireExtraSteps: boolean;
  isOpen: boolean;
  onAcceptOffer: () => void;
  onConfirmCancel: () => void;
  text: string;
}): React.ReactElement => (
  <Modal open={isOpen}>
    <Modal.Header>We&apos;re sorry to see you leave</Modal.Header>
    <Modal.Content>
      {text.split("\n").map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </Modal.Content>
    <Modal.Actions>
      <Button color={"green"} onClick={onAcceptOffer}>
        {doesOfferRequireExtraSteps
          ? "Take me to the offer"
          : "I want the discount"}
      </Button>
      <Button color={"red"} onClick={onConfirmCancel}>
        No, thanks - cancel my subscription
      </Button>
    </Modal.Actions>
  </Modal>
);
