import { GetAvailableWeeklyCoachingPlansData } from "@fluent-forever/types";
import { weeklyCoachingPortalApi } from "../../../api/weekly_coaching_api";
import * as App from "../../../application";
import { scrollToTop } from "../../../application/navigation";
import { loadActiveSubscription } from "../../../application/planSubscription";
import { store } from "../../../store/configureStore";
import { WeeklyCoachingSignupContext } from "../types/weekly_coaching_signup_machine_types";

export const weeklyCoachingCheckoutInvokation = async (
  ctx: WeeklyCoachingSignupContext
): Promise<void> => {
  scrollToTop();
  if (!ctx.selectedLanguage) throw "No Language selected";
  if (!ctx.selectedPlan) throw "No plan selected";
  await App.WeeklyCoaching.subscribeToWeeklyCoaching({
    langPairCode: ctx.selectedLanguage,
    planId: ctx.selectedPlan,
  });
  await loadActiveSubscription(store.dispatch);
};

export const fetchWeeklyCoachingPlans = (
  all = false
) => async (): Promise<GetAvailableWeeklyCoachingPlansData> => {
  const result = await weeklyCoachingPortalApi.getAvailableWeeklyCoachingPlans(
    all
  );
  if (result.success) {
    return result.data;
  } else {
    throw result.error;
  }
};
