/* eslint-disable @typescript-eslint/no-explicit-any */
import errorReducer from "./reducer";
export * from "./actions";
export default errorReducer;

export enum NotificationMessageType {
  none = 0,
  confirm = 1,
  info = 2,
  warn = 3,
  error = 4,
  newSubscription = 11,
}

export interface INotificationMessage {
  type: NotificationMessageType;
  title: string;
  details?: string;
  actions?: any;
}

export interface INotificationsState {
  readonly messages: INotificationMessage[];
}
