export * from "./actionCreators";
import { authenticationReducer } from "./reducer";

export interface IUserAuthenticationState {
  id?: string;
  //expiresIn: string;
  expiresAt?: number;
  accessToken?: string;
  idToken?: string;
}

export default authenticationReducer;
