import {
  IAccountSettings,
  IPlanSubscriptionResult,
  IUserAccount,
  LanguagePairCode,
  Models,
  SubscriptionProviderEnum,
  UserAccountStatus,
} from "@fluent-forever/types";
import { SubscriptionCoupon } from "@fluent-forever/utils";
import * as Operation from "application/operation";
import * as API from "../api";
import { initialize, reportOfferRedeemed } from "../api/AmexPerks";
import { CheckoutStep } from "../api/ChargeBee";
import { AmexConfig } from "../config";
import {
  trackCheckoutBillingInfoAdded,
  trackCheckoutCancelled,
  trackCheckoutPaymentInfoConfirmed,
  trackPlanSelected,
  trackPlanSubscriptionCompleted,
} from "../features/analytics/procedures/analytics_event_procedures";
import * as Store from "../store";
import { Notifications, Subscriptions } from "../store";
import { store } from "../store/configureStore";
import { NotificationMessageType } from "../store/modules/notifications";
import { Dispatch } from "../store/types";
import { httpRequest } from "./http_request";
import * as Navigation from "./navigation";
import {
  ErrorMessageResult,
  HasAnotherSubscriptionResult,
  loadActiveSubscription,
} from "./planSubscription";
import { loadUserInformation, selectUserId } from "./user";

export const loadUserInfoAndAccountSettings = async (
  dispatch: Dispatch
): Promise<IUserAccount | undefined> => {
  const [user] = await Promise.all([
    loadUserInformation(dispatch),
    loadActiveSubscription(dispatch),
  ]);
  return user;
};

export const subscribeToPlan = async ({
  coupon,
  discount,
  flow,
  forceStart,
  plan,
  langPairCode,
}: {
  coupon?: { couponId: string };
  discount?: Models.CouponDiscountType;
  flow: string;
  forceStart?: boolean;
  langPairCode?: LanguagePairCode;
  plan: Store.Plans.IPlanInfoData;
}): Promise<
  | IPlanSubscriptionResult
  | HasAnotherSubscriptionResult
  | ErrorMessageResult
  | undefined
> => {
  Operation.clearUserMessages(store.dispatch);
  try {
    // const accountSettings = await API.User.fetchAccountSettingsInfo(
    //   httpRequest
    // );
    /*
      To fix the issue https://fluentforever.visualstudio.com/Fluent%20Forever/_workitems/edit/2167
      We have commented out above code that fetches user account settings and allowing users to start the checkout

      TODO: We will need to fix https://fluentforever.visualstudio.com/Fluent%20Forever/_workitems/edit/1896
      using alternate options.
      */
    const accountSettings = {} as IAccountSettings;
    // NOTE:
    // We want to check if the user already has a subscription
    // before proceeding with the purchasing process.
    //
    // For example, this covers us from the next scenario:
    // 1) The user goes to the Portal and navigates to the page showing the available subscriptions
    // 2) The user logs in in a device and is shown the "Buy First Subscription" screen
    // 3) The user comes back to the Portal and presses the button to purchase a subscription
    //
    // Thanks to this check, we won't let the user purchase a second subscription.
    if (isAllowedToPurchaseNewSubscription(accountSettings)) {
      store.dispatch(Subscriptions.newPlanSelected(plan.id));
      trackPlanSelected({ ffId: selectUserId(store.getState()), flow, plan });
      const result = await API.ChargeBee.subscribe({
        couponId: coupon ? coupon.couponId : undefined,
        discount: discount ? discount : undefined,
        flow,
        forceStart,
        langPairCode,
        onCancel: onCheckoutCancel,
        onStepCompleted: onCheckoutStepCompleted,
        planId: plan.id,
        request: httpRequest,
      }).catch((e) => {
        if (e?.response?.data?.message === "Another subscription exists") {
          return { hasAnotherSubscription: true, success: false };
        }
        if (e?.response?.data?.errorDetails) {
          return {
            errorMessage: e.response.data.errorDetails,
            success: false,
          };
        }
        throw e;
      });

      if (
        !result?.success &&
        (result as HasAnotherSubscriptionResult)?.hasAnotherSubscription
      ) {
        return result as HasAnotherSubscriptionResult;
      }

      if (!result?.success && (result as ErrorMessageResult)?.errorMessage) {
        return result as ErrorMessageResult;
      }

      if (result && result.success) {
        const typedResult = result as IPlanSubscriptionResult;
        store.dispatch(Subscriptions.newSubscriptionSuccess(plan.id));

        // A trial period is provided for all subscriptions that are not using a coupon.
        // All coupon purchases default to not having a trial period.
        // The allowsTrial flag on the coupon metadata can override the coupon default and allow a trial period.
        const hasTrial: boolean =
          !typedResult.coupon ||
          SubscriptionCoupon.doesCouponAllowTrial(typedResult.coupon);

        // Report the subscription to Amex if applicable.
        if (coupon && coupon.couponId === AmexConfig.coupon) {
          initialize();
          reportOfferRedeemed();
        }

        trackPlanSubscriptionCompleted({
          discount: plan.totalPrice - typedResult.totalAmount,
          ffId: selectUserId(store.getState()) ?? "",
          flow,
          hasTrial,
          plan,
          price: plan.totalPrice,
          redeemedCode: undefined,
          redeemedCoupon: typedResult.coupon?.couponId,
          revenue: typedResult.totalAmount,
          subscriptionId: typedResult.subscription.id,
          subscriptionStatus: typedResult.subscription.status,
        });

        //Reload User Account to update subscription info
        await loadUserInfoAndAccountSettings(store.dispatch);
        Navigation.redirectToDefaultPath(store.dispatch);
        store.dispatch(
          Notifications.raiseNotification({
            title: "Plan subscription was successful",
            type: NotificationMessageType.newSubscription,
          })
        );
        return typedResult;
      } else {
        //ToDo: parse [result.notifications] messages for details
        throw new Error("Failed to create subscription.");
      }
    } else {
      store.dispatch(
        Subscriptions.ActiveSubscription.onSuccess(accountSettings)
      );
      await loadUserInfoAndAccountSettings(store.dispatch);
      Navigation.redirectToDefaultPath(store.dispatch);
      return undefined;
    }
  } catch (err) {
    Operation.onFail(
      `Failed to subscribe to plan [${plan.id}]`,
      store.dispatch,
      err
    );
    // I couldn't find a way to hide Chargebee using its API, so I'm going
    // with good old CSS instead.
    const cbContainer = document.getElementById("cb-container");
    if (cbContainer) {
      cbContainer.style.display = "none";
    }
    return undefined;
  }
};

export const cancelSubscription = async (
  subscriptionId: string,
  dispatch: Dispatch
): Promise<void> => {
  try {
    const result = await API.Subscriptions.cancelSubscription(
      { subscriptionId },
      httpRequest
    );
    if (result.success) {
      Operation.onSuccess(
        "Subscription was cancelled.",
        dispatch,
        "Application will be available until the expiry."
      );
      await loadUserInfoAndAccountSettings(dispatch);
    }
  } catch (e) {
    Operation.onFail(`Failed to cancel subscription`, dispatch, e);
  }
};

// === INTERNAL ===

const isAllowedToPurchaseNewSubscription = ({
  status,
  subscriptionProviderType,
}: IAccountSettings): boolean =>
  status
    ? status !== UserAccountStatus.Active ||
      // NOTE:
      // We always allow purchasing another Chargebee subscription, as the user
      // should be able to upgrade to a higher subscription.
      // Moreover, the backend properly deals with this scenario and will
      // cancel the old Chargebee subscription.
      subscriptionProviderType === SubscriptionProviderEnum.Chargebee
    : true;

/* Track the checkout selection steps */
const onCheckoutStepCompleted = (step: CheckoutStep, flow?: string) => {
  switch (step) {
    case CheckoutStep.cart_screen:
      // API.Analytics.checkout("Subscription Plan Confirmed");
      break;
    case CheckoutStep.add_billing_address:
      trackCheckoutBillingInfoAdded({
        ffId: selectUserId(store.getState()),
        flow,
      });
      break;
    case CheckoutStep.add_payment_method:
      trackCheckoutPaymentInfoConfirmed({
        ffId: selectUserId(store.getState()),
        flow,
      });
      break;
  }
};

const onCheckoutCancel = (planId: string, flow?: string) => {
  trackCheckoutCancelled({
    ffId: selectUserId(store.getState()),
    flow,
    planId,
  });
};
