/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAuthResult } from "api/Auth0";
import * as App from "application";
import { Operation } from "application";
import { IUserRoutingFlags } from "application/navigation";
import * as R from "ramda";
import * as React from "react";
import { connect } from "react-redux";
import { ROUTES } from "../../../config";
import { IRootState } from "../../../store";
import { Dispatch } from "../../../store/types";
import { OperationMessage } from "../../presentational/application/OperationMessage";

interface IAuthenticationHandlerProps {
  location: any;
  isAuthenticated: boolean;
  navigationFlags: IUserRoutingFlags;
  redirectToHome: (flags: IUserRoutingFlags) => any;
  redirectToAccount: (flags: IUserRoutingFlags) => any;
  authenticateUserFromUrlHash: (
    hash: string,
    query: string
  ) => Promise<IAuthResult>;
  onFail: (message: string, e: any) => void;
}

/** Processes authentication response (hash) from Auth0  */
class AuthenticationHandlerComponent extends React.Component<IAuthenticationHandlerProps> {
  componentDidMount() {
    this.authenticate();
  }

  componentDidUpdate(prevProps: IAuthenticationHandlerProps) {
    if (!R.equals(prevProps, this.props)) {
      this.authenticate();
    }
  }

  authenticate() {
    const { location, isAuthenticated } = this.props;
    if (
      /access_token|id_token|error/.test(location.hash) &&
      isAuthenticated === false
    ) {
      this.props
        .authenticateUserFromUrlHash(location.hash, location.search)
        .catch((e) => {
          //On crash prevent from this handler loading again
          this.props.redirectToHome(this.props.navigationFlags);
          this.props.onFail("Failed to authenticate", e);
        });
    } else {
      // Once authenticated or missing args redirect away
      this.props.redirectToAccount(this.props.navigationFlags);
    }
  }

  render() {
    return <OperationMessage message="Authenticating..." />;
  }
}

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: App.Authentication.isAuthenticated(state),
  navigationFlags: App.Navigation.getUserRoutingInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authenticateUserFromUrlHash: (hash: string, query: string) =>
    App.Authentication.authenticate(hash, query, dispatch),
  onFail: (message: string, e: any) => Operation.onFail(message, dispatch, e),
  redirectToAccount: () => App.Navigation.redirectTo(dispatch, ROUTES.SETTINGS),
  redirectToHome: () => App.Navigation.redirectToDefaultPath(dispatch),
});

export const AuthenticationHandler = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationHandlerComponent);
