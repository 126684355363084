import { UserAccountStatus } from "@fluent-forever/types";
import { ChargeBee } from "api";
import { SubscriptionInfoInnerComponent } from "features/subscription/components/SubscriptionInfoInnerComponent";
import * as React from "react";
import { Divider } from "semantic-ui-react";
import { LayoutContainer } from "theme";

//** Container/UI logic for Redeeming Code */
export const TestSettingsView = (): JSX.Element => {
  const planId = "1";
  const subscriptionId = "sub1";
  const discount = "N/A";
  const expires = "MM/DD/YYYY (or) Free beta access(backers) (or) Never";
  const planName = "Single Language (6mo)";

  return (
    <LayoutContainer>
      All Options:
      <SubscriptionInfoInnerComponent
        currentPlanId={"planId"}
        discounts={discount}
        expiry={expires}
        isAllowCancel={true}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={true}
        isAllowUpgradeSubscription={true}
        isBackerSubscription={false}
        isInAppSubscription={false}
        nextBillingAmount="$6.99"
        nextBillingDate="10-May-2019"
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle="Chargebee"
        subscriptionId="1"
      />
      <br />
      <br />
      <Divider horizontal>Retail subscription (within 30 days)</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        isAllowCancel={false}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={true}
        isBackerSubscription={false}
        isInAppSubscription={false}
        nextBillingAmount="$53.94"
        nextBillingDate="10-May-2019"
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle="Chargebee"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Retail subscription (after 30 days)</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        isAllowCancel={true}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={true}
        isBackerSubscription={false}
        isInAppSubscription={false}
        nextBillingAmount="$53.94"
        nextBillingDate="10-May-2019"
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle="Chargebee"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Cancelled subscription (account active)</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        expiry={"10-Mar-2019"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={true}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={false}
        isInAppSubscription={false}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle="Chargebee"
        status={UserAccountStatus.Cancelled}
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Expired subscription</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        expiry={"01-Jul-1999"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={true}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={false}
        isInAppSubscription={false}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName={planName}
        providerTitle="Chargebee"
        status={UserAccountStatus.Expired}
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Backer subscription (beta language)</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        discounts={"BACKER, 20%"}
        expiry={"Free access to beta languages"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={true}
        isInAppSubscription={false}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName="Single Language Backer (6mo)"
        providerTitle="Chargebee"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Backer subscription (activated)</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        discounts={"BACKER, 20%"}
        expiry={"10-Mar-2019"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={true}
        isAllowChangePaymentMethod={true}
        isAllowReNewSubscription={true}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={true}
        isInAppSubscription={false}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName="Single Language Backer (6mo)"
        providerTitle="Chargebee"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Lifetime Backer subscription</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        discounts={"BACKER, 20%"}
        expiry={"Never"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={false}
        isAllowChangePaymentMethod={false}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={true}
        isInAppSubscription={false}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName="Single Language Backer (Lifetime)"
        providerTitle="Chargebee"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Apple Store subscription</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        expiry={"Never"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={false}
        isAllowChangePaymentMethod={false}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={false}
        isInAppSubscription={true}
        nextBillingAmount={"$9.99"}
        nextBillingDate={"21-Mar-2019"}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName="iOS Monthly"
        providerTitle="Apple App Store"
        subscriptionId={subscriptionId}
      />
      <br />
      <br />
      <Divider horizontal>Android Store subscription</Divider>
      <SubscriptionInfoInnerComponent
        currentPlanId={planId}
        expiry={"Never"}
        isAllowCancel={false}
        isAllowChangeBillingAddress={false}
        isAllowChangePaymentMethod={false}
        isAllowReNewSubscription={false}
        isAllowUpgradeSubscription={false}
        isBackerSubscription={false}
        isInAppSubscription={true}
        nextBillingAmount={"$9.99"}
        nextBillingDate={"21-Mar-2019"}
        openBillingAddress={ChargeBee.openAddresses}
        openPaymentSources={ChargeBee.openPaymentSources}
        planName="Android Monthly"
        providerTitle="Google Play"
        subscriptionId={subscriptionId}
      />
    </LayoutContainer>
  );
};
