/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createStandardAction } from "typesafe-actions";
import { ISubscriptionData } from "./models";

export const NEW_PLAN_SELECTED = "NEW_PLAN_SELECTED";
export const NEW_SUBSCRIPTION_SUCCESS = "NEW_SUBSCRIPTION_SUCCESS";

export const FETCH_SUBSCRIPTIONS_INIT = "FETCH_SUBSCRIPTIONS_INIT";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILURE = "FETCH_SUBSCRIPTIONS_FAILURE";

interface ISuccessAction {
  type: typeof FETCH_SUBSCRIPTIONS_SUCCESS;
  subscriptions: ISubscriptionData[];
}

interface IFailureAction {
  type: typeof FETCH_SUBSCRIPTIONS_FAILURE;
  error: string;
}

export const fetchSubscriptions = () => {
  return {
    type: FETCH_SUBSCRIPTIONS_INIT,
  };
};

export const fetchSubscriptionsSuccess = (
  subscriptions: ISubscriptionData[]
): ISuccessAction => {
  return {
    subscriptions,
    type: FETCH_SUBSCRIPTIONS_SUCCESS,
  };
};

export const fetchSuccess = createStandardAction(
  FETCH_SUBSCRIPTIONS_SUCCESS
).map((Subscriptions: ReadonlyArray<ISubscriptionData>) => ({ Subscriptions }));

export const fetchSubscriptionsFailure = (error: string): IFailureAction => {
  return {
    error,
    type: FETCH_SUBSCRIPTIONS_FAILURE,
  };
};

/** Subscription to new plan was successful */
export const newSubscriptionSuccess = (planId: string) => {
  return {
    payload: { planId },
    type: NEW_SUBSCRIPTION_SUCCESS,
  };
};

/** User selected plan to subscribe to */
export const newPlanSelected = (planId: string) => {
  return {
    payload: { planId },
    type: NEW_PLAN_SELECTED,
  };
};
