/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Plans } from "store";

/** Get list of subscription plans */
export const fetchPlans = (): Plans.IPlanInfoData[] => [
  {
    durationMonths: 1,
    id: "SL-Monthly",
    info: "14-Day Free Trial",
    languageType: Plans.PlanLanguageType.Single,
    monthlyPrice: 9.99,
    name: "Monthly",
    subscriptionType: Plans.PlanSubscriptionType.Paid,
    totalPrice: 9.99,
  },
  {
    durationMonths: 6,
    id: "SL-6MO",
    info: "14-Day Free Trial",
    languageType: Plans.PlanLanguageType.Single,
    monthlyPrice: 8.99,
    name: "6-Month",
    subscriptionType: Plans.PlanSubscriptionType.Paid,
    totalPrice: 53.94,
  },
  {
    bestValue: true,
    durationMonths: 12,
    id: "SL-12MO",
    info: "14-Day Free Trial",
    languageType: Plans.PlanLanguageType.Single,
    monthlyPrice: 7.99,
    name: "12-Month",
    subscriptionType: Plans.PlanSubscriptionType.Paid,
    totalPrice: 95.88,
  },
  {
    bestValue: true,
    durationMonths: 24,
    id: "SL-24MO",
    info: "14-Day Free Trial",
    languageType: Plans.PlanLanguageType.Single,
    monthlyPrice: 6.99,
    name: "24-Month",
    subscriptionType: Plans.PlanSubscriptionType.Paid,
    totalPrice: 167.76,
  },
];

export const fetchWeeklyCoachingPlans = (): Plans.IPlanInfoData[] => [
  {
    durationMonths: 1,
    id: "weekly-coaching",
    languageType: Plans.PlanLanguageType.Single,
    monthlyPrice: 89,
    name: "Weekly Coaching",
    subscriptionType: Plans.PlanSubscriptionType.Paid,
    totalPrice: 89,
  },
  // {
  //   durationMonths: 1,
  //   id: "daily-coaching",
  //   languageType: Plans.PlanLanguageType.Single,
  //   monthlyPrice: 89,
  //   name: "Daily Coaching",
  //   subscriptionType: Plans.PlanSubscriptionType.Paid,
  //   totalPrice: 89,
  // },
];

export const getPlan = (planId: string): Plans.IPlanInfoData | undefined =>
  fetchPlans().find((plan) => plan.id === planId);

/** Get list of plans available for upgrade */
export const getUpgradePlans = (currentPlanId: string) => {
  const plans = fetchPlans();
  if (currentPlanId) {
    const current = plans.find((p) => p.id === currentPlanId);
    if (current) {
      //Only allow upgrade to higher plans
      return plans.filter((p) => p.durationMonths > current.durationMonths);
    }
  }
  return plans;
};
