export enum PlanLanguageType {
  Single = "single",
  Multi = "multi",
}

export enum PlanSubscriptionType {
  Paid = "paid",
  Backer = "backer",
}

export interface IPlanInfoData {
  id: string;
  name: string;
  monthlyPrice: number;
  totalPrice: number;
  subscriptionType: PlanSubscriptionType; //paid, free, backer
  languageType: PlanLanguageType; // single, multi
  durationMonths: number;
  info?: string;
  warning?: string;
  bestValue?: boolean;
}
