import { env } from "@fluent-forever/env";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import * as App from "application";
import { ROUTES } from "config/routes";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoDark } from "../../../assets/svgs/LogoDark";
import { MenuIcon } from "../../../assets/svgs/MenuIcon";
import { IRootState } from "../../../store";

const navigation: Record<
  string,
  {
    href?: string;
    name: string;
  }
> = {
  Account: {
    name: "Account",
  },
  Blog: {
    href: "https://blog.fluent-forever.com",
    name: "Blog",
  },
  Book: {
    href: "https://fluent-forever.com/book",
    name: "Get the Book",
  },
  Bootcamp: {
    name: "MXU Bootcamp",
  },
  LogIn: {
    name: "Log In",
  },
  Logout: {
    name: "Logout",
  },
  Method: {
    href: "https://fluent-forever.com/method",
    name: "Method",
  },
  WeeklyCoaching: {
    name: "Weekly Coaching",
  },
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Navbar = (): JSX.Element => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector((state) =>
    App.Authentication.isAuthenticated(state as IRootState)
  );
  const hasSubscription = useSelector((state) =>
    App.UserInfo.hasSubscriptions(state as IRootState)
  );

  const openLogin = () => App.Authentication.openLogin(dispatch);
  const onLogout = () => App.Authentication.logout(dispatch);
  const onAccountSettings = () =>
    App.Navigation.redirectTo(dispatch, ROUTES.SETTINGS);
  const onWeeklyCoaching = () =>
    App.Navigation.redirectTo(dispatch, ROUTES.WEEKLY_COACHING);
  const onBootcamp = () => App.Navigation.redirectTo(dispatch, ROUTES.BOOTCAMP);

  return (
    <div className="bg-gray-50">
      <Transition.Root as={Fragment} show={open}>
        <Dialog
          as="div"
          className="fixed z-20 inset-0 overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-40 transition-opacity" />
            </Transition.Child>

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-12">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-black bg-opacity-90 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            className="rounded-md text-white cursor-pointer focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                            type="button"
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-3">
                        <a
                          className={`h-12 px-10 w-full flex items-center text-[18px] no-underline hover:no-underline font-circular text-white hover:text-pastel-green focus:text-pastel-green hover:bg-zinc-700 focus:bg-zinc-700 font-medium rounded-md`}
                          href={navigation.Method.href}
                        >
                          {navigation.Method.name}
                        </a>
                        <a
                          className={`h-12 px-10 w-full flex items-center text-[18px] no-underline hover:no-underline font-circular text-white hover:text-pastel-green focus:text-pastel-green hover:bg-zinc-700 focus:bg-zinc-700 font-medium rounded-md`}
                          href={navigation.Blog.name}
                        >
                          {navigation.Blog.name}
                        </a>
                        <a
                          className={`h-12 px-10 w-full flex items-center text-[18px] no-underline hover:no-underline font-circular text-white hover:text-pastel-green focus:text-pastel-green hover:bg-zinc-700 focus:bg-zinc-700 font-medium rounded-md`}
                          href={navigation.Book.name}
                        >
                          {navigation.Book.name}
                        </a>
                        <button
                          className={`h-12 px-10 w-full flex items-center text-[18px] no-underline hover:no-underline font-circular text-white hover:text-pastel-green focus:text-pastel-green hover:bg-zinc-700 focus:bg-zinc-700 font-medium rounded-md`}
                          onClick={openLogin}
                        >
                          {navigation.LogIn.name}
                        </button>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Header */}
      <header className="relative z-10">
        <div className="max-w-7xl mx-auto px-[33px] lg:px-8">
          <div className="flex justify-between items-center py-[25px] md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Fluent Forever Logo</span>
                <LogoDark className="h-[25px] md:h-[45px] w-auto" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon aria-hidden="true" className="h-[45px] w-[45px]" />
              </button>
            </div>

            <div className="hidden md:flex md:flex-row items-center space-x-8">
              <a
                className={`flex whitespace-nowrap text-[20px] text-[#39374F] font-circular font-medium no-underline hover:no-underline hover:text-gray-900`}
                href={navigation.Method.href}
              >
                {navigation.Method.name}
              </a>
              <a
                className={`flex whitespace-nowrap text-[20px] text-[#39374F] font-circular font-medium no-underline hover:no-underline hover:text-gray-900`}
                href={navigation.Blog.href}
              >
                {navigation.Blog.name}
              </a>
              <a
                className={`flex whitespace-nowrap text-[20px] text-[#39374F] font-circular font-medium no-underline hover:no-underline hover:text-gray-900`}
                href={navigation.Book.href}
              >
                {navigation.Book.name}
              </a>
              {!isLoggedIn ? (
                <button
                  className={`flex whitespace-nowrap text-[20px] text-[#39374F] font-circular font-medium no-underline hover:no-underline hover:text-gray-900`}
                  onClick={openLogin}
                >
                  {navigation.LogIn.name}
                </button>
              ) : (
                <Menu as="div" className="relative inline-block text-center">
                  <div>
                    <Menu.Button className="inline-flex justify-center items-center w-full text-[20px] text-[#39374F] font-circular font-medium no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-slate-400">
                      Menu
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-[10px] h-[24px] w-[24px]"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 min-w-[160px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {hasSubscription && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? "bg-zinc-100" : "bg-white",
                                  "block w-full px-[18px] py-[13px] text-[16px] text-shark font-circular no-underline hover:no-underline"
                                )}
                                onClick={onAccountSettings}
                              >
                                {navigation.Account.name}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        {env.asBoolean(
                          "REACT_APP_WEEKLY_COACHING_ENABLED",
                          false
                        ) && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? "bg-zinc-100" : "bg-white",
                                  "block w-full px-[18px] py-[13px] text-[16px] text-shark font-circular no-underline hover:no-underline"
                                )}
                                onClick={onWeeklyCoaching}
                              >
                                {navigation.WeeklyCoaching.name}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        {env.asBoolean(
                          "REACT_APP_MXU_BOOTCAMP_ENABLED",
                          false
                        ) && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? "bg-zinc-100" : "bg-white",
                                  "block w-full px-[18px] py-[13px] text-[16px] text-shark font-circular no-underline hover:no-underline"
                                )}
                                onClick={onBootcamp}
                              >
                                {navigation.Bootcamp.name}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-zinc-100" : "bg-white",
                                "block w-full px-[18px] py-[13px] text-[16px] text-shark font-circular no-underline hover:no-underline"
                              )}
                              onClick={onLogout}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
