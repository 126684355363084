import { PortalToServerEventProperties, REST } from "@fluent-forever/types";
import axios from "axios";
import { ApiConfig } from "../../../config";

export const serverTrackSignUpButtonClicked = async ({
  properties,
  anonymousId,
}: {
  properties: PortalToServerEventProperties;
  anonymousId: string;
}): Promise<void> =>
  serverTrackAnonymousEvent({
    anonymousId,
    properties,
    title: "acq_sign_up_clicked_v06",
  });

export const serverTrackAccountCreated = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_account_created_v06",
  });

export const serverTrackVerificationCompleted = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_verification_completed_v06",
  });

export const serverTrackSubscriptionPlanSelected = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_subscription_plan_selected_v06",
  });

export const serverTrackCheckoutBillingInfoAdded = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_checkout_billing_info_added_v06",
  });

export const serverTrackCheckoutPaymentInfoConfirmed = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_checkout_payment_info_confirmed_v06",
  });

export const serverTrackCheckoutCancelled = async (
  properties: PortalToServerEventProperties
): Promise<void> =>
  serverTrackEvent({
    properties,
    title: "acq_checkout_cancelled",
  });

export const linkAnonymousUser = async (anonymousId: string): Promise<void> => {
  axios
    .post(getUserTrackingLinkUrl(), {
      anonymousId,
    })
    .catch((e) => {
      console.warn(e);
    });
};

// == INTERNAL ==

const serverTrackAnonymousEvent = async ({
  anonymousId,
  properties,
  title,
}: {
  anonymousId: string;
  properties: PortalToServerEventProperties;
  title: string;
}): Promise<void> => {
  await axios
    .post(getAnonymousTrackingUrl(), {
      anonymousId,
      properties,
      title,
    })
    .catch((e) => {
      console.warn(e);
    });
};

const serverTrackEvent = async ({
  properties,
  title,
}: {
  properties: PortalToServerEventProperties;
  title: string;
}): Promise<void> => {
  await axios
    .post(getTrackingUrl(), {
      properties,
      title,
    })
    .catch((e) => {
      console.warn(e);
    });
};

const getTrackingUrl = (): string => ApiConfig.url + REST.PATH.REGISTER_EVENT;

const getAnonymousTrackingUrl = (): string =>
  ApiConfig.url + REST.PATH.REGISTER_ANONYMOUS_EVENT;

const getUserTrackingLinkUrl = (): string =>
  ApiConfig.url + REST.PATH.LINK_USER;
