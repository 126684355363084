import SmallArrowImage from "assets/images/arrow-small.svg";
import FluentForeverWhiteLogo from "assets/images/ff-logo-white.svg";
import * as cn from "classnames";
import gsap, { Strong } from "gsap";
import React, { useEffect, useState } from "react";
import { ScrollScene } from "scrollscene";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import { temporaryAny } from "../../../types/any_types";
import { Image } from "../../image/components/Image";
import "../styles/HeaderSectionPromo.scss";

export const HeaderSectionPromoAmex = ({
  onSignUp,
  isValid,
}: {
  onSignUp: () => void;
  isValid: boolean;
}): React.ReactElement => {
  const [sceneFinished, setSceneFinished] = useState(false);

  // INTENDED: need null for useRef
  /* eslint-disable no-null/no-null */
  const triggerRef = React.useRef(null);
  const navbarRef = React.useRef(null);
  const parallaxRef = React.useRef(null);
  const backdropRef = React.useRef(null);
  const indicatorRef = React.useRef(null);
  const waveRef = React.useRef(null);
  const greenWaveRef = React.useRef(null);
  /* eslint-enable no-null/no-null */

  useEffect(() => {
    const { current: triggerElement } = triggerRef;
    const { current: navbarElement } = navbarRef;
    const { current: parallaxElement } = parallaxRef;
    const { current: backdropElement } = backdropRef;
    const { current: indicatorElement } = indicatorRef;
    const { current: waveElement } = waveRef;
    const { current: greenWaveElement } = greenWaveRef;

    if (
      !triggerElement &&
      !navbarElement &&
      !parallaxElement &&
      !backdropElement &&
      !indicatorElement &&
      !waveElement &&
      !greenWaveElement
    ) {
      return undefined;
    }

    const timeline = gsap.timeline({ paused: true });
    timeline.fromTo(
      parallaxElement,
      {
        yPercent: 0,
      },
      {
        duration: 1,
        yPercent: 20,
      },
      0
    );
    timeline.fromTo(
      backdropElement,
      {
        background:
          "linear-gradient(to right, rgba(184, 184, 184, 0), #202020)",
      },
      {
        background: "linear-gradient(to right, #74e09a, #74e09a)",
        duration: 1,
        ease: Strong.easeIn,
      },
      0
    );

    timeline.to(
      indicatorElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        opacity: 0,
        translateY: 150,
      },
      0
    );
    timeline.to(
      waveElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        height: 0,
      },
      0
    );

    timeline.to(
      greenWaveElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        height: 200,
      },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollScene = new ScrollScene({
      duration: "100%",
      gsap: { timeline },

      offset: -40,
      triggerElement,
      triggerHook: 0,
    });
    scrollScene.Scene.on("end", (e: temporaryAny) => {
      setSceneFinished(e.progress === 1);
    });
    // scrollScene.Scene.addIndicators({
    //   name: "header scene"
    // });

    return () => {
      scrollScene.destroy();
    };
  });

  const { mobile } = useMediaQueries();
  const links: { text: string; url: string }[] = [
    { text: "Method", url: "https://fluent-forever.com/method/" },
    { text: "Book", url: "https://fluent-forever.com/book/" },
    { text: "Blog", url: "https://blog.fluent-forever.com/" },
  ];
  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: FIX
      className={cn("header-section", !isValid && "invalid")}
      ref={triggerRef}
    >
      <div className="bg">
        <picture>
          <source
            srcSet={`/assets/images/${mobile ? "bg-mobile" : "bg"}.webp`}
            type="image/webp"
          />

          <div className="parallax" ref={parallaxRef}>
            <Image src={`/assets/images/${mobile ? "bg-mobile" : "bg"}.jpg`} />
          </div>
        </picture>
      </div>
      <div className="backdrop" ref={backdropRef} />
      <div
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: FIX
        className={cn("header-container", sceneFinished && "collapsed")}
        ref={navbarRef}
      >
        <div className="logo">
          <img src={FluentForeverWhiteLogo} />
        </div>

        <div className="signup-button" onClick={onSignUp}>
          Sign up
        </div>
        {!mobile &&
          links.map((link, i) => (
            <a className="link" href={link.url} key={i}>
              {link.text}
            </a>
          ))}
      </div>
      <div className="content-container">
        <div className="positional">
          {isValid ? (
            <span className="title amex">
              Welcome <span className="non-breaking">American Express</span>{" "}
              Card Member.
            </span>
          ) : (
            <span className="title">
              Sorry! This is an exclusive offer only for American Express Card
              Members.
            </span>
          )}
          {isValid ? (
            <span className="subtitle">
              Learn to speak a new language in 2021!
            </span>
          ) : (
            <span className="subtitle">
              However, we have a special price on our monthly plan just for you:
              2 months for FREE!
            </span>
          )}

          <div className="button-container">
            <div className="button" onClick={onSignUp}>
              Sign up
            </div>
            <span className="subtitle">{`${isValid ? 6 : 2} months free`}</span>
          </div>
          {isValid ? (
            <span className="legal amex">
              As a US American Express Card Member, you will not be charged
              during your 6 Month Free Trial. You will be charged every 6 Months
              in the amount of $53.94 after your 6 Month Free Trial ends and
              charges will continue semiannually at the current rates unless
              cancelled. You can cancel anytime during the 6 Months with no
              charge. Card Members must cancel the automatic renewal 24 hours
              before the due date of their first payment as well as subsequent
              semiannual payments to avoid being charged. Please visit{" "}
              <a className="link" href="https://help.fluent-forever.com/">
                this link
              </a>{" "}
              for contact information and/or to submit any additional questions
              or concerns about your 6 Month Free Trial.
            </span>
          ) : (
            <span className="legal">
              If you are an American Express Card Member and arrived at this
              page, please{" "}
              <a className="link" href="mailto:amex@fluent-forever.com">
                contact us
              </a>{" "}
              for additional help.
            </span>
          )}
        </div>
      </div>
      {isValid && (
        <div className="footer" ref={indicatorRef}>
          <span className="text">or scroll down to learn more</span>
          <img className="arrow" src={SmallArrowImage} />
        </div>
      )}
      {isValid &&
        ["", "-green"].map((className, i) => (
          <svg
            className={`wave${className}-container`}
            key={i}
            preserveAspectRatio="none"
            ref={className ? greenWaveRef : waveRef}
            viewBox="0 0 100 100"
          >
            <path
              className={`wave${className}`}
              d="M 0,100  L 0,0 C 40,0 60,100 100,100 C 140,100 160,0 200,0 C 240,0 260,100 300,100"
            />
          </svg>
        ))}
    </div>
  );
};
