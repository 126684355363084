/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export interface IConditionalRouteProps extends RouteProps {
  isTrue: boolean;
  // Alternative redirect to on false
  redirectTo?: string;
}

/* Only When True Will This Route Render Component */
export const ConditionalRoute = (
  props: IConditionalRouteProps
): JSX.Element | null => {
  const { component, render, isTrue, redirectTo, ...rest } = props;
  let renderComponent;
  if (isTrue) {
    if (component) {
      const Component = component;
      renderComponent = (p: any) => <Component {...p} />;
      return <Route render={renderComponent} {...rest} />;
    } else if (render) {
      renderComponent = render as any;
      return <Route render={render} {...rest} />;
    }
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  // eslint-disable-next-line no-null/no-null
  return null;
};
