import {
  ISubscriptionCoupon,
  IUserAccount,
  LanguagePairCode,
} from "@fluent-forever/types";
import * as API from "api";
import { fetchWeeklyCoachingPlans } from "../application/plans";
import * as Store from "../store";
import { Dispatch } from "../store/types";
import { httpRequest } from "./http_request";
import { Operation } from ".";

/** Indicate if user information was loaded */
export const isLoaded = ({ user }: Store.IRootState): boolean =>
  user && user.displayName !== "";

/** Indicate if user has active subscription  */
export const hasSubscriptions = ({
  user,
  activeSubscription,
}: Store.IRootState): boolean =>
  !!(
    (user && user.subscriptions && user.subscriptions.length > 0) ||
    activeSubscription.enrolledBootcamps.length ||
    activeSubscription.activeWeeklyCoachingSubscriptions.length
  );

export const hasAppSubscription = ({
  user,
  activeSubscription,
}: Store.IRootState): boolean =>
  Boolean(
    user &&
      user.subscriptions &&
      user.subscriptions.length > 0 &&
      !!activeSubscription.subscriptionId
  );

/** Active Subscriptions  */
export const getSubscriptions = ({ user }: Store.IRootState): string[] =>
  user && user.subscriptions ? user.subscriptions : [];

export const selectHasActiveWeeklyCoachingSubscription = ({
  activeSubscription,
}: Store.IRootState): boolean =>
  fetchWeeklyCoachingPlans().some(
    (plan) => plan.id === activeSubscription.planId
  );

export const getSubscriptionCoupon = ({
  user,
}: Store.IRootState): ISubscriptionCoupon | undefined =>
  user ? user.subscriptionCoupon : undefined;

export const getWeeklyCoachingSignUpLanguage = ({
  user,
}: Store.IRootState): LanguagePairCode | undefined =>
  user ? user.weeklyCoachingSignUpLanguage : undefined;

export const getWeeklyCoachingSignUpPlanId = ({
  user,
}: Store.IRootState): string | undefined =>
  user ? user.weeklyCoachingSignUpPlanId : undefined;

/** Display Name  */
export const displayName = ({ user }: Store.IRootState): string =>
  user && user.displayName ? user.displayName : "";

/** Personal/first name */
export const firstName = (state: Store.IRootState): string => {
  const { user } = state;
  return user ? (user.firstName ? user.firstName : displayName(state)) : "";
};

/** Email */
export const email = (state: Store.IRootState): string => {
  const { user } = state;
  return user ? (user.email ? user.email : displayName(state)) : "";
};

/** Indicate if user has active subscription  */
export const isPendingEmailConfirmation = ({
  user,
}: Store.IRootState): boolean => user && user.isPendingEmail === true;

export const selectUserId = (state: Store.IRootState): string | undefined =>
  state.user.id;

/** Load user information  */
export const loadUserInformation = async (
  dispatch: Dispatch
): Promise<IUserAccount | undefined> => {
  try {
    dispatch(Store.User.userInfoLoading());
    const userData = await API.User.fetchUserInfo(httpRequest);
    dispatch(
      Store.User.userInfoSuccess({
        activePlanId: userData.activePlanId,
        displayName: userData.displayName,
        email: userData.email,
        firstName: userData.firstName,
        id: userData.id || "",
        subscriptions: userData.activeSubscriptionId
          ? [userData.activeSubscriptionId]
          : [],
        trialDaysRemaining: userData.trialDaysRemaining,
        trialExpiryDate: userData.trialExpiryDate,
      })
    );
    return userData;
  } catch (error) {
    Operation.onFail(
      "Failed to load user account information",
      dispatch,
      error
    );
    dispatch(Store.User.userInfoFail());
    //If we fail to load user => then logout (no point loading application)
    dispatch(Store.Authentication.logout());
    return undefined;
  }
};
