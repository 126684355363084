/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as API from "api";
import { Subscriptions } from "store";
import {
  redeemCouponCodeFail,
  redeemCouponCodeInit,
  redeemCouponCodeSuccess,
  validateCouponCodeFail,
  validateCouponCodeInit,
  validateCouponCodeSuccess,
} from "store/modules/couponcodes/actions";
import { Dispatch } from "../store/types";
import { loadUserInfoAndAccountSettings } from "./account";
import { httpRequest } from "./http_request";
import { Operation } from ".";

/** Validate Coupon/Backer Code */
export function fetchCouponCodeInfo(code: string, dispatch: Dispatch) {
  dispatch(validateCouponCodeInit(code));
  return API.BackerCodes.validate(httpRequest, { code })
    .then((result) => {
      dispatch(validateCouponCodeSuccess(result));
      if (result.plan) {
        // API.Analytics.planSelected(result.plan, code);
      }
      return result;
    })
    .catch((err) => {
      dispatch(validateCouponCodeFail());
      Operation.onFail("Coupon code validation failed", dispatch, err);
    });
}

/** Submit/Redeem Coupon Code */
export function redeemCouponCode(code: string, dispatch: Dispatch) {
  dispatch(redeemCouponCodeInit());
  return API.BackerCodes.redeem(httpRequest, { code })
    .then((result) => {
      if (result && result.success) {
        dispatch(redeemCouponCodeSuccess());
        if (result.subscription && result.subscription.id && result.code.plan) {
          // New subscription plan redeemed
          // API.Analytics.planSubscriptionCompleted(
          //   result.code.plan,
          //   false,
          //   undefined,
          //   result.code.id,
          //   result.subscription.status
          // );
          dispatch(
            Subscriptions.newSubscriptionSuccess(result.subscription.id)
          );
          //ToDo: review if we can update local record from result instead of loading the user again
          loadUserInfoAndAccountSettings(dispatch).then(() => {
            Operation.onSuccess(
              "Hurray!",
              dispatch,
              "You've just taken the most important step in your language learning journey!"
            );
          });
        } else {
          // Other type (credit) of code redeemed
          Operation.onSuccess("Coupon code redeemed successfully", dispatch);
        }
        //Reload User Account To make sure we have the subscription/discounts

        return result;
      } else {
        throw new Error("Failed to redeem code");
      }
    })
    .catch((err) => {
      dispatch(redeemCouponCodeFail());
      Operation.onFail("Redemption of coupon code failed", dispatch, err);
      throw err;
    });
}
