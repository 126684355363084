/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as API from "api";
import { IAuthResult } from "api/Auth0";
import * as App from "application";
import * as Store from "store";
import { trackEmailConfirmed } from "../features/analytics/procedures/analytics_event_procedures";
import { Dispatch } from "../store/types";
import { RenewAccessTokenError } from "./authentication";
import { httpRequest } from "./http_request";
import { applicationInstance, Operation } from ".";

/** Verify user clicked on email confirmation link
 *  - Auth0 access token contains the email flag in meta data
 */
export const verifyUserEmail = (dispatch: Dispatch): Promise<boolean> => {
  console.debug("Verifying email...");
  const state = applicationInstance.store.getState();
  //get updated email confirmation flag from Auth0 profile
  const isAuthenticated = App.Authentication.isAuthenticated(state);

  if (isAuthenticated) {
    const isPendingEmailConfirmation = App.UserInfo.isPendingEmailConfirmation(
      state
    );
    if (isPendingEmailConfirmation) {
      //Check Email Confirmation
      dispatch(Store.User.userEmailConfirmationLoading());
      // Re-fetch the id token (user profile)
      return App.Authentication.renewAccessToken(dispatch)
        .then((result: IAuthResult) => {
          //ToDo: Do we need to also update/reset local tokens/expiry?
          //Not For Now: the current purpose is to only obtain updated user profile (which cannot be done via lock.getUserInfo)

          //redirect user first (to preserve user messages)

          if (result.userProfile && result.userProfile.emailVerified) {
            dispatch(
              Store.User.userEmailConfirmation(
                !result.userProfile.emailVerified
              )
            );
            App.Navigation.redirectToDefaultPath(dispatch);
            Operation.onSuccess(
              "Thank you for confirming your email.",
              dispatch
            );
            trackEmailConfirmed({
              authProvider: result.userProfile.authProvider,
              email: result.userProfile.email,
              ffId: result.userProfile.userId,
            });
            return true;
          } else {
            App.Navigation.redirectToDefaultPath(dispatch);
            Operation.onFail(
              `Your email was not verified yet. (Link was not clicked?)`,
              dispatch
            );
            return false;
          }
        })
        .catch((err) => {
          let message = "Failed to verify email confirmation";
          if (err && (err as RenewAccessTokenError).isRenewAccessTokenError) {
            message = "Failed to review access token";
          }
          Operation.onFail(message, dispatch, err);
          return false;
        });
      //NOTE: Original solution doesn't work (anymore?) as Auth0 returns stale user info
      // return loadAuthUserProfile(accessToken, dispatch).then((profile: IUserProfile) => {
      //     //redirect user first (to preserve user messages)
      //     App.Navigation.redirectToDefaultPath(dispatch);
      //     if (profile.emailVerified) {
      //         Operation.onSuccess("Thank you for confirming your email.", dispatch);
      //         API.Analytics.emailConfirmed(profile.email);
      //     } else {
      //         Operation.onFail(`Your email was was not verified yet. (Link was not clicked?)`, dispatch);
      //     }
      //     dispatch(Store.User.userEmailConfirmation(profile.emailVerified === false));
      //     return profile.emailVerified;
      // }).catch(err => {
      //     Operation.onFail('Failed to verify email confirmation', dispatch, err);
      //     return false;
      // });
    } else {
      //Already confirmed => just redirect
      App.Navigation.redirectToDefaultPath(dispatch);
      return Promise.resolve(true);
    }
  } else {
    //Can't confirm if not logged in => go to landing + tell user to log in
    App.Navigation.redirectToDefaultPath(dispatch);
    Operation.onSuccess(
      "Thank you for confirming your email. Please log in to access the portal.",
      dispatch
    );
    return Promise.resolve(true);
  }
};

/** Email Confirmation Resend */
export const reSend = (dispatch: Dispatch) => {
  return API.User.resendEmailConfirmation(httpRequest)
    .then((result) => {
      if (result) {
        Operation.onSuccess(
          "Confirmation email was re-sent to your email.",
          dispatch
        );
      }
      return result;
    })
    .catch((err) =>
      Operation.onFail("Failed to re-send confirmation email", dispatch, err)
    );
};
