/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAuthResult } from "api/Auth0";
import { temporaryAny } from "../../../types/any_types";
import * as actions from "./actions";
import { IUserAuthenticationState } from ".";

const initialState: IUserAuthenticationState = {
  accessToken: undefined,
  expiresAt: undefined,
  id: undefined,
  //expiresIn: undefined,
  idToken: undefined,
};
export const authenticationReducer = (
  state: IUserAuthenticationState = initialState,
  action: temporaryAny
) => {
  switch (action.type) {
    case actions.LOGIN_START:
    case actions.SIGN_UP_START:
    case actions.AUTHENTICATION_FAIL:
    case actions.LOGOUT:
      return initialState;
    case actions.AUTHENTICATION_SUCCESS:
      const {
        id,
        accessToken,
        idToken,
        expiresAt,
      } = action.payload as IAuthResult;
      return { ...state, accessToken, expiresAt, id, idToken };
    default:
      return state;
  }
};
