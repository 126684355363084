import SmallArrowImage from "assets/images/arrow-small.svg";
import MobileBackgroundImageJPG from "assets/images/bg-mobile.jpg";
import MobileBackgroundImageWEBP from "assets/images/bg-mobile.webp";
import DesktopBackgroundImageJPG from "assets/images/bg.jpg";
import DesktopBackgroundImageWEBP from "assets/images/bg.webp";
import FluentForeverWhiteLogoSVG from "assets/images/ff-logo-white.svg";
import * as cn from "classnames";
import gsap, { Strong } from "gsap";
import React, { useEffect, useState } from "react";
import { ScrollScene } from "scrollscene";
import { ICouponDisplayStrings } from "../../../components/presentational/views/Promo/LandingCouponPage/types/landingCouponPageTypes";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import { temporaryAny } from "../../../types/any_types";
import { Image } from "../../image/components/Image";
import "../styles/HeaderSectionPromo.scss";

export const HeaderSectionPromo = ({
  couponDetails,
  onSignUp,
}: {
  couponDetails: ICouponDisplayStrings;
  onSignUp: (buttonClassName: string) => void;
}): React.ReactElement => {
  const [sceneFinished, setSceneFinished] = useState(false);

  // INTENDED as useRef needs null
  /* eslint-disable no-null/no-null */
  const triggerRef = React.useRef(null);
  const navbarRef = React.useRef(null);
  const parallaxRef = React.useRef(null);
  const backdropRef = React.useRef(null);
  const indicatorRef = React.useRef(null);
  const waveRef = React.useRef(null);
  const greenWaveRef = React.useRef(null);
  /* eslint-enable no-null/no-null */

  useEffect(() => {
    const { current: triggerElement } = triggerRef;
    const { current: navbarElement } = navbarRef;
    const { current: parallaxElement } = parallaxRef;
    const { current: backdropElement } = backdropRef;
    const { current: indicatorElement } = indicatorRef;
    const { current: waveElement } = waveRef;
    const { current: greenWaveElement } = greenWaveRef;

    if (
      !triggerElement &&
      !navbarElement &&
      !parallaxElement &&
      !backdropElement &&
      !indicatorElement &&
      !waveElement &&
      !greenWaveElement
    ) {
      return undefined;
    }

    const timeline = gsap.timeline({ paused: true });
    timeline.fromTo(
      parallaxElement,
      {
        yPercent: 0,
      },
      {
        duration: 1,
        yPercent: 20,
      },
      0
    );
    timeline.fromTo(
      backdropElement,
      {
        background:
          "linear-gradient(to right, rgba(184, 184, 184, 0), #202020)",
      },
      {
        background: "linear-gradient(to right, #74e09a, #74e09a)",
        duration: 1,
        ease: Strong.easeIn,
      },
      0
    );

    timeline.to(
      indicatorElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        opacity: 0,
        translateY: 150,
      },
      0
    );
    timeline.to(
      waveElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        height: 0,
      },
      0
    );

    timeline.to(
      greenWaveElement,
      {
        duration: 1,
        ease: Strong.easeIn,
        height: 200,
      },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollScene = new ScrollScene({
      duration: "100%",
      gsap: { timeline },

      offset: -40,
      triggerElement,
      triggerHook: 0,
    });
    scrollScene.Scene.on("end", (e: temporaryAny) => {
      setSceneFinished(e.progress === 1);
    });
    // scrollScene.Scene.addIndicators({
    //   name: "header scene"
    // });

    return () => {
      scrollScene.destroy();
    };
  });

  const { mobile } = useMediaQueries();
  const links: { text: string; url: string }[] = [
    { text: "Method", url: "https://fluent-forever.com/method/" },
    { text: "Book", url: "https://fluent-forever.com/book/" },
    { text: "Blog", url: "https://blog.fluent-forever.com/" },
  ];
  return (
    <div className={"header-section"} ref={triggerRef}>
      <div className="bg">
        <picture>
          <source
            src={
              mobile ? MobileBackgroundImageWEBP : DesktopBackgroundImageWEBP
            }
            type="image/webp"
          />

          <div className="parallax" ref={parallaxRef}>
            <Image
              src={
                mobile ? MobileBackgroundImageJPG : DesktopBackgroundImageJPG
              }
            />
          </div>
        </picture>
      </div>
      <div className="backdrop" ref={backdropRef} />
      <div
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: FIX
        className={cn("header-container", sceneFinished && "collapsed")}
        ref={navbarRef}
      >
        <div className="logo">
          <img src={FluentForeverWhiteLogoSVG} />
        </div>

        <div
          className="signup-button header-signup-button"
          onClick={() => onSignUp("header-signup-button")}
        >
          Sign up
        </div>
        {!mobile &&
          links.map((link, i) => (
            <a className="link" href={link.url} key={i}>
              {link.text}
            </a>
          ))}
      </div>
      <div className="content-container">
        <div className="positional">
          <span className="title">{couponDetails.title}</span>
          <span className="subtitle">
            Learn to speak a new language in 2021!
          </span>

          <div className="button-container">
            <div
              className="button header-other-signup-button"
              onClick={() => onSignUp("header-other-signup-button")}
            >
              Sign up
            </div>
            <span className="subtitle">{couponDetails.detailText}</span>
          </div>
          <span className="legal">
            <p>{`${couponDetails.footerPricing}`}</p>
            <p>No commitment. Cancel anytime.</p>
          </span>
        </div>
      </div>
      <div className="footer" ref={indicatorRef}>
        <span className="text">or scroll down to learn more</span>
        <img className="arrow" src={SmallArrowImage} />
      </div>
      {["", "-green"].map((className, i) => (
        <svg
          className={`wave${className}-container`}
          key={i}
          preserveAspectRatio="none"
          ref={className ? greenWaveRef : waveRef}
          viewBox="0 0 100 100"
        >
          <path
            className={`wave${className}`}
            d="M 0,100  L 0,0 C 40,0 60,100 100,100 C 140,100 160,0 200,0 C 240,0 260,100 300,100"
          />
        </svg>
      ))}
    </div>
  );
};
