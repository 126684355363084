/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NAVIGATION_CHANGED, NAVIGATION_CHANGING } from "./actions";

export const navigationChanging = (newUrl: string, originalUrl: string) => ({
  payload: { newUrl, originalUrl },
  type: NAVIGATION_CHANGING,
});
export const navigationChanged = (newUrl: string) => ({
  payload: { newUrl },
  type: NAVIGATION_CHANGED,
});
