import { assign, createMachine } from "xstate";
import { getChurnCoupon } from "../../../api/subscriptions";
import { httpRequest } from "../../../application/http_request";
import { loadActiveSubscription } from "../../../application/planSubscription";
import { store } from "../../../store/configureStore";
import { isPlanIdMonthlyPlan } from "../functions/subscription_cancellation_functions";
import {
  acceptAntiChurnOfferForAnotherPlan,
  acceptAntiChurnOfferForSamePlan,
  cancelSubscription,
} from "../procedures/subscription_cancellation_procedures";
import {
  SubscriptionCancellationContext,
  SubscriptionCancellationEvent,
  SubscriptionCancellationState,
} from "./subscription_cancellation_machine_types";

export const subscriptionCancellationMachine = createMachine<
  SubscriptionCancellationContext,
  SubscriptionCancellationEvent,
  SubscriptionCancellationState
>({
  initial: "idle",
  states: {
    idle: {
      on: {
        START_CANCELLATION: {
          actions: assign((_, event) => ({
            planId: event.planId,
          })),
          target: "reasonsForm",
        },
      },
    },
    reasonsForm: {
      on: {
        ABORT_CANCELLATION: "idle",
        FILLED_IN_REASONS: [
          {
            actions: assign((_, event) => {
              return { surveyResponse: event.surveyResponse };
            }),
            cond: (context) => isPlanIdMonthlyPlan(context.planId),
            target: "loading",
          },
          {
            actions: assign((_, event) => {
              return { surveyResponse: event.surveyResponse };
            }),
            target: "cancelConfirmationModal",
          },
        ],
      },
    },
    loading: {
      invoke: {
        src: async (context) => {
          if (!context.planId) {
            throw new Error("No plan ID set");
          }
          const coupon = await getChurnCoupon(httpRequest);
          if (!coupon) {
            throw new Error("No offer found");
          }
          if (!coupon.metadata.text) {
            throw new Error("No offer text found");
          }
          return coupon;
        },
        onDone: {
          actions: assign((_, event) => ({
            couponId: event.data.couponId,
            planIdForOffer: event.data.planIds[0],
            offerText: event.data.metadata.text,
          })),
          target: "offerModal",
        },
        onError: {
          target: "cancelConfirmationModal",
        },
      },
    },
    offerModal: {
      on: {
        ACCEPT_OFFER: [
          {
            actions: [assign((_) => ({ surveyResponse: undefined }))],
            cond: (ctx) =>
              !!ctx.planIdForOffer && isPlanIdMonthlyPlan(ctx.planIdForOffer),
            target: "applyingCoupon",
          },
          {
            actions: [
              assign((_) => ({ surveyResponse: undefined })),
              (ctx) => {
                if (!ctx.couponId || !ctx.planIdForOffer) {
                  throw new Error("No plan ID or couponId for offer set");
                }
                acceptAntiChurnOfferForAnotherPlan({
                  couponId: ctx.couponId,
                  planIdForOffer: ctx.planIdForOffer,
                });
              },
            ],
            target: "idle",
          },
        ],
        REJECT_OFFER: {
          target: "cancelConfirmationModal",
        },
      },
    },
    cancelConfirmationModal: {
      on: {
        ABORT_CANCELLATION: "idle",
        CONFIRMED_CANCELLATION: {
          actions: [
            (
              context,
              {
                isCurrentSubscriptionInApp,
                subscriptionId,
                subscriptionProviderUrl,
                userId,
              }
            ) => {
              if (!context.surveyResponse) {
                throw new Error("surveyResponse not set");
              }
              cancelSubscription({
                isCurrentSubscriptionInApp,
                subscriptionId,
                subscriptionProviderUrl,
                surveyResponse: context.surveyResponse,
                userId,
              });
            },
          ],
          target: "idle",
        },
      },
    },
    applyingCoupon: {
      invoke: {
        src: async (ctx) => {
          if (!ctx.couponId) {
            throw new Error("couponId not set");
          }
          await acceptAntiChurnOfferForSamePlan({ couponId: ctx.couponId });
        },
        onDone: {
          target: "appliedCoupon",
        },
        onError: {
          target: "failedToApplyCoupon",
        },
      },
    },
    appliedCoupon: {
      on: {
        DISMISS: {
          actions: [() => loadActiveSubscription(store.dispatch)],
          target: "idle",
        },
      },
    },
    failedToApplyCoupon: {
      on: {
        DISMISS: "idle",
      },
    },
  },
});
