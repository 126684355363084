import { RedeemCode } from "components/containers";
import { SubscriptionInfoContainer } from "features/subscription/components/SubscriptionInfoContainer";
import * as React from "react";
import { connect } from "react-redux";
import { LayoutContainer } from "theme";
import {
  CancellationPolicy,
  CouponCodeRedemptionHelp,
} from "../../views/InfoLinks";

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

const AccountSettingsView: React.SFC = () => {
  return (
    <LayoutContainer>
      <div style={{ margin: "0 auto", maxWidth: 800 }}>
        <SubscriptionInfoContainer />
        <RedeemCode
          onCodeRedeemed={() => {
            /* Add Handler After Code is redeemed */
          }}
        />
        <CouponCodeRedemptionHelp />
        <CancellationPolicy />
      </div>
    </LayoutContainer>
  );
};

export const AccountSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsView);
