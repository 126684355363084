import { UserFacingBootcamp } from "@fluent-forever/types";
import React, { useEffect } from "react";
import { useState } from "react";
import { BootcampDate } from "../../../features/bootcamp/components/BootcampDate";

export const BootcampCancelForm = ({
  bootcamps,
  onSelectionUpdate,
}: {
  bootcamps: UserFacingBootcamp[];
  onSelectionUpdate: (updatedSelection: string[]) => unknown;
}): React.ReactElement => {
  const [selectedBootcamps, setSelectedBootcamps] = useState<string[]>([]);

  useEffect(() => {
    onSelectionUpdate(selectedBootcamps);
  }, [selectedBootcamps]);

  return (
    <div style={{ margin: 20 }}>
      <form style={{ margin: "40px 0" }}>
        {bootcamps.map((bootcamp, i) => (
          <div key={i} style={{ margin: "20px 0" }}>
            <input
              checked={selectedBootcamps.includes(bootcamp.id)}
              id={`bootcamp-${i}`}
              name={`bootcamp-${i}`}
              onChange={() =>
                setSelectedBootcamps((selected) =>
                  selected.includes(bootcamp.id)
                    ? selected.filter((sb) => sb !== bootcamp.id)
                    : [...selected, bootcamp.id]
                )
              }
              type="checkbox"
            />
            <label htmlFor={`bootcamp-${i}`}>
              <BootcampDate from={bootcamp.startDate} to={bootcamp.endDate} />
            </label>
          </div>
        ))}
      </form>
      <span>
        {`By clicking "Confirm" you will be cancelling your enrollment to the
        selected boocamps.`}
      </span>
    </div>
  );
};
