import {
  getLanguagePairCodeFromKnownAndLearnLanguages,
  ISubscriptionCoupon,
  LearnLanguageCode,
} from "@fluent-forever/types";
import React from "react";
import FluentForeverLogoDark from "../../../assets/images/ff-logo-dark.png";
import FluentForeverLogoSVG from "../../../assets/images/ff-logo-white.svg";
import { languageFlags } from "../../../assets/images/flags/flags";
import VideoPreview1 from "../../../assets/images/video_preview_1.png";
import VideoPreview2 from "../../../assets/images/video_preview_2.png";
import { getCouponDisplayStrings } from "../../../components/presentational/views/Promo/LandingCouponPage/logic/landingCouponPageLogic";
import { languagePairNamesWithVariant } from "../../bootcamp/constants/language";
import styles from "../styles/PromoVersionFour.module.scss";

export const PromoVersionFour = ({
  coupon,
  onSignUpPressed,
}: {
  coupon: ISubscriptionCoupon;
  onSignUpPressed: (buttonClassName: string) => void;
}): React.ReactElement => {
  const couponDetails = getCouponDisplayStrings(coupon);

  return (
    <div className={styles["version-four-container"]}>
      <Header />
      <FirstSection
        detailText={couponDetails.detailText}
        logoUrl={coupon.metadata.logoUrl}
        title={couponDetails.title}
      />
      <SecondSection onSignUpPressed={onSignUpPressed} />
      <ThirdSection />
      <FourthSection
        footerPricing={couponDetails.footerPricing}
        onSignUpPressed={onSignUpPressed}
      />
    </div>
  );
};

const Header = () => {
  return (
    <header className={styles["header-container"]}>
      <div className={styles["logo"]}>
        <img src={FluentForeverLogoSVG} />
      </div>
    </header>
  );
};

const FirstSection = ({
  detailText,
  title,
  logoUrl,
}: {
  detailText?: string;
  title: string;
  logoUrl?: string;
}) => {
  return (
    <section className={styles["first-section"]}>
      <div className={styles["title-container"]}>
        {logoUrl ? (
          <div className={styles["logo-container"]}>
            <img src={logoUrl} />
          </div>
        ) : (
          <React.Fragment />
        )}
        <h1>{title}</h1>
        <p>{detailText}</p>
      </div>
      <svg preserveAspectRatio="none" viewBox="0 0 100 100">
        <polygon points="0,0 100,0 50,100" />
      </svg>
    </section>
  );
};

const SecondSection = ({
  onSignUpPressed,
}: {
  onSignUpPressed: (buttonClassName: string) => void;
}) => {
  return (
    <section className={styles["second-section"]}>
      <a
        className={styles["get-offer"]}
        onClick={() => onSignUpPressed("GET OFFER")}
      >
        GET OFFER
      </a>
      <h2>This is the best method for serious language learners</h2>
      <VideoCard
        description="New discoveries in learning science are now available for you inside of an easy-to-use app."
        title="See Why Fluent Forever Was Invented"
        videoImageUrl={VideoPreview1}
        videoUrl="https://www.youtube.com/watch?v=bPVeIHcgfTs"
      />
      <VideoCard
        description="Our app will jump-start your learning using smart algorithms, ear training, and our proprietary learning method."
        title="Learn How it Works in 60 Seconds"
        videoImageUrl={VideoPreview2}
        videoUrl="https://www.youtube.com/watch?v=YSaWv5d0KOo"
      />
    </section>
  );
};

const VideoCard = ({
  videoImageUrl,
  videoUrl,
  title,
  description,
}: {
  videoImageUrl: string;
  videoUrl: string;
  title: string;
  description: string;
}) => {
  return (
    <a className={styles["video-card-container"]} href={videoUrl}>
      <section>
        <div className={styles["video-preview"]}>
          <svg viewBox="0 0 100 100">
            <polygon points="10,0 90,50 10,100" />
          </svg>
          <img src={videoImageUrl} />
        </div>
        <div className={styles["video-description"]}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </section>
    </a>
  );
};

const ThirdSection = () => {
  const languages: LearnLanguageCode[] = [
    "es-MX",
    "fr-FR",
    "de-DE",
    "it-IT",
    "ja-JP",
    "pt-BR",
    "es-ES",
    "ru-RU",
    "ko-KR",
    "zh-CN",
    "nl-NL",
    "he-IL",
  ];
  return (
    <section className={styles["third-section"]}>
      <h2>{`Instantly unlock your brain's natural ability to learn languages`}</h2>
      <svg preserveAspectRatio="xMidYMid slice" viewBox="0 0 120 100">
        <line x1="10" x2="110" y1="50" y2="50" />
      </svg>
      <p>{`Experience the new app that brings life to the renowned neuroscience-based method from our bestselling book.`}</p>
      <h2>{`Sign up now and choose any of these supported languages:`}</h2>
      <div className={styles["languages"]}>
        {languages.map((lang, i) => {
          const langPairCode = getLanguagePairCodeFromKnownAndLearnLanguages({
            known: "en-US",
            learn: lang,
          });
          return langPairCode ? (
            <div className={styles["single-language"]} key={i}>
              <img src={languageFlags[lang]} />
              <span>{languagePairNamesWithVariant[langPairCode]}</span>
            </div>
          ) : (
            <React.Fragment />
          );
        })}
      </div>
      <div className={styles["backdrop"]} />
    </section>
  );
};

const FourthSection = ({
  onSignUpPressed,
  footerPricing,
}: {
  onSignUpPressed: (buttonClassName: string) => void;
  footerPricing?: string;
}) => {
  return (
    <section className={styles["fourth-section"]}>
      <h3>How to redeem this offer:</h3>
      <ol type="1">
        <li>{`1. Click the Get Offer button.`}</li>
        <li>{`2. Verify your email address.`}</li>
        <li>{`3. We'll email you a link.`}</li>
        <li>{`4. Download the app.`}</li>
        <li>{`5. Log into your account.`}</li>
      </ol>
      <a
        className={styles["get-offer"]}
        onClick={() => onSignUpPressed("GET OFFER")}
      >
        GET OFFER
      </a>
      {footerPricing ? <span>{footerPricing}</span> : <React.Fragment />}
      <span>No commitment. Cancel anytime.</span>
      <span>Minimum Device Requirements:</span>
      <span>iOS 10.0 or Android 7.0</span>
      <img src={FluentForeverLogoDark} />
      <div />
    </section>
  );
};
