/** Subscribe to plan */
import {
  EvaluateUnpaidUserBootcampEnrollmentResultData,
  ServiceResponseError,
  SubmitUserBootcampEnrollmentRequestPayload,
} from "@fluent-forever/types";
import * as API from "../api";
import { hideChargebeeModal } from "../api/ChargeBee";
import { store } from "../store/configureStore";
import { loadActiveSubscription } from "./planSubscription";
import { Operation } from ".";

export const subscribeToBootcamp = async (
  payload: SubmitUserBootcampEnrollmentRequestPayload
): Promise<
  EvaluateUnpaidUserBootcampEnrollmentResultData | ServiceResponseError<unknown>
> => {
  Operation.clearUserMessages(store.dispatch);
  try {
    const result = await API.ChargeBee.subscribeBootcamp(payload);
    return result;
  } catch (err) {
    hideChargebeeModal();
    throw err;
  }
};

export const cancelBootcampEnrollments = async (
  bootcampIds: string[]
): Promise<void> => {
  Operation.clearUserMessages(store.dispatch);
  try {
    await API.MXU.cancelBootcampEnrollments({ bootcampIds });
    Operation.onSuccess(
      "Successfully cancelled bootcamp enrollments",
      store.dispatch
    );
    loadActiveSubscription(store.dispatch);
  } catch (err) {
    const { response } = err as {
      response: { data: ServiceResponseError<unknown> };
    };
    Operation.onFail(
      `Failed to cancel bootcamp enrollments.`,
      store.dispatch,
      response?.data?.error ? response.data.error : err
    );
  }
};
