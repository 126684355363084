import { LanguagePairCode } from "@fluent-forever/types";
import { useMachine } from "@xstate/react";
import React, { useEffect } from "react";
import { isMobileSafari } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { ROUTES } from "../../../config";
import { weeklyCoachingSignupMachine } from "../machines/weekly_coaching_signup_machine";

export const WeeklyCoachingSubscriptionAfterSignup = ({
  planId,
  langPairCode,
}: {
  planId: string;
  langPairCode: LanguagePairCode;
}): React.ReactElement => {
  const [machine, send] = useMachine(weeklyCoachingSignupMachine);

  const { context, value: currentState } = machine;

  useEffect(() => {
    send({ send, type: "INITIALIZE" });
  }, []);

  useEffect(() => {
    if (currentState === "idle") {
      send("SET_LANGUAGE_AND_PLAN", {
        value: { language: langPairCode, plan: planId },
      });
      // Automatically triggering the Chargebee checkout modal does not
      // work on Safari Mobile
      if (!isMobileSafari) {
        onSignUp();
      }
    }
  }, [currentState]);

  const { errorMessage } = context;
  const isLoading = currentState === "checkout";

  const onSignUp = () => send("START_CHECKOUT");

  return (
    <Container>
      <Segment basic>
        <Segment>
          <Dimmer active={isLoading} page>
            <Loader />
          </Dimmer>
          {errorMessage ? (
            <Message negative onDismiss={() => send("CLEAR_ERROR")}>
              <Message.Header>
                There was an issue with the checkout
              </Message.Header>
              <p>{errorMessage}</p>
            </Message>
          ) : undefined}
          {currentState === "success" ? (
            <>
              <Header>{`You've successfully purchased a weekly coaching subscription!`}</Header>
              <p>
                {`You can manage it on your `}
                <Link to={ROUTES.SETTINGS}>account</Link>
                {` page.`}
              </p>
            </>
          ) : (
            <>
              <Header>Just one last thing...</Header>
              <p>
                Click the button below and provide us with a few details to
                finish your weekly coaching subscription.
              </p>
              <Button onClick={onSignUp} primary size="big">
                Subscribe
              </Button>
            </>
          )}
        </Segment>
      </Segment>
    </Container>
  );
};
