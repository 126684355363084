import { Dialog, Transition } from "@headlessui/react";
import { GiftIcon } from "@heroicons/react/outline";
import React, { Fragment, useRef, useState } from "react";
import MaskedInput from "react-text-mask";
import * as App from "../../../../application";
import { selectUserId } from "../../../../application/user";
import { trackBackerCodeRedeemed } from "../../../../features/analytics/procedures/analytics_event_procedures";
import * as Store from "../../../../store";
import { store } from "../../../../store/configureStore";

export const RedeemBackerCode = (): React.ReactElement => {
  const [code, setCode] = useState("");
  const [codeInfo, setCodeInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showModal = codeInfo !== "";
  // eslint-disable-next-line no-null/no-null
  const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

  const onReset = () => {
    setCode("");
    setCodeInfo("");
    setIsLoading(false);
  };

  const clearWarnings = () => {
    store.dispatch(Store.Notifications.clearNotifications());
  };

  const fetchCodeInfo = (code: string) => {
    return App.CouponCode.fetchCouponCodeInfo(code, store.dispatch);
  };

  const redeemCode = async (code: string) => {
    await App.CouponCode.redeemCouponCode(code, store.dispatch);
    trackBackerCodeRedeemed({
      ffId: selectUserId(store.getState()) ?? "",
    });
  };

  const checkIsValid = (code: string) => {
    const result =
      code && code.replace("_", "").length >= "XXXX-XXXX-XXXX".length;
    return result;
  };

  const onGetCodeInfo = (event: React.FormEvent) => {
    event.preventDefault();

    if (checkIsValid(code)) {
      fetchCodeInfo(code)
        .then((info) => {
          if (info) {
            setCodeInfo(info.details);
          }
        })
        .catch(onReset);
    }
  };

  const onRedeemCode = () => {
    if (checkIsValid(code)) {
      setIsLoading(true);
      redeemCode(code)
        .then(() => {
          onReset();
        })
        .catch(onReset);
    }
  };

  return (
    <>
      <div className="max-w-[590px] mx-auto flex flex-col md:flex-row items-center space-x-[50px]">
        <div className="flex flex-col items-start justify-start">
          <p className="mb-2 text-shark text-[22px] font-circular font-medium tracking-[.2px]">
            Add a backers code:
          </p>
          <p className="text-gun-powder text-[15px] font-circular font-medium tracking-[.2px] bg-[#F7CD66] bg-opacity-10 rounded-full">
            This is not for coupons
          </p>
        </div>
        <form
          className="mt-[20px] md:mt-0 flex flex-row items-center"
          onSubmit={onGetCodeInfo}
        >
          <MaskedInput
            className={`pl-[16px] pr-[10px] h-[40px] bg-slate-100 rounded-[10px] placeholder-slate-400`}
            mask={[
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              "-",
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              "-",
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
              /[A-Z0-9]/,
            ]}
            onChange={(event) => {
              setCode(event.target.value);
              clearWarnings();
            }}
            placeholder="XXXX-XXXX-XXXX"
            value={code}
          />
          <button
            className="-ml-[25px] h-[40px] px-[20px] text-[18px] text-white font-medium bg-gun-powder tracking-[.2px] rounded-full"
            disabled={isLoading}
            type="submit"
          >
            Redeem
          </button>
        </form>
      </div>

      <Transition.Root as={Fragment} show={showModal}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={confirmButtonRef}
          onClose={() => setCodeInfo("")}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <GiftIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-green-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Redeem Code
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-[16px] text-slate-600">{codeInfo}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-pastel-green text-[18px] font-circular font-medium text-white tracking-[.2px] hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-300 sm:mt-0 sm:w-auto"
                    onClick={onRedeemCode}
                    ref={confirmButtonRef}
                    type="button"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
