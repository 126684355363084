import { LearnLanguageCode } from "@fluent-forever/types";
import BRFlag from "./BR.svg";
import CNFlag from "./CN.svg";
import DEFlag from "./DE.svg";
import FRFlag from "./FR.svg";
import ILFlag from "./IL.svg";
import ITFlag from "./IT.svg";
import JPFlag from "./JP.svg";
import KRFlag from "./KR.svg";
import MXFlag from "./MX.svg";
import NLFlag from "./NL.svg";
import RUFlag from "./RU.svg";
import SPFlag from "./SP.svg";
import USFlag from "./US.svg";

export const languageFlags: Record<LearnLanguageCode, string> = {
  "de-DE": DEFlag,
  "en-US": USFlag,
  "es-ES": SPFlag,
  "es-MX": MXFlag,
  "fr-FR": FRFlag,
  "he-IL": ILFlag,
  "it-IT": ITFlag,
  "ja-JP": JPFlag,
  "ko-KR": KRFlag,
  "nl-NL": NLFlag,
  "pt-BR": BRFlag,
  "ru-RU": RUFlag,
  "zh-CN": CNFlag,
};
