import * as App from "application";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "store";
import { Dispatch } from "../../../store/types";
import { OperationMessage } from "../../presentational/application/OperationMessage";

export interface IEmailConfirmationProps {
  isPendingEmailConfirmation: boolean;
  verifyEmailConfirmation(): Promise<boolean>;
}

const mapStateToProps = (state: IRootState) => ({
  isPendingEmailConfirmation: App.UserInfo.isPendingEmailConfirmation(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyEmailConfirmation: () =>
    App.EmailConfirmation.verifyUserEmail(dispatch),
});

/** Handler to check if user account has emailed confirmed */
class EmailConfirmationComponent extends React.Component<IEmailConfirmationProps> {
  UNSAFE_componentWillMount() {
    // Make sure this only runs once
    this.props.verifyEmailConfirmation();
  }

  render() {
    return <OperationMessage message="Confirming Email..." />;
  }
}

export const EmailConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirmationComponent);
