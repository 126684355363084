/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import * as App from "application";
import { ROUTES } from "config/routes";
import * as React from "react";
import { connect } from "react-redux";
import { StaticRouterProps, withRouter } from "react-router";
import { Switch } from "react-router-dom";
import { IRootState } from "store";
import { Pages } from "..";
import { AnonymousOnlyRoute, ProtectedRoute } from "../../containers";
import { OperationMessage } from "./OperationMessage";

export interface IContentViewProps extends StaticRouterProps {
  isAuthenticated: boolean;
  isLoading: boolean;
}

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: App.Authentication.isAuthenticated(state),
  isLoading: App.UserInfo.isLoaded(state) === false,
});

/** Component showing application content (switching between routes/different content views) */
const RedesignedContentViewComponent: React.SFC<IContentViewProps> = (
  props
) => {
  const { isAuthenticated, isLoading } = props;
  const loc = props.location as any;

  if (isAuthenticated && isLoading) {
    return <OperationMessage message="Loading..." />;
  }

  console.debug("Content Routing PATH:[" + loc.pathname + "]");
  console.debug(props.location);
  return (
    <Switch>
      <AnonymousOnlyRoute
        component={Pages.Landing}
        exact
        path={ROUTES.LANDING}
      />
      <ProtectedRoute
        component={Pages.EmailPending}
        exact
        path={ROUTES.EMAIL_PENDING}
      />
      <ProtectedRoute
        exact
        path={ROUTES.SUBSCRIBE}
        render={(routeProps) => (
          <Pages.Landing isSubscribing={true} {...routeProps} />
        )}
      />
      <ProtectedRoute component={Pages.Download} path={ROUTES.HOME} />
    </Switch>
  );
};
const reduxConnected = connect(mapStateToProps)(RedesignedContentViewComponent);
export const RedesignedContentView = withRouter<any>(reduxConnected); //this makes sure component re-renders when route changes
