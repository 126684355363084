import VideoIcon from "assets/images/video-icon.svg";
import * as React from "react";
import { Image, Modal } from "semantic-ui-react";
import "./styles.css";

export const CancellationPolicy: React.SFC = () => {
  return (
    <div className="cancellation-policy">
      <a
        href="https://help.fluent-forever.com/hc/en-us/articles/360030662051-What-is-the-Fluent-Forever-app-cancellation-policy-"
        rel="noreferrer"
        target="_blank"
      >
        Cancellation Policy
      </a>
    </div>
  );
};

export const CouponCodeRedemptionHelp: React.SFC = () => {
  return (
    <div className="coupon-code-redemption-help">
      <Modal
        closeIcon
        size={"large"}
        trigger={
          <a href="javascript:void(0);">
            <Image
              inline={true}
              size="mini"
              src={VideoIcon}
              style={{ padding: 5 }}
            />
            How to add a coupon code
          </a>
        }
      >
        <Modal.Content>
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope;"
            allowFullScreen
            frameBorder="0"
            height="700"
            src="https://www.youtube.com/embed/FrfJYd523oI"
            style={{
              width: "95%",
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};
