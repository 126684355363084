/* eslint-disable react/prop-types */
import * as React from "react";
import { Icon, Message } from "semantic-ui-react";

export interface IOperationMessageProps {
  message: string;
}

/** Display performed operation with spinner */
export const OperationMessage: React.SFC<IOperationMessageProps> = (props) => {
  return (
    <div style={{ display: "table", width: "100%" }}>
      <div style={{ display: "table-cell", verticalAlign: "middle" }}>
        <Message
          icon
          style={{
            margin: "auto",
            marginBottom: 50,
            marginTop: 50,
            minWidth: 120,
            width: 240,
          }}
        >
          <Icon loading name="circle notched" />
          <Message.Content>{props.message}</Message.Content>
        </Message>
      </div>
    </div>
  );
};
