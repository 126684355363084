import { createStandardAction } from "typesafe-actions";
import { ILanguageData } from "./models";

export const FETCH_LANGUAGES_INIT = "FETCH_LANGUAGES_INIT";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_FAILURE = "FETCH_LANGUAGES_FAILURE";

interface IFetchLanguageInitAction {
  readonly type: typeof FETCH_LANGUAGES_INIT;
}

export const fetchLanguages = (): IFetchLanguageInitAction => {
  return {
    type: FETCH_LANGUAGES_INIT,
  };
};

export interface IFetchLanguageSuccessAction {
  type: typeof FETCH_LANGUAGES_SUCCESS;
  languages: ILanguageData[];
}

export const fetchLanguagesSuccess = (
  languages: ILanguageData[]
): IFetchLanguageSuccessAction => {
  return {
    languages,
    type: FETCH_LANGUAGES_SUCCESS,
  };
};

export const fetchSuccess = createStandardAction(
  FETCH_LANGUAGES_SUCCESS
).map((languages: ReadonlyArray<ILanguageData>) => ({ languages }));

interface IFetchLanguageFailureAction {
  type: typeof FETCH_LANGUAGES_FAILURE;
  error: string;
}

export const fetchLanguagesFailure = (
  error: string
): IFetchLanguageFailureAction => {
  return {
    error,
    type: FETCH_LANGUAGES_FAILURE,
  };
};
