/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

const queries: any = {
  desktop: "(min-width: 1281px)",
  mobile: "(max-width: 760px)",
  tablet: "(min-width: 761px) and (max-width: 1280px)",
};

export const useMediaQueries = () => {
  const [queryMatch, setQueryMatch] = useState<{
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  }>({ desktop: false, mobile: false, tablet: false });

  useEffect(() => {
    const mediaQueryLists: any = {};
    const keys = Object.keys(queries);

    // To check whether event listener is attached or not
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc: any, media: string) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      // Setting state to the updated matches
      // when document either starts or stops matching a query
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: any = {};
      keys.forEach((media: string) => {
        mediaQueryLists[media] = window.matchMedia(queries[media]);
        matches[media] = mediaQueryLists[media].matches;
      });
      // Setting state to initial matching queries
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        mediaQueryLists[media].addListener(handleQueryListener);
      });
    }

    return () => {
      // If event listener is attached then remove it when deps change
      if (isAttached) {
        keys.forEach((media) => {
          mediaQueryLists[media].removeListener(handleQueryListener);
        });
      }
    };
  }, [queries]);

  return (
    queryMatch || {
      desktop: true,
      mobile: false,
      tablet: false,
    }
  );
};
