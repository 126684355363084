import React from "react";
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";

export const WeeklyCoachingSettingsModal = ({
  title,
  message,
  loading,
  onDismiss,
  onSubmit,
  isOpen,
  errorMessage,
  buttonText,
}: {
  title: string;
  message: string;
  loading: boolean;
  onDismiss: () => unknown;
  onSubmit: () => unknown;
  isOpen: boolean;
  errorMessage?: string;
  buttonText: string;
}): React.ReactElement => {
  return (
    <Modal open={isOpen}>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Are you sure?</Header>
          <p>{message}</p>
        </Modal.Description>
        {errorMessage ? (
          <Message negative>
            <Message.Header>{`There was an issue!`}</Message.Header>
            <p>{errorMessage}</p>
          </Message>
        ) : undefined}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Never mind" onClick={onDismiss} secondary />
        <Button content={buttonText} onClick={onSubmit} primary />
      </Modal.Actions>
    </Modal>
  );
};
