import * as React from "react";
import { SVGProps } from "react";

export const MenuIcon = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg
    fill={props.fill ?? "#262628"}
    height="46"
    viewBox="0 0 45 46"
    width="45"
    {...props}
  >
    <rect fill="inherit" height="3" rx="1.5" width="24" x="10.5" y="15" />
    <rect fill="inherit" height="3" rx="1.5" width="24" x="10.5" y="22" />
    <rect fill="inherit" height="3" rx="1.5" width="24" x="10.5" y="29" />
  </svg>
);
