/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IHttpRequest } from "application/utils";
import axios from "axios";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import { rootEpic, rootReducer } from "./modules";
import { IUserAuthenticationState } from "./modules/authentication";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: FIX
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// custom execution context to pass dependencies to Epics/Thunks
export interface IExecutionContext {
  request: IHttpRequest;
}
const injectContext: IExecutionContext = {
  request: axios as IHttpRequest,
};

const epicMiddleware = createEpicMiddleware({
  dependencies: injectContext,
});

const thunkMiddleware = thunk.withExtraArgument(injectContext);
// const segmentMiddleware = createTracker();

const configureStore = (initialState: {
  authentication: IUserAuthenticationState;
}) => {
  const middlewares = [thunkMiddleware, epicMiddleware];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const rootStore = createStore(rootReducer, initialState!, enhancer);
  epicMiddleware.run(rootEpic);
  return rootStore;
};

const getStoredState = (): IUserAuthenticationState => {
  const id = localStorage.getItem("current_user_id") ?? undefined;
  const accessToken = localStorage.getItem("access_token") ?? undefined;
  const expiresAt = localStorage.getItem("access_token_expires_at");
  return {
    accessToken,
    expiresAt: expiresAt ? Number(expiresAt) : undefined,
    id,
    idToken: undefined,
  };
};

const initStoreState = {
  authentication: getStoredState(),
};

export const store = configureStore(initStoreState);

store.subscribe(() => {
  //ToDO:refactor for performance!
  const auth: IUserAuthenticationState = store.getState().authentication;
  localStorage.setItem("current_user_id", auth.id as any);
  localStorage.setItem("access_token", auth.accessToken as any);
  localStorage.setItem(
    "access_token_expires_at",
    (auth.expiresAt ? auth.expiresAt.toString() : undefined) as any
  );
});

export default () => store;
