import * as App from "application";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "store";
import CalendarIllustration from "../../../../assets/images/calendar-illustration.svg";
import CelebrationEmoji from "../../../../assets/images/celebration-emoji.svg";
import EmailSentFooterIllustration from "../../../../assets/images/email-pending-footer-illustration.svg";
import "../../../../styles/tailwind.css";

interface IDownloadComponentProps {
  firstName: string;
  isInTrial: boolean;
  getTrialDaysRemaining: number;
}

const DownloadComponent = ({
  firstName,
  isInTrial,
  getTrialDaysRemaining,
}: IDownloadComponentProps) => (
  <main>
    <div className="mx-auto max-w-[1000px] px-[10px] md:px-0 flex flex-col items-center">
      {/* Welcome Section */}
      {isInTrial ? (
        <section className="mt-[30px] md:mt-[60px] flex flex-col md:flex-row items-center md:justify-around">
          <div className="flex flex-col items-center md:items-start">
            <p className="mt-[10px] md:mt-0 mb-0 text-[16px] md:text-[20px] text-gun-powder font-circular leading-[24px] md:leading-[30px] tracking-[.5px]">
              Hello, {firstName}!
            </p>
            <h1 className="mt-[10px] md:mt-0 mb-0 text-[25px] md:text-[40px] text-gun-powder font-circular font-medium leading-[38px] md:leading-[60px] tracking-[.2px]">
              Welcome to your free trial.
            </h1>
            <h2 className="mt-1 md:mt-[8px] text-[33px] md:text-[55px] text-green-700 font-semibold font-circular leading-[50px] md:leading-[80px]">
              {getTrialDaysRemaining} days left
            </h2>
          </div>

          <img
            className="ml-0 md:ml-[40px] w-[137px] md:w-[320px] h-[91px] md:h-[200px] order-first md:order-none"
            src={CalendarIllustration}
          />
        </section>
      ) : (
        <section className="mt-[30px] md:mt-[60px] flex flex-col md:flex-row items-center md:justify-around">
          <div className="flex flex-col items-center">
            <p className="mt-[10px] md:mt-0 mb-0 text-[16px] md:text-[20px] text-gun-powder font-circular leading-[24px] md:leading-[30px] tracking-[.5px]">
              Hello, {firstName}!
            </p>
            <h1 className="mt-[10px] md:mt-0 mb-0 text-[25px] md:text-[40px] text-gun-powder font-circular font-medium leading-[38px] md:leading-[60px] tracking-[.2px]">
              Welcome to Fluent Forever.
            </h1>
          </div>
        </section>
      )}

      {/* Celebration Section */}
      <section className="md:mx-0 mt-[50px] w-full flex flex-row items-center px-[10px] md:px-0 py-[14px] pl-[30px] bg-[#d8ffeb] rounded-[30px] md:rounded-full">
        <img
          className="w-[75px] md:w-[125px] h-[60px] md:h-[100px]"
          src={CelebrationEmoji}
        />

        <div className="ml-[20px] md:ml-[70px]">
          <p className="mb-0 text-[18px] md:text-[30px] text-gun-powder font-circular font-medium leading-[25px] md:leading-[45px]">
            Hurray!
          </p>
          <h3 className="mt-[10px] text-[19px] md:text-[35px] font-circular font-semibold leading-[25px] leading-[50px] tracking-[.2px]">
            Your subscription was successful.
          </h3>
        </div>
      </section>
    </div>

    {/* Footer Decoration Section */}
    <section className="mt-[36px] md:mt-[50px] flex flex-row justify-end md:justify-center">
      <img
        className="w-[228px] md:w-[634px] h-auto"
        src={EmailSentFooterIllustration}
      />
    </section>
  </main>
);

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => {
  return {
    firstName: App.UserInfo.firstName(state),
    getTrialDaysRemaining: App.Trial.getTrialDaysRemaining(state),
    isInTrial: App.Trial.isInTrial(state),
  };
};

export const Download = connect(mapStateToProps)(DownloadComponent);
