import React from "react";
import { ICouponDisplayStrings } from "../../../components/presentational/views/Promo/LandingCouponPage/types/landingCouponPageTypes";
import { FirstSectionPromo } from "../components/FirstSectionPromo";
import { FooterSectionPromo } from "../components/FooterSectionPromo";
import { FourthSectionPromo } from "../components/FourthSectionPromo";
import { HeaderSectionPromo } from "../components/HeaderSectionPromo";
import { SecondSectionPromo } from "../components/SecondSectionPromo";
import { ThirdSectionPromo } from "../components/ThirdSectionPromo";
import "../styles/PromoVersionThree.scss";

export const PromoVersionThree = ({
  couponDetails,
  onSignUpPressed,
}: {
  couponDetails: ICouponDisplayStrings;
  onSignUpPressed: (buttonClassName: string) => unknown;
}): React.ReactElement => {
  return (
    <div className="coupon-container" id="coupon">
      <HeaderSectionPromo
        couponDetails={couponDetails}
        onSignUp={onSignUpPressed}
      />
      <FirstSectionPromo couponDetails={couponDetails} shouldRenderNewUI />
      <SecondSectionPromo />
      <ThirdSectionPromo />
      <FourthSectionPromo
        couponDetails={couponDetails}
        onSignUp={onSignUpPressed}
      />
      <FooterSectionPromo />
    </div>
  );
};
