/* eslint-disable react/prop-types */
import { Models } from "@fluent-forever/types";
import * as App from "application";
import { RedirectToDefaultRoute } from "components/containers";
import { NotificationMessage } from "components/presentational/application";
import { PlanSubscriptions } from "components/presentational/controls/SubscriptionPlans/PlanSubscriptions";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState, Plans } from "store";
import { NotificationMessageType } from "store/modules/notifications";
import { LayoutContainer } from "theme";

export interface IPlanUpgradeProps {
  isAllowPlanUpgrade: boolean;
  plans: Plans.IPlanInfoData[];
  discounts?: Models.CouponDiscountType[];
}

const PlanUpgradeContainer: React.SFC<IPlanUpgradeProps> = (props) => {
  if (props.isAllowPlanUpgrade === false || props.plans.length === 0) {
    return <RedirectToDefaultRoute />;
  }
  return (
    <React.Fragment>
      <LayoutContainer>
        <NotificationMessage
          details="NOTE: Except for monthly subscription, the remaining time on current subscription will be added onto the new subscription."
          title="Upgrade Your Plan"
          type={NotificationMessageType.info}
        />
      </LayoutContainer>
      <PlanSubscriptions
        discounts={props.discounts}
        flow={"upgrade"}
        isAllowRedeemCoupon={false}
        planButtonText="Upgrade"
        plans={props.plans}
      />
    </React.Fragment>
  );
};

// === CONTAINER ===

const mapStateToProps = ({ activeSubscription }: IRootState) => ({
  discounts: activeSubscription.discounts,
  isAllowPlanUpgrade: activeSubscription.isAllowChangePlan,
  plans: App.Plans.getUpgradePlans(activeSubscription.planId),
});

export const PlanUpgradeView = connect(mapStateToProps)(PlanUpgradeContainer);
