import React, { useState } from "react";

export const Image = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
): React.ReactElement => {
  const [loaded, setLoaded] = useState(false);
  return (
    <img
      onLoad={() => setLoaded(true)}
      style={{ opacity: loaded ? 1 : 0, ...props.style }}
      {...props}
    />
  );
};
