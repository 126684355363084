/* eslint-disable react/prop-types */
import { IAuthResult } from "api/Auth0";
import * as App from "application";
import { OperationMessage } from "components/presentational/application/OperationMessage";
import * as React from "react";
import { connect } from "react-redux";
import {
  Accordion,
  Breadcrumb,
  Button,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Loader,
  Menu,
  Message,
  Modal,
  Pagination,
  Popup,
  Radio,
  Segment,
  Select,
  Sidebar,
  Step,
  Table,
  TextArea,
} from "semantic-ui-react";
import { Dispatch } from "../../../../store/types";
import { temporaryAny } from "../../../../types/any_types";

const options = [
  { key: ".com", text: ".com", value: ".com" },
  { key: ".net", text: ".net", value: ".net" },
  { key: ".org", text: ".org", value: ".org" },
];

const steps = [
  {
    description: "Choose your shipping options",
    icon: "truck",
    key: "shipping",
    title: "Shipping",
  },
  {
    active: true,
    description: "Enter billing information",
    icon: "payment",
    key: "billing",
    title: "Billing",
  },
  { disabled: true, icon: "info", key: "confirm", title: "Confirm Order" },
];

const tagOptions = [
  {
    label: { circular: true, color: "red", empty: true },
    text: "Important",
    value: "Important",
  },
  {
    label: { circular: true, color: "blue", empty: true },
    text: "Important",
    value: "Important",
  },
  {
    label: { circular: true, color: "green", empty: true },
    text: "Important",
    value: "Important",
  },
  {
    label: { circular: false, color: "yellow", empty: true },
    text: "Important",
    value: "Important",
  },
];

export const activeIndex = 2;

// group wrapper
const Section = (props: temporaryAny) => {
  return (
    <div>
      <Header>{props.title}</Header>
      {props.children}
    </div>
  );
};

export interface ITestThemeViewProps {
  renewToken: () => Promise<IAuthResult>;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  renewToken: () => App.Authentication.renewAccessToken(dispatch),
});

const TestThemeComp = (props: ITestThemeViewProps) => {
  return (
    <Container>
      <Segment>
        <Section title="Buttons">
          <Button onClick={props.renewToken} primary>
            Primary
          </Button>
          <Button secondary>Secondary</Button>
          <Button positive>Positive</Button>
          <Button negative>Negative</Button>
          <Button disabled>Disabled</Button>
          <Button inverted>Inverted</Button>
          <Button icon labelPosition="left">
            <Icon name="pause" />
            Icon-ed
          </Button>
          <Button.Group>
            <Button>Option 1</Button>
            <Button.Or />
            <Button positive>Option 2</Button>
          </Button.Group>
        </Section>
        <Section title="Inputs">
          <Input icon="user" loading placeholder="Loading..." />
          <Input error placeholder="Error..." />
          <Input icon="users" iconPosition="left" placeholder="With icon..." />
          <Input
            label={<Dropdown defaultValue=".com" options={options} />}
            labelPosition="right"
            placeholder="With Dropdown..."
          />
          <Input action placeholder="With button..." type="text">
            <input />
            <Button type="submit">Button</Button>
          </Input>
        </Section>

        <Section title="Label">
          <Menu compact>
            <Menu.Item as="a">
              <Icon name="mail" /> Messages
              <Label color="red" floating>
                22
              </Label>
            </Menu.Item>
            <Menu.Item as="a">
              <Icon name="users" /> Friends
              <Label color="teal" floating>
                22
              </Label>
            </Menu.Item>
          </Menu>
        </Section>

        <Section>
          <OperationMessage message="Loading..." />
        </Section>
        <Section title="List">
          <Header>List {"&"} Label</Header>
          <List divided selection>
            <List.Item>
              <Label color="red" horizontal>
                Fruit
              </Label>
              Kumquats
            </List.Item>
            <List.Item>
              <Label color="purple" horizontal>
                Candy
              </Label>
              Ice Cream
            </List.Item>
            <List.Item>
              <Label color="red" horizontal>
                Fruit
              </Label>
              Orange
            </List.Item>
            <List.Item>
              <Label horizontal>Dog</Label>
              Poodle
            </List.Item>
          </List>
        </Section>
        <Section title="Loader">
          <Loader>Loading</Loader>
        </Section>

        <Section title="Segment">
          <Segment>this is segment context</Segment>
          <Segment.Group horizontal>
            <Segment>Segment Left</Segment>
            <Segment>Segment Middle</Segment>
            <Segment>Segment Right</Segment>
          </Segment.Group>
        </Section>
        <Section title="Step">
          <Step.Group ordered>
            <Step completed>
              <Step.Content>
                <Step.Title>Shipping</Step.Title>
                <Step.Description>
                  Choose your shipping options
                </Step.Description>
              </Step.Content>
            </Step>

            <Step completed>
              <Step.Content>
                <Step.Title>Billing</Step.Title>
                <Step.Description>Enter billing information</Step.Description>
              </Step.Content>
            </Step>

            <Step active>
              <Step.Content>
                <Step.Title>Confirm Order</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
          <Step.Group items={steps} />
        </Section>
        <Section title="Breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Section link>Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section link>Registration</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section active>Personal Information</Breadcrumb.Section>
          </Breadcrumb>
        </Section>
        <Section title="Form 1">
          <Form>
            <Form.Field>
              <input placeholder="First name" type="text" />
              <Label pointing>Please enter a value</Label>
            </Form.Field>
            <Divider />

            <Form.Field>
              <Label pointing="below">Please enter a value</Label>
              <input placeholder="Last Name" type="text" />
            </Form.Field>
            <Divider />

            <Form.Field inline>
              <input placeholder="Username" type="text" />
              <Label pointing="left">That name is taken!</Label>
            </Form.Field>
            <Divider />

            <Form.Field inline>
              <Label pointing="right">
                Your password must be 6 characters or more
              </Label>
              <input placeholder="Password" type="password" />
            </Form.Field>
          </Form>
          <Divider />
          <Header>Form2</Header>
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                label="First name"
                placeholder="First name"
              />
              <Form.Field
                control={Input}
                label="Last name"
                placeholder="Last name"
              />
              <Form.Field
                control={Select}
                label="Gender"
                options={options}
                placeholder="Gender"
              />
            </Form.Group>
            <Form.Group inline>
              <label>Quantity</label>
              <Form.Field control={Radio} label="One" value="1" />
              <Form.Field control={Radio} disabled label="Two" value="2" />
              <Form.Field control={Radio} label="Three" value="3" />
              <Form.Field>
                <Checkbox disabled label="Disabled" />
              </Form.Field>
              <Form.Field>
                <Checkbox disabled label="Disabled" toggle />
              </Form.Field>
              <Form.Field>
                <Dropdown icon="filter" multiple text="Filter Posts">
                  <Dropdown.Menu>
                    <Input
                      className="search"
                      icon="search"
                      iconPosition="left"
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header content="Tag Label" icon="tags" />
                    <Dropdown.Menu scrolling>
                      {tagOptions.map((option, key) => (
                        <Dropdown.Item key={key} {...option} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Field>
              <Form.Field>
                <TextArea
                  placeholder="Tell us more"
                  style={{ minHeight: 100 }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field
              control={TextArea}
              label="About"
              placeholder="Tell us more about you..."
            />
            <Form.Field
              control={Checkbox}
              label="I agree to the Terms and Conditions"
            />
            <Form.Field control={Button}>Submit</Form.Field>
          </Form>
        </Section>
        <Section title="Grid">
          <Grid columns={3} relaxed>
            <Grid.Column>
              <Segment basic>Left Column</Segment>
            </Grid.Column>
            <Divider vertical>Or</Divider>
            <Grid.Column>
              <Segment basic>Center Column</Segment>
            </Grid.Column>
            <Divider vertical>And</Divider>
            <Grid.Column>
              <Segment basic>Right Column</Segment>
            </Grid.Column>
          </Grid>
        </Section>
        <Section title="Menu (pointing down)">
          <Menu pointing>
            <Menu.Item name="home" />
            <Menu.Item name="messages" />
            <Menu.Item name="friends" />
            <Menu.Menu position="right">
              <Menu.Item>
                <Input icon="search" placeholder="Search..." />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Section>
        <Section title="Menu (tabs)">
          <div>
            <Menu attached="top" tabular>
              <Menu.Item name="bio" />
              <Menu.Item active name="photos" />
              <Menu.Menu position="right">
                <Menu.Item>
                  <Input
                    icon={{ link: true, name: "search" }}
                    placeholder="Search users..."
                    transparent
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Segment attached="bottom">this is tab content</Segment>
          </div>
        </Section>
        <Section title="Menu (side tabs)">
          <Grid>
            <Grid.Column width={4}>
              <Menu fluid tabular vertical>
                <Menu.Item name="bio" />
                <Menu.Item active name="pics" />
                <Menu.Item name="companies" />
                <Menu.Item name="links" />
              </Menu>
            </Grid.Column>

            <Grid.Column stretched width={12}>
              <Segment>
                This is an stretched segment (grid column). This segment will
                always match the tab height
              </Segment>
            </Grid.Column>
          </Grid>
        </Section>
        <Section title="Menu (controls)">
          <Menu vertical>
            <Menu.Item name="inbox">
              {" "}
              <Label color="teal">1</Label>
              Inbox
            </Menu.Item>
            <Menu.Item name="spam">
              <Label>51</Label>
              Spam
            </Menu.Item>
            <Menu.Item name="updates">
              <Label>1</Label>
              Updates
            </Menu.Item>
            <Menu.Item>
              <Input icon="search" placeholder="Search mail..." />
            </Menu.Item>
          </Menu>
        </Section>
        <Section title="Messages">
          <Message
            content="Get the best news in your e-mail every day."
            header="Message with Icon"
            icon="inbox"
          />
          <Message info>
            <Message.Header>Was this what you wanted?</Message.Header>
            <p>Did you know it&apos;s been a while?</p>
          </Message>
          <Message
            content="Visit our registration page, then try again."
            header="You must register before you can do that!"
            warning
          />
          <Message
            content="Visit our registration page, then try again."
            header="You must register before you can do that!"
            success
          />
          <Message
            header="You must register before you can do that!"
            list={[
              "You must include both a upper and lower case letters in your password.",
              "You need to select your home country.",
            ]}
            negative
          />
        </Section>
        <Section title="Table">
          <Table celled compact definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Registration Date</Table.HeaderCell>
                <Table.HeaderCell>E-mail address</Table.HeaderCell>
                <Table.HeaderCell>Premium Plan</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row error>
                <Table.Cell collapsing>
                  <Checkbox slider />
                </Table.Cell>
                <Table.Cell>John Lilki</Table.Cell>
                <Table.Cell>September 14, 2013</Table.Cell>
                <Table.Cell>jhlilk22@yahoo.com</Table.Cell>
                <Table.Cell>No</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <Checkbox slider />
                </Table.Cell>
                <Table.Cell>Jamie Harington</Table.Cell>
                <Table.Cell warning>
                  <Icon name="attention" />
                  Hostile
                </Table.Cell>
                <Table.Cell>jamieharingonton@yahoo.com</Table.Cell>
                <Table.Cell>Yes</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <Checkbox slider />
                </Table.Cell>
                <Table.Cell>Jill Lewis</Table.Cell>
                <Table.Cell>May 11, 2014</Table.Cell>
                <Table.Cell>jilsewris22@yahoo.com</Table.Cell>
                <Table.Cell>Yes</Table.Cell>
              </Table.Row>
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="4">
                  <Button
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                  >
                    <Icon name="user" /> Add User
                  </Button>
                  <Button size="small">Approve</Button>
                  <Button disabled size="small">
                    Approve All
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Section>
        <Section title="Modal">
          <Modal centered={false} trigger={<Button>Show Modal</Button>}>
            <Modal.Header>Select a Photo</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header>Default Profile Image</Header>
                <p>
                  We&apos;ve found the following gravatar image associated with
                  your e-mail address.
                </p>
                <p>Is it okay to use this photo?</p>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Section>
        <Section title="Popup">
          <Popup
            flowing
            hoverable
            trigger={<Button>Show flowing popup</Button>}
          >
            <Grid centered columns={3} divided>
              <Grid.Column textAlign="center">
                <Header as="h4">Basic Plan</Header>
                <p>
                  <b>2</b> projects, $10 a month
                </p>
                <Button>Choose</Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">Business Plan</Header>
                <p>
                  <b>5</b> projects, $20 a month
                </p>
                <Button>Choose</Button>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header as="h4">Premium Plan</Header>
                <p>
                  <b>8</b> projects, $25 a month
                </p>
                <Button>Choose</Button>
              </Grid.Column>
            </Grid>
          </Popup>
        </Section>
        <Section title="Sidebar">
          <Sidebar.Pushable as={Segment}>
            <Sidebar
              animation="overlay"
              as={Menu}
              icon="labeled"
              inverted
              vertical
              visible
              width="thin"
            >
              <Menu.Item as="a">
                <Icon name="home" />
                Home
              </Menu.Item>
              <Menu.Item as="a">
                <Icon name="gamepad" />
                Games
              </Menu.Item>
              <Menu.Item as="a">
                <Icon name="camera" />
                Channels
              </Menu.Item>
            </Sidebar>

            <Sidebar.Pusher>
              <Segment basic>
                <Header as="h3">Application Content</Header>
                <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Section>
        <Section title="Accordion">
          <Accordion styled>
            <Accordion.Title index={0}>
              <Icon name="dropdown" />
              What is a dog?
            </Accordion.Title>
            <Accordion.Content>
              <p>
                A dog is a type of domesticated animal. Known for its loyalty
                and faithfulness, it can be found as a welcome guest in many
                households across the world.
              </p>
            </Accordion.Content>

            {/* <Accordion.Title active={activeIndex === 1} index={1}>
              <Icon name="dropdown" />
              What kinds of dogs are there?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                There are many breeds of dogs. Each breed varies in size and
                temperament. Owners often select a breed of dog that they find
                to be compatible with their own lifestyle and desires from a
                companion.
              </p>
            </Accordion.Content> */}

            <Accordion.Title active={activeIndex === 2} index={2}>
              <Icon name="dropdown" />
              How do you acquire a dog?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <p>
                Three common ways for a prospective owner to acquire a dog is
                from pet shops, private owners, or shelters.
              </p>
              <p>
                A pet shop may be the most convenient way to buy a dog. Buying a
                dog from a private owner allows you to assess the pedigree and
                upbringing of your dog before choosing to take it home. Lastly,
                finding your dog from a shelter, helps give a good home to a dog
                who may not find one so readily.
              </p>
            </Accordion.Content>
          </Accordion>
        </Section>
        <Section title="Pagination">
          <Pagination defaultActivePage={5} totalPages={10} />
        </Section>
        <Section title="Container">
          <Container>
            <Header>Header</Header>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa strong. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec pede justo, fringilla
              vel, aliquet nec, vulputate eget, arcu.
            </p>
          </Container>
        </Section>
      </Segment>
    </Container>
  );
};

export const TestThemeView = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestThemeComp);
