/**
 * This module should provide functions to track all of the events being
 * tracked in the portal.
 *
 * No other module should be allowed to use the analytics client.
 *
 * In this way, we centralize all our analytics events, which makes it easier to:
 * - Provide a full list of the events we are tracking
 * - Find all places where we are tracking a certain event
 * - Make sure that we are providing the needed properties for an event
 * - Make sure that if we update the properties for an event, we properly pass the new
 *   properties from all places that we track that event from
 * - ...
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { delay, getSubscriptionWorkflow } from "application/utils";
import * as R from "ramda";
import { Plans } from "store";
import { temporaryAny } from "../../../types/any_types";
import {
  linkAnonymousUser,
  serverTrackAccountCreated,
  serverTrackCheckoutBillingInfoAdded,
  serverTrackCheckoutCancelled,
  serverTrackCheckoutPaymentInfoConfirmed,
  serverTrackSubscriptionPlanSelected,
  serverTrackVerificationCompleted,
} from "./server_analytics_event_procedures";

// Use to create delay between calls
const TRACKING_CALL_DELAY_MS = 200;
// sequence calls
const first = (runFirst: temporaryAny) => {
  runFirst();
  return delay(TRACKING_CALL_DELAY_MS);
};

/* Wrappers for Segment analytics API */

declare interface IAnalyticsJs {
  identify: (userId: string, traits?: temporaryAny) => void;
  track: (eventName: string, traits?: temporaryAny) => void;
  //connect anonymous user with id identity
  alias: (id: temporaryAny) => void;
  page: (name?: temporaryAny) => void;
  user: () => temporaryAny;
  reset: () => void;
}

declare const analytics: IAnalyticsJs;

/** identity and track user information  */
export const trackUserInfo = (id: string, traits?: temporaryAny) => {
  if (analytics) {
    if (traits) {
      // Only add additional traits to current user
      analytics.identify(id, traits);
    }
  } else {
    console.error("Identify was called before analytics is ready");
  }
};

export const trackNewUser = (id: string, traits?: temporaryAny) => {
  if (analytics) {
    analytics.identify(id, traits);
  } else {
    console.error("identify was called before analytics is ready");
  }
};

/** user signed in */
export const trackUserLoggedIn = (userId: string): void => {
  first(() => trackUserInfo(userId)).then(() => {
    analytics.track("Customer Portal - Logged In", { ff_id: userId });
    linkAnonymousUser(getAnonymousId());
  });
};

/**
 * Get auto assigned Anonymous Id
 */
export const getAnonymousId = (): string => analytics.user().anonymousId();

// Reset current user/traits
export const logout = (): void => {
  analytics.reset();
};

// TODO: this method is not called anywhere. Figure out with Will whether
// we should invoke it after a signup, or remove it altogether.
/** user successfully filled in sign up form
 *  1. update user info (include new traits)
 *  2. track event
 */
export const trackSignUpFormCompleted = (
  authenticationType: temporaryAny,
  traits: {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
    created_at: string;
  }
) => {
  first(() => trackNewUser(traits.id, traits)).then(() =>
    analytics.track("Acquisition - Sign Up Form Completed", {
      authentication_type: authenticationType,
      ff_id: traits.id,
      method: authenticationType,
      platform: "web",
    })
  );
};

/** user successfully completed sign up flow
 *  1. update user info (include new traits)
 *  2. track event
 */
export const trackSignUpCompleted = (
  authenticationType: temporaryAny,
  traits: {
    created_at: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    workflow: string;
  }
) => {
  first(() => trackNewUser(traits.id, traits)).then(() => {
    analytics.track("Acquisition - Account Created", {
      authentication_type: authenticationType,
      ff_id: traits.id,
      method: authenticationType,
      platform: "web",
    });
    linkAnonymousUser(getAnonymousId());
    serverTrackAccountCreated({
      authentication_type: "email",
      ff_id: traits.id,
      workflow: traits.workflow,
    });
  });
};

/** user successfully verified email address */
export const trackEmailConfirmed = ({
  authProvider,
  email,
  ffId,
}: {
  authProvider: string;
  email: string;
  ffId: string;
}): void => {
  const properties = {
    email,
    ff_id: ffId,
    method: authProvider,
  };
  analytics.track("Acquisition - Verification Completed", properties);
  serverTrackVerificationCompleted(properties);
};

/** user selected subscription plan and triggered 'chargebee' modal to complete subscription payment process. */
export const trackPlanSelected = (params: {
  ffId?: string;
  flow: string;
  plan: Plans.IPlanInfoData;
  redeemedCode?: string;
}) => {
  const traits = getTraitsForTrackPlanSelected(params);
  analytics.track("Acquisition - Subscription Plan Selected", traits);
  const serverTrackProps = {
    ...traits,
    workflow: getSubscriptionWorkflow(params.flow),
  };

  serverTrackSubscriptionPlanSelected(R.omit(["flow"], serverTrackProps));
};

interface ISubscriptionIdentifyTraits {
  subscriptionStatus?: string;
  subscriptionProvider: "chargebee";
  subscriptionExpirationDate?: string;
  subscriptionLanguageType: "multi" | "single";
  subscriptionCustomerType: "backer" | "paid";
  subscriptionLength: number;
  subscriptionPlanId: string;
}

/** user successfully completed subscription payment and reached "payment
 * confirmation" page after chargebee payment processing.user successfully
 * completed subscription payment and reached "payment confirmation" page after
 * chargebee payment processing. */
export const trackPlanSubscriptionCompleted = ({
  discount,
  ffId,
  flow,
  hasTrial,
  plan,
  price,
  redeemedCode,
  redeemedCoupon,
  revenue,
  subscriptionId,
  subscriptionStatus,
}: {
  discount: number;
  ffId: string;
  flow: string;
  hasTrial: boolean;
  plan: Plans.IPlanInfoData;
  price: number;
  redeemedCode?: string;
  redeemedCoupon?: string;
  revenue: number;
  subscriptionId: string;
  subscriptionStatus?: string;
}) => {
  const userInfo = getUserPlanTraits(plan, subscriptionStatus);
  trackUserInfo(ffId, userInfo);
  const traits = {
    ...userInfo,
    currency: "USD",
    discount,
    ...(ffId ? { ff_id: ffId } : {}),
    flow,
    hasTrial,
    price,
    ...(redeemedCoupon ? { redeemedCoupon } : undefined),
    ...(redeemedCode && { redeemedBenefits: "backer code", redeemedCode }),
    revenue,
    subscriptionId,
  };
  if (redeemedCoupon) {
    analytics.identify(ffId, { redeemedCoupon });
  }
  analytics.track(
    "Web - Facebook - Acquisition - Subscription Purchased",
    traits
  );
};

export const trackCheckoutBillingInfoAdded = ({
  ffId,
  flow,
}: {
  ffId?: string;
  flow?: string;
}): void => {
  const properties = {
    ...(ffId ? { ff_id: ffId } : {}),
    step: 2,
  };
  analytics.track("Acquisition - Checkout - Billing Info Added", properties);
  serverTrackCheckoutBillingInfoAdded({
    ...properties,
    workflow: getSubscriptionWorkflow(flow),
  });
};

export const trackCheckoutPaymentInfoConfirmed = ({
  ffId,
  flow,
}: {
  ffId?: string;
  flow?: string;
}) => {
  const properties = {
    ...(ffId ? { ff_id: ffId } : {}),
    step: 3,
  };
  analytics.track(
    "Acquisition - Checkout - Payment Info Confirmed",
    properties
  );
  serverTrackCheckoutPaymentInfoConfirmed({
    ...properties,
    workflow: getSubscriptionWorkflow(flow),
  });
};

export const trackCheckoutCancelled = ({
  ffId,
  flow,
  planId,
}: {
  ffId?: string;
  flow?: string;
  planId: string;
}) => {
  serverTrackCheckoutCancelled({
    ff_id: ffId ?? "",
    subscrptionPlanId: planId,
    workflow: getSubscriptionWorkflow(flow),
  });
};

/** Track current/visited page */
export const pageVisited = () => {
  analytics.page();
};

function getTraitsForTrackPlanSelected({
  ffId,
  flow,
  plan,
  redeemedCode,
}: {
  ffId?: string;
  flow: string;
  plan: Plans.IPlanInfoData;
  redeemedCode?: string;
}) {
  return {
    currency: "USD",
    ...(ffId ? { ff_id: ffId } : {}),
    flow,
    subscriptionAmount: plan.totalPrice,
    subscriptionCustomerType: plan.subscriptionType,
    subscriptionLanguageType: plan.languageType,
    subscriptionLength: plan.durationMonths,
    subscriptionPlanId: plan.id,
    subscriptionProvider: "chargebee",
    ...(redeemedCode && { redeemedBenefits: "backer code", redeemedCode }),
    value: plan.totalPrice,
  };
}

export const trackSignUpButtonClicked = ({
  className,
}: {
  className: string;
}): void => {
  analytics.track("Sign Up Button Clicked", {
    click_id: className,
  });
};

export const trackSignUpButtonClickedOnPlanCard = ({
  planId,
}: {
  planId: string;
}): void => {
  analytics.track("Sign Up Button Clicked", {
    planId,
  });
};

export const trackUpgradeSubscriptionButtonClicked = ({
  ffId,
}: {
  ffId: string;
}): void => {
  analytics.track("Subscription Upgraded", {
    ff_id: ffId,
  });
};

export const trackCancelSubscriptionButtonClicked = ({
  ffId,
}: {
  ffId: string;
}): void => {
  analytics.track("Subscription Cancelled", {
    ff_id: ffId,
  });
};

export const trackRenewSubscriptionButtonClicked = ({
  ffId,
}: {
  ffId: string;
}): void => {
  analytics.track("Subscription Renewed", {
    ff_id: ffId,
  });
};

export const trackBackerCodeRedeemed = ({ ffId }: { ffId: string }): void => {
  analytics.track("Backer Code Redeemed", {
    ff_id: ffId,
  });
};

/* Build plan/subscription information object for identity traits */
function getUserPlanTraits(
  plan: Plans.IPlanInfoData,
  subscriptionStatus?: string
): ISubscriptionIdentifyTraits {
  // ToDo: Add subscriptionExpirationDate when we can.
  return {
    subscriptionCustomerType: plan.subscriptionType,
    subscriptionLanguageType: plan.languageType,
    subscriptionLength: plan.durationMonths,
    subscriptionPlanId: plan.id,
    ...(subscriptionStatus && { subscriptionStatus }), // Only include if value is provided
    subscriptionProvider: "chargebee",
  };
}
