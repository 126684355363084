/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Store from "store";

// === PUBLIC API ===

export const hasStartedTrial = ({ user }: Store.IRootState): boolean =>
  !!user.trialExpiryDate;

export const isInTrial = (state: Store.IRootState): boolean =>
  getTrialDaysRemaining(state) > 0;

export const getTrialHeader = (state: Store.IRootState) =>
  isInTrial(state)
    ? "Welcome to your free trial."
    : "Your free trial has ended.";

export const getTrialExpiryMessage = (state: Store.IRootState) => {
  const trialDaysRemaining = getTrialDaysRemaining(state);
  if (trialDaysRemaining <= 0) {
    return "Check out our subscription plans below to continue your progress.";
  }
  if (trialDaysRemaining === 1) {
    return "Last day!";
  }
  return `${trialDaysRemaining} days left`;
};

// === HELPERS ===

export const getTrialDaysRemaining = ({ user }: Store.IRootState): number =>
  user.trialDaysRemaining || 0;
