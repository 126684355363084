/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Models,
  UserFacingWeeklyCoachingSubscription,
} from "@fluent-forever/types";
import {
  ADD_ACTIVE_WEEKLY_COACHING_SUBSCRIPTION,
  FETCH_ACTIVE_SUBSCRIPTION_FAIL,
  FETCH_ACTIVE_SUBSCRIPTION_INIT,
  FETCH_ACTIVE_SUBSCRIPTION_SUCCESS,
  UPDATE_ACTIVE_SUBSCRIPTION,
} from "./actions";
import { IActiveSubscriptionState } from "./types";

export const fetch = () => ({ type: FETCH_ACTIVE_SUBSCRIPTION_INIT });
export const onSuccess = (payload: Models.IAccountSettings) => ({
  payload,
  type: FETCH_ACTIVE_SUBSCRIPTION_SUCCESS,
});
export const onFail = (error: string) => ({
  error,
  type: FETCH_ACTIVE_SUBSCRIPTION_FAIL,
});
export const update = (payload: Partial<IActiveSubscriptionState>) => ({
  payload,
  type: UPDATE_ACTIVE_SUBSCRIPTION,
});
export const addActiveWeeklyCoachingSubscription = (
  payload: UserFacingWeeklyCoachingSubscription
) => ({ payload, type: ADD_ACTIVE_WEEKLY_COACHING_SUBSCRIPTION });
