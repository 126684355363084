/* eslint-disable react/prop-types */
import { Models } from "@fluent-forever/types";
import { scrollToTop } from "application/navigation";
import { HasAnotherSubscriptionResult } from "application/planSubscription";
import * as React from "react";
import { useState } from "react";
import { Header } from "semantic-ui-react";
import { subscribeToPlan } from "../../../../application/account";
import {
  getAnonymousId,
  trackSignUpButtonClickedOnPlanCard,
} from "../../../../features/analytics/procedures/analytics_event_procedures";
import { serverTrackSignUpButtonClicked } from "../../../../features/analytics/procedures/server_analytics_event_procedures";
import { SubscriptionExistsModal } from "../../../../features/subscription/components/SubscriptionExistsModal";
import { IPlanInfoData } from "../../../../store/modules/plans";
import { RedeemCode } from "../../../containers";
import { SubscriptionItem } from "../../controls";
import {
  CancellationPolicy,
  CouponCodeRedemptionHelp,
} from "../../views/InfoLinks";
import "./styles.css";

export interface IPlanSubscriptionProps {
  discounts?: Models.CouponDiscountType[];
  flow: string;
  isAllowRedeemCoupon?: boolean;
  onSubscribe?: (params: {
    discount?: Models.CouponDiscountType;
    flow?: string;
    forceStart?: boolean;
    plan: IPlanInfoData;
  }) => void;
  planButtonText: string;
  plans: IPlanInfoData[];
}

const defaultOnSubscribe: IPlanSubscriptionProps["onSubscribe"] = ({
  discount,
  flow,
  forceStart,
  plan,
}): Promise<unknown> => {
  scrollToTop();
  return subscribeToPlan({
    discount,
    flow: flow ?? "",
    forceStart,
    plan,
  });
};

export const PlanSubscriptions = ({
  discounts,
  flow,
  isAllowRedeemCoupon = true,
  onSubscribe = defaultOnSubscribe,
  planButtonText,
  plans,
}: IPlanSubscriptionProps): React.ReactElement => {
  const [planToRetry, setPlanToRetry] = useState<IPlanInfoData | undefined>(
    undefined
  );

  const callOnSubscribe = ({
    forceStart = false,
    plan,
  }: {
    forceStart?: boolean;
    plan: IPlanInfoData;
  }) =>
    onSubscribe({
      discount: discounts && discounts.length ? discounts[0] : undefined,
      flow,
      forceStart,
      plan,
    });

  const onSubscribeWithRetry = async (plan: IPlanInfoData) => {
    if (flow === "sign up" && planButtonText === "Sign Up") {
      trackSignUpButtonClickedOnPlanCard({ planId: plan.id });
      serverTrackSignUpButtonClicked({
        anonymousId: getAnonymousId(),
        properties: { planId: plan.id, workflow: "organic" },
      });
    }

    const result = ((await callOnSubscribe({
      plan,
    })) as unknown) as HasAnotherSubscriptionResult;
    if (!result?.success && result?.hasAnotherSubscription) {
      setPlanToRetry(plan);
    }
  };

  return (
    <React.Fragment>
      <div className="ui container plans">
        <Header
          size="large"
          style={{ color: "black", marginBottom: "15px", marginTop: "0" }}
          textAlign="center"
        >
          Single Language Subscriptions
        </Header>
        <SubscriptionExistsModal
          isOpen={!!planToRetry}
          onForceRetry={() => {
            if (planToRetry) {
              callOnSubscribe({ forceStart: true, plan: planToRetry });
              setPlanToRetry(undefined);
            }
          }}
        />
        {plans.map((plan) => {
          const {
            id,
            name,
            monthlyPrice,
            totalPrice,
            info,
            bestValue,
            warning,
          } = plan;
          return (
            <div className="plan_column" key={id}>
              <SubscriptionItem
                bestValue={!!bestValue}
                buttonText={planButtonText}
                discounts={discounts}
                hidePurchaseButton={false}
                info={info}
                key={id}
                monthlyPrice={monthlyPrice}
                onSubscribe={() => onSubscribeWithRetry(plan)}
                title={name}
                totalPrice={totalPrice}
                warning={warning}
              />
            </div>
          );
        })}
      </div>
      {isAllowRedeemCoupon && (
        <div className="ui container" style={{ padding: 20 }}>
          <RedeemCode
            onCodeRedeemed={() => {
              /* Add Handler After Code is redeemed */
            }}
          />
        </div>
      )}
      <CouponCodeRedemptionHelp />
      <CancellationPolicy />
    </React.Fragment>
  );
};
