import React from "react";
import { Button, Modal } from "semantic-ui-react";

export const SubscriptionExistsModal = ({
  isOpen,
  onForceRetry,
}: {
  isOpen: boolean;
  onForceRetry: () => void;
}): React.ReactElement => (
  <Modal open={isOpen}>
    <Modal.Header>There seems to be a problem!</Modal.Header>
    <Modal.Content>
      <p>
        You&apos;ve already started a subscription today - if you proceed, you
        could be charged twice. Log into your account after a few minutes to
        check your subscription status.
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button color={"black"} onClick={() => location.reload()}>
        Let me check...
      </Button>
      <Button color={"red"} onClick={onForceRetry}>
        Proceed anyway
      </Button>
    </Modal.Actions>
  </Modal>
);
