import AppComponent from "App";
import * as App from "application";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "theme/dist/theme.css";

//// Development env only
// const glob = global as any;
// glob.App = App;

if (App.applicationInstance) {
  ReactDOM.render(
    <Provider store={App.applicationInstance.store}>
      <Router history={App.applicationInstance.history}>
        <AppComponent />
      </Router>
    </Provider>,
    document.getElementById("root") as HTMLElement
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
