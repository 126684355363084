export * from "./actionCreators";
import { ISubscriptionCoupon, LanguagePairCode } from "@fluent-forever/types";
import { userReducer } from "./reducer";

export interface IUserState {
  id: string;
  firstName: string;
  displayName: string;
  email: string;
  isPendingEmail: boolean;
  isConfirmEmailLoading: boolean;
  activePlanId?: string; //Chargebee Id of current active plan
  subscriptions?: string[];
  trialExpiryDate?: Date;
  trialDaysRemaining?: number;
  subscriptionCoupon?: ISubscriptionCoupon;
  weeklyCoachingSignUpLanguage?: LanguagePairCode;
  weeklyCoachingSignUpPlanId?: string;
}

export default userReducer;
