import {
  getLearnLanguageName,
  LanguagePairCode,
  noRenderNull,
} from "@fluent-forever/types";
import {
  getLearnLanguage,
  isoDateStringToIsoDateOnlyString,
} from "@fluent-forever/utils";
import { useMachine } from "@xstate/react";
import moment from "moment";
import React from "react";
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { getIsEnrollmentFormEnabled } from "../functions/bootcamp_functions";
import { bootcampEnrollmentMachine } from "../machines/bootcamp_enrollment_machine";

export const BootcampEnrollment = (): React.ReactElement => {
  const [machine, send] = useMachine(bootcampEnrollmentMachine);
  const { context } = machine;
  const { selectedDate, selectedLangPairCode } = context;
  const isDisabled = !getIsEnrollmentFormEnabled(machine);

  const languageOptions = context?.availableLanguages?.map((lang) => ({
    key: lang,
    text: getLearnLanguageName(getLearnLanguage(lang)),
    value: lang,
  }));
  const startDateOptions = context?.availableDates?.map((lang) => ({
    key: isoDateStringToIsoDateOnlyString(lang.startDate),
    text: moment(lang.startDate).format("MMMM Do, YYYY"),
    value: isoDateStringToIsoDateOnlyString(lang.startDate),
  }));

  if (machine.matches("error")) {
    return (
      <Segment className="text container fluid">
        <Message
          content="We couldn't load this page. Please refresh the page and try again."
          header="Oops! Something went wrong.."
          negative
        />
      </Segment>
    );
  }

  return (
    <div style={{ paddingBottom: "3vw", paddingTop: "3vw" }}>
      <Segment className="text container fluid">
        {/* Show Loader when loading or submitting form data */}
        {machine.matches("loading") || machine.matches("submitting") ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading...</Loader>
          </Dimmer>
        ) : (
          noRenderNull
        )}

        <h1 className="ui header">Bootcamp Enrollment Form</h1>
        <p>
          Use this form to finish enrolling in your bootcamp. You will be able
          to see your bootcamp in the Fluent Forever app after successfully
          completing the form.
        </p>

        {machine.matches("error") ? (
          <Message
            content="Please try enrolling again or contact support for assistance"
            header="Oops! Something went wrong.."
            negative
          />
        ) : context.participant ? (
          <Message
            content="We've detected a past bootcamp enrollment on your account. There's no need to fill this out twice."
            header="Nice to see you again!"
          />
        ) : machine.matches("success") ? (
          <Message
            content="You should now see your bootcamp in the Fluent Forever app"
            header="Enrollment successful!"
            success
          />
        ) : (
          noRenderNull
        )}

        <Form onSubmit={() => send({ type: "SUBMIT" })}>
          <Form.Field>
            <label>Bootcamp Language</label>

            <Dropdown
              name="langPairCode"
              onChange={(_, { value }) => {
                const langPairCode = value as LanguagePairCode;
                send({ langPairCode, type: "SELECT_LANGUAGE" });
              }}
              options={languageOptions}
              placeholder="Select Language"
              selection
              value={selectedLangPairCode}
            />
          </Form.Field>

          <Form.Field>
            <label>Bootcamp Start Date</label>

            <Dropdown
              name="bootcampDate"
              onChange={(_, { value }) => {
                const bootcampDate = value as string;
                send({ bootcampDate, type: "SELECT_BOOTCAMP" });
              }}
              options={startDateOptions}
              placeholder="Select Start Date"
              selection
              value={selectedDate}
            />
          </Form.Field>

          <Button disabled={isDisabled} type="submit">
            Submit
          </Button>
        </Form>
      </Segment>
    </div>
  );
};
