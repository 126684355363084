import { CancellationSurveyResponse, PATH } from "@fluent-forever/types";
import {
  cancelSubscription as accountCancelSubscription,
  subscribeToPlan,
} from "../../../application/account";
import { httpRequest } from "../../../application/http_request";
import { fetchPlans } from "../../../application/plans";
import { ApiConfig } from "../../../config";
import { store } from "../../../store/configureStore";
import { trackCancelSubscriptionButtonClicked } from "../../analytics/procedures/analytics_event_procedures";

export const cancelSubscription = async ({
  isCurrentSubscriptionInApp,
  subscriptionId,
  subscriptionProviderUrl,
  surveyResponse,
  userId,
}: {
  isCurrentSubscriptionInApp: boolean;
  subscriptionId: string;
  subscriptionProviderUrl: string;
  surveyResponse: CancellationSurveyResponse;
  userId: string;
}): Promise<void> => {
  trackCancelSubscriptionButtonClicked({ ffId: userId });
  if (isCurrentSubscriptionInApp) {
    return redirectUserToStoreSubscriptions(subscriptionProviderUrl);
  }
  await Promise.all([
    accountCancelSubscription(subscriptionId, store.dispatch),
    httpRequest.post(
      ApiConfig.url + PATH.ANSWER_CANCELLATION_SURVEY,
      surveyResponse
    ),
  ]);
};

export const redirectUserToStoreSubscriptions = (
  providerUrl?: string
): void => {
  window.open(providerUrl, "_blank");
};

export const acceptAntiChurnOfferForSamePlan = async ({
  couponId,
}: {
  couponId: string;
}): Promise<void> => {
  await httpRequest.post(ApiConfig.url + PATH.SUBSCRIPTION_APPLY_COUPON, {
    coupon: couponId,
  });
};

export const acceptAntiChurnOfferForAnotherPlan = async ({
  couponId,
  planIdForOffer,
}: {
  couponId: string;
  planIdForOffer: string;
}): Promise<void> => {
  const plan = fetchPlans().find((plan) => plan.id === planIdForOffer);
  if (!plan) {
    throw new Error("Plan not found");
  }
  await subscribeToPlan({
    coupon: {
      couponId,
    },
    flow: "antiChurn",
    forceStart: true,
    plan,
  });
};
