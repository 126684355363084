/* eslint-disable @typescript-eslint/no-explicit-any */
import * as API from "api";
import * as App from "application";
import { AxiosStatic } from "axios";
import { History } from "history";
import { Application } from "store";
import configureStore from "store/configureStore";
import { Store } from "store/types";
import { loadUserInfoAndAccountSettings } from "./account";
import { httpRequest } from "./http_request";
import { configureNavigation } from "./navigation";
import { IHttpRequest } from "./utils";

// Application
export interface IApplication {
  store: any;
  history: History;
  httpRequest: IHttpRequest;
}

let isInitialized = false;
/** Initialize application and setup all dependencies
 * returns application root object
 */
export const initialize = (): IApplication => {
  if (isInitialized) {
    throw new Error("Application Initialize Can Only Be Called Once!");
  }
  return onCreateApplication();
};

/**  Create Application */
function onCreateApplication(): IApplication {
  //Redux Store
  const store = configureStore();
  //Navigation
  const history = configureNavigation(store.dispatch);

  // Authentication
  const authStorage = getAuthenticationStore(store);
  API.ConfigureFetchAuthentication(httpRequest as AxiosStatic, authStorage);
  //API.ConfigureFetchLoadingIndicator(httpRequest, () => store.dispatch(Store.Application.applicationLoading()), () => store.dispatch(Store.Application.applicationStopLoading()))

  // Ensure Token Renewal on 1st load & browser refresh
  App.Authentication.scheduleTokenRenewal(
    authStorage.getAccessTokenExpiry(),
    store.dispatch
  );

  // Re-Load user information/profile
  if (authStorage.isAuthenticated()) {
    //User Info from Auth0
    App.Authentication.loadAuthUserProfile(
      authStorage.getAccessToken(),
      store.dispatch
    );
    //User Info from API services
    loadUserInfoAndAccountSettings(store.dispatch);
  }

  isInitialized = true;
  store.dispatch(Application.applicationInitialized());
  console.debug("Application Initialized");
  return { history, httpRequest, store };
}

/** Creates interface for authentication flags in store */
function getAuthenticationStore(store: Store) {
  return {
    getAccessToken: () => App.Authentication.getAccessToken(store.getState()),
    getAccessTokenExpiry: () =>
      App.Authentication.getAccessTokenExpiry(store.getState()),
    getUserAuth0Id: () => App.Authentication.getUserId(store.getState()),
    isAuthenticated: () => App.Authentication.isAuthenticated(store.getState()),
  };
}
