import {
  getLearnLanguage,
  noRenderNull,
  UserFacingWeeklyCoachingSubscription,
} from "@fluent-forever/types";
import { useMachine } from "@xstate/react";
import moment from "moment";
import React, { useEffect } from "react";
import { Button, Image, List, Table, Transition } from "semantic-ui-react";
import { languageFlags } from "../../../assets/images/flags/flags";
import { languagePairNamesWithVariant } from "../../bootcamp/constants/language";
import { weeklyCoachingSettingsMachine } from "../machines/weekly_coaching_settings_machine";
import { WeeklyCoachingSettingsModal } from "./WeeklyCoachingCancelModal";

export const WeeklyCoachingSubscriptionsDetails = ({
  subscriptions: initialSubscriptions,
}: {
  subscriptions?: UserFacingWeeklyCoachingSubscription[];
}): React.ReactElement | null => {
  const [machine, send] = useMachine(weeklyCoachingSettingsMachine);

  const {
    context: { weeklyCoachingSubscriptions, errorMessage },
    value: currentState,
  } = machine;

  useEffect(() => {
    send({ send, type: "INITIALIZE" });
  }, []);

  useEffect(() => {
    if (currentState === "idle") {
      if (weeklyCoachingSubscriptions.length === 0 && initialSubscriptions) {
        send({ data: initialSubscriptions, type: "SET_SUBSCRIPTIONS" });
      } else if (!initialSubscriptions) {
        send("FETCH_SUBSCRIPTIONS");
      }
    } else if (
      currentState === "cancel_success" ||
      currentState === "reactivate_success"
    ) {
      send("DISMISS_MODAL");
    }
  }, [currentState]);

  const activeSubscriptions = weeklyCoachingSubscriptions.filter(
    (sub) => sub.status === "auto_renewing"
  );
  const hasActiveSubscriptions = !!activeSubscriptions.length;

  const cancelledSubscriptions = weeklyCoachingSubscriptions.filter(
    (sub) => sub.status === "cancelled"
  );
  const hasCancelledSubscriptions = !!cancelledSubscriptions.length;

  if (weeklyCoachingSubscriptions.length === 0) return noRenderNull;

  const shouldShowCancelModal =
    currentState === "cancel_modal" ||
    currentState === "cancelling_subscription" ||
    currentState === "cancel_failure";

  const shouldShowReactivateModal =
    currentState === "reactivate_modal" ||
    currentState === "reactivating_subscription" ||
    currentState === "reactivate_failure";

  return (
    <div>
      <WeeklyCoachingSettingsModal
        buttonText={
          shouldShowCancelModal
            ? "Yes, cancel my subscription!"
            : "Yes, renew my subscription!"
        }
        errorMessage={errorMessage}
        isOpen={shouldShowCancelModal || shouldShowReactivateModal}
        loading={
          currentState === "cancelling_subscription" ||
          currentState === "reactivating_subscription"
        }
        message={
          shouldShowCancelModal
            ? "Your Weekly Coaching subscription will immediatelly be cancelled and you will not receive a refund."
            : "Your subscription will become active and it's expiration date will turn into the next billing date."
        }
        onDismiss={() => send("DISMISS_MODAL")}
        onSubmit={() =>
          send(
            shouldShowCancelModal
              ? "CONFIRM_CANCEL_ACTIVE_SUBSCRIPTION"
              : "CONFIRM_REACTIVATE_SUBSCRIPTION"
          )
        }
        title={
          shouldShowCancelModal
            ? "Cancel Weekly Coaching Subscription"
            : "Reactivate Weekly Coaching Subscription"
        }
      />
      {hasActiveSubscriptions || hasCancelledSubscriptions ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={"5"}>
                Weekly Coaching Subscriptions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {hasActiveSubscriptions ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Next Billing At</Table.HeaderCell>
                  <Table.HeaderCell>Billing Amount</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {activeSubscriptions.map((subscription, i) => (
                  <Table.Row key={i}>
                    <Table.Cell verticalAlign="middle">
                      <Transition.Group as={List} verticalAlign="middle">
                        <List.Item>
                          <Image
                            avatar
                            size="mini"
                            spaced="right"
                            src={
                              languageFlags[
                                getLearnLanguage(subscription.langPairCode)
                              ]
                            }
                          />
                          <List.Content
                            content={`Weekly Coaching - ${
                              languagePairNamesWithVariant[
                                subscription.langPairCode
                              ]
                            }`}
                          />
                        </List.Item>
                      </Transition.Group>
                    </Table.Cell>
                    <Table.Cell positive>Active</Table.Cell>
                    <Table.Cell>
                      {moment(subscription.nextBillingAt).format("MMM Do YYYY")}
                    </Table.Cell>
                    {subscription.nextBillingAmount ? (
                      <Table.Cell>
                        {new Intl.NumberFormat("en-US", {
                          currency: "USD",
                          style: "currency",
                        }).format(subscription.nextBillingAmount / 100)}
                      </Table.Cell>
                    ) : (
                      <Table.Cell negative>Unavailable</Table.Cell>
                    )}
                    <Table.Cell>
                      <Button
                        negative
                        onClick={() =>
                          send({
                            id: subscription.id,
                            type: "CANCEL_ACTIVE_SUBSCRIPTION",
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>{" "}
            </>
          ) : (
            noRenderNull
          )}
          {hasCancelledSubscriptions ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell colSpan="2">Expires At</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {cancelledSubscriptions.map((subscription, i) => (
                  <Table.Row key={i}>
                    <Table.Cell verticalAlign="middle">
                      <Transition.Group as={List} verticalAlign="middle">
                        <List.Item>
                          <Image
                            avatar
                            size="mini"
                            spaced="right"
                            src={
                              languageFlags[
                                getLearnLanguage(subscription.langPairCode)
                              ]
                            }
                          />
                          <List.Content
                            content={`Weekly Coaching - ${
                              languagePairNamesWithVariant[
                                subscription.langPairCode
                              ]
                            }`}
                          />
                        </List.Item>
                      </Transition.Group>
                    </Table.Cell>
                    <Table.Cell negative>Cancelled</Table.Cell>
                    <Table.Cell colSpan="2">
                      {moment(subscription.expiresAt).format("MMM Do YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() =>
                          send({
                            id: subscription.id,
                            type: "REACTIVATE_CANCELLED_SUBSCRIPTION",
                          })
                        }
                        secondary
                      >
                        Renew
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          ) : (
            noRenderNull
          )}
        </Table>
      ) : (
        noRenderNull
      )}
    </div>
  );
};
