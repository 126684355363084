/* eslint-disable react/prop-types */
import * as React from "react";
import { Message } from "semantic-ui-react";
import { NotificationMessageType } from "store/modules/notifications";

export interface INotificationMessageProps {
  title: string;
  type: NotificationMessageType;
  details?: string;
  onHide?: () => void;
}

export const NotificationMessage: React.SFC<INotificationMessageProps> = (
  props
) => {
  const isError = props.type === NotificationMessageType.error;
  const isWarn = props.type === NotificationMessageType.warn;
  const isConfirm = props.type === NotificationMessageType.confirm;
  const isInfo = props.type === NotificationMessageType.info;

  return (
    <Message
      info={isInfo}
      negative={isError}
      onDismiss={props.onHide}
      positive={isConfirm}
      warning={isWarn}
    >
      <Message.Header>{props.title}</Message.Header>
      <p>{props.details}</p>
    </Message>
  );
};
