/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  getCampaignUuid,
  init,
  offerRedeemed,
  validateUser,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: FIX
} from "amex-perks-sdk";
import { AmexConfig } from "config";
import { useEffect } from "react";

export interface InitOptions {
  mode: "qa" | "development" | "production";
  campaignUuid: string;
  merchantApiKey: string;
  shouldStripUrl: boolean;
}

export interface PerkOffer {
  order_cost: number;
  currency: "USD";
  metadata: { offer: string };
}

const isDevelopmentBuild = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const isAmexEnabled = () =>
  AmexConfig !== undefined && AmexConfig.merchantApiKey;

export const initialize = (options?: Partial<InitOptions>): void => {
  if (!isAmexEnabled()) {
    console.debug("Amex Perks not enabled");
    return;
  }

  const config = {
    ...AmexConfig,
    ...options,
    campaignUuid: getCampaignUuid(),
  };

  init(config);

  if (isDevelopmentBuild()) {
    console.debug("Amex Perks enabled", config);
  } else {
    console.debug("Amex Perks enabled");
  }
};

export const authorizeUser = (): Promise<unknown> => {
  if (!isAmexEnabled()) {
    return Promise.resolve();
  }
  return validateUser();
};

export const reportOfferRedeemed = (
  options?: Partial<PerkOffer>
): Promise<unknown> => {
  if (!isAmexEnabled()) {
    return Promise.resolve();
  }
  const payload = {
    currency: "USD",
    metadata: { offer: "fluent-forever-6-months" },
    order_cost: 0,
    ...options,
  };
  return offerRedeemed(payload); // Can throw if not intialized or bad keys, etc.
};

export const useAmexPerks = () => {
  useEffect(() => {
    initialize();
  }, []);
};
