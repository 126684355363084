/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { ISubscriptionData } from "./models";

export interface ISubscriptionState {
  readonly data: ISubscriptionData[];
  readonly loading: boolean;
  readonly error?: string;
}

export type SubscriptionsAction = ActionType<typeof actions>;

const initialState: ISubscriptionState = {
  data: [],
  loading: false,
};

const reducer = (state: ISubscriptionState = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_SUBSCRIPTIONS_INIT:
      return { ...state, loading: true };
    case actions.FETCH_SUBSCRIPTIONS_SUCCESS:
      return { ...state, data: action.subscriptions, loading: false };
    case actions.FETCH_SUBSCRIPTIONS_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default reducer;
