/* eslint-disable react/prop-types */
import { Models } from "@fluent-forever/types";
import * as App from "application";
import { RedirectToDefaultRoute } from "components/containers";
import { PlanSubscriptions } from "components/presentational/controls/SubscriptionPlans/PlanSubscriptions";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState, Plans } from "store";

export interface IPlanUpgradeProps {
  isAllowNewPlan: boolean;
  plans: Plans.IPlanInfoData[];
  discounts?: Models.CouponDiscountType[];
}

const NewPlanContainer: React.SFC<IPlanUpgradeProps> = (props) => {
  if (props.isAllowNewPlan === false || props.plans.length === 0) {
    return <RedirectToDefaultRoute />;
  }
  return (
    <React.Fragment>
      <PlanSubscriptions
        discounts={props.discounts}
        flow={"renew"}
        isAllowRedeemCoupon={true}
        planButtonText="Choose Plan"
        plans={props.plans}
      />
    </React.Fragment>
  );
};

// === CONTAINER ===

const mapStateToProps = ({ activeSubscription }: IRootState) => ({
  discounts: activeSubscription.discounts,
  isAllowNewPlan: activeSubscription.isAllowNewPlan,
  plans: App.Plans.fetchPlans(),
});

export const PlanRenewView = connect(mapStateToProps)(NewPlanContainer);
