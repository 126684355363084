import * as React from "react";
import { SVGProps } from "react";

export const CheckIcon = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg fill="none" height="32" viewBox="0 0 33 32" width="33" {...props}>
    <rect fill="#74E09A" height="30" rx="15" width="30" x="1.5" y="1" />
    <path
      d="M10.9668 16.2969L14.1367 19.6934L22.0332 12.3071"
      stroke="#262628"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
    <rect
      height="30"
      rx="15"
      stroke="black"
      strokeWidth="2"
      width="30"
      x="1.5"
      y="1"
    />
  </svg>
);
