/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Args, Models, REST } from "@fluent-forever/types";
import { ApiConfig as CONFIG_API } from "config/config";
import { IHttpRequest } from "../application/utils";

/** Cancel user subscription */
export const cancelSubscription = async (
  body: Args.ICancelSubscriptionArg,
  request: IHttpRequest
): Promise<Models.IExecutionResult> => {
  return request
    .post(CONFIG_API.url + REST.PATH.SUBSCRIPTION_CANCEL, body)
    .then((rst) => rst.data);
};

/** Get subscription coupon info */
export const getCouponInfo = async (
  coupon: string,
  request: IHttpRequest
): Promise<Models.ISubscriptionCoupon> => {
  return request
    .get(CONFIG_API.url + REST.PATH.SUBSCRIPTION_COUPON_INFO, {
      params: { coupon },
    })
    .then((rst) => rst.data);
};

export const getChurnCoupon = async (
  request: IHttpRequest
): Promise<Models.ISubscriptionCoupon> => {
  return request
    .get(CONFIG_API.url + REST.PATH.GET_CHURN_COUPON)
    .then((rst) => rst.data.data);
};
