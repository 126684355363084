import {
  CancellationSurveyReason,
  CancellationSurveyReasonOther,
} from "@fluent-forever/types";

export const getCanCancelSubscription = ({
  otherReason,
  selectedOption,
}: {
  otherReason: string;
  selectedOption?: CancellationSurveyReason;
}): boolean =>
  selectedOption !== undefined &&
  (selectedOption !== CancellationSurveyReasonOther.OTHER || !!otherReason);

export const isPlanIdMonthlyPlan = (planId?: string): boolean =>
  planId === "SL-Monthly";
