import { ISubscriptionCoupon } from "@fluent-forever/types";
import FluentForeverWhiteLogo from "assets/images/ff-logo-white.png";
import React from "react";
import {
  Footer as OldFooter,
  Layout,
  LayoutContent,
  LayoutHeader,
} from "theme";
import { ErrorHandler } from "../../../components/containers";
import {
  BannerAd,
  UserMenu,
} from "../../../components/presentational/application";
import { LandingCouponPage } from "../../../components/presentational/views/Promo/LandingCouponPage/LandingCouponPage";
import { AppConfig } from "../../../config";
import { Image } from "../../image/components/Image";

export const OldPromoVersion = ({
  coupon,
  onSignUpPressed,
}: {
  coupon: ISubscriptionCoupon;
  onSignUpPressed: (buttonClassName: string) => unknown;
}): React.ReactElement => {
  const ctaButtonText = "Sign Up";

  return (
    <Layout>
      <BannerAd />
      <LayoutHeader
        logo={
          <div>
            <Image src={FluentForeverWhiteLogo} style={{ width: 160 }} />
          </div>
        }
        onHeaderClick={() => window.open("http://fluent-forever.com", "_self")}
      >
        <UserMenu />
      </LayoutHeader>
      <LayoutContent>
        <ErrorHandler />
        <LandingCouponPage
          coupon={coupon}
          onSignUp={onSignUpPressed}
          signUpButtonText={ctaButtonText}
        />
      </LayoutContent>
      <OldFooter NewsLetterListGUID={AppConfig.newsLetterFormGUID} />
    </Layout>
  );
};
