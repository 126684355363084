/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Store from "store";
import { IRootState } from "store";
import { NotificationMessageType } from "store/modules/notifications";
import { Dispatch } from "../store/types";
import { temporaryAny } from "../types/any_types";
import { scrollToTop } from "./navigation";

/** Fail Handler */
export const onFail = (
  message: string,
  dispatch: Dispatch,
  err?: temporaryAny
) => {
  //Errors show on top make sure user can see it
  scrollToTop();
  let error = String(err || "");
  if (err) {
    if (err.response && err.response.data) {
      console.debug(err.response.data);
      if (
        err.response.data.message &&
        typeof err.response.data.message === "string"
      ) {
        error = String(err.response.data.message);
      }
    } else if (err.message) {
      error = err.message;
    } else if (err.errorDescription) {
      error = err.errorDescription;
    }
  }
  dispatch(Store.Notifications.raiseError(message, error));
};

/** Success Confirmation Handler */
export const onSuccess = (
  message: string,
  dispatch: Dispatch,
  details?: string
) => {
  //Confirmation show on top make sure user can see it
  scrollToTop();
  dispatch(Store.Notifications.raiseSuccess(message, details));
};

/** User Warning Message */
export const Warning = (
  title: string,
  dispatch: Dispatch,
  message?: string
) => {
  //Confirmation show on top make sure user can see it
  scrollToTop();
  dispatch(Store.Notifications.raiseWarning(title, message));
};

export const clearUserMessages = (dispatch: Dispatch) => {
  dispatch(Store.Notifications.clearNotifications());
};

const userNotificationTypes = [
  NotificationMessageType.info,
  NotificationMessageType.confirm,
  NotificationMessageType.warn,
  NotificationMessageType.error,
];

export const getUserNotifications = (state: IRootState) => {
  return getNotificationsByType(state, userNotificationTypes);
};

/** Select notification messages of specific type */
export const getNotificationsByType = (
  { notifications }: IRootState,
  includeTypes: NotificationMessageType[]
) => {
  return notifications && notifications.messages
    ? notifications.messages.filter((m) => includeTypes.includes(m.type))
    : [];
};
