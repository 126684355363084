import { ISubscriptionCoupon } from "@fluent-forever/types";
import { LanguageFlags } from "components/presentational/common/LanguageFlags";
import * as React from "react";
import { Container, Header, List } from "semantic-ui-react";
import { ButtonLarge } from "theme";
import { Video } from "../../../common/Video";
import { getCouponDisplayStrings } from "./logic/landingCouponPageLogic";
import "./style.css";

export interface ILandingCouponPageProps {
  coupon: ISubscriptionCoupon;
  signUpButtonText: string;
  onSignUp: (buttonClassName: string) => void;
}

export const LandingCouponPage = ({
  coupon,
  signUpButtonText,
  onSignUp,
}: ILandingCouponPageProps): React.ReactElement => {
  const couponDetails = getCouponDisplayStrings(coupon);

  return (
    <>
      <div className="landing-coupon__section-secondary">
        <Container style={{ width: 600 }}>
          <img
            className="landing-getapp__icon"
            src={coupon.metadata.logoUrl ?? "assets/images/ff-logo.svg"}
          />
          <Header as="h1" style={{ marginTop: 0 }}>
            {couponDetails.title}
          </Header>
          <p>
            {`${couponDetails.intro} to the
            most crowdfunded app in history: Fluent Forever. It’ll teach you to
            think in a new language, rather than just picking up a few words and
            phrases. No aimless memorization or BS translation exercises – just
            solid science on the best ways to jam new sounds, words, and
            sentences into your brain and keep them there long term.`}
          </p>
          <Header as="h3">
            {`Before you redeem this offer, let's make sure we're actually a good fit for you. Watch this first:`}
          </Header>
          <Video src="https://www.youtube.com/embed/96azqpKFLQg" />
        </Container>
      </div>
      <div className="landing-coupon__section-primary">
        <Container style={{ width: 600 }}>
          <Header as="h2">Our current languages:</Header>
          <LanguageFlags />
          <p>
            <strong>Don’t see your language?</strong>
            <br />
            <a href="https://fluentforever.typeform.com/to/qgKJct">
              Jump on our waitlist
            </a>{" "}
            {`and we’ll give you a discount once your language is ready!`}
          </p>
          <p className="landing-coupon__minimum-requirements">
            Minimum Device Requirements: iOS 10.0 or Android 7.0
          </p>
        </Container>
      </div>
      <div className="landing-coupon__section-secondary">
        <Container style={{ width: 600 }}>
          <Header as="h2">{`How to redeem this offer`}</Header>
          <List ordered>
            <List.Item>Start by clicking the Sign Up button below.</List.Item>
            <List.Item>
              Verify your email address (we’ll send you a link when you sign
              up).
            </List.Item>
            <List.Item>Download our app and log into your account.</List.Item>
            <List.Item>Learn stuff!</List.Item>
          </List>
          <Button
            onClick={() => onSignUp("landing-coupon__button")}
            text={signUpButtonText}
          />
          <p>{`${couponDetails.footerPricing}`}</p>
          <p>No commitment. Cancel anytime.</p>
        </Container>
      </div>
    </>
  );
};

interface IButtonProps {
  text: string;
  onClick: () => void;
}

const Button = ({ text, onClick }: IButtonProps) => (
  <ButtonLarge
    className="landing-coupon__button"
    id="sign_up_button"
    onClick={onClick}
  >
    {text}
  </ButtonLarge>
);
