import AmexLogo from "assets/images/amex.svg";
import gsap from "gsap";
import React, { useEffect } from "react";
import { ScrollScene } from "scrollscene";
import { useMediaQueries } from "../../../hooks/useMediaQueries";
import "../styles/FourthSectionPromo.scss";

export const FourthSectionPromoAmex = ({
  onSignUp,
}: {
  onSignUp: () => void;
}): React.ReactElement => {
  const { mobile } = useMediaQueries();

  // INTENDED: need null for useRef
  // eslint-disable-next-line no-null/no-null
  const containerRef = React.useRef(null);
  useEffect(() => {
    const { current: containerElement } = containerRef;

    if (!containerElement) {
      return undefined;
    }

    const timeline = gsap.timeline({ paused: true });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollScene = new ScrollScene({
      gsap: { timeline },
      toggle: { className: "in-view", element: containerElement },
      triggerElement: containerElement,
      triggerHook: 0.8,
    });

    return () => {
      scrollScene.destroy();
    };
  });

  return (
    <div className="fourth-section" ref={containerRef}>
      <svg
        className="wave-container"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
      >
        <path
          className="wave"
          d="M 0,100  L 0,0 C 40,0 60,100 100,100 C 140,100 160,0 200,0 C 240,0 260,100 300,100"
        />
      </svg>
      {!mobile && (
        <span className="title">
          Learn grammar intuitively in the context of sentences{" "}
          <span className="highlight">you</span> care about.
        </span>
      )}
      <div className="button-container">
        <div className="button" onClick={onSignUp}>
          Redeem your 6 months now!
        </div>
        <div className="subtitle">
          Exclusive to American Express Card Members.
        </div>
      </div>
      <img className="amex-logo" src={AmexLogo} />
    </div>
  );
};
