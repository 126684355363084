import { assign, createMachine, send } from "xstate";
import { defaultErrorMessage } from "../constants/bootcamp_constants";
import {
  getAvailableBootcampDatesAfterNextWednesdayForLanguage,
  getAvailableBootcampLanguages,
  initiateBootcampCheckout,
} from "../procedures/bootcamp_machine_procedures";
import { calculateBootcampPrice } from "../procedures/bootcamp_procedures";
import {
  BootcampSignupContext,
  BootcampSignupEvent,
} from "../types/bootcamp_signup_machine_types";

export const bootcampSignupMachine = createMachine<
  BootcampSignupContext,
  BootcampSignupEvent
>({
  id: "bootcampSignup",
  initial: "fetchingLanguages",
  context: {
    availableBootcamps: [],
    availableLanguages: [],
    enrolledBootcamps: [],
    enrollmentReason: "",
    isIntense: true,
    isPrivate: true,
    isSixBootcamps: true,
    totalPrice: 0,
  },
  states: {
    generalError: {
      type: "final",
    },
    fetchingLanguages: {
      invoke: {
        id: "fetchLanguages",
        src: getAvailableBootcampLanguages,
        onDone: {
          target: "fetchingBootcamps",
          actions: assign((_, { data }) => ({
            availableLanguages: data,
            selectedLanguage: data[0],
          })),
        },
        onError: {
          target: "generalError",
          actions: assign((_, { data }) => {
            const error = data?.response?.data?.error;
            return {
              errorMessage:
                typeof error === "string" ? error : defaultErrorMessage,
            };
          }),
        },
      },
    },
    fetchingBootcamps: {
      invoke: {
        id: "fetchBootcamps",
        src: (ctx) =>
          getAvailableBootcampDatesAfterNextWednesdayForLanguage(
            ctx.selectedLanguage
          ),
        onDone: {
          target: "dataEntry",
          actions: [
            assign((_, { data }) => ({
              availableBootcamps: data,
              selectedBootcampId: data[0].id,
            })),
            send("RECALCULATE_PRICE"),
          ],
        },
        onError: {
          target: "generalError",
          actions: assign((_, { data }) => {
            const error = data?.response?.data?.error;
            return {
              errorMessage:
                typeof error === "string" ? error : defaultErrorMessage,
            };
          }),
        },
      },
    },
    dataEntry: {
      on: {
        UPDATE_SELECTED_LANGUAGE: {
          target: "fetchingBootcamps",
          actions: assign((_, evt) => ({ selectedLanguage: evt.value })),
        },
        UPDATE_REASON: {
          actions: assign((_, evt) => ({ enrollmentReason: evt.value })),
        },
        UPDATE_IS_INTENSE: {
          actions: [
            assign((_, evt) => ({ isIntense: evt.value })),
            send("RECALCULATE_PRICE"),
          ],
        },
        UPDATE_IS_PRIVATE: {
          actions: [
            assign((_, evt) => ({ isPrivate: evt.value })),
            send("RECALCULATE_PRICE"),
          ],
        },
        UPDATE_IS_SIX_BOOTCAMPS: {
          actions: [
            assign((_, evt) => ({ isSixBootcamps: evt.value })),
            send("RECALCULATE_PRICE"),
          ],
        },
        UPDATE_SELECTED_BOOTCAMP_ID: {
          actions: [
            assign((_, evt) => ({ selectedBootcampId: evt.value })),
            send("RECALCULATE_PRICE"),
          ],
        },
        START_CHECKOUT: {
          target: "checkout",
        },
      },
    },
    checkout: {
      invoke: {
        id: "checkout",
        src: initiateBootcampCheckout,
        onDone: {
          target: "success",
          actions: assign((_, { data }) => {
            return {
              enrolledBootcamps: data,
            };
          }),
        },
        onError: {
          target: "generalError",
          actions: assign((_, { data }) => {
            const error = data?.response?.data?.error;
            return {
              errorMessage:
                typeof error === "string" ? error : defaultErrorMessage,
            };
          }),
        },
      },
    },
    success: {
      type: "final",
    },
  },
  on: {
    RECALCULATE_PRICE: {
      actions: assign(({ isSixBootcamps, isPrivate, isIntense }) => ({
        totalPrice: calculateBootcampPrice({
          isIntense,
          isSixBootcamps,
          isPrivate,
        }),
      })),
    },
  },
});
