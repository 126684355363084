import * as React from "react";
import { SVGProps } from "react";

export const ArrowUpIcon = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg fill="none" height={35} viewBox="0 0 30 35" width={30} {...props}>
    <path
      d="M15.0962 3.04395V32.2214M15.0962 3.04395L26.9931 14.9409M15.0962 3.04395L3.19922 14.9409"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.91803}
    />
  </svg>
);
