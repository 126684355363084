/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction, createStandardAction } from "typesafe-actions";
import { INotificationMessage, NotificationMessageType } from ".";

export const RAISE_NOTIFICATION_MESSAGE = "RAISE_NOTIFICATION_MESSAGE";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

//actions
export const raiseNotification = createStandardAction(
  RAISE_NOTIFICATION_MESSAGE
).map((message: INotificationMessage) => ({ message }));

export const clearNotifications = createAction(CLEAR_NOTIFICATIONS);

export const raiseError = (title: string, details?: string, actions?: any) =>
  raiseNotification({
    actions,
    details,
    title,
    type: NotificationMessageType.error,
  });
export const raiseSuccess = (title: string, details?: string, actions?: any) =>
  raiseNotification({
    actions,
    details,
    title,
    type: NotificationMessageType.confirm,
  });
export const raiseWarning = (title: string, details?: string, actions?: any) =>
  raiseNotification({
    actions,
    details,
    title,
    type: NotificationMessageType.warn,
  });
export const raiseInformation = (
  title: string,
  details?: string,
  actions?: any
) =>
  raiseNotification({
    actions,
    details,
    title,
    type: NotificationMessageType.info,
  });
