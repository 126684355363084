/** Mapping Of Known Routes */
export enum ROUTES {
  LANDING = "/",
  LOGIN = "/login",
  SIGNUP = "/signup",
  PROMO = "/promo",
  BOOTCAMP = "/bootcamp",
  BOOTCAMP_ENROLL = "/bootcamp/enroll",
  WEEKLY_COACHING = "/weekly-coaching",
  WEEKLY_COACHING_LANDING = "/weekly-coaching/landing",
  COUPON = "/coupon",
  HOME = "/home",
  SETTINGS = "/settings",
  EMAIL_PENDING = "/pendingemailconfirmation",
  EMAIL_CONFIRMATION = "/confirmemail",
  RELOAD = "/reload",
  SUBSCRIBE = "/subscribe", //Subscribe to plan
  SUBSCRIPTION_UPGRADE = "/upgrade", //Upgrade plan
  SUBSCRIPTION_RENEW = "/renew", //Renew plan
  AUTHENTICATE = "/authenticate",
  TEST_THEME = "/theme", // Development style tests
}
