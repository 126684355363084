import {
  EvaluateUnpaidUserBootcampEnrollmentResultData,
  GetAvailableBootcampLanguagesResultData,
  GetAvailableBootcampsResultData,
  LanguagePairCode,
  SanityBootcampParticipant,
  ServiceResponseError,
} from "@fluent-forever/types";
import * as API from "../../../api";
import * as App from "../../../application";
import { scrollToTop } from "../../../application/navigation";
import { BootcampSignupContext } from "../types/bootcamp_signup_machine_types";

export const getAvailableBootcampLanguages = async (): Promise<GetAvailableBootcampLanguagesResultData> =>
  API.MXU.getAvailableBootcampLanguages();

export const getAvailableBootcampDatesForLanguage = async (
  selectedLanguage?: LanguagePairCode
): Promise<GetAvailableBootcampsResultData> => {
  if (selectedLanguage === undefined) throw new Error("No language selected");
  return API.MXU.getAvailableBootcampDatesForLanguage(selectedLanguage);
};

export const getAvailableBootcampDatesAfterNextWednesdayForLanguage = async (
  selectedLanguage?: LanguagePairCode
): Promise<GetAvailableBootcampsResultData> => {
  if (selectedLanguage === undefined) throw new Error("No language selected");
  return API.MXU.getAvailableBootcampDatesAfterNextWednesdayForLanguage(
    selectedLanguage
  );
};

export const getBootcampParticipant = async (): Promise<
  SanityBootcampParticipant | undefined
> => API.MXU.getBootcampParticipant();

export const createBootcampParticipant = async (
  langPairCode: LanguagePairCode,
  bootcampDate: string
): Promise<SanityBootcampParticipant> =>
  API.MXU.createBootcampParticipant(langPairCode, bootcampDate);

export const initiateBootcampCheckout = async (
  ctx: BootcampSignupContext
): Promise<
  EvaluateUnpaidUserBootcampEnrollmentResultData | ServiceResponseError<unknown>
> => {
  scrollToTop();
  if (ctx.selectedBootcampId) {
    return App.Bootcamp.subscribeToBootcamp({
      bootcampId: ctx.selectedBootcampId,
      isIntense: ctx.isIntense,
      isPrivate: ctx.isPrivate,
      isSixBootcamps: ctx.isSixBootcamps,
      reason: ctx.enrollmentReason,
    });
  } else {
    throw new Error("No bootcamp selected");
  }
};
