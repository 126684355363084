import {
  CancelBootcampEnrollmentsRequestPayload,
  CancelBootcampEnrollmentsResult,
  EvaluateUnpaidUserBootcampEnrollmentResult,
  EvaluateUnpaidUserBootcampEnrollmentResultData,
  GetAvailableBootcampLanguagesResult,
  GetAvailableBootcampLanguagesResultData,
  GetAvailableBootcampsResult,
  GetAvailableBootcampsResultData,
  LanguagePairCode,
  REST,
  SanityBootcampParticipant,
  ServiceResponseOk,
  SubmitUserBootcampEnrollmentRequestPayload,
  SubmitUserBootcampEnrollmentResult,
  SubmitUserBootcampEnrollmentResultData,
} from "@fluent-forever/types";
import axios from "axios";
import { ApiConfig as CONFIG_API } from "config/config";
import { selectUserId } from "../application/user";
import { store } from "../store/configureStore";

export const getAvailableBootcampLanguages = async (): Promise<GetAvailableBootcampLanguagesResultData> => {
  const { data: result } = await axios.get<GetAvailableBootcampLanguagesResult>(
    CONFIG_API.url + REST.PATH.MXU.PREFIX + REST.PATH.MXU.BOOTCAMP_LANGUAGES
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const getAvailableBootcampDatesForLanguage = async (
  langPairCode: LanguagePairCode
): Promise<GetAvailableBootcampsResultData> => {
  const { data: result } = await axios.get<GetAvailableBootcampsResult>(
    CONFIG_API.url +
      REST.PATH.MXU.PREFIX +
      REST.PATH.MXU.BOOTCAMP_DATES_AVAILABLE,
    {
      params: {
        langPairCode,
      },
    }
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const getAvailableBootcampDatesAfterNextWednesdayForLanguage = async (
  langPairCode: LanguagePairCode
): Promise<GetAvailableBootcampsResultData> => {
  const { data: result } = await axios.get<GetAvailableBootcampsResult>(
    CONFIG_API.url + REST.PATH.MXU.PREFIX + REST.PATH.MXU.BOOTCAMP_DATES,
    {
      params: {
        langPairCode,
      },
    }
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const getBootcampParticipant = async (): Promise<
  SanityBootcampParticipant | undefined
> => {
  const userId = selectUserId(store.getState());
  if (!userId) throw new Error("No user Id found.");

  const { data: result } = await axios.get<
    ServiceResponseOk<SanityBootcampParticipant | undefined>
  >(
    CONFIG_API.url +
      REST.PATH.MXU.PREFIX +
      REST.PATH.MXU.BOOTCAMP_PARTICIPANT.replace(/:userId/, userId)
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const createBootcampParticipant = async (
  langPairCode: LanguagePairCode,
  bootcampDate: string
): Promise<SanityBootcampParticipant> => {
  const { data: result } = await axios.post<
    ServiceResponseOk<SanityBootcampParticipant>
  >(
    CONFIG_API.url + REST.PATH.MXU.PREFIX + REST.PATH.MXU.BOOTCAMP_PARTICIPANTS,
    { bootcampDate, langPairCode }
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const evaluateUnpaidUserBootcampEnrollments = async (
  paymentId: string
): Promise<EvaluateUnpaidUserBootcampEnrollmentResultData> => {
  const {
    data: result,
  } = await axios.get<EvaluateUnpaidUserBootcampEnrollmentResult>(
    CONFIG_API.url +
      REST.PATH.MXU.PREFIX +
      REST.PATH.MXU.BOOTCAMP_EVALUATE_UNPAID,
    {
      params: {
        paymentId,
      },
    }
  );
  if (result.success) return result.data;
  else throw result.error;
};

export const submitBootcampEnrollment = async (
  payload: SubmitUserBootcampEnrollmentRequestPayload
): Promise<SubmitUserBootcampEnrollmentResultData> => {
  const { data: result } = await axios.post<SubmitUserBootcampEnrollmentResult>(
    CONFIG_API.url + REST.PATH.MXU.PREFIX + REST.PATH.MXU.BOOTCAMP_ENROLLMENT,
    payload
  );

  if (result.success) return result.data;
  else throw result.error;
};

export const cancelBootcampEnrollments = async (
  payload: CancelBootcampEnrollmentsRequestPayload
): Promise<CancelBootcampEnrollmentsResult> => {
  const { data: result } = await axios.post<CancelBootcampEnrollmentsResult>(
    CONFIG_API.url + REST.PATH.MXU.PREFIX + REST.PATH.MXU.BOOTCAMP_CANCEL,
    payload
  );
  return result;
};
