import * as React from "react";
import { SVGProps } from "react";

export const ChevronDownIcon = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg fill="none" height="15" viewBox="0 0 26 15" width="26" {...props}>
    <path
      d="M25.0195 2.94443L12.9991 14.9648L0.978699 2.94443L2.98187 0.939845L12.9991 10.9585L23.0164 0.939845L25.0195 2.94443Z"
      fill="inherit"
    />
  </svg>
);
