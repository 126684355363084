/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Models, REST } from "@fluent-forever/types";
import { ApiConfig, ApiConfig as CONFIG_API } from "config/config";
import { IHttpRequest } from "../application/utils";

/** User Account Settings Info  */
export const fetchAccountSettingsInfo = (
  request: IHttpRequest
): Promise<Models.IAccountSettings> => {
  return request
    .get(CONFIG_API.url + REST.PATH.USER_ACCOUNT_SETTINGS)
    .then((rst) => rst.data);
};

/** Fetch user info from API */
export const fetchUserInfo = async (request: IHttpRequest) => {
  const result = await request.get(ApiConfig.url + REST.PATH.USER_ACCOUNT);
  return result.data as Models.IUserAccount;
};

// Re-send email confirmation email
export const resendEmailConfirmation = async (request: IHttpRequest) => {
  return request
    .post(CONFIG_API.url + REST.PATH.RESEND_EMAIL_CONFIRMATION)
    .then((result) => result.data);
};
