import * as App from "application";
import PreviewImage from "assets/images/preview.png";
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import { IRootState } from "store";
import "./StartYourFreeTrial.css";

interface IStartYourFreeTrialComponentProps {
  username: string;
}

const StartYourFreeTrialComponent = ({
  username,
}: IStartYourFreeTrialComponentProps) => {
  return (
    <div className="start-your-free-trial__section">
      <Container>
        <div className="start-your-free-trial__container">
          <div className="start-your-free-trial__instructions_and_perks">
            <p className="start-your-free-trial__hello">{`Hello, ${username}!`}</p>
            <p className="start-your-free-trial__title">
              Start Your Free 14-Day Trial
            </p>
            <p className="start-your-free-trial__subtitle">
              To activate your trial, choose any plan and check out. You
              won&apos;t be charged until your trial ends, and you can cancel at
              any time.
            </p>
            <ul className="start-your-free-trial__perks">
              <li>Access to all our languages</li>
              <li>Unlimited access to all our features</li>
              <li>Unlock bonus trial days with streaks</li>
            </ul>
          </div>
          <div className="start-your-free-trial__image">
            <img src={PreviewImage} />
          </div>
        </div>
      </Container>
    </div>
  );
};

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => ({
  username: App.UserInfo.firstName(state),
});

export const StartYourFreeTrial = connect(mapStateToProps)(
  StartYourFreeTrialComponent
);
