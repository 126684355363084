/* eslint-disable react/prop-types */
import * as React from "react";
import MaskedInput from "react-text-mask";
import { Button, Header, Input, Modal } from "semantic-ui-react";
import "./styles.css";

export interface IRedeemCodeComponentProps {
  code: string;
  codeInfo: string;
  isInvalid?: boolean;
  isLoading?: boolean;
  onCodeValueChanged: (code: string) => void;
  onSubmitCode?: () => void;
  onGetCodeInfo?: () => void;
  onHideCodeInfo?: () => void;
}

export const RedeemCodeComponent = (
  props: IRedeemCodeComponentProps
): JSX.Element => {
  const showModal = props.codeInfo ? true : false;
  const loading = props.isLoading ? true : false;
  const error = props.isInvalid ? true : false;
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div className={"redeem-code-divider"}>
          <span className={"redeem-code-help-text"}>
            Already have a backer code? Note: this is not for coupons
          </span>
          <Input
            action
            className={`theme-action-input`}
            error={error}
            placeholder="XXXX-XXXX-XXXX"
          >
            <MaskedInput
              mask={[
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                "-",
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                "-",
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
                /[A-Z0-9]/,
              ]}
              onChange={(event) => props.onCodeValueChanged(event.target.value)}
              placeholder="XXXX-XXXX-XXXX"
              style={{
                fontSize: "14px",
                fontWeight: 100,
                letterSpacing: "2.2px",
              }}
              value={props.code}
            />
            <Button onClick={props.onGetCodeInfo}>Redeem</Button>
          </Input>
        </div>
      </div>

      <Modal
        closeIcon
        onClose={props.onHideCodeInfo}
        open={showModal}
        size="small"
      >
        <Header content="Redeem Code" />
        <Modal.Content>
          <p>{props.codeInfo}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={loading}
            loading={loading}
            onClick={props.onSubmitCode}
            primary
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
