/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import * as ActiveSubscription from "./activeSubscription";
import activeSubscription, {
  IActiveSubscriptionState,
} from "./activeSubscription";
import * as Application from "./application";
import application, { IApplicationState } from "./application";
import * as Authentication from "./authentication";
import authentication, { IUserAuthenticationState } from "./authentication";
import * as CouponCodes from "./couponcodes";
import languages, { languagesEpic } from "./languages";
import { ILanguageState } from "./languages/reducer";
import * as Navigation from "./navigation";
import * as Notifications from "./notifications";
import notifications, { INotificationsState } from "./notifications";
import * as Plans from "./plans";
import subscriptions, * as SubscriptionHistory from "./subscriptions";
import * as User from "./user";
import user, { IUserState } from "./user";

const Subscriptions = { ActiveSubscription, ...SubscriptionHistory };
export {
  Application,
  Authentication,
  CouponCodes,
  User,
  Notifications,
  Navigation,
  Subscriptions,
  Plans,
};

export interface IRootState {
  application: IApplicationState;
  user: IUserState;
  authentication: IUserAuthenticationState;
  languages: ILanguageState;
  activeSubscription: IActiveSubscriptionState;
  subscriptions: any;
  notifications: INotificationsState;
}

export const rootReducer = combineReducers({
  activeSubscription,
  application,
  authentication,
  languages,
  notifications,
  subscriptions,
  user,
});

export const rootEpic = combineEpics(languagesEpic);
