/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from "typesafe-actions";
import { temporaryAny } from "../../../types/any_types";
import * as actions from "./actions";
import { ILanguageData } from "./models";

export interface ILanguageState {
  data: ILanguageData[];
  loading: boolean;
  error?: string;
}

export type LanguagesAction = ActionType<typeof actions>;

const initialState: ILanguageState = {
  data: [{ id: "00", name: "English" }],
  loading: false,
};

const reducer = (
  state: ILanguageState = initialState,
  action: temporaryAny
) => {
  switch (action.type) {
    case actions.FETCH_LANGUAGES_INIT:
      return { ...state, loading: true };
    case actions.FETCH_LANGUAGES_SUCCESS:
      return { ...state, data: action.languages, loading: false };
    case actions.FETCH_LANGUAGES_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default reducer;
