import { assign, createMachine } from "xstate";
import {
  cancelActiveWeeklyCoachingSubscription,
  fetchWeeklyCoachingSubscriptions,
  reactivateCancelledWeeklyCoachingSubscription,
} from "../procedures/weekly_coaching_settings_machine_procedures";
import {
  WeeklyCoachingSettingsContext,
  WeeklyCoachingSettingsEvent,
  WeeklyCoachingSettingsMachineState,
} from "../types/weekly_coaching_settings_machine_types";

export const weeklyCoachingSettingsMachine = createMachine<
  WeeklyCoachingSettingsContext,
  WeeklyCoachingSettingsEvent,
  WeeklyCoachingSettingsMachineState
>(
  {
    id: "weeklyCoachingSignup",
    initial: "init",
    context: {
      send: () => {
        throw new Error("send not set");
      },
      weeklyCoachingSubscriptions: [],
    },
    states: {
      init: {
        on: {
          INITIALIZE: {
            target: "idle",
            actions: "initialize",
          },
        },
      },
      idle: {
        on: {
          FETCH_SUBSCRIPTIONS: {
            target: "fetching_subscriptions",
          },
          SET_SUBSCRIPTIONS: {
            actions: "setSubscriptions",
          },
          CANCEL_ACTIVE_SUBSCRIPTION: {
            target: "cancel_modal",
            actions: "onCancelActiveSubscription",
          },
          REACTIVATE_CANCELLED_SUBSCRIPTION: {
            target: "reactivate_modal",
            actions: "onReactivateCancelledSubscription",
          },
        },
      },
      fetching_subscriptions: {
        invoke: {
          id: "fetching_subscriptions",
          src: fetchWeeklyCoachingSubscriptions,
          onDone: {
            target: "idle",
            actions: "onFetchingSubscriptionsSuccess",
          },
          onError: {
            target: "fetching_error",
            actions: "onFetchingSubscriptionsError",
          },
        },
      },
      fetching_error: {
        on: {
          FETCH_AGAIN: {
            target: "fetching_subscriptions",
          },
        },
      },
      cancel_modal: {
        on: {
          CONFIRM_CANCEL_ACTIVE_SUBSCRIPTION: {
            target: "cancelling_subscription",
          },
          DISMISS_MODAL: {
            target: "idle",
          },
        },
      },
      cancelling_subscription: {
        invoke: {
          id: "cancelling_subscription",
          src: (ctx) => {
            if (!ctx.selectedSubscriptionId) return throwUnknownError;
            return cancelActiveWeeklyCoachingSubscription(
              ctx.selectedSubscriptionId
            );
          },
          onDone: {
            target: "cancel_success",
          },
          onError: {
            target: "cancel_failure",
            actions: "onCancelSubscriptionFailure",
          },
        },
      },
      cancel_success: {
        on: {
          DISMISS_MODAL: {
            target: "fetching_subscriptions",
          },
        },
      },
      cancel_failure: {
        on: {
          CONFIRM_CANCEL_ACTIVE_SUBSCRIPTION: {
            target: "cancelling_subscription",
          },
          DISMISS_MODAL: {
            target: "idle",
          },
        },
      },
      reactivate_modal: {
        on: {
          CONFIRM_REACTIVATE_SUBSCRIPTION: {
            target: "reactivating_subscription",
          },
          DISMISS_MODAL: {
            target: "idle",
          },
        },
      },
      reactivating_subscription: {
        invoke: {
          id: "reactivating_subscription",
          src: (ctx) => {
            if (!ctx.selectedSubscriptionId) return throwUnknownError;
            return reactivateCancelledWeeklyCoachingSubscription(
              ctx.selectedSubscriptionId
            );
          },
          onDone: {
            target: "reactivate_success",
          },
          onError: {
            target: "reactivate_failure",
            actions: "onReactivateSubscriptionFailure",
          },
        },
      },
      reactivate_success: {
        on: {
          DISMISS_MODAL: {
            target: "fetching_subscriptions",
          },
        },
      },
      reactivate_failure: {
        on: {
          CONFIRM_REACTIVATE_SUBSCRIPTION: {
            target: "reactivating_subscription",
          },
          DISMISS_MODAL: {
            target: "fetching_subscriptions",
          },
        },
      },
    },
  },
  {
    actions: {
      initialize: assign((_, evt) => {
        if (evt.type !== "INITIALIZE") return {};
        return {
          send: evt.send,
        };
      }),
      setSubscriptions: assign((_, evt) => {
        if (evt.type !== "SET_SUBSCRIPTIONS") return {};
        return {
          weeklyCoachingSubscriptions: evt.data,
        };
      }),
      onFetchingSubscriptionsSuccess: assign((_, evt) => {
        if (evt.type !== "done.invoke.fetching_subscriptions") return {};
        return {
          weeklyCoachingSubscriptions: evt.data,
        };
      }),
      onFetchingSubscriptionsError: assign((_, evt) => {
        if (evt.type !== "error.platform.fetching_subscriptions") return {};
        return {
          weeklyCoachingSubscriptions: [],
          errorMessage: evt.data,
        };
      }),
      onCancelActiveSubscription: assign((_, evt) => {
        if (evt.type !== "CANCEL_ACTIVE_SUBSCRIPTION") return {};
        return {
          selectedSubscriptionId: evt.id,
        };
      }),
      onReactivateCancelledSubscription: assign((_, evt) => {
        if (evt.type !== "REACTIVATE_CANCELLED_SUBSCRIPTION") return {};
        return {
          selectedSubscriptionId: evt.id,
        };
      }),
      onCancelSubscriptionFailure: assign((_, evt) => {
        if (evt.type !== "error.platform.cancelling_subscription") return {};
        return {
          errorMessage: evt.data,
        };
      }),
      onReactivateSubscriptionFailure: assign((_, evt) => {
        if (evt.type !== "error.platform.reactivating_subscription") return {};
        return {
          errorMessage: evt.data,
        };
      }),
    },
  }
);

const defaultErrorMessage =
  "An unknown error has occurred. If this persists, please contact support.";

const throwUnknownError = () => {
  throw new Error(defaultErrorMessage);
};
