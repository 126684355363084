/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAuthResult } from "api/Auth0";
import {
  AUTHENTICATION_FAIL,
  AUTHENTICATION_SUCCESS,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
} from "./actions";

/* login actions  */
export const loginStart = () => ({ type: LOGIN_START });

// User Logged Into system
export const loginSuccess = (authentication: IAuthResult) => ({
  payload: authentication,
  type: LOGIN_SUCCESS,
});

// User was authenticated successfully
export const authenticationSuccess = (authentication: IAuthResult) => ({
  payload: authentication,
  type: AUTHENTICATION_SUCCESS,
});
export const authenticationFail = (reason: string) => ({
  payload: reason,
  type: AUTHENTICATION_FAIL,
});

export const signUpStart = () => ({ type: SIGN_UP_START });

// User Signed Up Into system (the first time)
export const signUpSuccess = (authentication: IAuthResult) => ({
  payload: authentication,
  type: SIGN_UP_SUCCESS,
});

export const logout = () => ({ type: LOGOUT });
