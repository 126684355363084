import { formatISODateShort } from "@fluent-forever/utils";
import React from "react";
import "../styles/BootcampDate.scss";

export const BootcampDate = ({
  from,
  to,
}: {
  from: string;
  to: string;
}): React.ReactElement => {
  return (
    <span className="bootcamp-date">{`${formatISODateShort(
      from
    )} - ${formatISODateShort(to)}`}</span>
  );
};
