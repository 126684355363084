/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Models } from "@fluent-forever/types";
import { temporaryAny } from "../../../types/any_types";

//Validation
export const COUPON_VALIDATE_INIT = "COUPON_VALIDATE_INIT";
export const COUPON_VALIDATE_SUCCESS = "COUPON_VALIDATE_SUCCESS";
export const COUPON_VALIDATE_FAIL = "COUPON_VALIDATE_FAIL";
export const COUPON_VALIDATE_CANCEL = "COUPON_VALIDATE_CANCEL";

//Redeem
export const COUPON_REDEEM_INIT = "COUPON_REDEEM_INIT";
export const COUPON_REDEEM_SUCCESS = "COUPON_REDEEM_SUCCESS";
export const COUPON_REDEEM_FAIL = "COUPON_REDEEM_FAIL";

// action creators
//** Retrieve coupon code information and summary */
export const validateCouponCodeInit = (code: temporaryAny) => ({
  code,
  type: COUPON_VALIDATE_INIT,
});
//** Coupon code information received successfully */
export const validateCouponCodeSuccess = (
  codeInfo: Models.ICouponCodeInfo
) => ({ codeInfo, type: COUPON_VALIDATE_SUCCESS });
//** Coupon code validation failed */
export const validateCouponCodeFail = () => ({ type: COUPON_VALIDATE_FAIL });
//** Cancel coupon code validation */
export const validateCouponCodeCancel = () => ({
  type: COUPON_VALIDATE_CANCEL,
});

//** Retrieve coupon code information and summary */
export const redeemCouponCodeInit = () => ({ type: COUPON_REDEEM_INIT });
//** Coupon code information received successfully */
export const redeemCouponCodeSuccess = () => ({ type: COUPON_REDEEM_SUCCESS });
//** Coupon code validation failed */
export const redeemCouponCodeFail = () => ({ type: COUPON_REDEEM_FAIL });
