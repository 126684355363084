/* eslint-disable react/prop-types */
import {
  noRenderNull,
  UserAccountStatus,
  UserFacingBootcamp,
  UserFacingWeeklyCoachingSubscription,
} from "@fluent-forever/types";
import * as App from "application";
import React from "react";
import { Button, Icon, Label, Message, Popup, Table } from "semantic-ui-react";
import { temporaryAny } from "../../../types/any_types";
import { BootcampDate } from "../../bootcamp/components/BootcampDate";
import { WeeklyCoachingSubscriptionsDetails } from "../../weekly_coaching/components/WeeklyCoachingSubscriptionsDetails";
import "./subscriptionInfoStyles.css";

export interface ISubscriptionInfoProps {
  currentPlanId: string;
  subscriptionId: string;
  providerTitle: string;
  providerUrl?: string;
  isInAppSubscription: boolean;
  planName: string;
  status?: UserAccountStatus;
  expiry?: string;
  discounts?: string;
  nextBillingDate?: string;
  nextBillingAmount?: string;
  activeWeeklyCoachingSubscriptions?: UserFacingWeeklyCoachingSubscription[];
  enrolledBootcamps?: UserFacingBootcamp[];
  warn?: string;
  isLoading?: boolean;
  //Control Option Flags
  isAllowChangePaymentMethod: boolean;
  isAllowChangeBillingAddress: boolean;
  isAllowCancel: boolean;
  isAllowUpgradeSubscription: boolean;
  isAllowReNewSubscription: boolean;
  isBackerSubscription: boolean;
  //Methods
  openBillingAddress: () => void;
  openPaymentSources: () => void;
  onRenew?: () => void;
  onRenewWeeklyCoaching?: () => void;
  onUpgrade?: () => void;
  onCancel?: (subscriptionId: string) => void;
  onManageMXU?: () => void;
}

const noPadding = { paddingBottom: 0, paddingTop: 0 };
const createRow = (
  title: string,
  value: temporaryAny,
  removePadding?: boolean
) => {
  const styles = removePadding ? noPadding : undefined;
  if (value) {
    return (
      <Table.Row>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell style={styles}>{value}</Table.Cell>
      </Table.Row>
    );
  }
  return undefined;
};

const createWarning = (message?: string) => {
  if (message) {
    return (
      <Message attached="bottom" warning>
        <Icon name="warning sign" />
        {message}
      </Message>
    );
  }
  return undefined;
};

export const SubscriptionInfoInnerComponent = (
  props: ISubscriptionInfoProps
): JSX.Element => {
  const {
    activeWeeklyCoachingSubscriptions = [],
    enrolledBootcamps = [],
  } = props;
  const isLifetimeSubscription = App.Subscriptions.isLifetimeSubscription(
    props.currentPlanId
  );
  let status: JSX.Element | undefined = undefined;
  const warning = createWarning(props.warn);
  if (
    props.status === UserAccountStatus.Cancelled ||
    props.status === UserAccountStatus.Expired
  ) {
    status = (
      <Label color="red" style={{ float: "right" }}>
        {props.status}
      </Label>
    );
  }

  const billingAddressRow = createRow(
    "Billing Address",
    props.isAllowChangeBillingAddress && (
      <Button
        onClick={() => props.openBillingAddress()}
        size="small"
        style={{ padding: 12 }}
      >
        Edit
      </Button>
    ),
    true
  );

  const paymentMethodRow = createRow(
    "Payment Method",
    props.isAllowChangePaymentMethod && (
      <Button
        onClick={() => props.openPaymentSources()}
        size="small"
        style={{ padding: 12 }}
      >
        Edit
      </Button>
    ),
    true
  );

  const providerLabel = props.providerTitle;
  const providerRow = createRow("Subscription", providerLabel);
  const hasBootcamps = !!enrolledBootcamps.length;
  const hasActiveWeeklyCoachingSubscription =
    activeWeeklyCoachingSubscriptions.length > 0;

  const hasAutoRenewingWeeklyCoachingSubscriptions =
    activeWeeklyCoachingSubscriptions.filter(
      (sub) => sub.status === "auto_renewing"
    ).length > 0;

  const discountRow = createRow("Discounts", props.discounts);
  const expiryRow = createRow(
    "Expiry",
    hasAutoRenewingWeeklyCoachingSubscriptions ? "Not Applicable" : props.expiry
  );
  const nextBillDateRow = createRow(
    "Next Billing Date",
    hasAutoRenewingWeeklyCoachingSubscriptions
      ? "Not Applicable"
      : props.nextBillingDate
  );
  const nextBillAmountRow = createRow(
    "Next Bill Amount",
    hasAutoRenewingWeeklyCoachingSubscriptions
      ? "Not Applicable"
      : props.nextBillingAmount
  );

  return (
    <React.Fragment>
      {hasActiveWeeklyCoachingSubscription ? (
        <WeeklyCoachingSubscriptionsDetails
          subscriptions={activeWeeklyCoachingSubscriptions}
        />
      ) : (
        noRenderNull
      )}
      {Boolean(props.subscriptionId) && (
        <Table celled definition>
          <Table.Body>
            {providerRow}
            <Table.Row>
              <Table.Cell>Plan</Table.Cell>
              <Table.Cell>
                {props.planName}
                {!props.isBackerSubscription && status}
              </Table.Cell>
            </Table.Row>
            {hasBootcamps ? (
              <Table.Row>
                <Table.Cell>Enrolled MXU Bootcamps</Table.Cell>
                <Table.Cell>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {enrolledBootcamps.map(({ startDate, endDate }, i) => (
                      <BootcampDate from={startDate} key={i} to={endDate} />
                    ))}
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : undefined}
            {expiryRow}
            {!isLifetimeSubscription && nextBillDateRow}
            {!isLifetimeSubscription && nextBillAmountRow}
            {discountRow}
            {billingAddressRow}
            {paymentMethodRow}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell
                className="subscription-controls-container"
                colSpan="2"
              >
                {props.isAllowReNewSubscription && (
                  <Popup
                    closeOnTriggerMouseLeave
                    content="Purchase new subscription"
                    on="hover"
                    position="bottom right"
                    style={{ zIndex: 10 }}
                    trigger={
                      <Button
                        icon
                        labelPosition="left"
                        onClick={props.onRenew}
                        primary
                        size="small"
                      >
                        <Icon name="cart plus" />
                        Renew Subscription
                      </Button>
                    }
                  />
                )}
                {props.isAllowUpgradeSubscription && (
                  <Popup
                    closeOnTriggerMouseLeave
                    content="Purchase a different plan. Note your current plan will be upgraded and the remaining term will be added to your new plan."
                    on="hover"
                    position="bottom right"
                    style={{ zIndex: 10 }}
                    trigger={
                      <Button
                        disabled={props.isLoading}
                        icon
                        labelPosition="left"
                        onClick={props.onUpgrade}
                        primary
                        size="small"
                      >
                        <Icon name="cart plus" />
                        {hasActiveWeeklyCoachingSubscription
                          ? "Change Subscription Plan"
                          : "Upgrade Subscription"}
                      </Button>
                    }
                  />
                )}
                {props.isAllowCancel && (
                  <Popup
                    closeOnTriggerMouseLeave
                    content={
                      props.isInAppSubscription
                        ? `Cancel current in-app subscription via ${props.providerTitle}`
                        : "Cancel current subscription. Note no refund will be provided but you can still use the application until the end of subscription term."
                    }
                    on="hover"
                    position="bottom right"
                    style={{ zIndex: 10 }}
                    trigger={
                      <Button
                        disabled={props.isLoading}
                        icon
                        labelPosition="left"
                        negative
                        onClick={() =>
                          props.onCancel && props.onCancel(props.subscriptionId)
                        }
                        size="small"
                      >
                        <Icon name="remove" />
                        {props.isInAppSubscription
                          ? "Cancel In-App Subscription"
                          : "Cancel"}
                      </Button>
                    }
                  />
                )}
                {hasBootcamps && (
                  <Popup
                    closeOnTriggerMouseLeave
                    content="Cancel one or more MXU bootcamp enrollments. Note no refund will be provided but you can still use the application until the end of subscription term."
                    on="hover"
                    position="bottom right"
                    style={{ zIndex: 10 }}
                    trigger={
                      <Button
                        disabled={props.isLoading}
                        icon
                        labelPosition="left"
                        onClick={props.onManageMXU}
                        secondary
                        size="small"
                      >
                        <Icon name="pencil alternate" />
                        Manage MXU Bootcamp
                      </Button>
                    }
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {!props.isBackerSubscription && warning}
    </React.Fragment>
  );
};
