import { httpRequest } from "../application/http_request";
import { ApiConfig } from "../config";

export const isMaintenanceModeEnabled = async (): Promise<boolean> => {
  try {
    await httpRequest.get(ApiConfig.url);
    return false;
  } catch (e) {
    return !!e.response && e.response.status === 503;
  }
};
