/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineEpics, ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map, switchMap } from "rxjs/operators";
import * as actions from "./actions";
import { IFetchLanguageSuccessAction } from "./actions";
import { LanguagesAction } from "./reducer";
import { ILanguageData } from ".";

const API_URL = "https://interview-ste.azurewebsites.net/languages";

const fetchLanguagesEpic = (
  action$: Observable<LanguagesAction>
): Observable<LanguagesAction> => {
  return action$.pipe(
    ofType(actions.FETCH_LANGUAGES_INIT),
    switchMap(() =>
      ajax.getJSON(API_URL).pipe(
        map<any, IFetchLanguageSuccessAction>((response: ILanguageData[]) =>
          actions.fetchLanguagesSuccess(response)
        ),
        catchError((error) => {
          let message = "";
          if (error.xhr) {
            message = `Error ${error.xhr.status} : ${error.xhr.statusText}`;
          } else if (error.message) {
            message = error.message;
          }

          message = message || "Unkown error";
          return of(actions.fetchLanguagesFailure(message));
        })
      )
    )
  );
};

const languagesEpic = combineEpics(fetchLanguagesEpic);
export default languagesEpic;
