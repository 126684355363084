import { env } from "@fluent-forever/env";
import { ISubscriptionCoupon, LanguagePairCode } from "@fluent-forever/types";
import * as App from "application";
import { ROUTES } from "config";
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import { IRootState } from "store";
import EmailSentFooterIllustration from "../../../../assets/images/email-pending-footer-illustration.svg";
import EmailSentIllustration from "../../../../assets/images/email-sent-illustration.svg";
import { ReloadIcon } from "../../../../assets/svgs/ReloadIcon";
import { WeeklyCoachingSubscriptionAfterSignup } from "../../../../features/weekly_coaching/views/WeeklyCoachingSubscriptionAfterSignup";
import { Dispatch } from "../../../../store/types";
import "../../../../styles/tailwind.css";
import { RedeemSubscriptionCouponSection } from "../../common/RedeemSubscriptionCouponSection";

export interface IEmailPendingProps {
  coupon?: ISubscriptionCoupon;
  flow: string;
  hasSubscriptions: boolean;
  onResendEmail: () => Promise<unknown>;
  onTryConfirm: () => boolean; //re-login
  weeklyCoachingPlanSignUpLangPairCode?: LanguagePairCode;
  weeklyCoachingPlanSignUpPlanId?: string;
}

const EmailPendingView = ({
  coupon,
  weeklyCoachingPlanSignUpPlanId,
  weeklyCoachingPlanSignUpLangPairCode,
  hasSubscriptions,
  onResendEmail,
  onTryConfirm,
}: IEmailPendingProps) => {
  const canRedeemSubscriptionCoupon = !!coupon && !hasSubscriptions;
  const comesFromWeeklyCoachingPlanLanding =
    !!weeklyCoachingPlanSignUpPlanId &&
    !!weeklyCoachingPlanSignUpLangPairCode &&
    !hasSubscriptions &&
    env.asBoolean("REACT_APP_WEEKLY_COACHING_ENABLED", false);
  return (
    <div className="email-pending__section-secondary bg-white">
      <Container>
        {canRedeemSubscriptionCoupon && coupon ? (
          <RedeemSubscriptionCouponSection
            coupon={coupon}
            flow={"subscribe with referral"}
          />
        ) : comesFromWeeklyCoachingPlanLanding &&
          weeklyCoachingPlanSignUpLangPairCode &&
          weeklyCoachingPlanSignUpPlanId ? (
          <WeeklyCoachingSubscriptionAfterSignup
            langPairCode={weeklyCoachingPlanSignUpLangPairCode}
            planId={weeklyCoachingPlanSignUpPlanId}
          />
        ) : (
          <main className="bg-white">
            <div className="mt-[60px] mx-auto max-w-[1000px] flex flex-col items-center">
              <img
                className="w-[243px] md:w-[500px] h-auto"
                src={EmailSentIllustration}
              />
              <h3 className="mt-[20px] md:mt-[60px] text-[18px] md:text-[20px] text-black text-center font-circular font-medium md:font-semibold leading-[27px] tracking-[.5px]">
                Please check your inbox for confirmation email and click the
                link included.
              </h3>
              <div className="mt-[18px]">
                <p className="text-center font-circular">
                  Didn’t receive email?
                </p>
                <button
                  className="py-[10px] px-[34px] text-white text-[16px] font-circular font-medium bg-royal-blue rounded-full"
                  onClick={onResendEmail}
                >
                  Resend Confirmation Email
                </button>
              </div>
              <button
                className="mt-[20px] flex flex-row items-center"
                onClick={onTryConfirm}
              >
                <span className="text-[16px] text-royal-blue font-medium tracking-[.2px]">
                  Reload
                </span>
                <ReloadIcon className="ml-2 h-[24px] w-[24px]" />
              </button>
            </div>
            <div className="mt-[45px] md:mt-[90px] w-full bg-white flex flex-row justify-end md:justify-center">
              <img
                className="w-[228px] md:w-[686px] h-auto"
                src={EmailSentFooterIllustration}
              />
            </div>
          </main>
        )}
      </Container>
      {/* {!canRedeemSubscriptionCoupon && !comesFromWeeklyCoachingPlanLanding ? (
        <Instructions text={"Nice! You can now download the app and log in!"} />
      ) : // eslint-disable-next-line no-null/no-null
      null} */}
    </div>
  );
};

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => ({
  coupon: App.UserInfo.getSubscriptionCoupon(state),
  hasSubscriptions: App.UserInfo.hasSubscriptions(state),
  weeklyCoachingPlanSignUpLangPairCode: App.UserInfo.getWeeklyCoachingSignUpLanguage(
    state
  ),
  weeklyCoachingPlanSignUpPlanId: App.UserInfo.getWeeklyCoachingSignUpPlanId(
    state
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onResendEmail: () => App.EmailConfirmation.reSend(dispatch),
  onTryConfirm: () =>
    App.Navigation.redirectTo(dispatch, ROUTES.EMAIL_CONFIRMATION),
});

export const EmailPending = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailPendingView);
