/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import FluentForeverWhiteLogo from "assets/images/ff-logo-white.png";
import { ErrorHandler } from "components/containers";
import { Application } from "components/presentational";
import { BannerAd, UserMenu } from "components/presentational/application";
import { AppConfig, ROUTES } from "config";
import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { Footer, Layout, LayoutContent, LayoutHeader } from "theme";
import { RedesignedContentView } from "./components/presentational/application/RedesignedContextView";
import { LanguageBar } from "./components/presentational/common/LanguageBar";
import { Navbar } from "./components/presentational/common/Navbar";
import { CoolPromoPage } from "./features/coupon/views/CoolPromoPage";
import { CouponAmex } from "./features/coupon/views/CouponAmex";

interface IAppProps {
  onHeaderClicked: () => void;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  onHeaderClicked: () => window.open("http://fluent-forever.com", "_self"),
});

const App = (props: IAppProps) => {
  const logo = (
    <div>
      <Image src={FluentForeverWhiteLogo} style={{ width: 160 }} />
    </div>
  );
  return (
    <Switch>
      <Route path={ROUTES.PROMO}>
        <CoolPromoPage />
      </Route>
      <Route path={ROUTES.COUPON}>
        <CouponAmex />
      </Route>
      <Route
        exact
        path={[
          ROUTES.LANDING,
          ROUTES.EMAIL_PENDING,
          ROUTES.SUBSCRIBE,
          ROUTES.HOME,
        ]}
      >
        <>
          <Navbar />
          <LanguageBar />
          <RedesignedContentView />
          <Footer NewsLetterListGUID={AppConfig.newsLetterFormGUID} />
        </>
      </Route>
      <Route>
        <Layout>
          <BannerAd />
          <LayoutHeader logo={logo} onHeaderClick={props.onHeaderClicked}>
            <UserMenu />
          </LayoutHeader>
          <LayoutContent>
            <ErrorHandler />
            <Application.ContentView />
          </LayoutContent>
          <Footer NewsLetterListGUID={AppConfig.newsLetterFormGUID} />
          {/* This is needed for the main view to fit a minimum height */}
          <style>{` main { flex: 1; } `}</style>
        </Layout>
      </Route>
    </Switch>
  );
};

const AppState = connect(mapStateToProps, mapDispatchToProps)(App);
export default withRouter<any, any>(AppState);
