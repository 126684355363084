import { ISubscriptionCoupon } from "@fluent-forever/types";
import * as App from "application";
import { fetchPlans } from "application/plans";
import { PlanSubscriptions } from "components/presentational/controls/SubscriptionPlans/PlanSubscriptions";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "store";
import { RedeemSubscriptionCouponSection } from "../../common/RedeemSubscriptionCouponSection";
import { StartYourFreeTrial } from "./sections/StartYourFreeTrial";

// get all plans
const plans = fetchPlans();

interface IPlanSubscriptionViewProps {
  coupon?: ISubscriptionCoupon;
  hasSubscriptions: boolean;
}

const PlanSubscriptionView = ({
  coupon,
  hasSubscriptions,
}: IPlanSubscriptionViewProps) => {
  return (
    <>
      {!!coupon && !hasSubscriptions ? (
        <RedeemSubscriptionCouponSection
          coupon={coupon}
          flow={"redeem subscription coupon"}
        />
      ) : (
        <>
          <StartYourFreeTrial />
          <PlanSubscriptions
            flow={"sign up"}
            isAllowRedeemCoupon={true}
            planButtonText="Start Trial"
            plans={plans}
          />
        </>
      )}
    </>
  );
};

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => ({
  coupon: App.UserInfo.getSubscriptionCoupon(state),
  hasSubscriptions: App.UserInfo.hasSubscriptions(state),
});

export const PlanSubscription = connect(mapStateToProps)(PlanSubscriptionView);
