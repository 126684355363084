/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  APPLICATION_INITIALIZED,
  APPLICATION_LOADING,
  APPLICATION_STOP_LOADING,
  DISABLE_MAINTENANCE_MODE,
  ENABLE_MAINTENANCE_MODE,
} from "./actions";

/* application is initialized  */
export const applicationInitialized = () => ({ type: APPLICATION_INITIALIZED });
export const applicationLoading = () => ({ type: APPLICATION_LOADING });
export const applicationStopLoading = () => ({
  type: APPLICATION_STOP_LOADING,
});
export const enableMaintenanceMode = () => ({ type: ENABLE_MAINTENANCE_MODE });
export const disableMaintenanceMode = () => ({
  type: DISABLE_MAINTENANCE_MODE,
});
