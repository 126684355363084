/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CouponCodeType } from "@fluent-forever/types";
import { temporaryAny } from "../../../types/any_types";
import * as actions from "./actions";
import { ICouponcodeState } from ".";

const initialState: ICouponcodeState = {
  codeInfo: {
    codeType: CouponCodeType.CreditOnly,
    details: "",
    id: "",
    plan: undefined,
  },
};
export const couponCodeReducer = (
  state: ICouponcodeState = initialState,
  action: temporaryAny
) => {
  switch (action.type) {
    case actions.COUPON_VALIDATE_SUCCESS:
      return { ...state, codeInfo: action.codeInfo };
    case actions.COUPON_VALIDATE_FAIL:
    case actions.COUPON_VALIDATE_CANCEL:
    case actions.COUPON_REDEEM_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
