import { LanguagePairCode } from "@fluent-forever/types";
import { useMachine } from "@xstate/react";
import React from "react";
import { Loader } from "semantic-ui-react";
import { ROUTES } from "../../../config";
import { BootcampDate } from "../components/BootcampDate";
import {
  languagePairNames,
  languagePairNamesWithVariant,
} from "../constants/language";
import { bootcampSignupMachine } from "../machines/bootcamp_signup_machine";
import "../styles/BootcampForm.scss";

export const BootcampForm = (): React.ReactElement => {
  const [state, send] = useMachine(bootcampSignupMachine);

  const { context, value: currentState } = state;

  const {
    availableBootcamps,
    availableLanguages,
    enrolledBootcamps,
    enrollmentReason,
    errorMessage,
    selectedLanguage,
    selectedBootcampId,
    totalPrice,
    isPrivate,
    isIntense,
    isSixBootcamps,
  } = context;

  const languageName = selectedLanguage
    ? languagePairNames[selectedLanguage]
    : "";

  switch (currentState) {
    case "fetchingLanguages":
    case "fetchingBootcamps":
      return <Loader>Loading...</Loader>;
    case "generalError":
      return (
        <div className="error-container">
          <div className="inner-container">
            <h2>There was an issue!</h2>
            <p>{errorMessage}</p>
          </div>
        </div>
      );
    case "dataEntry":
    case "checkout":
      return (
        <div className="form-container">
          <form>
            <div className="input-label-container language-selector">
              <label htmlFor="language">Language:</label>
              <select
                id="language"
                name="language"
                onChange={(e) =>
                  send({
                    type: "UPDATE_SELECTED_LANGUAGE",
                    value: e.target.value as LanguagePairCode,
                  })
                }
                value={selectedLanguage}
              >
                {availableLanguages.map((langPairCode, i) => (
                  <option key={i} value={langPairCode}>
                    {languagePairNamesWithVariant[langPairCode]}
                  </option>
                ))}
              </select>
            </div>
            <h1>{`MXU Bootcamp - ${languageName}`}</h1>
            <br />
            <p>
              {`Fill in your details below to join an exclusive beta version of the
        12-day Fluent Forever Bootcamp. You will spend 10 or 20 minutes each day
        working with a ${languageName} teacher to create at least five new sentences to
        study. We encourage you to come with things that you'd like to talk
        about. If not, our coaches will ask you questions designed to get to
        know you better. At least one of these days, you'll create sentences
        while applying a new grammar module. At the end of the 12-day program,
        you will give a 5-minute speech in ${languageName} about yourself and/or
        something you love to the group!`}
            </p>
            <p>{`By the end of this program you will:`}</p>
            <ul>
              <li>
                {`Have up to 40 new highly personal and effective sentences to study.`}
              </li>
              <li>
                {`Be able to use the Fluent Forever system and app more effectively.`}
              </li>
              <li>{`Feel more confident speaking in ${languageName}.`}</li>
              <li>
                {`Be surprised how much more fluent you are after a short period of study.`}
              </li>
              <li>
                {`Be connected with a small motivated group of like-minded language learners.`}
              </li>
              <li>{`Meet the ${languageName} you.`}</li>
            </ul>
            <p>{`Spaces are very limited, and each Bootcamp fills to capacity, so if you are interested, don't delay.`}</p>
            <p>{`If for any reason, you cannot attend the program you have signed up for, we will give you credit to join another Bootcamp. Credit expires in 90 days.`}</p>
            <p>{`The investment is $150 per Bootcamp with optional upgrades for a more intense course and privacy, as well as discounts for signing up to a 6 Bootcamp subscription.`}</p>
            <br />
            <br />
            <label htmlFor="reason">{`Why do you want to learn ${languageName}?`}</label>
            <br />
            <span className="sublabel">{`Please tell us where and with whom you will be using ${languageName}.`}</span>
            <br />
            <textarea
              id="reason"
              onChange={(e) =>
                send({ type: "UPDATE_REASON", value: e.target.value })
              }
              rows={3}
              value={enrollmentReason}
            />
            <br />
            <br />
            <label htmlFor="date">{`Which bootcamp would you like to join?`}</label>
            <br />
            {selectedBootcampId && availableBootcamps.length ? (
              availableBootcamps.map((bootcamp, i) => (
                <React.Fragment key={i}>
                  <div className="input-label-container">
                    <input
                      checked={selectedBootcampId === bootcamp.id}
                      id={bootcamp.id}
                      name="date"
                      onChange={(e) =>
                        e.target.checked
                          ? send({
                              type: "UPDATE_SELECTED_BOOTCAMP_ID",
                              value: bootcamp.id,
                            })
                          : 0
                      }
                      type="radio"
                    />
                    <label htmlFor={bootcamp.id}>
                      <BootcampDate
                        from={bootcamp.startDate}
                        to={bootcamp.endDate}
                      />
                    </label>
                  </div>
                  <br />
                </React.Fragment>
              ))
            ) : (
              <React.Fragment />
            )}
            <br />
            <label>{`Would you like to upgrade to the Intense track?`}</label>
            <br />
            <span className="sublabel">{`You'll get 20 minutes a day (rather than 10) with a tutor and double the grammar sessions for an additional $95 per bootcamp.`}</span>
            <br />
            <div className="input-label-container">
              <input
                checked={isIntense}
                id="intense"
                name="intense"
                onChange={(e) =>
                  send({ type: "UPDATE_IS_INTENSE", value: e.target.checked })
                }
                type="checkbox"
                value="intense"
              />
              <label htmlFor="intense">{`Yes, I'd like to upgrade for $95`}</label>
            </div>
            <br />
            <br />
            <label>{`Would you like to opt-in to increased Privacy?`}</label>
            <br />
            <span className="sublabel">{`We're able to offer the Bootcamp at such an affordable price because we plan to use your content to create sentence content for the Fluent Forever app. The sentences you create or inspire will never be attached to you personally in the Fluent Forever app. However, we understand that some people would prefer not to share their content at all, so we offer complete privacy for an additional $50 per bootcamp.`}</span>
            <br />
            <div className="input-label-container">
              <input
                checked={isPrivate}
                id="privacy"
                name="privacy"
                onChange={(e) =>
                  send({ type: "UPDATE_IS_PRIVATE", value: e.target.checked })
                }
                type="checkbox"
                value="privacy"
              />
              <label htmlFor="privacy">{`Yes, add $50 to keep my content private`}</label>
            </div>
            <br />
            <br />
            <label>{`Subscribe to 6, get 10% off and extras`}</label>
            <br />
            <span className="sublabel">{`While many people find that a single Bootcamp can have significant results, we find that 6 Bootcamps leads to a lasting dramatic improvement in speaking ability. So, to make that level of commitment easier, we'll give you 10% off, an Avant Assessment which qualifies you for a Global Seal of Biliteracy, and private sessions with a MasterCoach to help plan your curriculum and future.`}</span>
            <br />
            <div className="input-label-container">
              <input
                checked={isSixBootcamps}
                id="six_bootcamps"
                name="six_bootcamps"
                onChange={(e) =>
                  send({
                    type: "UPDATE_IS_SIX_BOOTCAMPS",
                    value: e.target.checked,
                  })
                }
                type="checkbox"
                value="six_bootcamps"
              />
              <label htmlFor="six_bootcamps">{`Yes, subscribe me to 6 consecutive bootcamps for a 10% discount`}</label>
            </div>
            <br />
            <br />
            <input
              onClick={(e) => {
                e.preventDefault();
                send("START_CHECKOUT");
              }}
              type="submit"
              value={`Subscribe for $${totalPrice}`}
            />
          </form>
        </div>
      );
    case "success":
      return (
        <div className="hooray-container">
          <div className="inner-container">
            <h3>{`Hooray!`}</h3>
            <span>{`You've successfully enrolled in the following ${languageName} ${
              enrolledBootcamps.length > 1 ? "bootcamps" : "bootcamp"
            }:`}</span>
            <div className="dates-container">
              {enrolledBootcamps.map(({ startDate, endDate }, i) => (
                <BootcampDate from={startDate} key={i} to={endDate} />
              ))}
            </div>
            <span>
              You can see and manage your MXU bootcamp enrollments right on your{" "}
              <a href={ROUTES.SETTINGS}>account</a> page.
            </span>
            <span>{`Keep an eye on your email, we'll communicate the steps to follow in order to participate!`}</span>
            <span>{`If you have any questions, don't hesitate to contact our support.`}</span>
          </div>
        </div>
      );

    default:
      return <React.Fragment />;
  }
};
