import { getLearnLanguageName, LearnLanguageCode } from "@fluent-forever/types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { useRef } from "react";
import { languageFlags } from "../../../assets/images/flags/flags";

export const LanguageBar = (): React.ReactElement => {
  // eslint-disable-next-line no-null/no-null
  const flagsRef = useRef<HTMLDivElement>(null);

  return (
    <div className="w-full bg-gray-200 h-[52px] md:h-[72px]">
      <div className="h-full px-1 md:px-0 max-w-7xl mx-auto flex flex-row items-center">
        <button
          className="mr-1 md:mr-5"
          onClick={() => {
            if (!flagsRef.current) return;
            flagsRef.current.scrollLeft -= 100;
          }}
        >
          <ChevronLeftIcon className="text-gray-800 hover:text-gray-500 h-[30px] w-[30px]" />
        </button>
        <div
          className="h-full flex flex-row space-x-5 items-center scroll-smooth overflow-x-scroll snap-x no-scrollbar"
          ref={flagsRef}
        >
          {Object.keys(languageFlags)
            .filter((langCode) => langCode !== "en-US")
            .map((langCode) => (
              <div
                className="snap-end flex items-center flex-shrink-0"
                key={langCode}
              >
                <img
                  className="h-5 md:h-[30px] w-5 md:w-[30px] rounded-full shadow-sm"
                  src={languageFlags[langCode as LearnLanguageCode]}
                />
                <p className="ml-1 text-[12px] md:text-[13px] text-gray-800 font-circular font-medium uppercase tracking-[.5px]">
                  {getLearnLanguageName(langCode as LearnLanguageCode)}
                </p>
              </div>
            ))}
        </div>
        <button
          className="ml-1 md:ml-5"
          onClick={() => {
            if (!flagsRef.current) return;
            flagsRef.current.scrollLeft += 100;
          }}
        >
          <ChevronRightIcon className="text-gray-800 hover:text-gray-500 h-[30px] w-[30px]" />
        </button>
      </div>
    </div>
  );
};
