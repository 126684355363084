import {
  LanguagePairCode,
  SanityBootcampParticipant,
  UserFacingBootcamp,
} from "@fluent-forever/types";
import {
  assign,
  createMachine,
  StateFrom,
  StateMachine,
  StateSchema,
} from "xstate";
import { getBootcampParticipant } from "../../../api/mxu";
import {
  createBootcampParticipant,
  getAvailableBootcampDatesForLanguage,
  getAvailableBootcampLanguages,
} from "../procedures/bootcamp_machine_procedures";

export interface BootcampEnrollmentContext {
  availableDates?: UserFacingBootcamp[];
  availableLanguages?: LanguagePairCode[];
  error?: Error;
  participant?: SanityBootcampParticipant;
  selectedDate?: string;
  selectedLangPairCode?: LanguagePairCode;
}

export type BootcampEnrollmentEvent =
  | { type: "SUBMIT" }
  | { type: "SELECT_LANGUAGE"; langPairCode: LanguagePairCode }
  | { type: "SELECT_BOOTCAMP"; bootcampDate: string }
  | { type: "error.platform.loadBootcampData"; data: Error }
  | {
      type: "done.invoke.loadBootcampData";
      data: {
        dates: UserFacingBootcamp[];
        languages: LanguagePairCode[];
        participant: SanityBootcampParticipant | undefined;
      };
    };

export type BootcampEnrollmentStateMachine = StateFrom<
  StateMachine<
    BootcampEnrollmentContext,
    StateSchema<BootcampEnrollmentContext>,
    BootcampEnrollmentEvent
  >
>;

export const bootcampEnrollmentMachine = createMachine<
  BootcampEnrollmentContext,
  BootcampEnrollmentEvent
>(
  {
    id: "bootcampConfirmation",
    initial: "loading",
    states: {
      loading: {
        invoke: {
          id: "loadBootcampData",
          src: "loadBootcampData",
          onDone: {
            target: "idle",
            actions: "assignBootcampDataToContext",
          },
          onError: {
            target: "error",
            actions: "assignErrorToContext",
          },
        },
      },
      idle: {
        on: {
          SELECT_BOOTCAMP: { actions: "assignSelectedDateToContext" },
          SELECT_LANGUAGE: { actions: "assignSelectedLanguageToContext" },
          SUBMIT: {
            target: "submitting",
            cond: (ctx) => !!ctx.selectedDate && !!ctx.selectedLangPairCode,
          },
        },
      },
      submitting: {
        invoke: {
          src: "createBootcampParticipant",
          onDone: {
            target: "success",
          },
          onError: {
            target: "idle",
            actions: "assignErrorToContext",
          },
        },
      },
      success: { type: "final" },
      error: { type: "final" },
    },
  },
  {
    actions: {
      assignBootcampDataToContext: assign((_, event) => {
        if (event.type !== "done.invoke.loadBootcampData") return {};
        const { dates, languages, participant } = event.data;
        return {
          availableDates: dates,
          availableLanguages: languages,
          participant,
        };
      }),
      assignErrorToContext: assign((_, event) => {
        if (event.type !== "error.platform.loadBootcampData") return {};
        return { error: event.data };
      }),
      assignSelectedDateToContext: assign((_, event) => {
        if (event.type !== "SELECT_BOOTCAMP") return {};
        return { selectedDate: event.bootcampDate };
      }),
      assignSelectedLanguageToContext: assign((_, event) => {
        if (event.type !== "SELECT_LANGUAGE") return {};
        return { selectedLangPairCode: event.langPairCode };
      }),
    },
    services: {
      createBootcampParticipant: async (ctx) => {
        if (!ctx.selectedLangPairCode) {
          throw new Error("No language pair code selected.");
        }
        if (!ctx.selectedDate) {
          throw new Error("No bootcamp date selected.");
        }
        const participant = await createBootcampParticipant(
          ctx.selectedLangPairCode,
          ctx.selectedDate
        );

        return participant;
      },
      loadBootcampData: async (_) => {
        const [participant, languages, dates] = await Promise.all([
          getBootcampParticipant(),
          getAvailableBootcampLanguages(),
          getAvailableBootcampDatesForLanguage("en-US_es-MX"),
        ]);
        // User should only see the most recent bootcamp date
        return { dates: dates.slice(0, 1), languages, participant };
      },
    },
  }
);
