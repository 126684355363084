import { ISubscriptionCoupon, Models } from "@fluent-forever/types";
import { SubscriptionCoupon } from "@fluent-forever/utils";
import * as API from "../api";
import { Subscriptions } from "../store";
import { Dispatch } from "../store/types";
import { httpRequest } from "./http_request";
import { Navigation, Operation } from ".";

/** Load user information  */
export const loadActiveSubscription = async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch(Subscriptions.ActiveSubscription.fetch());
  try {
    const accountSettingsInfo = await API.User.fetchAccountSettingsInfo(
      httpRequest
    );
    dispatch(Subscriptions.ActiveSubscription.onSuccess(accountSettingsInfo));
  } catch (e) {
    dispatch(
      Subscriptions.ActiveSubscription.onFail(
        e instanceof Error ? e.message : "Unknown error"
      )
    );
    Operation.onFail("Failed to retrieve user settings", dispatch, e);
  }
};

export const isMonthlyPlan = (coupon: ISubscriptionCoupon): boolean =>
  !!coupon.metadata.days && coupon.planId === "SL-Monthly";

export interface HasAnotherSubscriptionResult {
  hasAnotherSubscription: true;
  success: false;
}

export interface ErrorMessageResult {
  errorMessage: string;
  success: false;
}

export const openManagementPortal = (
  _subscriptionId: string,
  dispatch: Dispatch
): Promise<void> => {
  return API.ChargeBee.portalOpen()
    .then(() => {
      //On Close of popup
      return;
    })
    .catch((err) =>
      Operation.onFail(
        "Failed to open subscription management portal",
        dispatch,
        err
      )
    );
};

export const isInAppSubscription = (
  providerType: Models.SubscriptionProvider
): boolean =>
  providerType === Models.SubscriptionProviderEnum.iOS ||
  providerType === Models.SubscriptionProviderEnum.Android;

export const isLifetimeSubscription = (planId: string): boolean => {
  return ["MLB-Lifetime", "SLB-Lifetime"].includes(planId);
};

export const getCouponInfo = async (
  couponId: string,
  dispatch: Dispatch
): Promise<Models.ISubscriptionCoupon | undefined> => {
  try {
    const coupon = await API.Subscriptions.getCouponInfo(couponId, httpRequest);
    // console.log("CouponInfo", coupon);
    if (SubscriptionCoupon.isSubscriptionCoupon(coupon) && coupon.isValid) {
      return coupon;
    } else {
      throw new Error("Invalid subscription coupon");
    }
  } catch (e) {
    console.log(`Error getting subscription coupon`, e);
    Navigation.redirectToDefaultPath(dispatch);
    Operation.onFail("The promotion expired or is not valid anymore", dispatch);
    return undefined;
  }
};

export const getDiscountedSubscriptionPrice = (
  discountType: Models.CouponDiscountType,
  totalAmount: number
): number => {
  switch (discountType) {
    case Models.CouponDiscountType.IndieGoGo16:
      return totalAmount - (16.5 * totalAmount) / 100;
    case Models.CouponDiscountType.Kickstarter33:
      return totalAmount - (33 * totalAmount) / 100;
    default:
      return totalAmount;
  }
};
