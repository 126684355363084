export const USER_INFO_LOADING = "USER_INFO_LOADING";
export const LOAD_USER_INFO_SUCCESS = "LOAD_USER_INFO_SUCCESS";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const LOAD_USER_INFO_FAIL = "LOAD_USER_INFO_FAIL";
export const USER_EMAIL_CONFIRMATION_LOADING =
  "USER_EMAIL_CONFIRMATION_LOADING";
export const USER_EMAIL_CONFIRMATION_RESULT = "USER_EMAIL_CONFIRMATION_RESULT";
export const REGISTER_SUBSCRIPTION_COUPON = "REGISTER_SUBSCRIPTION_COUPON";
export const REGISTER_WEEKLY_COACHING_LANGUAGE =
  "REGISTER_WEEKLY_COACHING_LANGUAGE";
export const REGISTER_WEEKLY_COACHING_PLAN_ID =
  "REGISTER_WEEKLY_COACHING_PLAN_ID";
