/* eslint-disable react/prop-types */
import * as App from "application";
import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { IRootState } from "store/modules";
import { INotificationMessage } from "store/modules/notifications";
import { LayoutContainer } from "theme";
import { NotificationMessage } from "../../presentational/application";

const mapStateToProps = (state: IRootState) => ({
  isShowing: App.Operation.getUserNotifications(state).length > 0,
  messages: App.Operation.getUserNotifications(state),
});

const mapDispatchToProps = (dispatch: Redux.Dispatch) => ({
  onClose: () => App.Operation.clearUserMessages(dispatch),
});

interface INotificationHandlerProps {
  messages: INotificationMessage[];
  onClose(): void;
}

const NotificationHandlerComponent: React.SFC<INotificationHandlerProps> = (
  props
) => {
  if (props.messages === undefined || props.messages.length === 0) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }
  const result = props.messages.map((val, index) => (
    <NotificationMessage
      details={val.details}
      key={index}
      onHide={props.onClose}
      title={val.title}
      type={val.type}
    />
  ));
  return <LayoutContainer>{result}</LayoutContainer>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationHandlerComponent);
