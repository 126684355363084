/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Models } from "@fluent-forever/types";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { authorizeUser, useAmexPerks } from "../../../api/AmexPerks";
import * as App from "../../../application";
import { AmexConfig } from "../../../config";
import { Dispatch } from "../../../store/types";
import "../styles/CouponAmex.scss";
import { FirstSectionPromo } from "../components/FirstSectionPromo";
import { FooterSectionPromo } from "../components/FooterSectionPromo";
import { FourthSectionPromoAmex } from "../components/FourthSectionPromoAmex";
import { HeaderSectionPromoAmex } from "../components/HeaderSectionPromoAmex";
import { SecondSectionPromo } from "../components/SecondSectionPromo";
import { ThirdSectionPromo } from "../components/ThirdSectionPromo";

const CouponAmexComponent = ({
  redirectAway,
  onSignUp,
  loadCouponInformation,
  location,
}: {
  redirectAway: () => void;
  onSignUp: (ctaButtonText: string, coupon: Models.ISubscriptionCoupon) => void;
  loadCouponInformation: (
    coupon: string
  ) => Promise<Models.ISubscriptionCoupon | undefined>;
  location: any;
}) => {
  const search = location.search;
  const shouldFail = new URLSearchParams(search).get("fail");

  const [isValid, setIsValid] = useState(false);
  const [amexCoupon, setAmexCoupon] = useState<
    Models.ISubscriptionCoupon | undefined
  >(undefined);

  useAmexPerks();

  useEffect(() => {
    (async () => {
      await authorizeUser();
      initializeCoupon();
    })();
  }, []);

  const initializeCoupon = async () => {
    const coupon = await loadCouponInformation(AmexConfig.coupon);
    // we should use their SDK to determine
    // the value of this boolean
    setIsValid(!shouldFail);
    if (coupon) {
      setAmexCoupon(coupon);
    } else {
      redirectAway();
    }
  };

  const onSignUpPressed = () => {
    if (isValid) {
      onSignUp("amex", amexCoupon as Models.ISubscriptionCoupon);
    } else {
      window.location.href =
        "https://fluent-forever.app/promo?coupon=2MONTHSFREE";
    }
  };

  return amexCoupon ? (
    isValid ? (
      <div className="coupon-container" id="coupon">
        <HeaderSectionPromoAmex isValid={isValid} onSignUp={onSignUpPressed} />
        <FirstSectionPromo />
        <SecondSectionPromo />
        <ThirdSectionPromo />
        <FourthSectionPromoAmex onSignUp={onSignUpPressed} />
        <FooterSectionPromo />
      </div>
    ) : (
      <div className="coupon-container" id="coupon">
        <HeaderSectionPromoAmex isValid={isValid} onSignUp={onSignUpPressed} />
      </div>
    )
  ) : (
    <div className="loading-container">
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" />
      </svg>
    </div>
  );
};

// === CONTAINER ===

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCouponInformation: (coupon: string) =>
    App.Subscriptions.getCouponInfo(coupon, dispatch),
  onSignUp: (cbaButtonText: string, coupon: Models.ISubscriptionCoupon) =>
    App.Authentication.openPromoSignUp(dispatch, cbaButtonText, coupon),
  redirectAway: () => App.Navigation.redirectToDefaultPath(dispatch),
});

const ConnectedCouponAmexComponent = connect(
  undefined,
  mapDispatchToProps
)(CouponAmexComponent);

export const CouponAmex = withRouter<any, any>(ConnectedCouponAmexComponent);
