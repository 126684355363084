import {
  CancelWeeklyCoachingSubscriptionPayload,
  CancelWeeklyCoachingSubscriptionResult,
  ConfirmWeeklyCoachingCheckoutPayload,
  ConfirmWeeklyCoachingCheckoutResult,
  GetAvailableWeeklyCoachingPlansResult,
  GetUserWeeklyCoachingSubscriptionsResult,
  InitiateWeeklyCoachingCheckoutData,
  InitiateWeeklyCoachingCheckoutPayload,
  InitiateWeeklyCoachingCheckoutResult,
  ReactivateWeeklyCoachingSubscriptionPayload,
  ReactivateWeeklyCoachingSubscriptionResult,
  REST,
} from "@fluent-forever/types";
import axios from "axios";
import { ApiConfig as CONFIG_API } from "config/config";

const initiateWeeklyCoachingCheckout = async (
  payload: InitiateWeeklyCoachingCheckoutPayload
): Promise<InitiateWeeklyCoachingCheckoutData> => {
  const {
    data: result,
  } = await axios.post<InitiateWeeklyCoachingCheckoutResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      REST.PATH.WEEKLY_COACHING.CHECKOUT,
    payload,
    { validateStatus: undefined }
  );
  if (result.success) return result.data;
  else throw result.error;
};

const confirmWeeklyCoachingCheckout = async (
  payload: ConfirmWeeklyCoachingCheckoutPayload
): Promise<ConfirmWeeklyCoachingCheckoutResult> => {
  const {
    data: result,
  } = await axios.post<ConfirmWeeklyCoachingCheckoutResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      REST.PATH.WEEKLY_COACHING.CONFIRM_CHECKOUT,
    payload,
    { validateStatus: undefined }
  );
  if (result.success) return result;
  else throw result.error;
};

const cancelWeeklyCoachingSubscription = async (
  payload: CancelWeeklyCoachingSubscriptionPayload
): Promise<CancelWeeklyCoachingSubscriptionResult> => {
  const {
    data: result,
  } = await axios.post<CancelWeeklyCoachingSubscriptionResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      REST.PATH.WEEKLY_COACHING.CANCEL,
    payload,
    { validateStatus: undefined }
  );
  return result;
};

const reactivateWeeklyCoachingSubscription = async (
  payload: ReactivateWeeklyCoachingSubscriptionPayload
): Promise<ReactivateWeeklyCoachingSubscriptionResult> => {
  const {
    data: result,
  } = await axios.post<ReactivateWeeklyCoachingSubscriptionResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      REST.PATH.WEEKLY_COACHING.REACTIVATE,
    payload,
    { validateStatus: undefined }
  );
  return result;
};

const getAvailableWeeklyCoachingPlans = async (
  all = false
): Promise<GetAvailableWeeklyCoachingPlansResult> => {
  const {
    data: result,
  } = await axios.get<GetAvailableWeeklyCoachingPlansResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      (all
        ? REST.PATH.WEEKLY_COACHING.PLANS_ALL
        : REST.PATH.WEEKLY_COACHING.PLANS),
    { validateStatus: undefined }
  );
  return result;
};

const getWeeklyCoachingSubscriptions = async (): Promise<GetUserWeeklyCoachingSubscriptionsResult> => {
  const {
    data: result,
  } = await axios.get<GetUserWeeklyCoachingSubscriptionsResult>(
    CONFIG_API.url +
      REST.PATH.WEEKLY_COACHING.PREFIX +
      REST.PATH.WEEKLY_COACHING.USER_SUBSCRIPTIONS,
    { validateStatus: undefined }
  );
  return result;
};

export const weeklyCoachingPortalApi = {
  cancelWeeklyCoachingSubscription,
  confirmWeeklyCoachingCheckout,
  getAvailableWeeklyCoachingPlans,
  getWeeklyCoachingSubscriptions,
  initiateWeeklyCoachingCheckout,
  reactivateWeeklyCoachingSubscription,
};
