import { getTypedKeys } from "@fluent-forever/utils";
import { assign, createMachine } from "xstate";
import { hideChargebeeModal } from "../../../api/ChargeBee";
import * as App from "../../../application";
import { store } from "../../../store/configureStore";
import { defaultErrorMessage } from "../constants/weekly_coaching_constants";
import {
  fetchWeeklyCoachingPlans,
  weeklyCoachingCheckoutInvokation,
} from "../procedures/weekly_coaching_signup_machine_procedures";
import {
  WeeklyCoachingSignupContext,
  WeeklyCoachingSignupEvent,
  WeeklyCoachingSignupMachineState,
} from "../types/weekly_coaching_signup_machine_types";

export const weeklyCoachingSignupMachine = createMachine<
  WeeklyCoachingSignupContext,
  WeeklyCoachingSignupEvent,
  WeeklyCoachingSignupMachineState
>(
  {
    id: "weeklyCoachingSignup",
    initial: "init",
    context: {
      send: () => {
        throw new Error("send not set");
      },
    },
    states: {
      init: {
        on: {
          INITIALIZE: {
            target: "idle",
          },
        },
      },
      idle: {
        on: {
          FETCH_PLANS: {
            target: "fetchingPlans",
          },
          SET_LANGUAGE_AND_PLAN: {
            target: "dataEntry",
            actions: assign((_, evt) => {
              if (evt.type !== "SET_LANGUAGE_AND_PLAN") return {};
              return {
                selectedLanguage: evt.value.language,
                selectedPlan: evt.value.plan,
              };
            }),
          },
        },
      },
      fetchingPlans: {
        invoke: {
          id: "fetching-plans",
          src: (_, evt) => {
            if (evt.type !== "FETCH_PLANS")
              return () => {
                throw new Error(defaultErrorMessage);
              };
            return fetchWeeklyCoachingPlans(evt.all);
          },
          onDone: {
            target: "dataEntry",
            actions: "onFetchingPlansSuccess",
          },
          onError: {
            target: "fatalError",
            actions: "onFetchingPlansError",
          },
        },
      },
      dataEntry: {
        on: {
          UPDATE_SELECTED_LANGUAGE: {
            actions: "updateSelectedLanguage",
          },
          UPDATE_SELECTED_PLAN: {
            actions: assign((_, { value }) => ({ selectedPlan: value })),
          },
          START_CHECKOUT: {
            target: "checkout",
            actions: assign((_) => ({ errorMessage: undefined })),
          },
          CLEAR_ERROR: {
            actions: assign((_) => ({ errorMessage: undefined })),
          },
          START_LANDING_CHECKOUT: {
            actions: "startLandingCheckout",
          },
        },
      },
      checkout: {
        invoke: {
          src: weeklyCoachingCheckoutInvokation,
          onDone: {
            target: "success",
          },
          onError: {
            target: "dataEntry",
            actions: assign((_, { data }) => {
              hideChargebeeModal();
              return {
                errorMessage: data?.message
                  ? data.message
                  : typeof data === "string"
                  ? data
                  : defaultErrorMessage,
              };
            }),
          },
        },
      },
      fatalError: {
        on: {
          FETCH_PLANS: {
            target: "fetchingPlans",
          },
        },
      },
      success: {
        type: "final",
      },
    },
  },
  {
    actions: {
      startLandingCheckout: (ctx, evt) => {
        if (evt.type !== "START_LANDING_CHECKOUT") return 0;
        return (
          ctx.selectedPlan &&
          ctx.selectedLanguage &&
          App.Authentication[
            evt.logInOnly
              ? "openWeeklyCoachingLogIn"
              : "openWeeklyCoachingSignUp"
          ]({
            dispatch: store.dispatch,
            langPairCode: ctx.selectedLanguage,
            planId: ctx.selectedPlan,
          })
        );
      },
      updateSelectedLanguage: assign((ctx, evt) => {
        if (evt.type !== "UPDATE_SELECTED_LANGUAGE") return {};
        return {
          selectedLanguage: evt.value,
          selectedPlan: ctx.availableLanguagePlans?.[evt.value]?.[0],
        };
      }),
      onFetchingPlansError: assign((_, evt) => {
        if (evt.type !== "error.platform.fetching-plans") return {};
        return {
          errorMessage: evt.data,
        };
      }),
      onFetchingPlansSuccess: assign((_, evt) => {
        if (evt.type !== "done.invoke.fetching-plans") return {};
        const selectedLanguage = getTypedKeys(evt.data)[0];
        return {
          selectedLanguage,
          selectedPlan: evt.data[selectedLanguage]?.[0],
          availableLanguagePlans: evt.data,
        };
      }),
    },
  }
);
