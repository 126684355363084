export * from "./actionCreators";
import reducer from "./reducer";

export interface IApplicationState {
  isLoading: boolean;
  isInitialized: boolean;
  isMaintenanceModeEnabled: boolean;
}

export default reducer;
