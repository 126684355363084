import { ISubscriptionCoupon } from "@fluent-forever/types";
import { SubscriptionCoupon } from "@fluent-forever/utils";
import { isMonthlyPlan } from "../../../../../../application/planSubscription";
import { fetchPlans } from "../../../../../../application/plans";
import { ICouponDisplayStrings } from "../types/landingCouponPageTypes";

export function getCouponDisplayStrings(
  coupon: ISubscriptionCoupon
): ICouponDisplayStrings {
  const { metadata } = coupon;

  return {
    detailText: metadata.detailText,
    footerPricing: getFooter(coupon),
    intro: getIntro(coupon),
    title: metadata.title,
  };
}

function getFooter(coupon: ISubscriptionCoupon): string | undefined {
  const { metadata } = coupon;
  if (metadata.footer) {
    return metadata.footer;
  }

  if (isMonthlyPlan(coupon)) {
    const { days = 0, allowsTrial } = metadata;
    const totalDays =
      days +
      (allowsTrial ? SubscriptionCoupon.getCouponTrialDuration(coupon) : 0);
    return `${totalDays} Days Free, then $9.99/month.`;
  }

  return undefined;
}

const plans = fetchPlans();

function getIntro(coupon: ISubscriptionCoupon): string | undefined {
  const { metadata } = coupon;
  if (metadata.intro) {
    return metadata.intro;
  }

  if (isMonthlyPlan(coupon)) {
    const { metadata } = coupon;
    const { days = 0, allowsTrial } = metadata;
    const prefix = "That's a ";

    if (allowsTrial) {
      const trialDays = SubscriptionCoupon.getCouponTrialDuration(coupon);
      const totalDays = trialDays + days;
      return `${prefix}${totalDays}-day subscription (${trialDays}-day trial + ${days} days for free)`;
    }

    return `${prefix}${days}-day subscription for free`;
  }

  // Handle long-term plans
  const plan = plans.find((x) => x.id === coupon.planId);
  if (plan && plan.durationMonths > 1) {
    return `That's ${plan.durationMonths} months of access`;
  }

  return undefined;
}
