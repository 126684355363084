/* eslint-disable @typescript-eslint/no-explicit-any */
// import Types from 'Types';
import { LanguageList } from "components/presentational/views";
import * as React from "react";
import { connect } from "react-redux";
import * as Redux from "redux";
import { ILanguageData, languagesActions } from "store/modules/languages";
// import { ILanguageState } from 'store/modules/languages/reducer';

interface IDispatchProps {
  getDutch(): void;
}

interface ILanguagesState {
  readonly languages: ILanguageData[];
  readonly loading: boolean;
}

// type LanguagesProps = ILanguagesState & IDispatchProps;

const mapStateToProps = (state: any /*Types.RootState*/): ILanguagesState => ({
  languages: state.languages.data,
  loading: state.languages.loading,
});

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  Redux.bindActionCreators(
    {
      getDutch: () => dispatch(languagesActions.fetchLanguages()),
    },
    dispatch
  );

class Languages extends React.Component<any, ILanguagesState> {
  public readonly state: ILanguagesState = {
    languages: [],
    loading: false,
  };

  public render() {
    return (
      <div>
        <button onClick={this.props.getDutch}>Test</button>
        <LanguageList languages={this.props.languages} />
      </div>
    );
  }
}

// export default Languages;
export default connect<ILanguagesState, IDispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps
)(Languages);
