/* eslint-disable react/prop-types */
import * as App from "application";
import { getUserRoutingInfo } from "application/navigation";
import { ConditionalRoute } from "components/containers";
import { ROUTES } from "config";
import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { RouteProps } from "react-router-dom";
import { IRootState } from "store";

export interface IProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  checkIsValidPath(path: string): string;
}

const mapStateToProps = (state: IRootState) => ({
  checkIsValidPath: (path: string) =>
    App.Navigation.checkForMandatoryPath(getUserRoutingInfo(state), path),
  isAuthenticated: App.Authentication.isAuthenticated(state),
});

/* Route Accessible only when logged in 
	-> user must be logged in (otherwise redirect to Landing page)
	-> there are no pending/mandatory paths user has to complete before accessing the route
*/
const ProtectedRouteComponent: React.SFC<IProtectedRouteProps> = (props) => {
  const targetPath = (props.location as Location).pathname;
  const validPath = props.checkIsValidPath(targetPath);
  const condition = props.isAuthenticated && targetPath === validPath;
  // if not authenticated redirect to Landing page
  const path = props.isAuthenticated ? validPath : ROUTES.LANDING;
  return <ConditionalRoute isTrue={condition} redirectTo={path} {...props} />;
};

/* Route Accessible only when anonymous (e.g NOT logged in) 
	-> user must be logged out (otherwise redirect to Home page)
*/
const AnonymousOnlyRouteComponent: React.SFC<
  IProtectedRouteProps & {
    redirectAuthPath?: string;
  }
> = (props) => {
  const targetPath = (props.location as Location).pathname;
  const path =
    props.isAuthenticated === false
      ? targetPath
      : props.checkIsValidPath(props.redirectAuthPath ?? ROUTES.HOME);
  const condition = props.isAuthenticated === false;
  return <ConditionalRoute isTrue={condition} redirectTo={path} {...props} />;
};

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent);
export const AnonymousOnlyRoute = connect(mapStateToProps)(
  AnonymousOnlyRouteComponent
);
