import { noRenderNull } from "@fluent-forever/types";
import { getTypedKeys } from "@fluent-forever/utils";
import { useMachine } from "@xstate/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Message,
  Radio,
  Segment,
  Select,
} from "semantic-ui-react";
import { fetchWeeklyCoachingPlans } from "../../../application/plans";
import {
  getWeeklyCoachingSignUpLanguage,
  getWeeklyCoachingSignUpPlanId,
} from "../../../application/user";
import { ROUTES } from "../../../config";
import {
  languagePairNames,
  languagePairNamesWithVariant,
} from "../../bootcamp/constants/language";
import { weeklyCoachingSignupMachine } from "../machines/weekly_coaching_signup_machine";
import styles from "../styles/WeeklyCoachingForm.module.scss";

export const WeeklyCoachingForm = (): React.ReactElement => {
  const [machine, send] = useMachine(weeklyCoachingSignupMachine);
  const logInLangPairCode = useSelector(getWeeklyCoachingSignUpLanguage);
  const logInPlanId = useSelector(getWeeklyCoachingSignUpPlanId);

  const { context, value: currentState } = machine;
  const {
    availableLanguagePlans,
    errorMessage,
    selectedLanguage,
    selectedPlan,
  } = context;

  const isLoading = currentState === "checkout";

  const selectedLanguagePlans = selectedLanguage
    ? availableLanguagePlans?.[selectedLanguage]
    : undefined;

  const languageName = selectedLanguage
    ? languagePairNames[selectedLanguage]
    : "";

  useEffect(() => {
    send({ send, type: "INITIALIZE" });
  }, []);

  useEffect(() => {
    if (currentState === "idle") {
      if (logInLangPairCode && logInPlanId) {
        send({
          type: "SET_LANGUAGE_AND_PLAN",
          value: {
            language: logInLangPairCode,
            plan: logInPlanId,
          },
        });
        send("START_CHECKOUT");
      } else {
        send("FETCH_PLANS");
      }
    }
  }, [currentState]);

  switch (currentState) {
    case "fetchingPlans":
      return (
        <div className={styles["form-container"]}>
          <Segment padded="very">
            <Dimmer active inverted>
              <Loader size="big" />
            </Dimmer>
          </Segment>
        </div>
      );
    case "dataEntry":
    case "checkout":
      return (
        <div className={styles["form-container"]}>
          <Segment padded="very">
            <Dimmer active={isLoading} page>
              <Loader />
            </Dimmer>
            <Form className={styles["form"]}>
              {errorMessage ? (
                <Message negative onDismiss={() => send("CLEAR_ERROR")}>
                  <Message.Header>
                    There was an issue with the checkout
                  </Message.Header>
                  <p>{errorMessage}</p>
                </Message>
              ) : undefined}
              {availableLanguagePlans ? (
                <Form.Field>
                  <Label pointing="below">Select Language</Label>
                  <Select
                    onChange={(_, data) =>
                      send("UPDATE_SELECTED_LANGUAGE", { value: data.value })
                    }
                    options={getTypedKeys(availableLanguagePlans).map(
                      (langPairCode) => ({
                        key: langPairCode,
                        text: languagePairNamesWithVariant[langPairCode],
                        value: langPairCode,
                      })
                    )}
                    value={selectedLanguage}
                  />
                </Form.Field>
              ) : (
                noRenderNull
              )}
              <Header size="huge">{`MXU Weekly Coaching - ${languageName}`}</Header>
              <p>{`The program includes:`}</p>
              <List as="ul">
                <List.Item as="li">{`meeting your coach once a week for 20 minutes`}</List.Item>
                <List.Item as="li">{`adding your sentences into your Fluent Forever app`}</List.Item>
                <List.Item as="li">{`recordings of your sessions`}</List.Item>
                <List.Item as="li">{`attending the MasterClass every two weeks`}</List.Item>
              </List>
              <p>
                {`You will spend 20 minutes once a week working with a ${languageName} teacher
                    to create personalized sentences that will be added to your Fluent
                    Forever app. We encourage you to come with things that you'd like to
                    talk about. If not, our coaches will ask you questions designed to get
                    to know you better.`}
              </p>
              <p>
                {`The price is $89 monthly, which includes your Fluent Forever app
                    subscription.`}
              </p>
              <p>
                {`After registering, you will get a link to book an Onboarding Meeting,
                    where we will find a time that works with your schedule and you will
                    get all the information you need to set you up powerfully!`}
              </p>
              {selectedLanguagePlans && selectedLanguagePlans.length > 1
                ? selectedLanguagePlans.map((planId) => {
                    const plan = fetchWeeklyCoachingPlans().find(
                      (plan) => plan.id === planId
                    );
                    return plan ? (
                      <Form.Field>
                        <Radio
                          checked={selectedPlan === plan.id}
                          label={plan.name}
                          onChange={(_, data) =>
                            send("UPDATE_SELECTED_PLAN", { value: data.value })
                          }
                          value={plan.id}
                        />
                      </Form.Field>
                    ) : (
                      noRenderNull
                    );
                  })
                : noRenderNull}
              <Divider />
              <Grid>
                <Grid.Column textAlign="center">
                  <Button
                    animated="vertical"
                    disabled={!selectedLanguage || !selectedPlan}
                    onClick={() => send("START_CHECKOUT")}
                    primary
                    size="big"
                  >
                    <Button.Content visible>{"Subscribe"}</Button.Content>
                    <Button.Content className={"centered"} hidden>
                      <Icon name="arrow right" />
                    </Button.Content>
                  </Button>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        </div>
      );
    case "success":
      return (
        <div className={styles["success"]}>
          <Segment>
            <Header>{`You've successfully purchased a weekly coaching subscription!`}</Header>
            <p>
              {`You can manage it on your `}
              <Link to={ROUTES.SETTINGS}>account</Link>
              {` page.`}
            </p>
          </Segment>
        </div>
      );
    case "fatal_error":
      return (
        <div className={styles["form-container"]}>
          <Segment padded="very">
            <Header>{errorMessage}</Header>
            <Grid>
              <Grid.Column textAlign="center">
                <Button
                  animated="vertical"
                  onClick={() => send("FETCH_PLANS")}
                  primary
                  size="big"
                >
                  <Button.Content visible>{"Retry"}</Button.Content>
                  <Button.Content className={"centered"} hidden>
                    <Icon name="redo" />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid>
          </Segment>
        </div>
      );
    default:
      return <React.Fragment />;
  }
};
