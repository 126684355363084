/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RedeemCodeComponent } from "components/presentational/controls";
import * as React from "react";
import * as App from "../../../application";
import { selectUserId } from "../../../application/user";
import { trackBackerCodeRedeemed } from "../../../features/analytics/procedures/analytics_event_procedures";
import * as Store from "../../../store";
import { store } from "../../../store/configureStore";
import { temporaryAny } from "../../../types/any_types";

export interface IRedeemCodeOwnProps {
  onCodeRedeemed: (result: temporaryAny) => void;
}

interface IRedeemCodeState {
  code: string;
  codeInfo: string;
  isInvalid: boolean;
  isLoading: boolean;
}

const defaultState: IRedeemCodeState = {
  code: "",
  codeInfo: "",
  isInvalid: false,
  isLoading: false,
};

//** Container/UI logic for Redeeming Code */
export class RedeemCode extends React.Component<
  IRedeemCodeOwnProps,
  IRedeemCodeState
> {
  constructor(props: IRedeemCodeOwnProps) {
    super(props);
    this.state = defaultState;
  }

  clearWarnings = () =>
    store.dispatch(Store.Notifications.clearNotifications());
  fetchCodeInfo = (code: string) =>
    App.CouponCode.fetchCouponCodeInfo(code, store.dispatch);
  redeemCode = async (code: string) => {
    await App.CouponCode.redeemCouponCode(code, store.dispatch);
    trackBackerCodeRedeemed({
      ffId: selectUserId(store.getState()) ?? "",
    });
  };

  onCodeValueChanged = (code: string) => {
    this.setState({ code });
    this.setInvalid(false);
    this.clearWarnings();
  };

  onCodeInfoChanged = (codeInfo: string) => {
    this.setState({ codeInfo });
  };

  setLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  onGetCodeInfo = () => {
    const { code } = this.state;
    if (this.checkIsValid(code)) {
      this.fetchCodeInfo(code)
        .then((info) => {
          if (info) {
            this.onCodeInfoChanged(info.details);
          }
        })
        .catch(this.onReset);
    }
  };

  onReset = () => {
    this.setState(defaultState);
  };

  setInvalid = (isInvalid = false) => {
    this.setState({ isInvalid });
  };

  checkIsValid = (code: string) => {
    const result =
      code && code.replace("_", "").length >= "XXXX-XXXX-XXXX".length;
    if (!result) {
      this.setInvalid(true);
    }
    return result;
  };

  onRedeemCode = () => {
    const { code } = this.state;
    if (this.checkIsValid(code)) {
      this.setLoading(true);
      this.redeemCode(code)
        .then((result) => {
          this.props.onCodeRedeemed(result);
          this.onReset();
        })
        .catch(this.onReset);
    }
  };

  render() {
    const { code, codeInfo, isInvalid, isLoading } = this.state;
    return (
      <RedeemCodeComponent
        code={code}
        codeInfo={codeInfo}
        isInvalid={isInvalid}
        isLoading={isLoading}
        onCodeValueChanged={this.onCodeValueChanged}
        onGetCodeInfo={this.onGetCodeInfo}
        onHideCodeInfo={this.onReset}
        onSubmitCode={this.onRedeemCode}
      />
    );
  }
}
