import {
  CancellationSurveyReason,
  CancellationSurveyReasonMap,
  CancellationSurveyReasonOther,
  CancellationSurveyResponse,
} from "@fluent-forever/types";
import React, { CSSProperties, useState } from "react";
import { ViewStyle } from "react-native";
import { Button, Form, Modal } from "semantic-ui-react";
import { getCanCancelSubscription } from "../functions/subscription_cancellation_functions";

const options: {
  label: string;
  value: CancellationSurveyReason;
}[] = Object.keys(CancellationSurveyReasonMap).map((key) => {
  const parsedKey = parseInt(key, 10) as CancellationSurveyReason;
  return {
    label: CancellationSurveyReasonMap[parsedKey],
    value: parsedKey,
  };
});

export const SubscriptionCancellationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  userId,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (answer: CancellationSurveyResponse) => void;
  userId: string;
}): React.ReactElement => {
  const [selectedOption, setSelectedOption] = useState<
    CancellationSurveyReason | undefined
  >(undefined);
  const [otherReason, setOtherReason] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [canFollowUpByEmail, setCanFollowUpByEmail] = useState(true);
  const [hasOtherReasonError, setHasOtherReasonError] = useState(false);
  const canCancelSubscription = getCanCancelSubscription({
    otherReason,
    selectedOption,
  });

  const onSaveAttempt = (): void => {
    if (
      selectedOption === CancellationSurveyReasonOther.OTHER &&
      !otherReason
    ) {
      setHasOtherReasonError(true);
    }
    if (!canCancelSubscription || !selectedOption) {
      return;
    }

    if (selectedOption === CancellationSurveyReasonOther.OTHER) {
      onConfirm({
        canFollowUpByEmail,
        option: CancellationSurveyReasonOther.OTHER,
        otherReason,
        suggestions,
        userId,
      });
    } else {
      onConfirm({
        canFollowUpByEmail,
        option: selectedOption,
        suggestions,
        userId,
      });
    }
  };

  return (
    <Modal open={isOpen}>
      <Modal.Header>We&apos;re sorry to see you go</Modal.Header>
      <Modal.Content>
        <Form>
          <p>1. Why are you cancelling your Fluent Forever subscription?</p>
          <Form.Group grouped>
            {options.map((option, index) => (
              <Form.Radio
                checked={selectedOption === option.value}
                key={index}
                label={option.label}
                onChange={(_, { value }) =>
                  setSelectedOption(value as CancellationSurveyReason)
                }
                style={styles.radioRow}
                value={option.value}
              />
            ))}
            {selectedOption === CancellationSurveyReasonOther.OTHER &&
            hasOtherReasonError ? (
              <p style={styles.error}>This field is required.</p>
            ) : undefined}
            <Form.Input
              disabled={selectedOption !== CancellationSurveyReasonOther.OTHER}
              fluid
              onChange={(_, { value }) => {
                setOtherReason(value);
              }}
              style={styles.otherReasonsRow}
            />
          </Form.Group>
          <p>2. Please share any other feedback you have for us</p>
          <Form.TextArea
            onChange={(_, { value }) => {
              setSuggestions(typeof value === "string" ? value : "");
            }}
            style={styles.textArea}
          />
          <p>3. Can we contact you by email to follow up on your responses?</p>
          <Form.Group inline>
            <Form.Radio
              checked={canFollowUpByEmail}
              label="Yes"
              onChange={(_, { value }) => {
                setCanFollowUpByEmail(value === "yes");
              }}
              value="yes"
            />
            <Form.Radio
              checked={!canFollowUpByEmail}
              label="No"
              onChange={(_, { value }) => {
                setCanFollowUpByEmail(value === "yes");
              }}
              value="no"
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color={"black"} onClick={onCancel}>
          I don&apos;t want to cancel my subscription
        </Button>
        <Button
          color={canCancelSubscription ? "green" : "grey"}
          onClick={onSaveAttempt}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface Styles {
  error: CSSProperties;
  otherReasonsRow: ViewStyle;
  radioRow: ViewStyle;
  textArea: ViewStyle;
}

const styles: Styles = {
  error: { color: "#900" },
  otherReasonsRow: { marginBottom: 20, marginTop: 5 },
  radioRow: { marginBottom: 5 },
  textArea: {
    maxWidth: "100%",
    minHeight: 80,
    minWidth: "100%",
    width: "100%",
  },
};
