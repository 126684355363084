import { Base64 } from "js-base64";

export const encodeToQueryParam = <U = never, T extends U = U>(
  value: T
): string => Base64.encodeURI(JSON.stringify(value));

export const decodeFromQueryParam = <U = never, T extends U = U>({
  queryParam,
  validator,
}: {
  queryParam: string;
  validator: (v: unknown) => v is T;
}): T | undefined => {
  try {
    const parsedValue = JSON.parse(Base64.decode(queryParam));
    return validator(parsedValue) ? parsedValue : undefined;
  } catch (e) {
    console.log(`Error decoding query param ${queryParam}`, e);
    return undefined;
  }
};
