import { ROUTES } from "./routes";

// TODO: figure out which of these env vars are optional and remove them
//       from the array.
//
// const missingEnvVars = [
//   "REACT_APP_AMEX_CAMPAIGN_UUID_OFFERPAGE",
//   "REACT_APP_AMEX_COUPON",
//   "REACT_APP_AMEX_MERCHANT_ID",
//   "REACT_APP_AMEX_MODE",
//   "REACT_APP_API_PATH",
//   "REACT_APP_AUTH0_AUDIENCE",
//   "REACT_APP_AUTH0_CLIENT_ID",
//   "REACT_APP_AUTH0_DOMAIN",
//   "REACT_APP_CHARGEBEE_DEFAULT_PLAN_ID",
//   "REACT_APP_CHARGEBEE_SITE",
//   "REACT_APP_DISABLE_SIGNUP",
//   "REACT_APP_NEWSLETTER_SIGNUP_FORM_GUID",
//   "REACT_APP_PATH_URL"
// ].filter(key => process.env[key] === undefined);
//
// if (missingEnvVars.length > 0) {
//   throw new Error(`Missing environment vars: ${missingEnvVars.toString()}`);
// }

export const AppConfig = {
  PATH: process.env.REACT_APP_PATH_URL as string,
  newsLetterFormGUID: process.env
    .REACT_APP_NEWSLETTER_SIGNUP_FORM_GUID as string,
  progressSiteUrl: "https://progress.fluent-forever.com/",
  waitlistUrl: "https://fluentforever.typeform.com/to/qswW93",
};

// Auth0 API Configuration
export const Auth0Config = {
  appUrl: process.env.REACT_APP_PATH_URL as string,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  callbackAuthenticateUrl:
    (process.env.REACT_APP_PATH_URL as string) + ROUTES.AUTHENTICATE,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
};

export const ApiConfig = {
  url: process.env.REACT_APP_API_PATH as string,
};

export const CHARGEBEE = {
  defaultPlanId: process.env.REACT_APP_CHARGEBEE_DEFAULT_PLAN_ID as string,
  site: process.env.REACT_APP_CHARGEBEE_SITE as string,
};

export const AmexConfig = {
  coupon: process.env.REACT_APP_AMEX_COUPON as string,
  merchantApiKey: process.env.REACT_APP_AMEX_MERCHANT_ID as string,
  mode: process.env.REACT_APP_AMEX_MODE as string,
  shouldStripUrl: false,
};

export const DisableSignup = process.env.REACT_APP_DISABLE_SIGNUP === "true";
