/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosStatic } from "axios";
import { ApiConfig } from "config/config";
import { v4 as uuidv4 } from "uuid";

export interface IUserAuthenticationInfo {
  isAuthenticated(): boolean;
  getAccessToken(): string | undefined;
  getAccessTokenExpiry(): number | undefined;
  getUserAuth0Id(): string | undefined;
}

// Ensure every request to API includes access token and auth0id
export const ConfigureFetchAuthentication = (
  axios: AxiosStatic,
  authentication: IUserAuthenticationInfo
) => {
  axios.interceptors.request.use(
    (config) => {
      if (config.url && config.url.startsWith(ApiConfig.url)) {
        if (authentication.isAuthenticated()) {
          const accessToken = authentication.getAccessToken();
          const userAuth0Id = authentication.getUserAuth0Id();
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            UserAuth0Id: `${userAuth0Id}`,
          };

          config.headers = { ...config.headers, ...headers };
        }

        const correlationHeader = { "X-Correlation-ID": `${uuidv4()}` };
        config.headers = { ...config.headers, ...correlationHeader };
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  console.debug("axios authentication configured");
};

// Ensure every request/response to API shows loading indicator
export const ConfigureFetchLoadingIndicator = (
  axios: AxiosStatic,
  startLoading: () => void,
  stopLoading: () => void
) => {
  axios.interceptors.request.use(
    (config) => {
      startLoading();
      return config;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      stopLoading();
      return response;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );

  console.debug("axios loading configured");
};
