/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { INotificationsState } from ".";

export type NotificationAction = ActionType<typeof actions>;

const initialState: INotificationsState = {
  messages: [],
};

const reducer = (
  state: INotificationsState = initialState,
  action: NotificationAction
) => {
  switch (action.type) {
    case actions.RAISE_NOTIFICATION_MESSAGE:
      return { messages: [...state.messages, action.message] };
    case actions.CLEAR_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
