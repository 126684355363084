/* eslint-disable react/prop-types */
import * as React from "react";
import { ILanguageData } from "store/modules/languages";

interface ILanguageListItemProps {
  readonly language: ILanguageData;
}

const LanguageListItem: React.SFC<ILanguageListItemProps> = (props) => {
  const { language } = props;
  return <div>{language.name}</div>;
};

interface ILanguageListProps {
  readonly languages: ReadonlyArray<ILanguageData>;
}

export const LanguageList: React.SFC<ILanguageListProps> = (props) => {
  const { languages } = props;
  return (
    <span>
      {languages.map((language) => (
        <LanguageListItem key={language.name} language={language} />
      ))}
    </span>
  );
};

LanguageList.defaultProps = {
  languages: [],
};
