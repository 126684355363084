import { UserFacingWeeklyCoachingSubscription } from "@fluent-forever/types";
import { weeklyCoachingPortalApi } from "../../../api/weekly_coaching_api";

export const fetchWeeklyCoachingSubscriptions = () => async (): Promise<
  UserFacingWeeklyCoachingSubscription[]
> => {
  const result = await weeklyCoachingPortalApi.getWeeklyCoachingSubscriptions();
  if (result.success) {
    return result.data;
  } else {
    throw result.error;
  }
};

export const cancelActiveWeeklyCoachingSubscription = (
  subscriptionId: UserFacingWeeklyCoachingSubscription["id"]
) => async (): Promise<undefined> => {
  const result = await weeklyCoachingPortalApi.cancelWeeklyCoachingSubscription(
    { subscriptionId }
  );
  if (result.success) {
    return result.data;
  } else {
    throw result.error;
  }
};

export const reactivateCancelledWeeklyCoachingSubscription = (
  subscriptionId: UserFacingWeeklyCoachingSubscription["id"]
) => async (): Promise<undefined> => {
  const result = await weeklyCoachingPortalApi.reactivateWeeklyCoachingSubscription(
    { subscriptionId }
  );
  if (result.success) {
    return result.data;
  } else {
    throw result.error;
  }
};
