import { getLearnLanguageName, LearnLanguageCode } from "@fluent-forever/types";
import * as React from "react";
import { languageFlags } from "../../../assets/images/flags/flags";

export const LanguageFlags = (): React.ReactElement => (
  <div className="h-full flex items-center  no-scrollbar flex-wrap m-4">
    {Object.keys(languageFlags)
      .filter((langCode) => langCode !== "en-US")
      .map((langCode) => (
        <div className="flex items-center p-2" key={langCode}>
          <img
            className="h-5 md:h-[30px] w-5 md:w-[30px] rounded-full shadow-sm"
            src={languageFlags[langCode as LearnLanguageCode]}
          />
          <p className="ml-1 text-[12px] md:text-[13px] text-gray-800 font-circular font-medium uppercase tracking-[.5px]">
            {getLearnLanguageName(langCode as LearnLanguageCode)}
          </p>
        </div>
      ))}
  </div>
);
