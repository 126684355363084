import {
  getLanguagePairCodeFromKnownAndLearnLanguages,
  LearnLanguageCode,
} from "@fluent-forever/types";
import gsap from "gsap";
import * as React from "react";
import { useEffect } from "react";
import { ScrollScene } from "scrollscene";
import { languageFlags } from "../../../assets/images/flags/flags";
import { languagePairNamesWithVariant } from "../../bootcamp/constants/language";
import "../styles/ThirdSectionPromo.scss";

export const ThirdSectionPromo = (): React.ReactElement => {
  // INTENDED: null is needed for useRef
  /* eslint-disable no-null/no-null */
  const containerRef = React.useRef(null);
  const sceneContainerRef = React.useRef(null);
  const secondTriggerRef = React.useRef(null);
  const firstCharacterRef = React.useRef(null);
  const secondCharacterRef = React.useRef(null);
  const firstTextRef = React.useRef(null);
  const secondTextRef = React.useRef(null);
  const languagesRef = React.useRef(null);
  /* eslint-enable no-null/no-null */

  const languages: LearnLanguageCode[] = [
    "de-DE",
    "es-ES",
    "es-MX",
    "fr-FR",
    "he-IL",
    "it-IT",
    "ja-JP",
    "ko-KR",
    "nl-NL",
    "pt-BR",
    "ru-RU",
    "zh-CN",
  ];

  const getLanguageName = (code: LearnLanguageCode): string => {
    const langPairCode = getLanguagePairCodeFromKnownAndLearnLanguages({
      known: "en-US",
      learn: code,
    });
    return langPairCode ? languagePairNamesWithVariant[langPairCode] : "";
  };

  useEffect(() => {
    const { current: containerElement } = containerRef;
    const { current: sceneContainerElement } = sceneContainerRef;
    const { current: secondTriggerElement } = secondTriggerRef;
    const { current: firstCharacterElement } = firstCharacterRef;
    const { current: secondCharacterElement } = secondCharacterRef;
    const { current: firstTextElement } = firstTextRef;
    const { current: secondTextElement } = secondTextRef;
    const { current: languagesElement } = languagesRef;

    if (
      !containerElement &&
      !sceneContainerElement &&
      !secondTriggerElement &&
      !firstCharacterElement &&
      !firstTextElement &&
      !secondTextElement &&
      !languagesElement &&
      !secondCharacterElement
    ) {
      return undefined;
    }

    const timeline = gsap.timeline({ paused: true });

    timeline.fromTo(
      firstCharacterElement,
      {
        translateY: 0,
      },
      { duration: 1, ease: "none", translateY: 200 },
      0
    );

    timeline.fromTo(
      secondCharacterElement,
      {
        translateY: 0,
      },
      { duration: 1, ease: "none", translateY: 400 },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollSceneBG = new ScrollScene({
      duration: "100%",
      gsap: { timeline },
      toggle: { className: "in-view", element: containerElement },
      triggerElement: containerElement,
      triggerHook: 0.5,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollSceneFirst = new ScrollScene({
      toggle: { className: "in-view", element: firstTextElement },
      triggerElement: firstTextElement,
      triggerHook: 0.8,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollSceneSecond = new ScrollScene({
      toggle: { className: "in-view", element: secondTextElement },
      triggerElement: secondTextElement,
      triggerHook: 0.8,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollSceneLanguages = new ScrollScene({
      toggle: { className: "in-view", element: languagesElement },
      triggerElement: languagesElement,
      triggerHook: 0.8,
    });

    return () => {
      scrollSceneBG.destroy();
      scrollSceneFirst.destroy();
      scrollSceneSecond.destroy();
      scrollSceneLanguages.destroy();
    };
  });

  return (
    <div className="third-section" ref={containerRef}>
      <div className="waves-container">
        <svg
          className="wave-1"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 100 20"
        >
          <path d="M 0,15  C 20,15 30,5 50,5 C 70,5 80,15 100,15 C 120,15 130,5 150,5 C 170,5 180,15 200,15 " />
        </svg>
        <svg
          className="wave-2"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 100 20"
        >
          <path d="M 0,15  C 20,15 30,5 50,5 C 70,5 80,15 100,15 C 120,15 130,5 150,5 C 170,5 180,15 200,15 " />
        </svg>
      </div>
      <div
        ref={secondTriggerRef}
        style={{ position: "absolute", top: "100%" }}
      />
      <div className="scene-container" ref={sceneContainerRef}>
        <span className="first-text" ref={firstTextRef}>
          Imagine you had access to a language learning app that actually works,
          an app that rewires your brain for a new language instead of forcing
          you to memorize.
        </span>
        <span className="second-text" ref={secondTextRef}>
          Now, imagine being
          <br />
          Fluent Forever in:
        </span>

        <span className="character-1" ref={firstCharacterRef}>
          あ
        </span>
        <span className="character-2" ref={secondCharacterRef}>
          Я
        </span>
        <div className="languages-container" id="languages" ref={languagesRef}>
          {languages.map((lang, i) => (
            <div className="single-language" key={i}>
              <img src={languageFlags[lang]} />
              <span>{getLanguageName(lang)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
