/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Auth0Lock } from "auth0-lock";
import { Auth0Config, DisableSignup } from "config/config";
import { temporaryAny } from "../types/any_types";

/*
 *  User Login/SignUp popup
 *  (Auth0 Lock )
 */

// Custom Sign Up Fields
const firstNameField = {
  // The following properties are optional
  icon: "https://content.fluent-forever.app/static/user_offline.png",

  name: "firstName",

  placeholder: "first name",
  validator: (value?: string) => {
    return {
      hint: "Must be at least 1 character long",
      valid: value && value.trim().length >= 1,
    };
  },
};

const lastNameField = {
  // The following properties are optional
  icon: "https://content.fluent-forever.app/static/user_offline.png",

  name: "lastName",

  placeholder: "last name",
  validator: (value?: string) => {
    return {
      hint: "Must be at least 1 character long",
      valid: value && value.trim().length >= 1,
    };
  },
};

const signUpNewsletter = {
  name: "newsletter",
  placeholder: "Get updates, tips, and offers",
  prefill: "false",
  type: "checkbox",
};

export interface IAuthResult {
  id: string;
  // expiresIn: number;
  name: string;
  expiresAt: number;
  accessToken: string;
  idToken: string;
  // meta data info injected Auth0 rule into idToken
  userProfile: IUserProfile;
}

export interface IUserProfile {
  userId: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  authProvider: string;
  emailVerified: boolean;
  createdAt: temporaryAny;
}

export const createDefaultOptions = () => ({
  additionalSignUpFields: [firstNameField, lastNameField, signUpNewsletter],
  allowForgotPassword: true,
  allowLogin: true,
  allowSignUp: true,
  allowedConnections: undefined,
  auth: {
    audience: Auth0Config.audience,
    autoParseHash: false,
    // Make sure we use our own routing to process hash => see processAuthenticationFromUrlHash
    params: {
      prompt: "select_account",
      scope: "openid profile",
    },

    redirectUrl: Auth0Config.callbackAuthenticateUrl,
    responseType: "token id_token",
  },
  closable: true,
  configurationBaseUrl: "https://cdn.auth0.com",
  defaultADUsernameFromEmailPrefix: false,
  hashCleanup: false,
  languageDictionary: {
    signUpTerms:
      'By clicking Sign Up, you agree to our <a href="https://fluent-forever.com/terms-of-service/" target="_blank" style="text-decoration: underline;">Terms</a> and <a href="https://fluent-forever.com/privacy-policy/" target="_blank" style="text-decoration: underline;">Privacy Policy</a>',
    signUpTitle: "",
    title: "",
  },
  mustAcceptTerms: false,
  prefill: { email: true, username: true },
  rememberLastLogin: false,
  socialButtonStyle: "small",
  theme: {
    logo:
      "https://fluent-forever.com/wp-content/uploads/2018/10/Fluent-Forever-Option-1-01.png",
    primaryColor: "#60c290",
  },
});

// Available Lock configuration options: https://auth0.com/docs/libraries/lock/v11/configuration
const lock = createInstance(createDefaultOptions());
let tokenRenewalTimeout: number | undefined = undefined;

/*
 *  User AUTHENTICATION Management
 *  (Auth0 API Wrapper)
 */
class Auth0Authentication {
  constructor() {
    // make sure the refresh is always scheduled (even when just page reloads)
  }

  public openLogin = async () => {
    lock.show({
      allowForgotPassword: true,
      allowLogin: true,
      allowSignUp: !DisableSignup,
      initialScreen: "login",
    });
  };

  public openSignUp = async () => {
    lock.show({
      allowForgotPassword: true,
      allowLogin: true,
      allowSignUp: !DisableSignup,
      initialScreen: "signUp",
    });
  };

  public openNewInstance = async (options: temporaryAny) => {
    const instance = new Auth0Lock(
      Auth0Config.clientId,
      Auth0Config.domain,
      options
    );
    instance.show();
  };

  // Clear User session
  logout = async () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at_datetime");
    localStorage.removeItem("scopes");
    localStorage.removeItem("user_info");
    clearTimeout(tokenRenewalTimeout);
  };

  /* Parse authentication callback information in hash string (callback from authentication provider redirected to url+#hash)  */
  parseAuthenticationHash = (urlHashValue: string) => {
    // Parses a URL hash fragment to extract the result of an Auth0 authentication response
    return new Promise<IAuthResult>((resolve, reject) => {
      lock.resumeAuth(urlHashValue, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          console.debug("Authentication Success");
          const result: IAuthResult = this.toAuthResult(authResult);
          console.debug(result);
          resolve(result);
        } else {
          console.error("Authentication Failed!");
          console.error(err);
          reject(err);
        }
      });
    });
  };

  // Get latest user profile (idToken) from Auth0
  getUserProfile = (accessToken?: string): Promise<IUserProfile> => {
    if (!accessToken) {
      throw new Error("Access token was not provided");
    }

    //NOTE: [lock.getUserInfo] is rather pointless as it returns the same/stale information as at the time of authentication not the actual user profile
    // see https://community.auth0.com/t/auth0-returns-a-stale-userinfo/13030

    return new Promise<IUserProfile>((resolve, reject) => {
      lock.getUserInfo(accessToken, (err, profile) => {
        if (profile) {
          console.log(profile);
          const userInfo = this.toUserProfile(profile);
          console.log(userInfo);
          resolve(userInfo);
        } else {
          reject(err);
        }
      });
    });
  };

  scheduleTokenRenewal = (
    expiresAtDate: number | undefined,
    callback: (result: IAuthResult) => void
  ) => {
    //const expiresAtDate = JSON.parse(localStorage.getItem('expires_at_datetime'));
    if (expiresAtDate) {
      const delay = expiresAtDate - (Date.now() + 30000); // refresh 30 seconds before expiry
      const expiryDate = new Date(expiresAtDate);
      console.debug(
        `Scheduling token renewal to [${expiryDate}] delay [${delay}]`
      );
      if (delay > 0) {
        if (tokenRenewalTimeout) {
          clearTimeout(tokenRenewalTimeout); // cancel previous refresh
        }
        tokenRenewalTimeout = window.setTimeout(() => {
          this.renewToken().then((result) => {
            if (callback) {
              callback(result);
            }
          });
        }, delay);
      }
    }
  };

  /** acquire a new token for a user who is already authenticated */
  renewToken = () => {
    return new Promise<IAuthResult>((resolve, reject) => {
      lock.checkSession({}, (err, authResult) => {
        if (authResult) {
          console.debug(`New Token received`);
          const result = this.toAuthResult(authResult);
          resolve(result);
        } else {
          const error = `Failed to refresh token. Details:${err.error}: ${err.error_description}`;
          console.error(error);
          reject(error);
        }
      });
    });
  };

  private toAuthResult(authResult: any): IAuthResult {
    console.debug("Auth Result");
    console.debug(authResult);
    const userProfile = this.toUserProfile(authResult.idTokenPayload);
    return {
      accessToken: authResult.accessToken,

      //expiresIn: authResult.expiresIn,
      expiresAt: authResult.expiresIn * 1000 + new Date().getTime(),
      id: authResult.idTokenPayload.sub,
      idToken: authResult.idToken,
      name: userProfile.name,
      userProfile,
    };
  }

  private toUserProfile(result: temporaryAny): IUserProfile {
    const meta = result["https://app.fluent-forever.com/user_info"] || {};
    const {
      ff_user_id: userId,
      email,
      created_at,
      email_verified,
      identity_provider: authProvider,
    } = meta;
    const firstName = meta.firstName || result.given_name;
    const lastName = meta.lastName || result.family_name;

    // when custom sign up form is used name come from form fields
    const name =
      firstName || lastName ? `${firstName} ${lastName}` : result.name;
    return {
      authProvider,
      createdAt: created_at,
      email,
      emailVerified: email_verified || false,
      firstName,
      lastName,
      name,
      userId,
    };
  }

  // /** Store Access Token & User Id */
  // private setSession(authResult) {
  // 	if (authResult) {
  // 		console.debug('Store authentication values...');
  // 		console.debug(authResult);

  // 		const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
  // 		localStorage.setItem('access_token', authResult.accessToken);
  // 		localStorage.setItem('id_token', authResult.idToken);
  // 		localStorage.setItem('expires_at_datetime', expiresAt);
  // 		localStorage.setItem('user_auth0_id', authResult.idTokenPayload.sub);

  // 		// schedule a token renewal
  // 		this.scheduleTokenRenewal(expiresAt);

  // 	} else {
  // 		console.error('Failed to store authentication result');
  // 	}
  // }
}

export const Auth0 = new Auth0Authentication();
const glbl = global as any;
glbl.Auth0Authentication = Auth0;

function createInstance(options: temporaryAny) {
  const instance = new Auth0Lock(
    Auth0Config.clientId,
    Auth0Config.domain,
    options
  );
  instance.on("authenticated", () => {
    //Auth0 authenticated handler
  });
  const handleLockError = (error: temporaryAny) => {
    console.error(error);
  };
  instance.on("authorization_error", handleLockError);
  instance.on("unrecoverable_error", handleLockError);
  return instance;
}
