/* eslint-disable react/prop-types */
import * as App from "application";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, RouteProps } from "react-router-dom";
import { IRootState } from "store";

export interface IRedirectToDefaultProps extends RouteProps {
  redirectTo: string;
}

const mapStateToProps = (state: IRootState) => ({
  redirectTo: App.Navigation.getUserDefaultRoute(
    App.Navigation.getUserRoutingInfo(state)
  ),
});

/* Redirects user to their default route based on their current state
 */
const RedirectToDefaultRouteComponent: React.SFC<IRedirectToDefaultProps> = (
  props
) => {
  return <Redirect to={props.redirectTo} />;
};

export const RedirectToDefaultRoute = connect(mapStateToProps)(
  RedirectToDefaultRouteComponent
);
