import * as Application from "./application";
import * as Authentication from "./authentication";
import * as CouponCode from "./backerCode";
import * as Bootcamp from "./bootcamp";
import * as EmailConfirmation from "./emailVerification";
import { initialize } from "./initialize";
import * as Navigation from "./navigation";
import * as Operation from "./operation";
import * as Subscriptions from "./planSubscription";
import * as Plans from "./plans";
import * as Trial from "./trial";
import * as UserInfo from "./user";
import * as WeeklyCoaching from "./weekly_coaching";

export const applicationInstance = initialize();

export {
  Application,
  Authentication,
  Bootcamp,
  CouponCode,
  EmailConfirmation,
  Navigation,
  Operation,
  Plans,
  Subscriptions,
  Trial,
  UserInfo,
  WeeklyCoaching,
};
