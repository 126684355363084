import { BootcampEnrollmentStateMachine } from "../machines/bootcamp_enrollment_machine";

export const formatDateString = (isoDateString: string): string => {
  /**
   * Takes a full ISO date string like: "2022-02-07T06:00:00.000Z"
   * and returns only the part we care about: "2022-02-07"
   */
  return new Date(isoDateString).toISOString().split("T")[0];
};

export const getIsEnrollmentFormEnabled = (
  machine: BootcampEnrollmentStateMachine
): boolean => {
  const { context } = machine;
  const { participant, selectedDate, selectedLangPairCode } = context;

  if (!machine.matches("idle")) return false;
  return !participant && Boolean(selectedDate) && Boolean(selectedLangPairCode);
};
