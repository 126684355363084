import AppPreview1Image from "assets/images/app_preview_1.svg";
import AppPreview2Image from "assets/images/app_preview_2.svg";
import gsap, { Cubic } from "gsap";
import * as React from "react";
import { useEffect } from "react";
import { ScrollScene } from "scrollscene";
import "../styles/SecondSectionPromo.scss";

export const SecondSectionPromo = (): React.ReactElement => {
  // INTENDED: null is needed for useRef
  /* eslint-disable no-null/no-null */
  const containerRef = React.useRef(null);
  const titleRef = React.useRef(null);
  const firstPreviewRef = React.useRef(null);
  const secondPreviewRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  /* eslint-enable no-null/no-null */

  useEffect(() => {
    const { current: containerElement } = containerRef;
    const { current: titleElement } = titleRef;
    const { current: firstPreviewElement } = firstPreviewRef;
    const { current: secondPreviewElement } = secondPreviewRef;
    const { current: descriptionElement } = descriptionRef;

    if (
      !containerElement &&
      !titleElement &&
      !firstPreviewElement &&
      !secondPreviewElement &&
      !descriptionElement
    ) {
      return undefined;
    }

    const timeline = gsap.timeline({ paused: true });

    timeline.fromTo(
      firstPreviewElement,
      { xPercent: 0 },
      {
        duration: 1,
        ease: Cubic.easeInOut,
        xPercent: -35,
      },
      0
    );

    timeline.fromTo(
      secondPreviewElement,
      { xPercent: 0 },
      {
        duration: 1,
        ease: Cubic.easeInOut,
        xPercent: 35,
      },
      0
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollScene = new ScrollScene({
      duration: "50%",
      gsap: { timeline },
      offset: -50,
      toggle: { className: "in-view", element: containerElement },
      triggerElement: containerElement,
      triggerHook: 0.5,
    });

    return () => {
      scrollScene.destroy();
    };
  });

  return (
    <div className="second-section" ref={containerRef}>
      <span className="title" ref={titleRef}>
        Learn how to sound like a native with instructional videos, exercises,
        and cutting-edge <span className="highlight">ear training</span>.
      </span>
      <div className="previews-container">
        <img
          className="first-preview"
          ref={firstPreviewRef}
          src={AppPreview1Image}
        />{" "}
        <img
          className="second-preview"
          ref={secondPreviewRef}
          src={AppPreview2Image}
        />
      </div>
      <span className="description" ref={descriptionRef}>
        Discover the immersive mobile experience designed to unlock your ability
        to learn a new language and never forget it.
      </span>
    </div>
  );
};
