/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import { ActionType } from "typesafe-actions";
import * as creators from "./actionCreators";
import * as actions from "./actions";
import { IActiveSubscriptionState } from "./types";

export type SubscriptionAction = ActionType<typeof creators>;

const initialState: IActiveSubscriptionState = {
  activeWeeklyCoachingSubscriptions: [],
  enrolledBootcamps: [],
  isAllowCancel: false,
  isAllowChangeBillingAddress: false,
  isAllowChangePaymentMethod: false,
  isAllowChangePlan: false,
  isAllowNewPlan: false,
  isAllowRefund: false,
  isBackerSubscription: false,
  planId: "",
  planName: "",
  subscriptionId: "",
};

const reducer = (
  state: IActiveSubscriptionState = initialState,
  action: any
) => {
  switch (action.type) {
    case actions.FETCH_ACTIVE_SUBSCRIPTION_INIT:
      return state;
    case actions.FETCH_ACTIVE_SUBSCRIPTION_SUCCESS:
      return { ...state, ...action.payload };
    case actions.FETCH_ACTIVE_SUBSCRIPTION_FAIL:
      return { ...state };
    case actions.UPDATE_ACTIVE_SUBSCRIPTION:
      return { ...state, ...action.payload };
    case actions.ADD_ACTIVE_WEEKLY_COACHING_SUBSCRIPTION:
      return {
        ...state,
        activeWeeklyCoachingSubscriptions: R.uniqBy(
          ({ langPairCode }) => langPairCode,
          state.activeWeeklyCoachingSubscriptions.concat(action.payload)
        ),
      };
    default:
      return state;
  }
};

export default reducer;
