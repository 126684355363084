import { ISubscriptionCoupon, Models } from "@fluent-forever/types";
import * as App from "application";
import { AppConfig, DisableSignup } from "config/config";
import { OutputParams } from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { Dispatch } from "../../../../store/types";
import { LandingCouponPage } from "./LandingCouponPage/LandingCouponPage";

export interface IPromoViewProps {
  isSignupDisabled: boolean;
  queryValues?: OutputParams;
  checkMaintenanceMode: () => Promise<boolean>;
  loadCouponInformation: (
    coupon: string
  ) => Promise<Models.ISubscriptionCoupon | undefined>;
  signUp: (ctaButtonText: string, coupon: ISubscriptionCoupon) => void;
  redirectAway: () => void;
  redirectToWaitingList: () => void;
}

export interface IPromoViewState {
  coupon?: ISubscriptionCoupon;
}

class PromoView extends React.Component<IPromoViewProps, IPromoViewState> {
  constructor(props: IPromoViewProps) {
    super(props);
    this.state = {
      coupon: undefined,
    };
  }

  async componentDidMount() {
    const isMaintenanceModeEnabled = await this.props.checkMaintenanceMode();
    if (isMaintenanceModeEnabled || this.props.isSignupDisabled) {
      this.props.redirectToWaitingList();
      return;
    }

    if (this.props.queryValues && this.props.queryValues.coupon) {
      const couponId = this.props.queryValues.coupon as string;
      const coupon = await this.props.loadCouponInformation(couponId);
      if (coupon) {
        this.setState({ coupon });
      }
    } else {
      this.props.redirectAway();
    }
  }

  render() {
    const { coupon } = this.state;
    const ctaButtonText = "Sign Up";
    return coupon ? (
      <LandingCouponPage
        coupon={coupon}
        onSignUp={() => this.props.signUp(ctaButtonText, coupon)}
        signUpButtonText={ctaButtonText}
      />
    ) : (
      <Segment>
        <Dimmer active inverted>
          <Loader>Loading...</Loader>
        </Dimmer>
        <div style={{ height: window.innerHeight }} />
      </Segment>
    );
  }
}

// === CONTAINER ===

const mapStateToProps = () => ({
  isSignupDisabled: DisableSignup,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkMaintenanceMode: () => App.Application.checkMaintenanceMode(dispatch),
  loadCouponInformation: (coupon: string) =>
    App.Subscriptions.getCouponInfo(coupon, dispatch),
  redirectAway: () => App.Navigation.redirectToDefaultPath(dispatch),
  redirectToWaitingList: () =>
    App.Navigation.redirectToExternalLink(AppConfig.waitlistUrl),
  signUp: (ctaButtonText: string, coupon: ISubscriptionCoupon) =>
    App.Authentication.openPromoSignUp(dispatch, ctaButtonText, coupon),
});

export const Promo = connect(mapStateToProps, mapDispatchToProps)(PromoView);
