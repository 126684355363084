/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  isLanguagePairCode,
  ISubscriptionCoupon,
  LanguagePairCode,
} from "@fluent-forever/types";
import { SubscriptionCoupon } from "@fluent-forever/utils";
import * as API from "api";
import { createDefaultOptions, IAuthResult } from "api/Auth0";
import { Application, Navigation, Operation } from "application";
import { AppConfig, DisableSignup, ROUTES } from "config";
import * as Store from "store";
import { userProfileLoaded } from "store/modules/user";
import {
  trackSignUpCompleted,
  trackUserLoggedIn,
} from "../features/analytics/procedures/analytics_event_procedures";
import {
  decodeFromQueryParam,
  encodeToQueryParam,
} from "../features/request/functions/request_functions";
import { Dispatch } from "../store/types";
import { loadUserInfoAndAccountSettings } from "./account";
import { redirectTo, redirectToExternalLink, scrollToTop } from "./navigation";
import { isSignUpMethod, SignUpMethod, WorkFlow } from "./types";
import { loadUserInformation } from "./user";

/** Check User Is Logged into application */
export const isAuthenticated = (state: Store.IRootState): boolean => {
  const expireAt = getAccessTokenExpiry(state);
  const token = getAccessToken(state);
  if (expireAt && token) {
    return new Date().getTime() < expireAt;
  }
  return false;
};

enum AuthenticationType {
  Initial, //Initial Login/Sign Up
  Refresh, //Refresh of tokens
}

// Main handler for authentication response
const authenticationResultHandler = (
  dispatch: Dispatch,
  authType: AuthenticationType
) => (result: IAuthResult) => {
  if (result) {
    dispatch(Store.Authentication.authenticationSuccess(result));

    if (authType === AuthenticationType.Initial) {
      if (result.userProfile && result.userProfile.userId) {
        // regular sign in
        dispatch(Store.Authentication.loginSuccess(result));
        // Segment Tracking
        trackUserLoggedIn(result.userProfile.userId);
      } else {
        // no meta data => a new sign up
        dispatch(Store.Authentication.signUpSuccess(result));
      }
    } else if (authType === AuthenticationType.Refresh) {
      //Token Refresh Handler
    }
  }
  return result;
};

/** Process authentication information from authentication callback hash  */
export const authenticate = async (
  hash: string,
  query: string,
  dispatch: Dispatch
) => {
  const result = await API.Auth0.parseAuthenticationHash(hash);
  const isNewSignup = !result.userProfile || !result.userProfile.userId;
  const queryParams = new URLSearchParams(query || "");
  const signUpMethodQueryParam = queryParams.get("signUpMethod");
  let workflow: WorkFlow = "organic";
  let redirectUrl: string | undefined = undefined;
  if (signUpMethodQueryParam) {
    const signUpMethod = decodeFromQueryParam<SignUpMethod>({
      queryParam: signUpMethodQueryParam,
      validator: isSignUpMethod,
    });
    if (signUpMethod) {
      switch (signUpMethod) {
        case "promo": {
          workflow = "promo";
          const couponQueryParam = queryParams.get("coupon");
          if (couponQueryParam) {
            const coupon = decodeFromQueryParam<ISubscriptionCoupon>({
              queryParam: couponQueryParam,
              validator: SubscriptionCoupon.isSubscriptionCoupon,
            });
            if (coupon) {
              dispatch(Store.User.registerSubscriptionCoupon(coupon));
            }
          }
          break;
        }
        case "weekly-coaching-log-in":
        case "weekly-coaching-sign-up": {
          if (signUpMethod === "weekly-coaching-log-in") {
            redirectUrl = ROUTES.WEEKLY_COACHING;
          }
          const langPairCodeQueryParam = queryParams.get("langPairCode");
          const planIdQueryParam = queryParams.get("planId");
          if (langPairCodeQueryParam) {
            const langPairCode = decodeFromQueryParam<LanguagePairCode>({
              queryParam: langPairCodeQueryParam,
              validator: isLanguagePairCode,
            });
            if (langPairCode) {
              dispatch(Store.User.registerWeeklyCoachingLanguage(langPairCode));
            }
          }
          if (planIdQueryParam) {
            const planId = decodeFromQueryParam<string>({
              queryParam: planIdQueryParam,
              validator: (v: unknown): v is string => typeof v === "string",
            });
            if (planId) {
              dispatch(Store.User.registerWeeklyCoachingPlanId(planId));
            }
          }
          break;
        }
      }
    }
  }
  const resultHandler = authenticationResultHandler(
    dispatch,
    AuthenticationType.Initial
  );
  resultHandler(result);
  scheduleTokenRenewal(result.expiresAt, dispatch);
  if (isNewSignup) {
    const user = await loadUserInformation(dispatch);
    if (user?.id) {
      trackSignUpCompleted(result.userProfile.authProvider, {
        created_at: result.userProfile.createdAt,
        email: user.email,
        firstName: result.userProfile.firstName,
        id: user.id,
        lastName: result.userProfile.lastName,
        workflow,
      });
    }
  } else {
    await loadUserInfoAndAccountSettings(dispatch);
  }
  if (redirectUrl) redirectTo(dispatch, redirectUrl);
  return result;
};

/** Load user Auth0 profile information */
export const loadAuthUserProfile = (
  accessToken: string | undefined,
  dispatch: Dispatch
) => {
  return API.Auth0.getUserProfile(accessToken)
    .then((profile) => {
      dispatch(userProfileLoaded(profile));
      return profile;
    })
    .catch((err) =>
      Operation.onFail("Failed to load user auth0 profile", dispatch, err)
    );
};

/** Schedule Authentication Token renewal based on current expiry */
export const scheduleTokenRenewal = (
  expiresAt: number | undefined,
  dispatch: Dispatch
) => {
  const resultHandler = authenticationResultHandler(
    dispatch,
    AuthenticationType.Refresh
  );
  API.Auth0.scheduleTokenRenewal(expiresAt, resultHandler);
};

export interface RenewAccessTokenError {
  isRenewAccessTokenError: true;
}

/** Renew Authentication Token(s) */
export const renewAccessToken = (dispatch: Dispatch) => {
  console.debug("Renewing token...");
  return API.Auth0.renewToken()
    .then((authResult) => {
      console.debug("Token renewed.");
      authenticationResultHandler(
        dispatch,
        AuthenticationType.Refresh
      )(authResult);
      return authResult;
    })
    .catch((err) => {
      throw {
        ...err,
        isRenewAccessTokenError: true,
      };
    });
};

/** Open Login Popup */
export const openLogin = (dispatch: Dispatch) => {
  if (Application.isMaintenanceModeEnabled()) {
    redirectToExternalLink(AppConfig.progressSiteUrl);
  } else {
    //popup shows on top make sure user can see it
    scrollToTop();
    API.Auth0.openLogin().then(() => {
      dispatch(Store.Authentication.loginStart());
    });
  }
};

/** Open Sign Up Popup */
export const openSignUp = (dispatch: Dispatch) => {
  if (Application.isMaintenanceModeEnabled() || DisableSignup) {
    redirectToExternalLink(AppConfig.waitlistUrl);
  } else {
    //popup shows on top make sure user can see it
    scrollToTop();
    API.Auth0.openSignUp().then(() => {
      dispatch(Store.Authentication.signUpStart());
    });
  }
};

/** Open Promo Sign Up Popup */
export const openPromoSignUp = (
  dispatch: Dispatch,
  _ctaButtonText: string,
  coupon: ISubscriptionCoupon
) => {
  //popup shows on top make sure user can see it
  scrollToTop();
  const options = createDefaultOptions();
  const params = new URLSearchParams();
  params.set("signUpMethod", encodeToQueryParam<SignUpMethod>("promo"));
  params.set("coupon", encodeToQueryParam<ISubscriptionCoupon>(coupon));
  options.auth.redirectUrl += `?${params.toString()}`;
  options.allowSignUp = true;
  options.allowLogin = false;
  API.Auth0.openNewInstance(options).then(() => {
    dispatch(Store.Authentication.signUpStart());
  });
};

/** Open Weekly Chat Plan Sign Up Popup */
export const openWeeklyCoachingSignUp = ({
  dispatch,
  langPairCode,
  planId,
}: {
  dispatch: Dispatch;
  langPairCode: LanguagePairCode;
  planId: string;
}) => {
  //popup shows on top make sure user can see it
  scrollToTop();
  const options = createDefaultOptions();
  const params = new URLSearchParams();
  params.set(
    "signUpMethod",
    encodeToQueryParam<SignUpMethod>("weekly-coaching-sign-up")
  );
  params.set(
    "langPairCode",
    encodeToQueryParam<LanguagePairCode>(langPairCode)
  );
  params.set("planId", encodeToQueryParam<string>(planId));
  options.auth.redirectUrl += `?${params.toString()}`;
  options.allowSignUp = true;
  options.allowLogin = false;
  API.Auth0.openNewInstance(options).then(() => {
    dispatch(Store.Authentication.signUpStart());
  });
};

/** Sing user out of application */
export const logout = (dispatch: Dispatch) => {
  API.Auth0.logout().then(() => {
    dispatch(Store.Authentication.logout());
    Navigation.redirectTo(dispatch, ROUTES.LANDING);
  });
};

/** Open Weekly Chat Plan Log In Popup */
export const openWeeklyCoachingLogIn = ({
  dispatch,
  langPairCode,
  planId,
}: {
  dispatch: Dispatch;
  langPairCode: LanguagePairCode;
  planId: string;
}) => {
  //popup shows on top make sure user can see it
  scrollToTop();
  const options = createDefaultOptions();
  const params = new URLSearchParams();
  params.set(
    "signUpMethod",
    encodeToQueryParam<SignUpMethod>("weekly-coaching-log-in")
  );
  params.set(
    "langPairCode",
    encodeToQueryParam<LanguagePairCode>(langPairCode)
  );
  params.set("planId", encodeToQueryParam<string>(planId));
  options.auth.redirectUrl += `?${params.toString()}`;
  options.allowSignUp = false;
  options.allowLogin = true;
  API.Auth0.openNewInstance(options).then(() => {
    dispatch(Store.Authentication.loginStart());
  });
};

/** State Selectors */
/** User Id  */
export const getUserId = ({ authentication }: Store.IRootState) => {
  return authentication && authentication.id ? authentication.id : undefined;
};

/** Authentication Access Token (Oauth)  */
export const getAccessToken = ({ authentication }: Store.IRootState) => {
  return authentication && authentication.accessToken
    ? authentication.accessToken
    : undefined;
};

/** Authentication Access Token (Oauth)  */
export const getAccessTokenExpiry = ({ authentication }: Store.IRootState) => {
  return authentication && authentication.expiresAt
    ? authentication.expiresAt
    : undefined;
};
