import { ISubscriptionCoupon } from "@fluent-forever/types";
import { SubscriptionCoupon } from "@fluent-forever/utils";
import * as App from "application";
import * as React from "react";
import { useEffect } from "react";
import { isMobileSafari } from "react-device-detect";
import { Container } from "semantic-ui-react";
import { ButtonLarge } from "theme";
import { subscribeToPlan } from "../../../application/account";
import { isMonthlyPlan } from "../../../application/planSubscription";
import "./RedeemSubscriptionCouponSection.css";

interface RedeemSubscriptionCouponSectionProps {
  coupon: ISubscriptionCoupon;
  flow: string;
}

export function RedeemSubscriptionCouponSection({
  coupon,
  flow,
}: RedeemSubscriptionCouponSectionProps): React.ReactElement {
  useEffect(() => {
    // NOTE:
    // Automatically triggering the Chargebee checkout modal does not
    // work on Safari Mobile (the modal does not show at all, and if the user
    // would later try to manually open the modal it would complain that another
    // checkout was already in progress).
    // For that reason, we don't try to open it automatically and we let
    // the user open it himself/herself by clicking the button.
    if (!isMobileSafari) {
      onSubscribeWithReferralCoupon(coupon);
    }
  }, []);

  const onSubscribeWithReferralCoupon = (coupon: ISubscriptionCoupon) => {
    if (coupon) {
      const plan = App.Plans.getPlan(coupon.planId);
      if (plan) {
        subscribeToPlan({ coupon, flow, plan });
      }
    }
  };

  let CallToAction = "Claim Discount";
  if (isMonthlyPlan(coupon)) {
    const { metadata } = coupon;
    const { days, allowsTrial } = metadata;
    const totalDays =
      (days || 0) +
      (allowsTrial ? SubscriptionCoupon.getCouponTrialDuration(coupon) : 0);
    CallToAction = `Get ${totalDays} Days Free`;
  }

  return (
    <div className="redeem-subscription-coupon-section__section-secondary">
      <Container style={{ width: 600 }}>
        <p className="redeem-subscription-coupon-section__text">
          Just one last thing.
        </p>
        <p className="redeem-subscription-coupon-section__text">
          Click the button below and provide us with a few details to receive
          your special offer and access the Fluent Forever app.
        </p>
        <ButtonLarge
          className="redeem-subscription-coupon-section__button"
          onClick={() => onSubscribeWithReferralCoupon(coupon)}
        >
          {CallToAction}
        </ButtonLarge>
      </Container>
    </div>
  );
}
