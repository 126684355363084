/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { env } from "@fluent-forever/env";
import * as App from "application";
import { ROUTES } from "config/routes";
import * as History from "history";
import * as React from "react";
import { connect } from "react-redux";
import { RouteProps, withRouter } from "react-router-dom";
import {
  Button,
  Dropdown,
  Table,
  TableCell,
  TableRow,
} from "semantic-ui-react";
import { IRootState } from "store/modules";
import { CssClasses } from "theme";
import { getAnonymousId } from "../../../features/analytics/procedures/analytics_event_procedures";
import { serverTrackSignUpButtonClicked } from "../../../features/analytics/procedures/server_analytics_event_procedures";
import { Dispatch } from "../../../store/types";
import "./UserMenu.css";

export interface IUserMenuProps extends RouteProps {
  isMaintenanceModeEnabled: boolean;
  isLoggedIn: boolean;
  hasSubscription: boolean;
  displayName: string;
  isBacker?: boolean;
  onAccountSettings: () => boolean;
  onBootcamp: () => boolean;
  onWeeklyCoaching: () => boolean;
  onLogout: () => void;
  onSignUp: (buttonText: string) => void;
  onLogin: (buttonText: string) => void;
}

const UserMenuComponent: React.SFC<IUserMenuProps> = (props) => {
  const nav = (
    <Table className={CssClasses.Mobile.hidden} id="nav-table">
      <TableRow>
        <TableCell
          className="nav-item"
          href="https://fluent-forever.com/method"
        >
          Method
        </TableCell>
        <TableCell className="nav-item" href="https://fluent-forever.com/book">
          Book
        </TableCell>
        <TableCell className="nav-item" href="https://blog.fluent-forever.com">
          Blog
        </TableCell>
      </TableRow>
    </Table>
  );
  let signUp: JSX.Element | undefined = (
    <Button
      className={CssClasses.Mobile.hidden}
      id="sign-up"
      onClick={() => props.onSignUp("Sign Up")}
      size="small"
    >
      Sign Up
    </Button>
  );
  let control: JSX.Element | undefined = (
    <Button icon="" id="log-in" onClick={() => props.onLogin("Log In")}>
      Log In
    </Button>
  );
  let username = props.displayName;
  if (username.length > 15) {
    username = username.substring(0, 12) + "...";
  }
  if (props.isLoggedIn) {
    signUp = undefined;
    const { hasSubscription } = props;
    control = (
      <Dropdown text="Menu">
        <Dropdown.Menu direction="left">
          {hasSubscription && (
            <Dropdown.Item onClick={props.onAccountSettings} text="Account" />
          )}
          {env.asBoolean("REACT_APP_WEEKLY_COACHING_ENABLED", false) ? (
            <Dropdown.Item
              onClick={props.onWeeklyCoaching}
              text="Weekly Coaching"
            />
          ) : undefined}
          <Dropdown.Item onClick={props.onLogout} text="Logout" />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  // Hide Log in and Sign up button on Promo page
  if ((props.location as History.Location).pathname === "/promo") {
    control = undefined;
    signUp = undefined;
  }

  return (
    <div id="menu">
      {" "}
      {nav} {control} {signUp}{" "}
    </div>
  );
};

// === CONTAINER ===

const mapStateToProps = (state: IRootState) => ({
  displayName: App.UserInfo.displayName(state),
  hasSubscription: App.UserInfo.hasSubscriptions(state),
  isLoggedIn: App.Authentication.isAuthenticated(state),
  isMaintenanceModeEnabled: App.Application.isMaintenanceModeEnabled(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAccountSettings: () => App.Navigation.redirectTo(dispatch, ROUTES.SETTINGS),
  onBootcamp: () => App.Navigation.redirectTo(dispatch, ROUTES.BOOTCAMP),
  onLogin: () => App.Authentication.openLogin(dispatch),
  onLogout: () => App.Authentication.logout(dispatch),
  onSignUp: () => {
    App.Authentication.openSignUp(dispatch);
    serverTrackSignUpButtonClicked({
      anonymousId: getAnonymousId(),
      properties: { workflow: "organic" },
    });
  },
  onWeeklyCoaching: () =>
    App.Navigation.redirectTo(dispatch, ROUTES.WEEKLY_COACHING),
});

const UserMenuSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenuComponent);

export const UserMenu = withRouter<any, any>(UserMenuSection);
