import {
  Args,
  ConfirmWeeklyCoachingCheckoutResult,
  EvaluateUnpaidUserBootcampEnrollmentResultData,
  InitiateWeeklyCoachingCheckoutPayload,
  LanguagePairCode,
  Models,
  REST,
  SubmitUserBootcampEnrollmentRequestPayload,
} from "@fluent-forever/types";
import { getSubscriptionWorkflow, IHttpRequest } from "application/utils";
import axios from "axios";
import { ApiConfig as CONFIG_API, AppConfig, CHARGEBEE } from "config/config";
import { temporaryAny } from "../types/any_types";
import {
  evaluateUnpaidUserBootcampEnrollments,
  submitBootcampEnrollment,
} from "./mxu";
import { weeklyCoachingPortalApi } from "./weekly_coaching_api";

export const hideChargebeeModal = (): void => {
  // I couldn't find a way to hide Chargebee using its API, so I'm going
  // with good old CSS instead.
  const cbContainer = document.getElementById("cb-container");
  if (cbContainer) {
    cbContainer.style.display = "none";
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: FIX
export const chargebeeInstance = Chargebee.init({
  enableFBQTracking: true,
  enableGTMTracking: true,
  site: CHARGEBEE.site,
});

chargebeeInstance.setPortalSession(async () => {
  const body: Args.ICreatePortalSessionArg = { callbackPath: AppConfig.PATH };
  const rst = await axios.post(
    CONFIG_API.url + REST.PATH.SUBSCRIPTION_PORTAL_CREATE,
    body
  );
  return rst.data;
});

const portalInstance = chargebeeInstance.createChargebeePortal();

const sections = {
  ACCOUNT_DETAILS: "account_details",
  ADDRESS: "portal_address",
  ADD_PAYMENT_SOURCE: "portal_add_payment_method",
  BILLING_HISTORY: "portal_billing_history",
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION: "portal_choose_payment_method",
  EDIT_ACCOUNT_DETAILS: "portal_edit_account",
  EDIT_BILLING_ADDRESS: "portal_edit_billing_address",
  EDIT_PAYMENT_SOURCE: "portal_edit_payment_method",
  EDIT_SHIPPING_ADDRESS: "portal_edit_shipping_address",
  EDIT_SUBSCRIPTION: "edit_subscription",
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS: "portal_edit_subscription_cf",
  PAYMENT_SOURCES: "portal_payment_methods",
  SUBSCRIPTION_CANCELLATION: "sub_cancel",
  SUBSCRIPTION_DETAILS: "sub_details",
  VIEW_PAYMENT_SOURCE: "portal_view_payment_method",
  VIEW_SCHEDULED_CHANGES: "scheduled_changes",
};

export enum CheckoutStep {
  cart_screen = "cart_screen",
  review_screen = "review_screen",
  thankyou_screen = "thankyou_screen",

  //account info
  add_account_info = "add_account_info",
  edit_account_info = "edit_account_info",

  //billing address
  add_billing_address = "add_billing_address",
  edit_billing_address = "edit_billing_address",

  //payment_method
  add_payment_method = "add_payment_method",
  edit_payment_method = "edit_payment_method",
}

export interface ISubscriptionOptions {
  couponId?: string;
  discount?: Models.CouponDiscountType;
  flow?: string;
  forceStart?: boolean;
  onCancel?: (planId: string, flow?: string) => void;
  onStepStarted?: (step: CheckoutStep) => void;
  onStepCompleted?: (step: CheckoutStep, flow?: string) => void;
  planId: string;
  request: IHttpRequest;
  langPairCode?: LanguagePairCode;
}

class Subscription {
  /** Subscribe current user into plan */
  subscribe = (options: ISubscriptionOptions) => {
    return new Promise<Models.IPlanSubscriptionResult>((resolve, reject) => {
      let prevStep: CheckoutStep | undefined;
      const { request } = options;
      chargebeeInstance.openCheckout({
        close: () => {
          chargebeeInstance.closeAll();
          options.onCancel?.(options.planId, options.flow);
          reject();
        },
        error: (error: temporaryAny) => {
          chargebeeInstance.closeAll();
          reject(error);
        },
        hostedPage: () => {
          //ToDo: move this portion to API and inject callback into subscribe
          const body: Args.IStartNewSubscriptionCheckoutArg = {
            couponId: options.couponId,
            discount: options.discount,
            forceStart: options.forceStart,
            langPairCode: options.langPairCode,
            planId: options.planId,
          };
          return request
            .post(CONFIG_API.url + REST.PATH.SUBSCRIPTION_START, body)
            .then((response) => {
              return response.data.hosted_page;
            })
            .catch((err) => {
              console.error(err);
              reject(err);
              throw err;
            });
        },
        loaded: () => undefined,
        step(step: CheckoutStep) {
          if (prevStep !== step) {
            if (prevStep && options.onStepCompleted) {
              options.onStepCompleted(prevStep, options.flow);
            }
            if (options.onStepStarted) {
              options.onStepStarted(step);
            }
            prevStep = step;
          }
        },
        success: (hostedPageId: string) => {
          //Subscription Was Created in chargebee => Retrieve Info on Backed
          //ToDo: move this portion to API and inject callback into subscribe
          request
            .post(CONFIG_API.url + REST.PATH.SUBSCRIPTION_ON_CREATED, {
              hostedPageId,
              workflow: getSubscriptionWorkflow(options.flow),
            })
            .then((result) => {
              chargebeeInstance.closeAll();
              return resolve(result.data as Models.IPlanSubscriptionResult);
            })
            .catch((err) => reject(err));
        },
      });
    });
  };

  /** Subscribe to bootcamp */
  subscribeBootcamp = (body: SubmitUserBootcampEnrollmentRequestPayload) => {
    return new Promise<EvaluateUnpaidUserBootcampEnrollmentResultData>(
      (resolve, reject) => {
        let prevStep: CheckoutStep | undefined;
        chargebeeInstance.openCheckout({
          close: () => undefined,
          error: (error: temporaryAny) => {
            chargebeeInstance.closeAll();
            reject(error);
          },
          hostedPage: async () => submitBootcampEnrollment(body),
          loaded: () => undefined,
          step(step: CheckoutStep) {
            if (prevStep !== step) {
              prevStep = step;
            }
          },
          success: async (paymentId: string) => {
            try {
              const result = await evaluateUnpaidUserBootcampEnrollments(
                paymentId
              );
              resolve(result);
            } catch (e) {
              reject(e);
            }
            chargebeeInstance.closeAll();
          },
        });
      }
    );
  };

  /** Subscribe to weekly coaching */
  subscribeWeeklyCoaching = (
    body: InitiateWeeklyCoachingCheckoutPayload
  ): Promise<ConfirmWeeklyCoachingCheckoutResult> => {
    return new Promise<ConfirmWeeklyCoachingCheckoutResult>(
      (resolve, reject) => {
        let prevStep: CheckoutStep | undefined;
        chargebeeInstance.openCheckout({
          close: () => {
            chargebeeInstance.closeAll();
            reject("The checkout was closed!");
          },
          error: (error: temporaryAny) => {
            chargebeeInstance.closeAll();
            reject(error);
          },
          hostedPage: async () =>
            weeklyCoachingPortalApi.initiateWeeklyCoachingCheckout(body),
          loaded: () => undefined,
          step(step: CheckoutStep) {
            if (prevStep !== step) {
              prevStep = step;
            }
          },
          success: async (checkoutId: string) => {
            // We hide the chargebee modal on success because
            // the user will get prompted to close it, which
            // will make this all reject on the method above
            hideChargebeeModal();
            try {
              resolve(
                await weeklyCoachingPortalApi.confirmWeeklyCoachingCheckout({
                  checkoutId,
                })
              );
            } catch (e) {
              reject(e);
            }
            chargebeeInstance.closeAll();
          },
        });
      }
    );
  };

  /** Create subscription From Backer Code */
  createBackerSubscription = (arg: Args.ICreateBackerSubscriptionArg) => {
    axios
      .post(CONFIG_API.url + REST.PATH.SUBSCRIPTION_CREATE_BACKER_PLAN, arg)
      .then(() => undefined);
  };

  portalOpen = async () => {
    portalInstance.open({
      close: () => undefined,
    });
  };

  openSubscription = (subscriptionId: temporaryAny) => {
    portalInstance.openSection({
      params: { subscriptionId },
      sectionType: sections.SUBSCRIPTION_DETAILS,
    });
  };

  openAccountDetails = () => {
    portalInstance.openSection({
      sectionType: sections.ACCOUNT_DETAILS,
    });
  };

  openBillingInvoices = () => {
    portalInstance.openSection({
      sectionType: sections.BILLING_HISTORY,
    });
  };

  openAddresses = () => {
    portalInstance.openSection({
      sectionType: sections.ADDRESS,
    });
  };

  openPaymentSources = () => {
    portalInstance.openSection({
      sectionType: sections.PAYMENT_SOURCES,
    });
  };
}

export const ChargeBee = new Subscription();
