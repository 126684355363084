/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Authentication, UserInfo } from "application";
import { ROUTES } from "config";
import createHistory from "history/createBrowserHistory";
import * as Store from "store";
import { IRootState } from "store";
import { Dispatch } from "../store/types";
import { applicationInstance } from ".";
// import * as App from 'application';

//Browser Navigation/Session History
const history = createHistory();

export const configureNavigation = (dispatch: Dispatch) => {
  history.listen((location) => {
    onNavigationChanged(dispatch)(location.pathname);
  });
  return history;
};

export const getCurrentPath = () => {
  return history.location.pathname;
};

//** Redirect user to specific URL */
export const redirectTo = (dispatch: Dispatch, url: string) => {
  const currentUrl = getCurrentPath();
  if (url !== currentUrl) {
    console.debug(`Navigate to:[${url}]`);
    dispatch(Store.Navigation.navigationChanging(url, currentUrl));
    history.replace(url);
    return true;
  } else {
    console.debug(`Redirect to [${url}] => Ignored (already url)`);
    return false;
  }
};

/** Information/flags used to calculate/check user routes (workflow) */
export interface IUserRoutingFlags {
  isAuthenticated: boolean;
  isPendingEmailConfirmation: boolean;
  hasActiveSubscription: boolean;
}

// Get Default Route for current user state
export const getUserDefaultRoute = (flags: IUserRoutingFlags) => {
  const defaultPath = flags.isAuthenticated ? ROUTES.HOME : ROUTES.LANDING;
  return checkForMandatoryPath(flags, defaultPath);
};

// Helper method redirecting user to their current default route
export const redirectToDefaultPath = (dispatch: Dispatch) => {
  const flags = getUserRoutingInfo(applicationInstance.store.getState());
  const path = getUserDefaultRoute(flags);
  return redirectTo(dispatch, path);
};

export const redirectToExternalLink = (url: string) => {
  window.location.replace(url);
};

/** Same as redirect but checks for all navigation flags  */
export const navigateTo = (
  currentPath: string,
  flags: IUserRoutingFlags,
  dispatch: Dispatch
) => {
  const validRoute = checkForMandatoryPath(flags, currentPath);
  redirectTo(dispatch, validRoute);
};

/** Check if current user must be routed to a mandatory path before accessing target path (e.g email confirmation) */
export const checkForMandatoryPath = (
  flags: IUserRoutingFlags,
  targetRoute: string
): string => {
  if (flags.isAuthenticated) {
    //Ensure Mandatory path
    if (flags.isPendingEmailConfirmation === true) {
      return ROUTES.EMAIL_PENDING;
    } else if (
      targetRoute === ROUTES.WEEKLY_COACHING ||
      targetRoute === ROUTES.BOOTCAMP
    ) {
      return targetRoute;
    } else if (flags.hasActiveSubscription === false) {
      return ROUTES.SUBSCRIBE;
    }
    //Prevent accessing mandatory path after completion
    if (
      flags.isPendingEmailConfirmation === false &&
      targetRoute === ROUTES.EMAIL_PENDING
    ) {
      return ROUTES.HOME;
    } else if (
      flags.hasActiveSubscription === true &&
      targetRoute === ROUTES.SUBSCRIBE
    ) {
      return ROUTES.HOME;
    }
  }
  return targetRoute;
};

/** Store state selector for flags used to calculate user path */
export function getUserRoutingInfo(state: IRootState): IUserRoutingFlags {
  return {
    hasActiveSubscription: UserInfo.hasSubscriptions(state),
    isAuthenticated: Authentication.isAuthenticated(state),
    isPendingEmailConfirmation: UserInfo.isPendingEmailConfirmation(state),
  };
}

// history change listener
const onNavigationChanged = (dispatch: Dispatch) => (url: string) => {
  dispatch(Store.Navigation.navigationChanged(url));
  //ToDo: review is this an action or should the notification reducer listen to navigation action?
  dispatch(Store.Notifications.clearNotifications());
};

/** Temp fix for all popups and issues on mobile   */
export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
