import * as React from "react";
import { SVGProps } from "react";

export const LogoDark = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg fill="none" height="46" viewBox="0 0 139 46" width="139" {...props}>
    <path
      clipRule="evenodd"
      d="M85.1024 27.8025C84.5332 27.5674 84.0778 26.9795 84.0778 26.039V11.9311C84.0778 10.9906 83.167 10.1676 82.2562 10.2852C81.4593 10.4027 81.0039 11.1081 81.0039 11.9311V26.6268C81.0039 27.3322 81.1178 27.92 81.2316 28.5079C81.3454 29.0957 81.687 29.4484 82.0285 29.9187C82.3701 30.2714 82.8255 30.5065 83.5086 30.7416C83.7363 30.8592 83.964 30.8592 84.3055 30.9768C85.2163 31.0943 86.0132 30.5065 86.1271 29.6835C86.1271 28.8606 85.7855 28.1552 85.1024 27.8025Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M96.3283 30.8233C96.8963 30.704 97.4643 30.5847 97.9187 30.4655C98.3731 30.3462 98.6003 29.9884 98.6003 29.5114V18.1814C98.6003 17.2273 97.9187 16.5117 97.0099 16.5117C96.1011 16.5117 95.4195 17.2273 95.4195 18.1814V27.9609C95.1923 27.9609 93.6019 28.1995 93.2611 28.1995C92.2387 28.1995 91.4435 27.8417 91.1027 27.2454C90.6483 26.6491 90.4211 25.4564 90.4211 24.0253V18.1814C90.4211 17.2273 89.7395 16.5117 88.8307 16.5117C87.9218 16.5117 87.2402 17.2273 87.2402 18.1814V24.5023C87.2402 25.4564 87.3538 26.2913 87.4674 27.1261C87.6946 27.9609 87.9218 28.6765 88.3762 29.1536C88.8307 29.7499 89.3987 30.2269 90.0803 30.4655C90.7619 30.8233 91.6707 30.9425 92.8067 30.9425C94.2835 31.0618 95.3059 30.9425 96.3283 30.8233Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M112.494 28.8084C112.38 28.0039 111.693 27.5443 110.891 27.7741C110.204 27.889 109.403 28.0039 108.716 28.0039C107.456 28.0039 106.426 27.6592 105.739 27.0846C105.052 26.51 104.708 25.5906 104.594 24.5563H112.609C113.067 24.5563 113.525 24.0966 113.525 23.6369C113.525 23.522 113.525 23.4071 113.525 23.4071C113.525 21.1086 112.952 19.3848 111.922 18.2356C110.891 17.0863 109.403 16.5117 107.571 16.5117C106.769 16.5117 105.968 16.6266 105.281 16.9714C104.479 17.3162 103.792 17.7759 103.22 18.3505C102.647 18.9251 102.189 19.7295 101.846 20.6489C101.502 21.5683 101.273 22.6026 101.273 23.7518C101.273 24.7861 101.388 25.7055 101.731 26.6249C101.96 27.4293 102.418 28.2338 102.991 28.9233C103.563 29.6129 104.25 30.0725 105.166 30.4173C106.082 30.7621 107.113 30.9919 108.258 30.9919C108.716 30.9919 109.174 30.9919 109.632 30.877C110.09 30.877 110.548 30.7621 110.891 30.6471C111.006 30.6471 111.235 30.5322 111.578 30.5322C112.265 30.0725 112.609 29.383 112.494 28.8084ZM104.937 20.9937C105.052 20.6489 105.281 20.3042 105.51 19.9594C105.739 19.6146 106.082 19.3848 106.426 19.2699C106.769 19.04 107.227 19.04 107.685 19.04C108.143 19.04 108.601 19.1549 108.945 19.2699C109.288 19.3848 109.517 19.6146 109.746 19.9594C109.975 20.1892 110.204 20.534 110.319 20.9937C110.433 21.3385 110.548 21.7981 110.548 22.2578H104.708C104.708 21.7981 104.708 21.4534 104.937 20.9937Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M127.335 29.2116V22.921C127.335 21.9715 127.219 21.1406 127.103 20.3098C126.871 19.479 126.639 18.8855 126.176 18.2921C125.712 17.6986 125.132 17.3426 124.321 16.9865C123.509 16.6304 122.582 16.5117 121.539 16.5117C120.38 16.5117 119.22 16.6304 118.293 16.7491C117.713 16.8678 117.134 16.9865 116.67 17.1052C116.206 17.2239 115.975 17.5799 115.975 18.0547V29.3303C115.975 30.2798 116.67 30.9919 117.597 30.9919C118.525 30.9919 119.22 30.2798 119.22 29.3303V19.479C119.452 19.479 119.8 19.3603 120.148 19.3603C120.611 19.3603 120.959 19.2416 121.423 19.2416C122.002 19.2416 122.466 19.3603 122.814 19.479C123.162 19.5977 123.509 19.835 123.741 20.1911C123.973 20.5472 124.089 20.9033 124.205 21.4967C124.321 21.9715 124.321 22.6836 124.321 23.3957V29.2116C124.321 30.1611 125.016 30.8732 125.944 30.8732C126.639 30.7545 127.335 30.0424 127.335 29.2116Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M137.498 30.9779C138.212 30.863 138.569 30.1735 138.45 29.4841C138.331 28.7946 137.617 28.335 136.904 28.4499C136.428 28.5648 135.953 28.5648 135.596 28.5648C134.764 28.5648 134.169 28.335 133.813 27.8753C133.456 27.4157 133.218 26.6113 133.218 25.5772V20.0616H136.904C137.617 20.0616 138.212 19.487 138.212 18.7976C138.212 18.1081 137.617 17.5336 136.904 17.5336H133.218V15.2354C133.218 14.3161 132.267 13.5118 131.316 13.6267C130.483 13.7416 130.008 14.4311 130.008 15.2354V25.6921C130.008 26.6113 130.127 27.4157 130.246 28.1052C130.365 28.7946 130.721 29.3691 131.078 29.9437C131.435 30.5182 132.029 30.863 132.742 31.0928C133.456 31.3226 134.288 31.4375 135.239 31.4375C135.834 31.3226 136.785 31.2077 137.498 30.9779Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M77.1445 5.45102C78.073 4.6393 79.1176 4.29142 80.1621 4.05949C80.0461 4.05949 79.93 4.05949 79.6979 4.17546C79.8139 4.17546 79.93 4.17546 80.0461 4.05949C80.7425 3.94353 81.3228 3.36373 81.4388 2.66797V2.55201C81.5549 1.62432 80.7425 0.812598 79.6979 0.928559C78.073 1.16048 76.332 1.85624 75.0553 3.01585C73.1983 4.6393 72.0376 7.07447 71.9216 9.50964V14.4959H70.5288C69.6003 14.4959 68.9039 15.1917 68.9039 16.1194C68.9039 17.0471 69.6003 17.7428 70.5288 17.7428H71.9216V22.8451C71.8055 24.4686 71.1091 25.8601 69.9485 26.9037C69.02 27.7155 67.9754 28.1793 66.8147 28.2953C66.1184 28.4112 65.538 28.991 65.422 29.6868V29.8027C65.3059 30.7304 66.2344 31.5422 67.1629 31.4262C67.279 31.4262 67.279 31.4262 67.3951 31.4262C69.02 31.1943 70.6449 30.4985 72.0376 29.3389C73.8947 27.7155 75.0553 25.3962 75.1714 22.8451V17.6269H76.5641C77.4927 17.6269 78.189 16.9311 78.189 16.0034C78.189 15.0758 77.4927 14.38 76.5641 14.38H75.1714V9.50964C75.2874 8.00215 75.9838 6.49467 77.1445 5.45102Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M96.5486 34.6721C96.1793 34.6721 95.81 34.5562 95.4407 34.5562C94.3328 34.5562 93.5943 34.788 93.1019 35.3677C92.6095 35.9473 92.3633 36.6429 92.3633 37.5704V45.2219C92.3633 45.5697 92.7326 45.9175 93.1019 45.9175C93.4712 45.9175 93.8405 45.5697 93.8405 45.2219V39.4253H96.3024C96.6717 39.4253 96.9179 39.1934 96.9179 38.8457C96.9179 38.4979 96.6717 38.266 96.3024 38.266H93.8405V37.5704C93.8405 36.9907 93.9635 36.527 94.2097 36.1792C94.4559 35.8314 94.9483 35.7155 95.5638 35.7155C95.6869 35.7155 96.0562 35.7155 96.3024 35.8314C96.6717 35.9473 96.9179 35.7155 97.041 35.3677C97.041 35.0199 96.9179 34.788 96.5486 34.6721Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M103.308 38.9589C102.986 38.6051 102.663 38.3692 102.34 38.1333C101.91 37.8975 101.48 37.8975 101.05 37.8975C100.62 37.8975 100.19 38.0154 99.7597 38.1333C99.3296 38.3692 99.007 38.6051 98.7919 38.9589C98.4693 39.3127 98.2542 39.7845 98.1467 40.2562C98.0392 40.728 97.9316 41.3177 97.9316 41.9074C97.9316 42.4971 98.0392 43.0867 98.1467 43.5585C98.2542 44.0302 98.5768 44.502 98.7919 44.8558C99.1145 45.2096 99.4371 45.4455 99.7597 45.6814C100.19 45.9173 100.62 45.9173 101.05 45.9173C101.48 45.9173 101.91 45.7993 102.34 45.6814C102.771 45.4455 103.093 45.2096 103.308 44.8558C103.631 44.502 103.846 44.0302 103.953 43.5585C104.061 43.0867 104.169 42.4971 104.169 41.9074C104.169 41.3177 104.061 40.728 103.953 40.2562C103.846 39.7845 103.631 39.3127 103.308 38.9589ZM102.448 44.0302C102.125 44.502 101.588 44.7379 101.05 44.7379C100.405 44.7379 99.9748 44.502 99.6522 44.0302C99.3296 43.5585 99.1145 42.8509 99.1145 41.9074C99.1145 41.0818 99.3296 40.3742 99.6522 39.7845C99.9748 39.3127 100.512 39.0768 101.05 39.0768C101.695 39.0768 102.125 39.3127 102.448 39.7845C102.771 40.2562 102.986 40.9639 102.986 41.9074C102.986 42.7329 102.771 43.4406 102.448 44.0302Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M110.041 38.0105C109.806 38.0105 109.453 37.8975 109.1 37.8975C108.513 37.8975 108.042 37.8975 107.572 38.0105C107.219 38.1236 106.984 38.1236 106.749 38.2366C106.514 38.3497 106.396 38.4627 106.396 38.6888V44.7934C106.396 45.1326 106.749 45.4717 107.102 45.4717C107.455 45.4717 107.807 45.1326 107.807 44.7934V39.254C107.925 39.254 108.042 39.254 108.277 39.141C108.513 39.141 108.748 39.0279 108.983 39.0279C109.218 39.0279 109.571 39.0279 109.923 39.141C110.276 39.141 110.511 39.0279 110.629 38.6888C110.511 38.3497 110.394 38.0105 110.041 38.0105Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M137.439 38.0105C137.204 38.0105 136.852 37.8975 136.499 37.8975C135.911 37.8975 135.441 37.8975 134.971 38.0105C134.618 38.1236 134.383 38.1236 134.148 38.2366C133.912 38.3497 133.795 38.4627 133.795 38.6888V44.7934C133.795 45.1326 134.148 45.4717 134.5 45.4717C134.853 45.4717 135.206 45.1326 135.206 44.7934V39.254C135.323 39.254 135.441 39.254 135.676 39.141C135.911 39.141 136.146 39.0279 136.381 39.0279C136.616 39.0279 136.969 39.0279 137.322 39.141C137.674 39.141 137.91 39.0279 138.027 38.6888C138.027 38.3497 137.792 38.0105 137.439 38.0105Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M114.695 37.8975C114.241 37.8975 113.901 38.0137 113.447 38.1299C112.994 38.2461 112.654 38.5948 112.427 38.8273C112.087 39.176 111.86 39.5247 111.746 40.1058C111.52 40.5707 111.52 41.1519 111.52 41.8492C111.52 42.4304 111.633 43.0115 111.746 43.4764C111.86 43.9414 112.087 44.4063 112.427 44.755C112.767 45.1037 113.107 45.3361 113.561 45.5686C114.014 45.801 114.581 45.9173 115.148 45.9173C115.829 45.9173 116.396 45.801 116.849 45.6848C117.076 45.5686 117.303 45.3361 117.303 44.9874C117.303 44.6387 116.963 44.4063 116.622 44.5225C116.282 44.6387 115.829 44.755 115.375 44.755C114.581 44.755 114.014 44.5225 113.561 44.1738C113.221 43.7089 112.994 43.1278 112.88 42.4304H117.53C117.643 42.4304 117.756 42.3142 117.756 42.1979C117.756 41.6168 117.643 39.9896 116.963 39.0598C116.396 38.1299 115.715 37.8975 114.695 37.8975ZM112.767 41.1519C112.767 40.9194 112.88 40.687 112.994 40.3383C113.107 40.1058 113.221 39.8734 113.334 39.6409C113.447 39.4084 113.674 39.2922 113.901 39.176C114.128 39.0598 114.354 38.9435 114.695 38.9435C115.262 38.9435 115.602 39.176 115.942 39.5247C116.282 39.8734 116.396 40.4545 116.396 41.0356H112.767V41.1519Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M129.396 37.8975C128.942 37.8975 128.602 38.0137 128.148 38.1299C127.695 38.2461 127.355 38.5948 127.128 38.8273C126.788 39.176 126.561 39.5247 126.448 40.1058C126.221 40.5707 126.221 41.1519 126.221 41.8492C126.221 42.4304 126.334 43.0115 126.448 43.4764C126.561 43.9414 126.788 44.4063 127.128 44.755C127.468 45.1037 127.808 45.3361 128.262 45.5686C128.715 45.801 129.282 45.9173 129.849 45.9173C130.53 45.9173 131.097 45.801 131.55 45.6848C131.777 45.5686 132.004 45.3361 132.004 44.9874C132.004 44.6387 131.664 44.4063 131.324 44.5225C130.983 44.6387 130.53 44.755 130.076 44.755C129.282 44.755 128.715 44.5225 128.262 44.1738C127.922 43.7089 127.695 43.1278 127.581 42.4304H132.231C132.344 42.4304 132.458 42.3142 132.458 42.1979C132.458 41.6168 132.344 39.9896 131.664 39.0598C131.097 38.1299 130.416 37.8975 129.396 37.8975ZM127.468 41.1519C127.468 40.9194 127.581 40.687 127.695 40.3383C127.808 40.1058 127.922 39.8734 128.035 39.6409C128.148 39.4084 128.375 39.2922 128.602 39.176C128.829 39.0598 129.056 38.9435 129.396 38.9435C129.963 38.9435 130.303 39.176 130.643 39.5247C130.983 39.8734 131.097 40.4545 131.097 41.0356H127.468V41.1519Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M124.713 37.8975C124.356 37.8975 124.118 38.1305 123.999 38.3636C123.88 38.7131 123.761 39.0627 123.761 39.4123C123.643 39.9949 123.405 40.4611 123.286 41.0437C123.048 41.6263 122.929 42.0924 122.691 42.5585C122.453 43.0247 122.335 43.4908 122.216 43.8403C122.097 43.4908 121.859 43.0247 121.74 42.5585C121.502 42.0924 121.383 41.5098 121.145 41.0437C120.908 40.4611 120.789 39.9949 120.67 39.4123C120.551 39.0627 120.432 38.7131 120.432 38.3636C120.313 38.1305 120.075 37.8975 119.838 37.8975H119.719C119.243 37.8975 119.005 38.247 119.124 38.7132C119.481 39.8784 119.838 40.9272 120.194 41.9759C120.67 43.0247 121.027 44.0734 121.383 44.8891C121.502 45.2387 121.859 45.4717 122.216 45.4717C122.572 45.4717 122.929 45.2387 123.048 44.8891C123.405 43.9569 123.88 43.0247 124.237 41.9759C124.594 40.9272 125.069 39.7619 125.307 38.7132C125.426 38.3636 125.069 37.8975 124.713 37.8975Z"
      fill="#282B31"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M55.2705 30.1229C57.3506 27.1451 58.5062 23.4801 58.5062 19.586C58.5062 19.2425 58.5062 19.0134 58.5062 18.6698C58.5062 18.4407 58.5062 18.2117 58.5062 18.0971C58.5062 17.9826 58.5062 17.8681 58.5062 17.7535C58.5062 17.639 58.5062 17.5245 58.5062 17.2954C58.5062 17.0664 58.5062 16.9518 58.3906 16.7228C58.3906 16.6082 58.3906 16.4937 58.3906 16.4937C58.3906 16.2646 58.2751 16.0356 58.2751 15.8065V15.692C58.2751 15.4629 58.1595 15.1193 58.0439 14.8903C55.9638 6.98763 48.799 1.14654 40.2475 0.91748H18.4064C8.35255 0.91748 0.263252 8.93465 0.14769 18.8989V19.357C0.0321289 29.6648 8.23699 38.0255 18.5219 38.2546L29.9625 38.3691C34.0072 38.3691 37.8207 40.0871 40.4786 43.0649C40.5942 43.1794 40.7097 43.294 40.8253 43.523C41.5187 44.5538 42.6743 45.1265 44.061 45.0119C45.1011 44.8974 45.91 44.3248 46.4878 43.523L52.2659 34.7041L55.2705 30.1229Z"
      fill="url(#paint0_linear_663_120980)"
      fillRule="evenodd"
    />
    <path
      d="M38.8231 11.2801C36.4026 11.2801 34.0973 12.3158 32.4837 14.0419L29.4868 17.4941L26.49 14.0419V13.9268C24.8763 12.2007 22.5711 11.165 20.1506 11.165C15.3095 11.165 11.5059 15.0776 11.5059 19.7956C11.5059 24.6287 15.4248 28.5413 20.1506 28.5413C22.5711 28.5413 24.8763 27.5056 26.49 25.7795L29.6021 22.2122C32.8295 25.8946 32.8295 25.8946 32.9447 26.0096C34.5584 27.6207 36.7484 28.5413 39.0536 28.5413C43.8947 28.5413 47.8136 24.6287 47.8136 19.7956C47.5831 15.1926 43.6641 11.2801 38.8231 11.2801ZM24.0695 23.8232C23.0321 24.974 21.5337 25.5493 20.0353 25.5493C16.9232 25.5493 14.5027 23.0177 14.5027 20.0258C14.5027 16.9188 17.0385 14.5022 20.0353 14.5022C21.5337 14.5022 23.0321 15.0776 24.0695 16.2283L27.4121 20.0258L24.0695 23.8232ZM38.8231 25.5493C37.3247 25.5493 35.9415 24.974 34.9042 23.9383C34.6737 23.7081 32.9447 21.6368 31.4463 19.9107L34.7889 16.1132C35.8263 14.9625 37.3247 14.3871 38.8231 14.3871C41.9352 14.3871 44.3557 16.9188 44.3557 19.9107C44.3557 23.0177 41.9352 25.5493 38.8231 25.5493Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M34.026 24.8589C34.6068 24.2835 34.6068 23.3629 34.1421 22.6725C33.4452 21.867 32.5159 20.8313 31.7028 19.9107L35.0714 16.1132C36.1168 14.9625 37.6269 14.3871 39.1369 14.3871C40.2985 14.3871 41.3439 14.7323 42.157 15.3077C42.0409 15.1926 41.9247 15.1926 41.8086 15.0776C41.9247 15.0776 42.0409 15.1926 42.0409 15.1926C42.6217 15.5379 43.4348 15.5379 43.8994 14.9625L44.0156 14.8474C44.7125 14.157 44.5963 13.1213 43.7832 12.5459C42.3893 11.6253 40.7631 11.165 39.0208 11.165C36.5815 11.165 34.2583 12.2007 32.6321 13.9268L29.612 17.3791L26.5919 13.9268L27.2888 14.7323C26.7081 14.0419 25.6626 14.0419 24.9657 14.6173C24.3849 15.1926 24.3849 15.9982 24.8495 16.6886L27.5212 19.7956L24.1526 23.5931C23.1072 24.7438 21.5971 25.3192 20.0871 25.3192C18.9255 25.3192 17.8801 24.974 16.9508 24.3986C16.37 24.0534 15.5569 24.0534 14.9761 24.6287L14.86 24.7438C14.163 25.4343 14.2792 26.4699 15.0923 27.0453L15.2084 27.1604C16.6023 28.081 18.2285 28.5413 19.9709 28.5413C22.4102 28.5413 24.7334 27.5056 26.3596 25.7795L29.4958 22.2122C30.5413 23.3629 31.2382 24.1684 31.7028 24.7438C32.2836 25.5493 33.329 25.5493 34.026 24.8589Z"
      fill="#269276"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M55.2705 30.1229C57.3506 27.1451 58.5062 23.4801 58.5062 19.586C58.5062 19.2425 58.5062 19.0134 58.5062 18.6698C58.5062 18.4407 58.5062 18.2117 58.5062 18.0971C58.5062 17.9826 58.5062 17.8681 58.5062 17.7535C58.5062 17.639 58.5062 17.5245 58.5062 17.2954C58.5062 17.0664 58.5062 16.9518 58.3906 16.7228C58.3906 16.6082 58.3906 16.4937 58.3906 16.4937C58.3906 16.2646 58.2751 16.0356 58.2751 15.8065V15.692C58.2751 15.4629 58.1595 15.1193 58.0439 14.8903C55.9638 6.98763 48.799 1.14654 40.2475 0.91748H18.4064C8.35255 0.91748 0.263252 8.93465 0.14769 18.8989V19.357C0.0321289 29.6648 8.23699 38.0255 18.5219 38.2546L29.9625 38.3691C34.0072 38.3691 37.8207 40.0871 40.4786 43.0649C40.5942 43.1794 40.7097 43.294 40.8253 43.523C41.5187 44.5538 42.6743 45.1265 44.061 45.0119C45.1011 44.8974 45.91 44.3248 46.4878 43.523L52.2659 34.7041L55.2705 30.1229Z"
      fill="url(#paint1_linear_663_120980)"
      fillRule="evenodd"
    />
    <path
      d="M38.8231 11.2801C36.4026 11.2801 34.0973 12.3158 32.4837 14.0419L29.4868 17.4941L26.49 14.0419V13.9268C24.8763 12.2007 22.5711 11.165 20.1506 11.165C15.3095 11.165 11.5059 15.0776 11.5059 19.7956C11.5059 24.6287 15.4248 28.5413 20.1506 28.5413C22.5711 28.5413 24.8763 27.5056 26.49 25.7795L29.6021 22.2122C32.8295 25.8946 32.8295 25.8946 32.9447 26.0096C34.5584 27.6207 36.7484 28.5413 39.0536 28.5413C43.8947 28.5413 47.8136 24.6287 47.8136 19.7956C47.5831 15.1926 43.6641 11.2801 38.8231 11.2801ZM24.0695 23.8232C23.0321 24.974 21.5337 25.5493 20.0353 25.5493C16.9232 25.5493 14.5027 23.0177 14.5027 20.0258C14.5027 16.9188 17.0385 14.5022 20.0353 14.5022C21.5337 14.5022 23.0321 15.0776 24.0695 16.2283L27.4121 20.0258L24.0695 23.8232ZM38.8231 25.5493C37.3247 25.5493 35.9415 24.974 34.9042 23.9383C34.6737 23.7081 32.9447 21.6368 31.4463 19.9107L34.7889 16.1132C35.8263 14.9625 37.3247 14.3871 38.8231 14.3871C41.9352 14.3871 44.3557 16.9188 44.3557 19.9107C44.3557 23.0177 41.9352 25.5493 38.8231 25.5493Z"
      fill="white"
    />
    <path
      clipRule="evenodd"
      d="M34.026 24.8589C34.6068 24.2835 34.6068 23.3629 34.1421 22.6725C33.4452 21.867 32.5159 20.8313 31.7028 19.9107L35.0714 16.1132C36.1168 14.9625 37.6269 14.3871 39.1369 14.3871C40.2985 14.3871 41.3439 14.7323 42.157 15.3077C42.0409 15.1926 41.9247 15.1926 41.8086 15.0776C41.9247 15.0776 42.0409 15.1926 42.0409 15.1926C42.6217 15.5379 43.4348 15.5379 43.8994 14.9625L44.0156 14.8474C44.7125 14.157 44.5963 13.1213 43.7832 12.5459C42.3893 11.6253 40.7631 11.165 39.0208 11.165C36.5815 11.165 34.2583 12.2007 32.6321 13.9268L29.612 17.3791L26.5919 13.9268L27.2888 14.7323C26.7081 14.0419 25.6626 14.0419 24.9657 14.6173C24.3849 15.1926 24.3849 15.9982 24.8495 16.6886L27.5212 19.7956L24.1526 23.5931C23.1072 24.7438 21.5971 25.3192 20.0871 25.3192C18.9255 25.3192 17.8801 24.974 16.9508 24.3986C16.37 24.0534 15.5569 24.0534 14.9761 24.6287L14.86 24.7438C14.163 25.4343 14.2792 26.4699 15.0923 27.0453L15.2084 27.1604C16.6023 28.081 18.2285 28.5413 19.9709 28.5413C22.4102 28.5413 24.7334 27.5056 26.3596 25.7795L29.4958 22.2122C30.5413 23.3629 31.2382 24.1684 31.7028 24.7438C32.2836 25.5493 33.329 25.5493 34.026 24.8589Z"
      fill="#269276"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_663_120980"
        x1="29.3128"
        x2="29.3128"
        y1="1.52587"
        y2="45.8419"
      >
        <stop offset="0.000500308" stopColor="#77C794" />
        <stop offset="1" stopColor="#5BB68C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_663_120980"
        x1="29.3263"
        x2="29.3263"
        y1="0.91748"
        y2="45.0264"
      >
        <stop stopColor="#77DF9D" />
        <stop offset="1" stopColor="#5FC090" />
      </linearGradient>
    </defs>
  </svg>
);
