export type SignUpMethod =
  | "weekly-coaching-sign-up"
  | "weekly-coaching-log-in"
  | "promo";
export type WorkFlow = "promo" | "organic";

export const isSignUpMethod = (v: unknown): v is SignUpMethod =>
  typeof v === "string" &&
  ["weekly-coaching-sign-up", "weekly-coaching-log-in", "promo"].indexOf(v) >=
    0;
