import FluentForeverOnWhiteImage from "assets/images/ff_on_white.svg";
import * as React from "react";
import { useEffect } from "react";
import { ScrollScene } from "scrollscene";
import "../styles/FirstSectionPromo.scss";
import { ICouponDisplayStrings } from "../../../components/presentational/views/Promo/LandingCouponPage/types/landingCouponPageTypes";

export interface FirstSectionPromoProps {
  couponDetails?: ICouponDisplayStrings;
  shouldRenderNewUI?: boolean;
}

export const FirstSectionPromo = ({
  couponDetails,
  shouldRenderNewUI = false,
}: FirstSectionPromoProps): React.ReactElement => {
  // INTENDED: null is needed for useRef
  // eslint-disable-next-line no-null/no-null
  const triggerRef = React.useRef(null);
  // eslint-disable-next-line no-null/no-null
  const positionRef = React.useRef(null);

  useEffect(() => {
    const { current: triggerElement } = triggerRef;
    const { current: positionElement } = positionRef;

    if (!triggerElement && !positionElement) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: FIX
    const scrollScene = new ScrollScene({
      duration: "100%",
      toggle: { className: "in-view", element: triggerElement },
      triggerElement,
      triggerHook: 0.5,
    });

    return () => {
      scrollScene.destroy();
    };
  });

  let intro = "The";
  if (shouldRenderNewUI && couponDetails?.intro) {
    intro = `${couponDetails.intro} the`;
  }

  return (
    <div className="first-section" ref={triggerRef}>
      <div className="first-section-position" ref={positionRef}>
        <div className="video-container">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="video"
            frameBorder="0"
            src="https://www.youtube-nocookie.com/embed/96azqpKFLQg"
          />
        </div>
        <div className="first-section-text">
          <div className="first-section-text-title">
            <span>Meet</span>
            <img src={FluentForeverOnWhiteImage} />
          </div>
          <span className="first-section-text-description">
            {intro} most crowdfunded app in history. It’ll teach you to think in
            a new language, rather than just picking up a few words and phrases.
            No aimless memorization or boring translation exercises – just solid
            science on the best ways to jam new sounds, words, and sentences
            into your brain and keep them there long term.
          </span>
        </div>
      </div>
    </div>
  );
};
