import { Args, Models, REST } from "@fluent-forever/types";
import { IHttpRequest } from "application/utils";
import { ApiConfig as CONFIG_API } from "config/config";

/** Validate Coupon/Backer Code */
export const validate = (
  request: IHttpRequest,
  body: Args.IValidateCouponCodeArg
): Promise<Models.ICouponCodeInfo> => {
  return request
    .post(CONFIG_API.url + REST.PATH.COUPON_CODE_VALIDATE, body)
    .then((rst) => rst.data);
};

/** Submit Coupon Code */
export const redeem = (
  request: IHttpRequest,
  body: Args.IRedeemCouponCodeArg
): Promise<Models.ICouponCodeRedemptionResult> => {
  return request
    .post(CONFIG_API.url + REST.PATH.COUPON_CODE_REDEEM, body)
    .then((rst) => rst.data);
};
