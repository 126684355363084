import * as React from "react";
import { SVGProps } from "react";

export const ReloadIcon = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg
    fill={props.fill ?? "#0050C7"}
    height="25"
    viewBox="0 0 24 25"
    width="24"
    {...props}
  >
    <path
      d="M12 5.5835V1.5835L17 6.5835L12 11.5835V7.5835C8.69 7.5835 6 10.2735 6 13.5835C6 16.8935 8.69 19.5835 12 19.5835C15.31 19.5835 18 16.8935 18 13.5835H20C20 18.0035 16.42 21.5835 12 21.5835C7.58 21.5835 4 18.0035 4 13.5835C4 9.1635 7.58 5.5835 12 5.5835Z"
      fill="inherit"
    />
  </svg>
);
