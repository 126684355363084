import * as React from "react";
import { SVGProps } from "react";

export const RibbonSvg = (
  props: SVGProps<SVGSVGElement>
): React.ReactElement => (
  <svg fill="none" height="72" viewBox="0 0 52 72" width="52" {...props}>
    <path
      clipRule="evenodd"
      d="M43.1173 66.1285C44.1279 67.7801 46.6614 67.1751 46.8168 65.2451L51.413 8.14707C51.5018 7.04311 50.6766 6.07713 49.5723 5.99245L7.35534 2.755C6.25695 2.67077 5.29726 3.49061 5.20887 4.58867L0.625561 61.5268C0.4702 63.4569 2.8743 64.4593 4.13612 62.9907L23.9914 39.8804C24.8756 38.8512 26.5061 38.9825 27.2143 40.1398L43.1173 66.1285Z"
      fill="#04172A"
      fillRule="evenodd"
    />
    <path
      d="M5.38281 1.45606C5.38281 0.351487 6.27824 -0.543945 7.38281 -0.543945H49.7235C50.828 -0.543945 51.7235 0.351485 51.7235 1.45605V62.5332C51.7235 64.4695 49.2467 65.2758 48.1068 63.7106L30.1698 39.0817C29.3711 37.9849 27.7352 37.9849 26.9364 39.0817L8.9995 63.7106C7.85959 65.2758 5.38281 64.4695 5.38281 62.5332V1.45606Z"
      fill="#74E09A"
    />
  </svg>
);
