/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ISubscriptionCoupon, LanguagePairCode } from "@fluent-forever/types";
import { IUserProfile } from "api/Auth0";
import {
  LOAD_USER_INFO_FAIL,
  LOAD_USER_INFO_SUCCESS,
  REGISTER_SUBSCRIPTION_COUPON,
  REGISTER_WEEKLY_COACHING_LANGUAGE,
  REGISTER_WEEKLY_COACHING_PLAN_ID,
  USER_EMAIL_CONFIRMATION_LOADING,
  USER_EMAIL_CONFIRMATION_RESULT,
  USER_INFO_LOADING,
  USER_PROFILE_LOADED,
} from "./actions";

export const userInfoLoading = () => ({ type: USER_INFO_LOADING });
export const userInfoSuccess = (user: {
  id: string;
  firstName: string;
  displayName: string;
  email: string;
  activePlanId?: string;
  subscriptions?: string[];
  trialExpiryDate?: Date;
  trialDaysRemaining?: number;
}) => ({ payload: user, type: LOAD_USER_INFO_SUCCESS });
export const userProfileLoaded = (userProfile: IUserProfile) => ({
  payload: userProfile,
  type: USER_PROFILE_LOADED,
});
export const userInfoFail = () => ({ type: LOAD_USER_INFO_FAIL });

export const userEmailConfirmationLoading = () => ({
  type: USER_EMAIL_CONFIRMATION_LOADING,
});
export const userEmailConfirmation = (isPendingEmail: boolean) => ({
  payload: isPendingEmail,
  type: USER_EMAIL_CONFIRMATION_RESULT,
});

export const registerSubscriptionCoupon = (coupon: ISubscriptionCoupon) => ({
  payload: coupon,
  type: REGISTER_SUBSCRIPTION_COUPON,
});

export const registerWeeklyCoachingLanguage = (
  langPairCode: LanguagePairCode
) => ({
  payload: langPairCode,
  type: REGISTER_WEEKLY_COACHING_LANGUAGE,
});

export const registerWeeklyCoachingPlanId = (planId: string) => ({
  payload: planId,
  type: REGISTER_WEEKLY_COACHING_PLAN_ID,
});
